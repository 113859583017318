import React, { ComponentType, forwardRef, useState } from 'react';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import { InputTypes } from '@lib/components/Input/enums';
import { TestIds } from '@lib/hocs/enums';
import styles from './withFloatingLabel.module.scss';

export type FloatingLabelProps = {
  setIsFocused: (isFocused: boolean) => void;
  setIsComponentFocused: (isFocused: boolean) => void;
};

type Props = {
  disabled?: boolean;
  hasError?: boolean;
  hasErrorAsTooltip?: boolean;
  label?: string;
  leftIcon?: string;
  name?: string;
  onClear?: () => void;
  rightIcon?: string;
  type?: string;
  value?: unknown;
};

type WrappedComponentProps<T> = T & FloatingLabelProps;

const withFloatingLabel = <T extends object>(
  WrappedComponent: ComponentType<WrappedComponentProps<T>>,
) =>
  forwardRef<unknown, Props & T>(({ label, ...rest }, ref) => {
    const {
      disabled,
      hasError,
      leftIcon,
      rightIcon,
      type,
      value,
      onClear,
      hasErrorAsTooltip,
    } = rest;
    const [isFocused, setIsFocused] = useState(false);
    const [isComponentFocused, setIsComponentFocused] = useState(false);
    const isValueEmpty = Array.isArray(value)
      ? _filter(value, _isEmpty).length === value.length
      : !value;
    const whenValueState =
      !isValueEmpty ||
      isFocused ||
      WrappedComponent.displayName === 'TextEditor';
    const whenTextareaState = type === InputTypes.textarea;
    const whenInputNumberState = type === InputTypes.number;
    const whenRightIconState =
      !!rightIcon ||
      (hasErrorAsTooltip && hasError) ||
      WrappedComponent.displayName === 'Select' ||
      [
        InputTypes.date,
        InputTypes.dateRange,
        InputTypes.time,
        InputTypes.timeRange,
        InputTypes.dateTime,
        InputTypes.password,
      ].some((item) => item === type);
    const whenClearIconState = !!onClear;
    const whenLeftIconState = !!leftIcon || type === InputTypes.tel;
    return (
      <div
        data-testid={TestIds.floatingLabelRoot}
        onMouseEnter={disabled ? undefined : () => setIsFocused(true)}
        onMouseLeave={
          disabled || isComponentFocused ? undefined : () => setIsFocused(false)
        }
        onTouchStart={disabled ? undefined : () => setIsFocused(true)}
        className={styles.floatingLabelRoot}
      >
        <WrappedComponent
          {...(rest as T)}
          ref={ref}
          setIsFocused={setIsFocused}
          setIsComponentFocused={setIsComponentFocused}
        />
        {label && (
          <div
            data-testid={TestIds.floatingLabelBg}
            className={classNames(styles.fieldLabelBg, {
              [styles.fieldLabelBgWhenValue]: whenValueState,
              [styles.fieldLabelBgWhenLeftIcon]: whenLeftIconState,
              [styles.fieldLabelBgWhenRightIcon]:
                whenRightIconState || disabled,
              [styles.fieldLabelBgWhenInputNumber]: whenInputNumberState,
              [styles.fieldLabelBgWhenClearNumber]: whenClearIconState,
            })}
          />
        )}
        {label && (
          <div
            data-testid={TestIds.floatingLabel}
            className={classNames(styles.fieldLabel, {
              [styles.fieldLabelWhenLeftIcon]: whenLeftIconState,
              [styles.fieldLabelWhenValue]: whenValueState,
              [styles.fieldLabelWhenDisabled]: disabled,
              [styles.fieldLabelWhenError]: hasError,
              [styles.fieldLabelWhenText]: !whenTextareaState,
              [styles.fieldLabelWhenTextarea]: whenTextareaState,
            })}
          >
            {label}
          </div>
        )}
      </div>
    );
  });

export default withFloatingLabel;
