import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserSetupMutation } from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import Form from '@lib/components/ReactHookForm/FormContainer';
import Typography from '@lib/components/Typography/Typography';
import { Values, FieldItem } from '@lib/interfaces/form';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { getSetupPasswordFormFields } from 'utils/authUtils';
import styles from '@lib/layouts/AuthLayout/AuthComponents.module.scss';
import formStyles from '@lib/assets/styles/Form.module.scss';
import useWhoamiQueryHook from 'utils/fetch/useWhoamiQueryHook';

function SetupPasswordForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentUserEmail, currentUserUsername } = useWhoamiQueryHook({
    fetchPolicy: 'cache-first',
  });

  const defaultValues = {
    email: currentUserEmail || currentUserUsername,
    password: '',
    passwordConfirmation: '',
  };

  const [passwordUpdateMutation, passwordUpdateResult] = useUserSetupMutation({
    onCompleted: () => {
      navigate(0);
    },
    onError: (error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
    },
  });
  const { error } = passwordUpdateResult;

  const fields: FieldItem[] = getSetupPasswordFormFields({ t });

  const onSubmit = (values: Values) => {
    const password = values.password as string;
    const passwordConfirmation = values.passwordConfirmation as string;
    // let codeOfConductAccepted = false;
    // if (_get(values, ['codeOfConductAccepted', 0, 'value'])) {
    //   codeOfConductAccepted = true;
    // }
    passwordUpdateMutation({
      variables: {
        password,
        passwordConfirmation,
        codeOfConductAccepted: false,
      },
    });
  };

  return (
    <div className={styles.resetPasswordForm}>
      <Typography variant="h2" className={styles.title}>
        {t('finish-your-registration')}
      </Typography>
      <Form
        disableAwayConfirmation
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        fields={fields}
        formClassName={formStyles.form}
        graphQLErrors={error?.graphQLErrors}
        actionsComponent={Button}
        actionsComponentProps={{
          type: 'submit',
          buttonType: ButtonTypes.primaryFilled,
          buttonText: t('confirm'),
          leftIcon: 'check',
          fullWidth: true,
        }}
      />
    </div>
  );
}

export default memo(SetupPasswordForm);
