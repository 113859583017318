import React from 'react';
import _memoize from 'lodash/memoize';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { ValidationError } from 'yup';
import { FieldChangeCallBack, FieldItem, Value } from '@lib/interfaces/form';
import { Steps } from '@lib/enums/form';
import FieldArray from '@lib/components/ReactHookForm/FieldArray';
import FormItem from '@lib/components/ReactHookForm/FormItem';
import classNames from 'classnames';
import { SourceAppendValues } from '@lib/components/ReactHookForm/types';
import styles from './Form.module.scss';

type FormData = {
  fieldsArray: React.ReactNode[];
  schemaObj: {
    [key: string]: Yup.AnySchema<unknown, unknown, unknown, Yup.Flags>;
  };
  fieldChangeCallBacks: FieldChangeCallBack[];
  uniqueSubFieldNames?: string[];
};

function isArrayFieldOptionalAndEmpty(value: Value) {
  return Array.isArray(value) || value === null || value === '';
}

// Yup unique method checks array filed values. Empty values are ignored as optional.
Yup.addMethod(Yup.array, 'unique', function unique(field, message) {
  return this.test('unique', message, function (array = []) {
    const { path, createError } = this;
    const seenValues = new Map();
    const errors: ValidationError[] = [];

    array.forEach((item, index) => {
      const value = item?.[field]?.toLowerCase();

      if (!value || isArrayFieldOptionalAndEmpty(item[field])) {
        return;
      }

      if (seenValues.has(value)) {
        errors.push(
          createError({
            path: `${path}[${index}].${field}`,
            message,
          }),
        );
        errors.push(
          createError({
            path: `${path}[${seenValues.get(value)}].${field}`,
            message,
          }),
        );
      } else {
        seenValues.set(value, index);
      }
    });

    return errors.length > 0 ? new Yup.ValidationError(errors) : true;
  });
});

type GetFieldArgs = {
  field: FieldItem;
  formId?: string;
  formItemsClassName?: string;
  isVisible?: boolean;
  sourceAppendValues?: SourceAppendValues;
  stepIndex?: Steps;
  t: TFunction<'translation', undefined>;
};

function processField(args: GetFieldArgs) {
  const {
    field,
    formId,
    formItemsClassName,
    isVisible = true,
    sourceAppendValues,
    stepIndex,
    t,
  } = args;
  const formData: FormData = {
    fieldsArray: [],
    schemaObj: {},
    fieldChangeCallBacks: [],
  };

  const {
    fieldComponents,
    isDraggable,
    name,
    subFieldEmptyStateText,
    subFieldEmptyStateTitle,
    subFieldTitle,
    subFields,
    subFieldsEntity,
    subFieldsMaxLength,
    subFieldsMinLength,
    validation,
    ...restFieldProps
  } = field;

  if (subFields && subFields.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const subFormData = processSubFields({
      formId,
      formItemsClassName,
      isVisible,
      sourceAppendValues,
      stepIndex,
      subFields,
      t,
    });
    formData.fieldsArray.push(
      <FieldArray
        key={name}
        parentName={name}
        {...restFieldProps}
        emptyStateText={subFieldEmptyStateText}
        emptyStateTitle={subFieldEmptyStateTitle}
        entity={subFieldsEntity}
        fieldComponents={fieldComponents}
        formId={formId}
        formItemsClassName={formItemsClassName}
        isDraggable={isDraggable}
        isVisible={isVisible}
        sourceAppendValues={sourceAppendValues}
        subFields={subFields}
        subFieldsMaxLength={subFieldsMaxLength}
        subFieldsMinLength={subFieldsMinLength}
        title={subFieldTitle}
      />,
    );
    let fieldValidation = Yup.array().of(
      Yup.object().shape(subFormData.schemaObj),
    );
    // TODO: finish it
    // if (subFieldsMinLength) {
    //   fieldValidation = fieldValidation.min(subFieldsMinLength, t('min-length-error'));
    // }
    // if (subFieldsMaxLength) {
    //   fieldValidation = fieldValidation.max(subFieldsMaxLength, t('max-length-error'));
    // }
    if (subFormData.uniqueSubFieldNames?.length) {
      subFormData.uniqueSubFieldNames.forEach((uniqueSubFieldName) => {
        // @ts-ignore
        fieldValidation = fieldValidation.unique(
          uniqueSubFieldName,
          t('unique-field-error'),
        );
      });
    }
    formData.schemaObj[name] = fieldValidation;
    formData.fieldChangeCallBacks.push(...subFormData.fieldChangeCallBacks);
  } else {
    formData.fieldsArray.push(
      <FormItem
        {...restFieldProps}
        name={name}
        key={name}
        formId={formId}
        validation={validation}
        isVisible={isVisible}
      />,
    );
    if (validation) {
      formData.schemaObj[name] = validation;
    }
    if (restFieldProps.fieldChangeCallBack) {
      formData.fieldChangeCallBacks.push(restFieldProps.fieldChangeCallBack);
    }
  }

  return formData;
}

type SubFields = {
  formId?: string;
  formItemsClassName?: string;
  isVisible?: boolean;
  sourceAppendValues?: SourceAppendValues;
  stepIndex?: Steps;
  subFields: FieldItem[];
  t: TFunction<'translation', undefined>;
};

function processSubFields(args: SubFields) {
  const {
    formId,
    formItemsClassName,
    isVisible,
    sourceAppendValues,
    stepIndex,
    subFields,
    t,
  } = args;
  const formData: FormData = {
    fieldsArray: [],
    schemaObj: {},
    fieldChangeCallBacks: [],
    uniqueSubFieldNames: [],
  };
  subFields.forEach((field) => {
    const { fieldsArray, schemaObj, fieldChangeCallBacks } = processField({
      field,
      formId,
      formItemsClassName,
      isVisible,
      sourceAppendValues,
      stepIndex,
      t,
    });
    formData.fieldsArray.push(...fieldsArray);
    formData.schemaObj = { ...formData.schemaObj, ...schemaObj };
    formData.fieldChangeCallBacks.push(...fieldChangeCallBacks);
    if (field.isUnique && formData.uniqueSubFieldNames) {
      formData.uniqueSubFieldNames.push(field.name);
    }
  });

  return formData;
}

type GetFieldsArgs = {
  fields: FieldItem[];
  formId?: string;
  formItemsClassName?: string;
  stepIndex?: Steps;
  t: TFunction<'translation', undefined>;
  sourceAppendValues?: SourceAppendValues;
};

function getFormData(args: GetFieldsArgs) {
  const {
    fields,
    formId,
    formItemsClassName,
    stepIndex,
    sourceAppendValues,
    t,
  } = args;
  const formData: FormData = {
    fieldsArray: [],
    schemaObj: {},
    fieldChangeCallBacks: [],
  };

  fields.forEach((field) => {
    const {
      name,
      groupFields,
      element: Element,
      componentProps,
      step = Steps.step1,
    } = field;
    const isVisible = stepIndex === step;
    const isGroupField = groupFields?.length;
    if (isGroupField) {
      // Render field group
      const group: React.ReactNode[] = [];
      groupFields.forEach((groupField) => {
        const { fieldsArray, schemaObj, fieldChangeCallBacks } = processField({
          field: groupField,
          formId,
          formItemsClassName,
          isVisible,
          sourceAppendValues,
          stepIndex,
          t,
        });
        group.push(...fieldsArray);
        formData.schemaObj = { ...formData.schemaObj, ...schemaObj };
        formData.fieldChangeCallBacks.push(...fieldChangeCallBacks);
      });
      if (Element && group.length) {
        formData.fieldsArray.push(
          <div
            className={classNames(styles.formItemColumn, styles.formItemGroup)}
            key={name}
            style={isVisible ? undefined : { display: 'none' }}
          >
            <Element {...componentProps}>
              <div className={styles.formItems}>{group}</div>
            </Element>
          </div>,
        );
      }
    } else {
      const { fieldsArray, schemaObj, fieldChangeCallBacks } = processField({
        field,
        formId,
        formItemsClassName,
        sourceAppendValues,
        stepIndex,
        t,
        isVisible,
      });
      formData.fieldsArray.push(...fieldsArray);
      formData.schemaObj = { ...formData.schemaObj, ...schemaObj };
      formData.fieldChangeCallBacks.push(...fieldChangeCallBacks);
    }
  });

  return formData;
}

export default _memoize(getFormData);
