import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  FieldError,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import _get from 'lodash/get';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { FieldComponents, FieldItem, Values } from '@lib/interfaces/form';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import Typography from '@lib/components/Typography/Typography';
import StrictModeDroppable from '@lib/components/StrictModeDroppable/StrictModeDroppable';
import usePrevious from '@lib/hooks/usePrevious';
import _isEqual from 'lodash/isEqual';
import {
  FieldArrayItemRenderAddMore,
  FieldArrayItemRenderEmptyState,
  FieldArrayItemType,
  SourceAppendValues,
} from '@lib/components/ReactHookForm/types';
import ControllerErrorRender from '@lib/components/ReactHookForm/ControllerErrorRender';
import findNewElementIndex from './findNewElementIndex';
import { useFieldArrayContext } from './FormProvider';
// eslint-disable-next-line import/no-cycle
import FieldArrayItem from './FieldArrayItem'; // TODO: fix it
import styles from './Form.module.scss';

interface Props {
  emptyStateText?: string;
  emptyStateTitle?: string;
  entity?: string;
  fieldComponents?: FieldComponents;
  formId?: string;
  formItemsClassName?: string;
  hideErrors?: boolean;
  isDraggable?: boolean;
  isVisible?: boolean;
  isVisibleFn?: (values: Values) => boolean;
  parentName: string;
  sourceAppendValues?: SourceAppendValues;
  subFields: FieldItem[];
  subFieldsMaxLength?: number;
  subFieldsMinLength?: number;
  title?: string;
}

interface LoopComponentProps {
  fieldItem: FieldArrayItemType;
  groupIndex?: number;
  index: number;
}

interface RenderItemProps {
  groupIndex?: number;
  id?: string;
  index: number;
  subProvided?: DraggableProvided;
}

function FieldArray(props: Props) {
  const {
    emptyStateText,
    emptyStateTitle,
    entity,
    fieldComponents,
    formId,
    formItemsClassName,
    hideErrors,
    isDraggable,
    isVisible = true,
    isVisibleFn,
    parentName,
    sourceAppendValues,
    subFields,
    subFieldsMinLength,
    subFieldsMaxLength,
    title,
  } = props;
  const { t } = useTranslation();
  const [focusedItemIndex, setFocusedItemIndex] = useState<number | null>(null);
  const { fields, append, remove, move, insert, update, replace } =
    useFieldArray({ name: parentName });
  const prevFields = usePrevious(fields);
  const watch = useWatch({ disabled: isVisibleFn === undefined });
  const { formState } = useFormContext();
  const error = _get(formState, ['errors', parentName]) as
    | FieldError
    | undefined;
  const sourceAppendValue = _get(sourceAppendValues, parentName);

  const FieldArrayAddItemComponent = _get(
    fieldComponents,
    'FieldArrayAddItemComponent',
  );
  const FieldArrayEmptyStateComponent = _get(
    fieldComponents,
    'FieldArrayEmptyStateComponent',
  );
  const FieldArrayLoopComponent = _get(
    fieldComponents,
    'FieldArrayLoopComponent',
  );

  const { updateFieldArrayMethods, removeFieldArrayMethods } =
    useFieldArrayContext();

  const renderItem = ({
    index,
    groupIndex,
    subProvided,
    id,
  }: RenderItemProps) => (
    <FieldArrayItem
      append={append}
      dragHandleProps={subProvided?.dragHandleProps}
      fieldComponents={fieldComponents}
      formId={formId}
      formItemsClassName={formItemsClassName}
      groupIndex={groupIndex}
      id={id}
      index={index}
      isDraggable={isDraggable}
      isFocused={focusedItemIndex === index}
      onClick={() => setFocusedItemIndex(index)}
      parentName={parentName}
      remove={remove}
      sourceAppendValues={sourceAppendValues}
      subFields={subFields}
      subFieldsMaxLength={subFieldsMaxLength}
      subFieldsMinLength={subFieldsMinLength}
      t={t}
      title={title}
    />
  );

  const renderAddMore: FieldArrayItemRenderAddMore = (
    appendValue,
    appendCallback,
  ) => {
    let isAddMoreVisible = fields.length > 0;
    if (subFieldsMaxLength)
      isAddMoreVisible = fields.length < subFieldsMaxLength;
    if (FieldArrayAddItemComponent === null) return null;
    if (FieldArrayAddItemComponent) {
      return (
        <FieldArrayAddItemComponent
          append={append}
          appendCallback={appendCallback}
          appendValue={appendValue}
          isAddMoreVisible={isAddMoreVisible}
          parentName={parentName}
        />
      );
    }
    if (!isAddMoreVisible) return null;
    return (
      <div className={styles.subFieldAddMoreBtn}>
        <Button
          buttonText={t(`${entity}-add-more`)}
          buttonType={ButtonTypes.primaryOutlined}
          disabled={!isAddMoreVisible}
          fullWidth
          leftIcon="add"
          onClick={() => {
            append(appendValue);
            if (appendCallback) appendCallback();
          }}
        />
      </div>
    );
  };

  const renderEmptyState: FieldArrayItemRenderEmptyState = (
    appendValue,
    snapshot,
    visible,
  ) => {
    const isEmptyStateVisible =
      emptyStateTitle || emptyStateText || fields.length === 0 || visible;
    if (!isEmptyStateVisible) return null;
    if (FieldArrayEmptyStateComponent === null) return null;
    if (FieldArrayEmptyStateComponent) {
      if (fields.length > 0) return null;
      return (
        <FieldArrayEmptyStateComponent
          append={append}
          appendValue={appendValue || {}}
          onClick={() => append(appendValue || {})}
          parentName={parentName}
          isUsingPlaceholder={snapshot?.isUsingPlaceholder}
          isAddMoreVisible
        />
      );
    }
    return (
      <div className={styles.formItemEmptyState}>
        {emptyStateTitle && (
          <Typography variant="h3" className={styles.subFieldEmptyStateTitle}>
            {emptyStateTitle}
          </Typography>
        )}
        {emptyStateText && (
          <Typography variant="body" className={styles.subFieldEmptyStateText}>
            {emptyStateText}
          </Typography>
        )}
        {fields.length === 0 && (
          <Button
            buttonText={t(`${entity}-add`)}
            buttonType={ButtonTypes.primaryOutlined}
            leftIcon="add"
            onClick={() => append(appendValue)}
            fullWidth
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!_isEqual(prevFields, fields)) {
      const newFocusedItemIndex = findNewElementIndex(
        prevFields as FieldArrayItemType[],
        fields as FieldArrayItemType[],
      );
      if (newFocusedItemIndex) setFocusedItemIndex(newFocusedItemIndex);
    }
  }, [fields, prevFields]);

  useEffect(() => {
    updateFieldArrayMethods(parentName, {
      append,
      remove,
      move,
      insert,
      update,
      replace,
    });
    return () => {
      removeFieldArrayMethods(parentName);
    };
  }, [
    append,
    insert,
    move,
    parentName,
    remove,
    removeFieldArrayMethods,
    replace,
    update,
    updateFieldArrayMethods,
  ]);

  if (isVisibleFn && !isVisibleFn(watch)) return null;

  if (FieldArrayLoopComponent) {
    return (
      <div
        className={styles.formItemColumn}
        style={isVisible ? undefined : { display: 'none' }}
      >
        <FieldArrayLoopComponent
          appendValue={sourceAppendValue}
          fields={fields}
          parentName={parentName}
          renderAddMore={renderAddMore}
          renderEmptyState={renderEmptyState}
        >
          {({ fieldItem, index, groupIndex }: LoopComponentProps) => (
            <div className={styles.formItemFieldArray} key={fieldItem.id}>
              {renderItem({ index, groupIndex, id: fieldItem.id })}
            </div>
          )}
        </FieldArrayLoopComponent>
      </div>
    );
  }

  if (!isDraggable) {
    return (
      <div
        className={styles.formItemColumn}
        style={isVisible ? undefined : { display: 'none' }}
      >
        {renderEmptyState(sourceAppendValue)}
        {fields.map((fieldItem, index) => (
          <div className={styles.formItemFieldArray} key={fieldItem.id}>
            {renderItem({ index, id: fieldItem.id })}
          </div>
        ))}
        {!hideErrors && !!error && !Array.isArray(error) && (
          <div className={styles.formIFieldArrayError}>
            <ControllerErrorRender error={error} />
          </div>
        )}
        {renderAddMore(sourceAppendValue)}
      </div>
    );
  }

  return (
    <div
      className={styles.formItemColumn}
      style={isVisible ? undefined : { display: 'none' }}
    >
      <StrictModeDroppable droppableId={`FieldArrayDroppable-${parentName}`}>
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {renderEmptyState(sourceAppendValue, snapshot, true)}
            {fields.map((fieldItem, index) => (
              <Draggable
                key={fieldItem.id}
                draggableId={fieldItem.id}
                index={index}
              >
                {(subProvided, subSnapshot) => (
                  <div
                    className={classNames(styles.formItemFieldArray, {
                      [styles.formItemFieldArrayDragging]:
                        subSnapshot.isDragging,
                    })}
                    ref={subProvided.innerRef}
                    {...subProvided.draggableProps}
                    style={subProvided.draggableProps.style}
                  >
                    {renderItem({ index, subProvided, id: fieldItem.id })}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
      {!hideErrors && !!error && !Array.isArray(error) && (
        <div className={styles.formIFieldArrayError}>
          <ControllerErrorRender error={error} />
        </div>
      )}
      {renderAddMore()}
    </div>
  );
}

export default memo(FieldArray);
