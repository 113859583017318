import React from 'react';
import classNames from 'classnames';
import AccountsIcon from '@lib/assets/icons/extended_accounts.svg?react';
import ActivateIcon from '@lib/assets/icons/extended_check.svg?react';
import AddIcon from '@lib/assets/icons/extended_add.svg?react';
import Cancel from '@lib/assets/icons/extended_cancel.svg?react';
import DeactivateIcon from '@lib/assets/icons/extended_do_not_disturb.svg?react';
import DeleteIcon from '@lib/assets/icons/extended_delete.svg?react';
import HomeIcon from '@lib/assets/icons/extended_home.svg?react';
import PasswordIcon from '@lib/assets/icons/extended_password.svg?react';
import SearchOff from '@lib/assets/icons/extended_search_off.svg?react';
import ThanksIcon from '@lib/assets/icons/extended_thanks.svg?react';
import VideocamIcon from '@lib/assets/icons/extended_videocam.svg?react';
import OfflineIcon from '@lib/assets/icons/extended_offline.svg?react';
import VideocamOffIcon from '@lib/assets/icons/extended_videocam_off.svg?react';
import WarningIcon from '@lib/assets/icons/extended_warning.svg?react';
import WarningYellowIcon from '@lib/assets/icons/extended_warning_yellow_48.svg?react';
import LockIcon from '@lib/assets/icons/extended_lock.svg?react';
import HelpIcon from '@lib/assets/icons/extended_help.svg?react';
import ErrorRedIcon from '@lib/assets/icons/extended_error_red_48.svg?react';
import { IconName, ModalIconColor } from './enums';
import styles from './ModalIcon.module.scss';

interface Props {
  icon: IconName;
  iconColor?: ModalIconColor;
  rootClassName?: string;
}

function getIcon(name: IconName) {
  switch (name) {
    case IconName.Home:
      return <HomeIcon />;
    case IconName.Delete:
      return <DeleteIcon />;
    case IconName.ManageAccounts:
      return <AccountsIcon />;
    case IconName.ChangePassword:
      return <PasswordIcon />;
    case IconName.Thanks:
      return <ThanksIcon />;
    case IconName.GroupAdd:
      return <AddIcon />;
    case IconName.Deactivate:
      return <DeactivateIcon />;
    case IconName.Activate:
      return <ActivateIcon />;
    case IconName.Warning:
      return <WarningIcon />;
    case IconName.WarningYellow:
      return <WarningYellowIcon />;
    case IconName.SearchOff:
      return <SearchOff />;
    case IconName.Cancel:
      return <Cancel />;
    case IconName.VideoCam:
      return <VideocamIcon />;
    case IconName.VideoCamOff:
      return <VideocamOffIcon />;
    case IconName.Offline:
      return <OfflineIcon />;
    case IconName.Lock:
      return <LockIcon />;
    case IconName.Help:
      return <HelpIcon />;
    case IconName.ErrorRed:
      return <ErrorRedIcon />;
    default:
      return <div>DEMO</div>;
  }
}

function ModalIcon(props: Props) {
  const { icon, iconColor = ModalIconColor.Blue, rootClassName } = props;
  return (
    <div className={classNames(styles.root, iconColor, rootClassName)}>
      <div>{getIcon(icon)}</div>
    </div>
  );
}

export default ModalIcon;
