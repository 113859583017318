import React, { memo, useRef } from 'react';

function Logo({ className }: { className?: string }) {
  const svgRef = useRef<SVGSVGElement | null>(null);
  return (
    <svg
      ref={svgRef}
      width="141"
      height="40"
      viewBox="0 0 141 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <path
        id="animate"
        d="M100.636 10.1951C100.636 8.6098 101.87 7.42456 103.482 7.42456C105.093 7.42456 106.361 8.60613 106.361 10.1951C106.361 11.7839 105.126 12.9949 103.482 12.9949C101.837 12.9949 100.636 11.8133 100.636 10.1951ZM100.636 10.1951C100.636 8.6098 101.87 7.42456 103.482 7.42456C105.093 7.42456 106.361 8.60613 106.361 10.1951C106.361 11.7839 105.126 12.9949 103.482 12.9949C101.837 12.9949 100.636 11.8133 100.636 10.1951Z"
        fill="black"
      >
        <animateTransform
          begin="animateTransform.end"
          id="pause"
          dur="20s"
          type="translate"
          attributeType="XML"
          attributeName="transform"
        />
        <animateMotion
          id="animateTransform"
          dur="0.1s"
          begin="10s; pause.end"
          fill="freeze"
        >
          <mpath xlinkHref="#jumpPath" />
        </animateMotion>
      </path>
      <path
        id="jumpPath"
        d="M0,0 Q0,-12 0,0"
        fill="none"
        stroke="transparent"
      />
      <path
        d="M65.0842 20.1539C65.0842 26.2819 60.3101 30.8871 53.9509 30.8871H45.3501V9.41699H53.9509C60.3101 9.41699 65.0842 14.0222 65.0842 20.1502V20.1539ZM60.0565 20.1539C60.0565 16.6385 57.4009 13.9635 53.8278 13.9635H50.2547V26.348H53.8278C57.4009 26.348 60.0565 23.6729 60.0565 20.1576V20.1539Z"
        fill="black"
      />
      <path
        d="M84.4713 15.0168V30.8873H79.8539V29.3021C78.6194 30.546 76.9447 31.2616 74.9829 31.2616C70.5557 31.2616 67.2026 27.6838 67.2026 22.9539C67.2026 18.2239 70.5557 14.6462 74.9829 14.6462C76.9447 14.6462 78.6194 15.3617 79.8539 16.6057V15.0205H84.4713V15.0168ZM79.8539 22.9502C79.8539 20.7082 78.1793 19.0312 75.9004 19.0312C73.6215 19.0312 71.9767 20.7118 71.9767 22.9502C71.9767 25.1886 73.6514 26.8692 75.9004 26.8692C78.1494 26.8692 79.8539 25.1886 79.8539 22.9502Z"
        fill="black"
      />
      <path d="M105.79 15.0166H101.173V30.8871H105.79V15.0166Z" fill="black" />
      <path
        d="M122.205 26.4986V30.8872H108.733V27.5884L115.469 19.4055H108.86V15.0168H121.985V18.3156L115.249 26.4986H122.205Z"
        fill="black"
      />
      <path
        d="M98.3231 19.156V15.0169H94.1794V11.0979H93.012L87.1265 17.9855V19.156H89.562V25.7207C89.562 28.9866 91.3 31.258 95.2237 31.258C96.4583 31.258 97.3758 31.0415 98.3231 30.5718V26.3078C97.7227 26.6197 97.0252 26.8693 96.2047 26.8693C94.8433 26.8693 94.1794 26.1831 94.1794 24.9721V19.1524H98.3231V19.156Z"
        fill="black"
      />
      <path
        d="M135.919 14.958L132.268 24.9097L128.583 14.958H123.369L129.735 30.3442L129.265 31.3276C128.859 32.1569 128.236 32.4945 127.55 32.4945C127.199 32.4945 126.841 32.4137 126.509 32.2743L125.017 34.021L123.962 35.2576C125.088 36.2227 126.815 36.8281 128.296 36.8281C130.511 36.8281 132.51 35.4154 133.417 33.144L141.003 14.9617H135.916L135.919 14.958Z"
        fill="black"
      />
      <path
        d="M28.0627 6.96959C27.4846 7.17141 26.9811 7.53102 26.6118 8.01172L25.1982 9.83912L13.2593 25.3023C12.6178 26.1352 11.8346 26.8361 10.9581 27.3829L10.186 29.2176L14.5163 31.533H15.9932C18.7234 31.533 21.2932 30.2744 22.9418 28.1351L32.7845 15.3396L33.9669 13.8021C34.3585 13.2957 34.5785 12.6793 34.6009 12.0445L34.8247 5.33667C34.8359 4.99174 34.489 4.74221 34.1571 4.85597L28.0627 6.97325V6.96959Z"
        fill="url(#paint0)"
      />
      <path
        d="M10.4134 24.6933V17.8791V12.5033C10.4134 12.096 10.0778 11.762 9.66003 11.762H0.753408C0.339407 11.762 0 12.0923 0 12.5033V17.8791H0.014919C0.350596 25.4749 6.71354 31.5295 14.5162 31.5295H15.4598C15.8141 31.5295 16.1722 31.5148 16.5265 31.4891L17.3619 31.4231C13.4718 31.6176 10.4134 28.4692 10.4134 24.6933Z"
        fill="url(#paint1)"
      />
      <defs>
        <linearGradient
          id="paint0"
          x1="16.0977"
          y1="28.8507"
          x2="30.1941"
          y2="10.2555"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C9CAFF" />
          <stop offset="0.19" stopColor="#C9CAFF" />
          <stop offset="0.7" stopColor="#214EEB" />
          <stop offset="1" stopColor="#132E94" />
        </linearGradient>
        <linearGradient
          id="paint1"
          x1="8.68284"
          y1="9.74746"
          x2="8.68284"
          y2="31.3497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C9CAFF" />
          <stop offset="0.19" stopColor="#C9CAFF" />
          <stop offset="0.7" stopColor="#214EEB" />
          <stop offset="1" stopColor="#132E94" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default memo(Logo);
