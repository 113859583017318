import React from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import ErrorBoundary from '@lib/components/ErrorBoundary/ErrorBoundary';
import Logo from '@lib/components/Logo/Logo';
import ForgotPasswordLayout from '@lib/layouts/ForgotPasswordLayout/ForgotPasswordLayout';
import ReleaseTracker from '@lib/components/ReleaseTreacker/ReleaseTracker';
import styles from './AuthLayout.module.scss';

const {
  VITE_PLATFORM_URL: domain,
  VITE_RELEASE_VERSION: releaseVersion,
  VITE_RELEASE_TRACKER_SECONDS: releaseTrackerSeconds,
  MODE: mode,
  // @ts-ignore
} = import.meta.env;

interface Props {
  captureException: (error: Error, response?: unknown) => void;
  finishRegistrationUrl: string;
  forgotPasswordUrl: string;
  loginUrl: string;
  logoUrl: string;
}

function AuthLayout(props: Props): React.ReactElement {
  const {
    logoUrl,
    loginUrl,
    forgotPasswordUrl,
    finishRegistrationUrl,
    captureException,
  } = props;
  const location = useLocation();
  const { pathname } = location;
  if (pathname === forgotPasswordUrl) {
    return (
      <ForgotPasswordLayout
        loginUrl={loginUrl}
        captureException={captureException}
      />
    );
  }

  const getOutlet = () => (
    <ErrorBoundary location={location} captureException={captureException}>
      <Outlet />
    </ErrorBoundary>
  );

  if (pathname === finishRegistrationUrl) {
    return getOutlet();
  }

  return (
    <>
      <ReleaseTracker
        captureException={captureException}
        domain={domain}
        releaseVersion={releaseVersion}
        releaseTrackerSeconds={releaseTrackerSeconds}
        mode={mode}
      />
      <div className={styles.root}>
        <div className={styles.header}>
          <NavLink to={logoUrl}>
            <Logo />
          </NavLink>
        </div>
        <div className={styles.body}>
          <div className={styles.wrap}>{getOutlet()}</div>
        </div>
      </div>
    </>
  );
}

export default AuthLayout;
