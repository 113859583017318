import type { MaskitoOptions, MaskitoPostprocessor } from '@maskito/core';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';
import { MAX_NUMBER_VALUE } from '@lib/enums/form';

export const TIME_MASK: MaskitoOptions = {
  ...maskitoNumberOptionsGenerator({
    decimalZeroPadding: true,
    precision: 2,
    decimalSeparator: ':',
    thousandSeparator: '',
    min: 0,
  }),
  postprocessors: [
    (elementState) => {
      const { value, selection } = elementState;

      if (['', ':', ':00', ':0'].includes(value)) {
        return {
          value: '',
          selection: [0, 0] as const,
        };
      }

      const [hours = '0', minutes = '0'] = value.split(':');
      const validMinutes = Math.min(Number(minutes), 59)
        .toString()
        .padEnd(2, '0');
      const formattedValue = `${hours}:${validMinutes}`;

      return {
        ...elementState,
        value: formattedValue,
        selection,
      };
    },
  ],
};

export const PRICE_INPUT_MASK: MaskitoOptions = {
  ...maskitoNumberOptionsGenerator({
    decimalZeroPadding: true,
    precision: 2,
    decimalSeparator: '.',
    thousandSeparator: ',',
    min: 0,
  }),
  postprocessors: [
    (elementState) => {
      const { value, selection } = elementState;

      if (['', '.', '.00', '.0'].includes(value)) {
        return {
          value: '',
          selection: [0, 0] as const,
        };
      }

      const [part1 = '0', part2 = '0'] = value.split('.');
      const validPart2 = Math.min(Number(part2), 99).toString().padEnd(2, '0');
      const formattedValue = `${part1}.${validPart2}`;

      return {
        ...elementState,
        value: formattedValue,
        selection,
      };
    },
  ],
};

export const getNumberInInputMask: (args: {
  precision?: number;
  maxLength?: number;
  max?: number;
  min?: number;
  minusSign?: string;
}) => MaskitoOptions = ({
  precision = 0,
  maxLength = `${MAX_NUMBER_VALUE}`.length,
  max,
  min,
  minusSign,
}) => {
  const baseOptions = maskitoNumberOptionsGenerator(
    precision > 0
      ? {
          decimalSeparator: '.',
          thousandSeparator: '',
          precision,
          max,
          min,
          minusSign,
        }
      : {
          decimalSeparator: undefined,
          thousandSeparator: '',
          precision: 0,
          max,
          min,
          minusSign,
        },
  );

  const lengthLimiterPostprocessor: MaskitoPostprocessor = (elementState) => {
    const { value, selection } = elementState;

    const limitedValue =
      maxLength !== undefined && value.replace('-', '').length > maxLength
        ? value.slice(0, maxLength + (value.startsWith('-') ? 1 : 0))
        : value;

    return {
      value: limitedValue,
      selection: [
        Math.min(selection[0], limitedValue.length),
        Math.min(selection[1], limitedValue.length),
      ],
    };
  };

  return {
    ...baseOptions,
    postprocessors: [
      ...(baseOptions.postprocessors || []),
      lengthLimiterPostprocessor,
    ],
  };
};
