import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Values } from '@lib/interfaces/form';
import Typography from '@lib/components/Typography/Typography';
import Form from '@lib/components/ReactHookForm/FormContainer';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import toast from '@lib/components/Toast/Toast';
import {
  ScopeEnum,
  useAuthenticationSessionCreateMutation,
} from 'graphql-common';
import { getLoginFormFields } from 'utils/authUtils';
import styles from '@lib/layouts/AuthLayout/AuthComponents.module.scss';

const defaultValues = {
  login: '',
  password: '',
};

function LoginForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loginMutation] = useAuthenticationSessionCreateMutation({
    onCompleted: () => {
      navigate(0);
    },
    onError: (error) => {
      if (
        error.networkError &&
        'statusCode' in error.networkError &&
        error.networkError.statusCode === 429
      ) {
        toast({ content: t('too-many-requests-error') });
      } else {
        toast({ content: error.message || 'Oops! Something went wrong' });
      }
    },
  });

  const fields = getLoginFormFields({ t });

  const onSubmit = (values: Values) => {
    const login = values.login as string;
    const password = values.password as string;
    loginMutation({
      variables: { input: { uid: login, password, scope: ScopeEnum.User } },
    });
  };

  const submitBtn = () => (
    <div className={styles.loginActions}>
      <Button
        type="submit"
        buttonType={ButtonTypes.primaryFilled}
        buttonText={t('log-in')}
        leftIcon="logout"
        fullWidth
      />
    </div>
  );

  return (
    <div className={styles.loginWrap}>
      <Typography variant="h2" className={styles.title}>
        {t('welcome-to-datizy')}
      </Typography>
      <Form
        disableAwayConfirmation
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        fields={fields}
        actionsComponent={submitBtn}
      />
    </div>
  );
}

export default LoginForm;
