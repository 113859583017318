import React, { memo } from 'react';
import classNames from 'classnames';
import _get from 'lodash/get';
import { materialIcons, materialSymbolsOutlined } from '@lib/enums/common';
import styles from './MaterialIcon.module.scss';

type Props = {
  block?: boolean;
  className?: string;
  filled?: boolean;
  icon: string;
  materialClassName?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  size?: string;
  symbolsOutlined?: boolean;
};

function MaterialIcon(props: Props) {
  const {
    block,
    className,
    filled,
    icon,
    materialClassName,
    onClick,
    size = 'md-24',
    symbolsOutlined,
  } = props;
  let materialClassNameValue = materialClassName || materialIcons;
  if (symbolsOutlined) materialClassNameValue = materialSymbolsOutlined;
  const iconClassName = classNames(
    materialClassNameValue,
    className,
    _get(styles, size) as string,
    {
      [styles.pointer]: onClick !== undefined,
      [styles.block]: block,
      [styles.filled]: filled,
    },
  );
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <i
      role="button"
      tabIndex={0}
      className={iconClassName}
      onClick={onClick}
      translate="no"
    >
      {icon}
    </i>
  );
}

export default memo(MaterialIcon);
