import React, { useRef } from 'react';
import classNames from 'classnames';
import { useResizeDetector } from 'react-resize-detector';
import useWindowDimensions from '@lib/hooks/useWindowDimensions';
import Loader from '@lib/components/CircleLoader/CircleLoader';
import styles from './CircleLoaderWrapper.module.scss';

export enum CircleLoaderWrapperType {
  default = 'default',
  blur = 'blur',
}

interface Props {
  children: React.ReactNode;
  isInline?: boolean;
  isLoading?: boolean;
  overlayBg?: string;
  rootClassName?: string;
  size?: number;
  rootStyle?: React.CSSProperties;
  type?: CircleLoaderWrapperType;
  width?: number;
}

/**
 * The CircleLoaderWrapper component is designed for rendering a Loader on top of content
 */
function CircleLoaderWrapper(props: Props) {
  const {
    children,
    isInline = false,
    isLoading = false,
    overlayBg,
    rootClassName,
    size = 48,
    rootStyle,
    type = CircleLoaderWrapperType.default,
    width = 4,
  } = props;
  const targetRef = useRef(null);
  const { height: rootHeight } = useResizeDetector({
    targetRef,
  });
  const { height: windowHeight } = useWindowDimensions();

  // Loader should be displayed in the middle of the content,
  // but if the content is larger than the screen height,
  // it should be displayed pinned to the top
  const loaderClassName = classNames(styles.loader, {
    [styles.loaderIsCentered]: rootHeight && rootHeight < windowHeight,
    [styles.loaderIsPressedToTop]: rootHeight && rootHeight >= windowHeight,
  });

  const className = classNames(
    styles.root,
    {
      [styles.rootInline]: isInline,
    },
    rootClassName,
  );

  return (
    <div ref={targetRef} className={className} style={rootStyle}>
      <div
        className={classNames(styles.overlayWrap, {
          [styles.overlayWrapVisible]: isLoading,
        })}
      >
        <div
          className={classNames(styles.overlay, {
            [styles.overlayDefault]:
              type === CircleLoaderWrapperType.default && isLoading,
          })}
          style={{ backgroundColor: overlayBg }}
        />
        <div className={loaderClassName}>
          <Loader padding={0} width={width} size={size} />
        </div>
      </div>
      <div
        className={classNames(styles.content, {
          [styles.contentBlur]:
            type === CircleLoaderWrapperType.blur && isLoading,
        })}
      >
        {children}
      </div>
    </div>
  );
}

export default CircleLoaderWrapper;
