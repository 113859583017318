import React from 'react';
import classNames from 'classnames';
import { materialIcons } from '@lib/enums/common';
import defaultStyles from './Default.module.scss';

interface Props {
  icon: string;
  styles: { [key: string]: string };
  className?: string;
  renderLoader: React.ReactNode;
  position: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

function Icon(props: Props) {
  const { icon, styles, className, renderLoader, position, onClick } = props;
  const isLoading = icon === 'loading';
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <i
      className={classNames(
        {
          [materialIcons]: !isLoading,
          [defaultStyles.leftIcon]: position === 'left',
          [defaultStyles.rightIcon]: position === 'right',
          [styles.leftIcon]: position === 'left',
          [styles.rightIcon]: position === 'right',
          [styles.loadingIcon]: isLoading,
        },
        className,
      )}
      onClick={onClick}
      translate="no"
    >
      {isLoading ? renderLoader : icon}
    </i>
  );
}

export default Icon;
