import React from 'react';
import { Tooltip as TooltipCommon } from 'antd';
import Typography from '@lib/components/Typography/Typography';
import { TooltipPlacement } from 'antd/es/tooltip';
import styles from './Tooltip.module.scss';

export type TooltipProps = {
  body: React.ReactNode;
  children: React.ReactNode;
  contentClassName?: string;
  disabled?: boolean;
  maxWidth?: number;
  placement?: TooltipPlacement;
};

function Tooltip(props: TooltipProps) {
  const {
    body,
    children,
    contentClassName,
    disabled,
    maxWidth = 280,
    placement = 'bottom',
  } = props;
  const title = (
    <div style={{ maxWidth }} className={styles.container}>
      <Typography variant="caption">{body}</Typography>
    </div>
  );
  return (
    <TooltipCommon
      placement={placement}
      classNames={{ root: styles.overlay }}
      title={disabled ? undefined : title}
    >
      <div className={contentClassName}>{children}</div>
    </TooltipCommon>
  );
}

export default Tooltip;
