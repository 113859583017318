import classNames from 'classnames';

function mergeCssModules(...modules: { [key: string]: string }[]) {
  return modules.reduce(
    (result, module) =>
      Object.keys(module).reduce((subResult, idx) => {
        const value = module[idx];
        return subResult[idx]
          ? { ...subResult, [idx]: classNames(subResult[idx], value) }
          : { ...subResult, [idx]: value };
      }, result),
    {},
  );
}

export default mergeCssModules;
