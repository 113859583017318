import { useOutletContext } from 'react-router-dom';
import { User } from 'graphql-common';

export type ContextProps = {
  completed: boolean;
  onLogoutHandler: () => void;
  setCompleted: (v: boolean) => void;
  user: User;
};

export default function useOutletContextHook() {
  const outletContext: ContextProps = useOutletContext();
  return outletContext;
}
