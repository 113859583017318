import React from 'react';
import { useTranslation } from 'react-i18next';
import _noop from 'lodash/noop';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import CountdownCircleTimer from '@lib/components/CountdownCircleTimer/CountdownCircleTimer';
import styles from './Toast.module.scss';

interface CloseButtonProps {
  closeToast?: () => void;
  closeCallback?: () => void;
  autoClose: number;
}

function CloseButton({
  closeToast = _noop,
  closeCallback,
  autoClose,
}: CloseButtonProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className={styles.closeButton}>
      {closeCallback ? (
        <div>
          <Button
            buttonText={t('undo')}
            buttonType={ButtonTypes.tertiaryFilled}
            onClick={closeToast}
            rightContent={
              <CountdownCircleTimer
                size={24}
                duration={autoClose}
                onComplete={closeCallback}
              />
            }
          />
        </div>
      ) : (
        <IconButton
          icon="close"
          onClick={() => {
            if (closeToast) closeToast();
          }}
          type={IconButtonTypes.QuaternaryOutlined}
        />
      )}
    </div>
  );
}

export default CloseButton;
