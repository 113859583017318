import React from 'react';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import {
  ScopeEnum,
  useAuthenticationPasswordsForgotMutation,
} from 'graphql-common';
import Form from '@lib/components/ReactHookForm/FormContainer';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { Values } from '@lib/interfaces/form';
import toast from '@lib/components/Toast/Toast';
import { APP_URLS } from 'constants/urls';
import getAppUrlWithDomain from '@lib/utils/getAppUrlWithDomain';
import { getForgotPasswordFormFields } from 'utils/authUtils';
import styles from '@lib/layouts/AuthLayout/AuthComponents.module.scss';
import useOutletContext, { ContextProps } from 'hooks/useOutletContext';

const defaultValues = {
  email: '',
};

function ForgotPasswordForm() {
  const { t } = useTranslation();
  const { completed, setCompleted }: ContextProps = useOutletContext();

  const [sendPasswordResetMutation] = useAuthenticationPasswordsForgotMutation({
    onCompleted: (response) => {
      const message = _get(response, 'data.message', '');
      toast({ content: message });
      setCompleted(true);
    },
    onError: (error) => {
      toast({ content: error.message || 'Oops! Something went wrong' });
    },
  });

  const fields = getForgotPasswordFormFields({ t, completed });

  const onSubmit = (values: Values) => {
    const email = values.email as string;
    sendPasswordResetMutation({
      variables: {
        input: {
          email,
          redirectUrl: getAppUrlWithDomain(APP_URLS.auth.resetPassword.path),
          scope: ScopeEnum.User,
        },
      },
    });
  };

  const submitBtn = () => (
    <div className={styles.forgotPasswordActions}>
      <Button
        type="submit"
        buttonType={ButtonTypes.primaryFilled}
        buttonText={completed ? t('resend-reset-link') : t('send-reset-link')}
        leftIcon="mail"
        fullWidth
      />
    </div>
  );

  return (
    <Form
      disableAwayConfirmation
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      fields={fields}
      actionsComponent={submitBtn}
    />
  );
}

export default ForgotPasswordForm;
