import React, { forwardRef, memo } from 'react';
import classNames from 'classnames';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Typography from '@lib/components/Typography/Typography';
import styles from './CheckBox.module.scss';

export interface CheckboxProps {
  checked: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  hasError?: boolean;
  iconClassName?: string;
  iconProps?: {
    icon: string;
    iconChecked: string;
    symbolsOutlined?: boolean;
  };
  id: string;
  label?: React.ReactNode;
  labelClassName?: string;
  name?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  wrapperClassName?: string;
  indeterminate?: boolean;
  rightLabelIcon?: React.ReactNode;
}

const Checkbox = forwardRef(
  (props: CheckboxProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      checked,
      disabled = false,
      fullWidth = false,
      hasError = false,
      iconClassName = '',
      iconProps,
      id,
      indeterminate,
      label = '',
      labelClassName = '',
      name,
      onChange,
      wrapperClassName = '',
      rightLabelIcon,
    } = props;

    const inputId = `${name}.${id}`;

    const wrapperClass = classNames(styles.wrapper, {
      [styles.wrapper]: !fullWidth,
      [styles.disabled]: disabled,
      [styles.fullWidthWrapper]: fullWidth,
      [wrapperClassName]: !!wrapperClassName,
    });

    const labelClass = classNames({
      [styles.label]: true,
      [styles.labelWhenError]: hasError,
      [labelClassName]: !!labelClassName,
    });

    const iconClass = classNames(styles.icon, {
      [styles.checkedIcon]: checked || indeterminate,
      [styles.withErrorIcon]: hasError,
      [styles.disabledIcon]: disabled,
      [iconClassName]: !!iconClassName,
    });

    const checkboxIcon = () => {
      if (indeterminate) return 'indeterminate_check_box';
      if (iconProps) return checked ? iconProps?.icon : iconProps?.iconChecked;
      return checked ? 'check_box' : 'check_box_outline_blank';
    };

    return (
      <div id={name} tabIndex={-1} className={wrapperClass}>
        <input
          ref={ref}
          id={inputId}
          name={name}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className={styles.input}
        />
        <label className={labelClass} htmlFor={inputId}>
          <span className={iconClass} role="button" tabIndex={0}>
            <MaterialIcon
              icon={checkboxIcon()}
              symbolsOutlined={iconProps?.symbolsOutlined}
            />
          </span>
          {label && (
            <Typography variant="body" className={styles.text}>
              {label}
            </Typography>
          )}
          {rightLabelIcon && (
            <span className={styles.rightLabelIcon}>{rightLabelIcon}</span>
          )}
        </label>
      </div>
    );
  },
);

export default memo(Checkbox);
