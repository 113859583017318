import React from 'react';
import classNames from 'classnames';
import { Modal as AntdModal } from 'antd';
import Typography, { Variant } from '@lib/components/Typography/Typography';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import Collapse from '@lib/components/Collapse/Collapse';
import {
  IconName,
  ModalIconColor,
  ModalMode,
} from '@lib/components/Modal/enums';
import ModalIcon from './ModalIcon';
import styles from './Modal.module.scss';

interface Props {
  actions?: React.JSX.Element;
  caption?: string;
  children?: React.JSX.Element;
  description?: React.ReactNode;
  descriptionAlign?: 'left' | 'center';
  descriptionChildren?: React.ReactNode;
  icon?: IconName;
  iconColor?: ModalIconColor;
  isOpen: boolean;
  modalContentClassName?: string;
  modalBodyClassName?: string;
  onClose?: () => void;
  title?: string;
  titleAlign?: 'left' | 'center';
  titleVariant?: Variant;
  mode?: ModalMode;
}

function Modal(props: Props) {
  const {
    actions,
    caption,
    children,
    description,
    descriptionAlign = 'center',
    descriptionChildren,
    icon,
    iconColor = ModalIconColor.Blue,
    isOpen,
    modalContentClassName,
    modalBodyClassName,
    onClose,
    title,
    titleAlign = 'center',
    titleVariant = 'h2',
    mode = ModalMode.Default,
  } = props;

  let isCloseBtnVisible = false;
  if (onClose) isCloseBtnVisible = true;

  const afterOpenModal = () => {};

  const onRequestClose = () => {
    if (onClose) onClose();
  };

  return (
    <AntdModal
      open={isOpen}
      afterOpenChange={afterOpenModal}
      onCancel={onRequestClose}
      maskClosable={false}
      centered
      classNames={{
        mask: styles.mask,
      }}
      rootClassName={classNames(`mode-${mode}`)}
      closeIcon={null}
      footer={null}
      width={mode === ModalMode.Default ? 680 : '100%'}
      destroyOnClose
    >
      <div className={styles.modalHeader}>
        {caption && (
          <Typography variant="body" strong className={styles.modalHeaderTitle}>
            {caption}
          </Typography>
        )}
        {isCloseBtnVisible && (
          <IconButton
            icon="close"
            type={IconButtonTypes.PrimaryOutlined}
            onClick={onRequestClose}
            className={styles.modalHeaderLeftIcon}
          />
        )}
      </div>
      <div
        className={classNames(
          styles.modalBody,
          {
            [styles.modalBodyWithActions]: actions !== undefined,
          },
          modalBodyClassName,
        )}
      >
        <div>
          {icon && (
            <div className={styles.modalIcon}>
              <ModalIcon icon={icon} iconColor={iconColor} />
            </div>
          )}
          {title && (
            <Collapse minHeight={120} useCropBlurWhite>
              <Typography
                variant={titleVariant}
                strong
                className={classNames(styles.modalTitle, {
                  [styles.modalTitleLeft]: titleAlign === 'left',
                  [styles.modalTitleCenter]: titleAlign === 'center',
                })}
              >
                {title}
              </Typography>
            </Collapse>
          )}
          {description && (
            <Typography
              variant="body"
              className={classNames(styles.modalDescription, {
                [styles.modalDescriptionCentered]:
                  descriptionAlign === 'center',
              })}
            >
              {description}
            </Typography>
          )}
          {descriptionChildren && <div>{descriptionChildren}</div>}
        </div>
        <div className={classNames(styles.modalContent, modalContentClassName)}>
          {children}
        </div>
        {actions !== undefined && actions}
      </div>
    </AntdModal>
  );
}

export default Modal;
