import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useMemo,
} from 'react';
import { BreadCrumb, BreadcrumbsContextValue } from './types';

const BreadcrumbContext = createContext<BreadcrumbsContextValue | undefined>(
  undefined,
);

interface BreadcrumbProviderProps {
  children: ReactNode;
}

export function BreadCrumbsProvider({ children }: BreadcrumbProviderProps) {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadCrumb[]>([]);
  const value = useMemo(() => ({ breadcrumbs, setBreadcrumbs }), [breadcrumbs]);
  return (
    <BreadcrumbContext.Provider value={value}>
      {children}
    </BreadcrumbContext.Provider>
  );
}

export const useBreadcrumbContext = () => {
  const context = useContext(BreadcrumbContext);
  if (!context) {
    throw new Error(
      'Breadcrumb component must be used within BreadcrumbProvider',
    );
  }
  return context;
};
