import React, { memo } from 'react';
import classNames from 'classnames';
import Typography, { Variant } from '@lib/components/Typography/Typography';
import styles from './Link.module.scss';

export enum LinkTypes {
  primaryBlue = 'primary-blue',
  secondaryGray = 'secondary-gray',
  tertiaryBlack = 'tertiary-black',
}

export enum LinkSizes {
  large = 'large',
  small = 'small',
}

interface Props {
  children: React.ReactNode;
  className?: string;
  component?: React.JSX.ElementType;
  href?: string;
  onClick?: () => void;
  size?: LinkSizes;
  target?: string;
  to?: string;
  type?: LinkTypes;
  underline?: boolean;
  variant?: Variant;
}

function Link(props: Props): React.JSX.Element {
  const {
    children,
    className,
    component,
    href,
    onClick,
    size = LinkSizes.large,
    target,
    to,
    type = LinkTypes.primaryBlue,
    underline = true,
    variant = 'link',
  } = props;
  const linkClassName = classNames(
    className,
    styles.rootLink,
    styles[type],
    styles[size],
    { [styles.underlined]: underline },
  );
  return (
    <Typography
      className={linkClassName}
      component={component}
      href={href}
      onClick={onClick}
      target={target}
      to={to}
      variant={variant}
    >
      {children}
    </Typography>
  );
}

export default memo(Link);
