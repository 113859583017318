import React, { memo, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import Transition from 'react-transition-group/Transition';
import classNames from 'classnames';
import styles from '@lib/components/ReactHookForm/Form.module.scss';
import Typography from '@lib/components/Typography/Typography';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import IconButton, {
  IconButtonTypes,
} from '@lib/components/IconButton/IconButton';
import DragDrop from '@lib/assets/icons/dragDrop.svg?react';

const duration = 150;

const defaultStyle = {
  transition: `${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

interface Props {
  children: React.ReactNode;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  errorsCount: number;
  isDraggable?: boolean;
  onRemove: () => void;
  title?: string;
}

function FieldArrayItemAccordion(props: Props) {
  const {
    children,
    errorsCount,
    isDraggable,
    title,
    dragHandleProps,
    onRemove,
  } = props;
  const [isAccordionOpen, setAccordionOpen] = useState(true);
  const toggleAccordion = () => setAccordionOpen((prevState) => !prevState);
  const accordionClassName = classNames(styles.subFieldAccordion, {
    [styles.subFieldAccordionWithErrors]: errorsCount,
    [styles.accordionClassNameDraggable]: isDraggable,
  });
  return (
    <>
      <div
        className={classNames(styles.subFieldHead, {
          [styles.subFieldHeadOpened]: isAccordionOpen,
        })}
      >
        <button
          type="button"
          className={accordionClassName}
          onClick={toggleAccordion}
        >
          <Typography variant="body" strong>
            {title}
          </Typography>
          {!!errorsCount && (
            <Typography variant="caption" className={styles.errorsCount}>
              {errorsCount}
            </Typography>
          )}
          <MaterialIcon
            icon="arrow_drop_down"
            className={styles.subFieldAccordionIcon}
          />
        </button>
        {isDraggable && (
          <div
            {...dragHandleProps}
            className={styles.subFieldAccordionDragDrop}
          >
            <DragDrop />
          </div>
        )}
        <IconButton
          className={styles.subFieldDeleteBtn}
          icon="delete"
          onClick={onRemove}
          symbolsOutlined
          type={IconButtonTypes.PrimaryOutlined}
        />
      </div>
      <Transition in={isAccordionOpen} timeout={duration} unmountOnExit>
        {(transitionState) => (
          <div
            style={{
              ...defaultStyle,
              // @ts-ignore
              ...transitionStyles[transitionState],
            }}
            className={styles.accordionContent}
          >
            {children}
          </div>
        )}
      </Transition>
    </>
  );
}

export default memo(FieldArrayItemAccordion);
