import _get from 'lodash/get';
import { FieldError } from 'react-hook-form';

export default function getErrorStr(error?: FieldError): string | undefined {
  if (!error) {
    return undefined;
  }

  // Handle the case where error is a FieldError directly
  if (
    !Array.isArray(error) &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    return error.message;
  }

  // Handle the case where error is an array of errors
  if (Array.isArray(error)) {
    const messages = error.map(getErrorStr).filter((msg) => !!msg);
    return messages.join(', ');
  }

  // Handle the case where error is an object with nested errors
  const errorKeys = Object.keys(error);
  const messages = errorKeys
    .map((key) => {
      const nestedError = _get(error, key);
      return getErrorStr(nestedError);
    })
    .filter((msg) => !!msg);

  return messages.join(', ');
}
