import React from 'react';
import { Trans as TransCommon } from 'react-i18next';
import styles from './Trans.module.scss';

interface Props {
  i18nKey: string;
  values?: { [key: string]: unknown };
}

export default function Trans({ i18nKey, values }: Props) {
  if (!i18nKey) return null;
  return (
    <TransCommon
      i18nKey={i18nKey}
      components={{
        // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
        a: <a />,
        br: <br />,
        bold: <strong />,
        li: <li />,
        ul: <ul />,
        ol: <ol />,
        blue: <span className={styles.blue} />,
      }}
      values={values}
    />
  );
}
