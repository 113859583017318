import React, {
  useState,
  useContext,
  createContext,
  useMemo,
  useCallback,
} from 'react';
import _noop from 'lodash/noop';
import {
  FieldArrayContextType,
  FieldArrayData,
  FieldArrayDataObject,
} from '@lib/components/ReactHookForm/types';

const FieldArrayContext = createContext({
  updateFieldArrayMethods: _noop,
  removeFieldArrayMethods: _noop,
  fieldArrayMethodsByName: {},
});

export const useFieldArrayContext = () =>
  useContext<FieldArrayContextType>(FieldArrayContext);

interface Props {
  children: React.ReactNode;
}

function FormProvider({ children }: Props) {
  const [fieldArrayMethodsByName, setFieldArrayMethodsByName] =
    useState<FieldArrayDataObject>({});

  const updateFieldArrayMethods = useCallback(
    (fieldName: string, v: FieldArrayData) => {
      setFieldArrayMethodsByName((prev) => ({ ...prev, [fieldName]: v }));
    },
    [],
  );

  const removeFieldArrayMethods = useCallback(
    (fieldName: keyof FieldArrayData) => {
      setFieldArrayMethodsByName((prev) => {
        const newData = { ...prev };
        delete newData[fieldName];
        return newData;
      });
    },
    [],
  );

  const value = useMemo(
    () => ({
      updateFieldArrayMethods,
      removeFieldArrayMethods,
      fieldArrayMethodsByName,
    }),
    [fieldArrayMethodsByName, removeFieldArrayMethods, updateFieldArrayMethods],
  );

  return (
    <FieldArrayContext.Provider value={value}>
      {children}
    </FieldArrayContext.Provider>
  );
}

export default FormProvider;
