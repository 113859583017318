import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Id,
  toast,
  ToastContainer as ToastContainerCommon,
  ToastOptions,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usePrevious from '@lib/hooks/usePrevious';
import CloseButton from './CloseButton';
import './Toast.scss';

export function ToastContainer() {
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);
  useEffect(() => {
    if (pathname !== prevPathname) {
      toast.dismiss();
    }
  }, [pathname, prevPathname]);
  return <ToastContainerCommon />;
}

const MAX_TOASTS = 3;
export const AUTO_CLOSE = 10;
export const TOAST_AUTO_CLOSE_SEC = AUTO_CLOSE * 1000;
let activeToasts: Id[] = [];

const DEFAULT_OPTIONS = {
  position: toast.POSITION.BOTTOM_RIGHT,
  hideProgressBar: true,
  autoClose: TOAST_AUTO_CLOSE_SEC,
  draggable: false,
  closeOnClick: false,
};

type LimitedToastOptions = {
  content: React.ReactNode;
  options?: ToastOptions;
  closeCallback?: () => void;
};

const limitedToast = ({
  content,
  options = {},
  closeCallback,
}: LimitedToastOptions) => {
  setTimeout(() => {
    const toastId = toast(content, {
      ...DEFAULT_OPTIONS,
      ...options,
      closeButton: (
        <CloseButton closeCallback={closeCallback} autoClose={AUTO_CLOSE} />
      ),
    });
    activeToasts.push(toastId);
    if (activeToasts.length > MAX_TOASTS) {
      const firstToastId = activeToasts[0];
      activeToasts = activeToasts.filter((id) => id !== firstToastId);
      toast.dismiss(firstToastId);
    }
  }, 100);
};

export default limitedToast;
