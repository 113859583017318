import React from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import styles from './ActionsRow.module.scss';

interface Props {
  actions: React.JSX.Element[];
  className?: string;
}

function ActionsRow(props: Props) {
  const { actions, className } = props;
  const rowClassName = classNames(
    styles.root,
    {
      [styles.twoColumns]: actions.length === 2,
      [styles.fourColumns]: actions.length === 4,
    },
    className,
  );
  return (
    <div className={rowClassName}>
      {actions.map((action) => (
        <div key={uuidv4()}>{action}</div>
      ))}
    </div>
  );
}

export default ActionsRow;
