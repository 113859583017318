import React from 'react';
import classNames from 'classnames';
import styles from './PageBody.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
  withoutHeader?: boolean;
  withSteps?: boolean;
}

function PageBody(props: Props) {
  const { className, children, withoutHeader, withSteps } = props;
  return (
    <div
      className={classNames(
        styles.root,
        {
          [styles.withoutHeader]: withoutHeader,
          [styles.withSteps]: withSteps,
        },
        className,
      )}
    >
      {Array.isArray(children) ? children.map((child) => child) : children}
    </div>
  );
}

export default PageBody;
