export enum ModalMode {
  Default = 'default',
  FullScreen = 'fullScreen',
}

export enum ModalIconColor {
  Blue = 'blue',
  Yellow = 'yellow',
  Red = 'red',
}

export enum IconName {
  Activate = 'activate',
  Cancel = 'cancel',
  ChangePassword = 'change_password',
  Deactivate = 'deactivate',
  Delete = 'delete',
  ErrorRed = 'error_red',
  GroupAdd = 'group_add',
  Help = 'help',
  Home = 'home',
  Lock = 'lock',
  ManageAccounts = 'manage_accounts',
  Offline = 'offline',
  SearchOff = 'search_off',
  Thanks = 'thanks',
  VideoCam = 'videocam',
  VideoCamOff = 'videocam_off',
  Warning = 'warning',
  WarningYellow = 'warning_yellow',
}
