import React, { memo } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import selectStyles from '@lib/utils/selectStyles';
import mergeCssModules from '@lib/utils/mergeCssModules';
import Loader from '@lib/components/CircleLoader/CircleLoader';
import Icon from './Icon';
import defaultStyles from './Default.module.scss';
import themeStyles from './Button.module.scss';

export enum ButtonTypes {
  primaryFilled = 'primary-filled',
  primaryOutlined = 'primary-outlined',
  primaryText = 'primary-text',
  secondaryOutlined = 'secondary-outlined',
  tertiaryFilled = 'tertiary-filled',
  quaternaryOutlined = 'quaternary-outlined',
  negativeOutlined = 'negative-outlined',
  negativeFilled = 'negative-filled',
}

export enum ButtonSizes {
  large = 'large',
  small = 'small',
}

export type LoaderProps = { [key: string]: string | number };

export interface ButtonProps {
  buttonSize?: ButtonSizes;
  buttonText?: React.ReactNode;
  buttonType?: ButtonTypes;
  className?: string;
  classes?: Record<string, string>;
  disabled?: boolean;
  fullWidth?: boolean;
  isFocused?: boolean;
  isLoading?: boolean;
  leftIcon?: string;
  leftIconClassName?: string;
  loaderProps?: LoaderProps;
  navigate?: (to: string) => void;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  rightContent?: React.JSX.Element;
  rightIcon?: string;
  rightIconClassName?: string;
  role?: string;
  singleLoaderWrapperClassName?: string;
  target?: string;
  to?: string;
  type?: 'button' | 'submit' | 'reset';
  useRipple?: boolean;
}

const createRipple = (event: React.MouseEvent<HTMLElement>) => {
  const button = event.currentTarget;
  const circle = document.createElement('span');
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;
  circle.style.width = `${diameter}px`;
  circle.style.height = `${diameter}px`;
  circle.style.left = `${event.nativeEvent.offsetX - radius}px`;
  circle.style.top = `${event.nativeEvent.offsetY - radius}px`;
  circle.classList.add(defaultStyles.ripple);
  const ripple = button.getElementsByClassName(defaultStyles.ripple)[0];
  if (ripple && ripple.parentElement !== null) {
    ripple.parentElement.removeChild(ripple);
  }
  button.appendChild(circle);
};

function Button(props: ButtonProps): React.JSX.Element {
  const {
    buttonSize = ButtonSizes.large,
    buttonText,
    buttonType = ButtonTypes.primaryFilled,
    className,
    classes,
    disabled,
    fullWidth,
    isFocused,
    isLoading,
    leftIcon,
    leftIconClassName,
    loaderProps,
    onClick,
    rightContent,
    rightIcon,
    rightIconClassName,
    role,
    singleLoaderWrapperClassName,
    target,
    to,
    type = 'button',
    useRipple = true,
  } = props;

  const navigate = useNavigate();

  const styles = mergeCssModules(
    defaultStyles,
    selectStyles(themeStyles, classes),
  );

  const renderLoader = (
    <Loader
      color="currentColor"
      size={24}
      width={1}
      padding={0}
      {...loaderProps}
    />
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (useRipple) {
      createRipple(event);
    }

    if (to && target === '_blank') {
      window.open(to, '_blank')?.focus();
    } else if (to && navigate) {
      navigate(to);
    } else if (onClick) {
      onClick(event);
    }
  };

  const onMouseUp = (event: React.MouseEvent<HTMLElement>) =>
    event.currentTarget.blur();

  const buttonClassName = classNames(
    {
      [defaultStyles.root]: true,
      [styles.root]: true,
      [styles[buttonType]]: true,
      [styles[buttonSize]]: true,
      [styles.disabled]: disabled,
      [styles.loading]: isLoading,
      [styles.fullWidth]: fullWidth,
      [styles.focused]: isFocused,
    },
    className,
  );

  return (
    <button
      className={buttonClassName}
      disabled={disabled || isLoading}
      onClick={handleClick}
      onMouseUp={onMouseUp}
      role={role}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {isLoading && (
        <div
          className={classNames([
            styles.singleLoaderWrapper,
            singleLoaderWrapperClassName,
            defaultStyles.singleLoaderCenterPosition,
          ])}
        >
          {renderLoader}
        </div>
      )}
      <div
        className={classNames(defaultStyles.buttonContent, {
          [defaultStyles.buttonContentHidden]: isLoading,
        })}
      >
        {!!leftIcon && (
          <Icon
            icon={leftIcon}
            styles={styles}
            className={leftIconClassName}
            renderLoader={renderLoader}
            position="left"
          />
        )}
        <span className={styles.buttonText}>{buttonText}</span>
        {!!rightContent && (
          <div className={defaultStyles.rightIcon}>{rightContent}</div>
        )}
        {!!rightIcon && (
          <Icon
            icon={rightIcon}
            styles={styles}
            className={rightIconClassName}
            renderLoader={renderLoader}
            position="right"
          />
        )}
      </div>
    </button>
  );
}

export default memo(Button);
