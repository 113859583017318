export default function isTextCropped(element: HTMLElement | null) {
  if (!element) {
    return false;
  }

  const range = document.createRange();
  range.selectNodeContents(element);
  const rangeRect = range.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();
  return (
    rangeRect.width > elementRect.width || rangeRect.height > elementRect.height
  );
}
