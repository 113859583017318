import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  children: JSX.Element | null;
  portalRootId: string;
}

function Portal({ children, portalRootId }: Props): JSX.Element | null {
  const portalElement = useRef(document.createElement('div')).current;

  useEffect(() => {
    const portalRoot = document.getElementById(portalRootId);

    if (!portalRoot) {
      return () => undefined;
    }

    portalRoot.appendChild(portalElement);

    return () => {
      portalRoot.removeChild(portalElement);
    };
  }, [portalRootId, portalElement]);

  return children ? createPortal(children, portalElement) : null;
}

export default Portal;
