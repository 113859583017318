import React, { memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import selectStyles from '@lib/utils/selectStyles';
import mergeCssModules from '@lib/utils/mergeCssModules';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import Tooltip from '@lib/components/Tooltip/Tooltip';
import isTextCropped from '@lib/utils/isTextCropped';
import defaultStyles from './Default.module.scss';
import themeStyles from './Chip.module.scss';

export enum ChipTypes {
  chipAquamarine = 'chip-aquamarine',
  chipBlack = 'chip-black',
  chipBlue = 'chip-blue',
  chipBrown = 'chip-brown',
  chipCorral = 'chip-corral',
  chipDisabled = 'chip-disabled',
  chipFilter = 'chip-filter',
  chipGray = 'chip-gray',
  chipGreen = 'chip-green',
  chipLime = 'chip-lime',
  chipOrange = 'chip-orange',
  chipPink = 'chip-pink',
  chipRed = 'chip-red',
  chipViolet = 'chip-violet',
  chipWhite = 'chip-white',
  chipYellow = 'chip-yellow',
}

export enum ChipSizes {
  large = 'large',
  medium = 'medium',
  small = 'small',
  extraSmall = 'extraSmall',
  smallLeftIconOnly = 'smallLeftIconOnly',
}

export interface ChipProps {
  asBlockElement?: boolean;
  className?: string;
  classes?: Record<string, string>;
  clickable?: boolean;
  counter?: number;
  fill?: boolean;
  isActive?: boolean;
  label?: React.ReactNode;
  leftIcon?: string;
  leftIconClassName?: string;
  leftIconComponent?: React.ReactNode;
  leftIconSymbolsOutlined?: boolean;
  leftImage?: string | null;
  leftImageClassName?: string;
  leftImageRounded?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  rightIconComponent?: React.ReactNode;
  rounded?: boolean;
  size?: ChipSizes;
  strikethrough?: boolean;
  target?: string;
  to?: string;
  tooltip?: string;
  tooltipForCroppedTextDisabled?: boolean;
  type: ChipTypes;
}

function Chip(props: ChipProps) {
  const {
    asBlockElement,
    className,
    classes,
    clickable,
    counter,
    fill = true,
    isActive,
    label,
    leftIcon,
    leftIconClassName,
    leftIconComponent,
    leftIconSymbolsOutlined,
    leftImage,
    leftImageClassName,
    leftImageRounded,
    onClick,
    onClose,
    rightIconComponent,
    rounded,
    size = ChipSizes.small,
    strikethrough,
    target,
    to,
    tooltip,
    tooltipForCroppedTextDisabled,
    type,
  } = props;

  const textRef = useRef<HTMLElement>(null);
  const navigate = useNavigate();
  const styles = mergeCssModules(
    defaultStyles,
    selectStyles(themeStyles, classes),
  );
  const [isTooltipForCroppedTextDisabled, setIsTooltipForCroppedTextDisabled] =
    useState(true);

  const hasLabel = !!label;

  const chipClassName = classNames(
    {
      [defaultStyles.root]: true,
      [defaultStyles.asBlockElement]: asBlockElement,
      [styles.root]: true,
      [styles[type]]: true,
      [styles[size]]: true,
      [styles.active]: isActive,
      [styles.rounded]: rounded,
      [styles.hasLabel]: hasLabel,
      [styles.pointer]: clickable || onClick !== undefined || to !== undefined,
      [styles.transparent]: !fill,
    },
    className,
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (to && target === '_blank') {
      window.open(to, '_blank')?.focus();
    } else if (to && navigate) {
      navigate(to);
    } else if (onClick) {
      onClick(event);
    }
  };

  const getLeftIcon = () => {
    if (leftIconComponent) {
      return <span className={styles.leftIcon}>{leftIconComponent}</span>;
    }
    if (leftImage) {
      return (
        <img
          src={leftImage}
          alt=""
          className={classNames(styles.leftImage, leftImageClassName, {
            [styles.leftImageRounded]: leftImageRounded,
          })}
        />
      );
    }
    if (leftIcon) {
      let iconSize = 'md-16';
      if (leftIcon === 'circle' && size !== ChipSizes.smallLeftIconOnly) {
        iconSize = 'md-12';
      }
      if (size === ChipSizes.extraSmall) {
        iconSize = 'md-8';
      }
      return (
        <MaterialIcon
          icon={leftIcon}
          size={iconSize}
          className={classNames(styles.leftIcon, leftIconClassName)}
          symbolsOutlined={leftIconSymbolsOutlined}
        />
      );
    }
    return undefined;
  };

  const getRightIcon = () => {
    if (rightIconComponent) {
      return <span className={styles.rightIcon}>{rightIconComponent}</span>;
    }
    if (onClose) {
      return (
        <MaterialIcon
          icon="cancel"
          size="md-24"
          className={styles.rightIcon}
          onClick={onClose}
        />
      );
    }
    return null;
  };

  const chipContent = (
    <div className={classNames(defaultStyles.chipContent)}>
      {getLeftIcon()}
      {hasLabel && (
        <span
          className={classNames(styles.chipText, {
            [styles.chipTextStrikethrough]: strikethrough,
          })}
          ref={textRef}
        >
          {label}
        </span>
      )}
      {!!counter && <span className={styles.chipCounter}>{counter}</span>}
      {getRightIcon()}
    </div>
  );

  const chip =
    size === ChipSizes.smallLeftIconOnly ? (
      <span className={chipClassName}>{chipContent}</span>
    ) : (
      <button type="button" className={chipClassName} onClick={handleClick}>
        {chipContent}
      </button>
    );

  useEffect(() => {
    const textRefEl = textRef.current;
    if (textRefEl) {
      setIsTooltipForCroppedTextDisabled(
        tooltipForCroppedTextDisabled ||
          !label ||
          !isTextCropped(textRef.current),
      );
    }
  }, [label, tooltipForCroppedTextDisabled]);

  if (!isTooltipForCroppedTextDisabled) {
    return <Tooltip body={label}>{chip}</Tooltip>;
  }

  if (tooltip) {
    return <Tooltip body={tooltip}>{chip}</Tooltip>;
  }

  return chip;
}

export default memo(Chip);
