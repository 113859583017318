// TODO: find a better way to handle colors

export const blue500 = '#214EEB';
export const blue400 = '#5069F0';
export const blue300 = '#7486F3';
export const blue200 = '#9FA8F6';
export const blue100 = '#C6CAFA';
export const blue50 = '#E9EAFD';
export const blue20 = '#F1F2FF';

export const gray1000 = '#000';
export const gray900 = '#24273A';
export const gray700 = '#414458';
export const gray600 = '#F8F9FE33';
export const gray500 = '#5F6D7E';
export const gray400 = '#9C9EB6';
export const gray200 = '#D6D8F0';
export const gray100 = '#E4E6F8';
export const gray60 = '#697077';
export const gray50 = '#F0F2FD';
export const gray10 = '#F8F9FE';
export const gray0 = '#FFFFFF';

export const disable500 = '#939295';
export const disable300 = '#DADADB';
export const disable400 = '#9C9EB6';
export const disable100 = '#F0F0F0';

export const neutral600 = '#E6E9EC';

export const error900 = '#EB214D';
export const error200 = '#FBBECB';
export const error100 = '#FCD7DF';

export const warning200 = '#FFE5BF';
export const warning900 = '#FFA51F';
export const warning100 = '#FFF3DF';

export const success900 = '#00B67A';
export const success200 = '#C6F2E0';
export const success100 = '#E3F6EE';

export const red = '#EB214D';
export const redLight = '#FCD7DF';
export const corral = '#FF551F';
export const corralLight = '#FFE3DB';
export const orange = '#FFA51F';
export const orangeLight = '#FFF3DF';
export const yellow = '#FFE500';
export const yellowLight = '#FFFBE7';
export const lime = '#7FE300';
export const limeLight = '#E7F9D1';

export const green = '#00B67A';
export const greenLight = '#DBFFE5';

export const brown = '#5B3905';
export const brownLight = '#DBCAB0';

export const pink = '#EB21E3';
export const pinkLight = '#FFE1FC';

export const violet = '#8621EB';
export const violetLight = '#F0DDFF';

export const blue = '#214EEB';
export const blueLight = '#DCDEFF';

export const aquamarine = '#00ABB6';
export const aquamarineLight = '#E0FFFF';
