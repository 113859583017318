import React from 'react';
import classNames from 'classnames';
import selectStyles from '@lib/utils/selectStyles';
import { StyleObj } from '@lib/interfaces/style';
import defaultStyles from './Default.module.scss';

interface Props {
  value?: string;
  maxLength?: number;
  className?: string;
  classes?: StyleObj;
}

function CharsRemaining(props: Props) {
  const { value = '', maxLength = 255, className, classes } = props;
  const styles = selectStyles(defaultStyles, classes);
  const length = value ? value.length : 0;
  return (
    <span
      className={classNames(styles.main, className)}
      data-test-id="chars-remaining"
    >
      {maxLength - length} of {maxLength} characters remaining
    </span>
  );
}

export default CharsRemaining;
