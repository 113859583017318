import { Values } from '@lib/interfaces/form';

export default function replaceEmptyStringWithNull(
  obj: any,
  skipNames?: string[],
): Values {
  if (typeof obj === 'object' && obj !== null) {
    const newObj = {}; // Create a new object

    Object.keys(obj).forEach((key) => {
      const skipValueReplace = (skipNames || [])?.includes(key);
      const value = obj[key];
      if (value === '' && !skipValueReplace) {
        newObj[key] = null;
      } else if (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        !(obj as { $$typeof: symbol }).$$typeof &&
        !skipValueReplace
      ) {
        newObj[key] = replaceEmptyStringWithNull(value);
      } else if (Array.isArray(value) && value.length && !skipValueReplace) {
        newObj[key] = value.map((item: unknown) => {
          if (typeof item === 'object' && item !== null) {
            return replaceEmptyStringWithNull(item);
          }
          return item;
        });
      } else {
        newObj[key] = value; // Copy other properties as-is
      }
    });

    return newObj; // Return the new object
  }

  return obj; // Return the original input if it's not an object
}
