import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _noop from 'lodash/noop';
import CircleLoaderWrapper, {
  CircleLoaderWrapperType,
} from '@lib/components/CircleLoaderWrapper/CircleLoaderWrapper';
import { FormProps } from './types';
import { useFieldArrayContext } from './FormProvider';
import styles from './Form.module.scss';

function Form(props: FormProps) {
  const {
    actions,
    actionsComponent: ActionsComponent,
    actionsComponentProps,
    fieldsArray,
    formClassName,
    formId,
    formItemsClassName,
    formState,
    handleSubmit,
    loading = false,
    onSubmitHandler,
    renderAfterForm,
    sourceFields,
    stepsCount,
  } = props;
  const { errors, isDirty } = formState;

  const { fieldArrayMethodsByName } = useFieldArrayContext();

  const handleDrag: OnDragEndResponder = useCallback(
    (result) => {
      const { source, destination, draggableId, reason } = result;
      const destinationIndex = destination?.index;
      const destinationDroppableId = destination?.droppableId;
      const sourceDroppableId = source?.droppableId;
      const fieldName = destinationDroppableId?.split('-')[1];
      if (fieldName && reason === 'DROP') {
        const fieldArrayMethods = _get(
          fieldArrayMethodsByName,
          fieldName,
          undefined,
        );
        const handleMove = _get(fieldArrayMethods, 'move', _noop);
        const handleInsert = _get(fieldArrayMethods, 'insert', _noop);
        if (destinationIndex !== undefined) {
          if (destinationDroppableId === sourceDroppableId) {
            if (handleMove) {
              handleMove(source.index, destinationIndex);
            }
          } else if (
            destinationDroppableId === 'FieldArrayDroppable-questions' &&
            sourceDroppableId === 'FieldArrayDroppable-questions-source'
          ) {
            const subForm = sourceFields?.questions?.find(
              ({ id }) => id === draggableId,
            );
            if (subForm) {
              handleInsert(
                destinationIndex,
                _omit(subForm, 'renderCustomIcon', 'nameTranslationKey'),
              );
            }
          }
        }
      }
      return result;
    },
    [fieldArrayMethodsByName, sourceFields?.questions],
  );

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <form
        // @ts-ignore
        onSubmit={handleSubmit(onSubmitHandler)}
        className={formClassName}
        id={formId}
        autoComplete="off"
      >
        <CircleLoaderWrapper
          type={CircleLoaderWrapperType.blur}
          isLoading={loading}
          rootClassName={styles.circleLoaderWrapper}
        >
          <div className={classNames(styles.formItems, formItemsClassName)}>
            {fieldsArray.map((field) => field)}
          </div>
        </CircleLoaderWrapper>
        {actions !== undefined && (
          <div>
            {actions({
              // @ts-ignore
              errors,
              isDirty,
              loading,
              onSubmitForm: handleSubmit(onSubmitHandler),
            })}
          </div>
        )}
        {ActionsComponent !== undefined && (
          <ActionsComponent
            {...(actionsComponentProps || {})}
            stepsCount={stepsCount}
            isDirty={isDirty}
            loading={loading}
          />
        )}
      </form>
      {renderAfterForm !== undefined && renderAfterForm()}
    </DragDropContext>
  );
}

export default memo(Form);
