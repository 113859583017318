import { useState, useEffect } from 'react';
import { getWindowSizes } from '@lib/utils/getDimensions';

export default function useWindowDimensions(): {
  width: number;
  height: number;
} {
  const [windowDimensions, setWindowDimensions] = useState(getWindowSizes());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowSizes());
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return windowDimensions;
}
