import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@lib/components/Modal/Modal';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import ActionsRow from '@lib/components/ActionsRow/ActionsRow';
import { IconName } from '@lib/components/Modal/enums';

interface ConfirmationModalProps {
  cancelButtonLabel?: string;
  confirmButtonLabel: string;
  description: React.ReactNode;
  icon: IconName;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
}

function ConfirmationModal({
  cancelButtonLabel,
  confirmButtonLabel,
  description,
  icon,
  isOpen,
  onClose,
  onConfirm,
  title,
}: ConfirmationModalProps) {
  const { t } = useTranslation();
  return (
    <Modal
      actions={
        <ActionsRow
          actions={[
            <Button
              onClick={onClose}
              buttonType={ButtonTypes.secondaryOutlined}
              buttonText={cancelButtonLabel || t('cancel')}
              leftIcon="close"
              fullWidth
            />,
            <Button
              onClick={onConfirm}
              buttonType={ButtonTypes.primaryFilled}
              buttonText={confirmButtonLabel}
              leftIcon="check"
              fullWidth
            />,
          ]}
        />
      }
      description={description}
      icon={icon}
      isOpen={isOpen}
      onClose={onClose}
      title={title}
    />
  );
}

export default ConfirmationModal;
