import React, { Suspense } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ApolloProvider } from '@apollo/client';
import ApolloClient from 'graphql-common/ApolloClient';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import { APP_URLS } from 'constants/urls';
import { BreadCrumbsProvider } from '@lib/components/BreadCrumbs/BreadCrumbsContext';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import AntdConfigProvider from '@lib/components/Antd/ConfigProvider';
import NotFoundPage from './NotFoundPage';
import ResetPassword from './ResetPassword';
import ProtectedRoute from './ProtectedRoute';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import SetupPassword from './SetupPassword';
import FinishRegistration from './FinishRegistration';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <ProtectedRoute />,
    children: [
      {
        path: APP_URLS.auth.index.path,
        element: <AuthLayout />,
        children: [
          {
            path: APP_URLS.auth.login.path,
            element: <Login />,
          },
          {
            path: APP_URLS.auth.forgotPassword.path,
            element: <ForgotPassword />,
          },
          {
            path: APP_URLS.auth.resetPassword.path,
            element: <ResetPassword />,
          },
          {
            path: APP_URLS.auth.index.path,
            index: true,
            element: <Navigate to={APP_URLS.auth.login.path} replace />,
          },
        ],
      },
      {
        path: APP_URLS.setup.index.path,
        element: <AuthLayout />,
        children: [
          {
            path: APP_URLS.setup.finishRegistration.path,
            element: <FinishRegistration />,
          },
          {
            path: APP_URLS.setup.confirmEmail.path,
            element: <FinishRegistration />,
          },
          {
            path: APP_URLS.setup.updatePassword.path,
            element: <SetupPassword />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]);

function App(): React.ReactElement {
  return (
    <ApolloProvider client={ApolloClient}>
      <AntdConfigProvider>
        <BreadCrumbsProvider>
          <Suspense fallback={<CircleLoader />}>
            <RouterProvider router={router} />
          </Suspense>
        </BreadCrumbsProvider>
      </AntdConfigProvider>
    </ApolloProvider>
  );
}

export default App;
