import React, { forwardRef, memo, useMemo } from 'react';
import classNames from 'classnames';
import _some from 'lodash/some';
import _filter from 'lodash/filter';
import { v4 as uuidv4 } from 'uuid';
import CheckBox from '@lib/components/CheckBox/CheckBox';
import { TFunction } from 'i18next';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import styles from './CheckBoxGroup.module.scss';

export enum CheckBoxWrapViews {
  row = 'row',
  list = 'list',
  calendar = 'calendar',
  monthList = 'monthList',
}

export interface CheckBoxGroupOption {
  label?: React.ReactNode;
  labelKey?: string;
  value: string;
}

export interface CheckBoxGroupProps {
  CheckBoxComponent?: React.JSX.ElementType;
  checkBoxComponentProps?: Record<string, unknown>;
  itemClass?: string;
  label?: React.ReactNode;
  name?: string;
  onChange?: (v: CheckBoxGroupOption[]) => void;
  options: CheckBoxGroupOption[];
  value?: CheckBoxGroupOption[];
  wrapClass?: string;
  wrapView?: CheckBoxWrapViews;
}

function getOptionLabel(
  option: CheckBoxGroupOption,
  t: TFunction<'translation', undefined>,
) {
  if (option?.labelKey) return t(option.labelKey);
  return _get(option, 'label', '') as string;
}

const CheckBoxGroup = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: CheckBoxGroupProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      CheckBoxComponent = CheckBox,
      itemClass,
      label,
      name: fieldName,
      onChange,
      options = [],
      value: fieldValue,
      wrapClass,
      wrapView = CheckBoxWrapViews.list,
      checkBoxComponentProps,
      ...rest
    } = props;
    const { t } = useTranslation();
    const hasLabel = !!label && typeof label === 'string';
    const hasCustomLabel = !!label && typeof label !== 'string';

    const preparedOptions = useMemo(
      () =>
        options.map((option) => ({
          ...option,
          label: getOptionLabel(option, t),
        })),
      [options, t],
    );

    const onChangeHandler = (v: CheckBoxGroupOption) => {
      if (onChange) {
        const exists = _some(fieldValue, ['value', v.value]);
        let newValue: CheckBoxGroupOption[];
        if (exists) {
          newValue = _filter(fieldValue, (item) => item.value !== v.value);
        } else {
          newValue = Array.isArray(fieldValue) ? [...fieldValue, v] : [v];
        }
        onChange(newValue);
      }
    };

    return (
      <div
        className={classNames(styles.groupRoot, {
          [styles.rootWithLabel]: hasLabel,
        })}
      >
        {hasLabel && <span className={styles.groupLabel}>{label}</span>}
        {hasCustomLabel && label}
        <div
          className={classNames(wrapClass, {
            [styles.defaultList]: wrapView === CheckBoxWrapViews.list,
            [styles.defaultRow]: wrapView === CheckBoxWrapViews.row,
            [styles.calendarRow]: wrapView === CheckBoxWrapViews.calendar,
            [styles.monthListRow]: wrapView === CheckBoxWrapViews.monthList,
          })}
        >
          {preparedOptions.map((option) => {
            const { value, label: optionLabel } = option;
            return (
              <div className={itemClass} key={uuidv4()}>
                <CheckBoxComponent
                  {...rest}
                  {...checkBoxComponentProps}
                  checked={
                    Array.isArray(fieldValue)
                      ? _some(fieldValue, { value: option.value })
                      : false
                  }
                  id={`${fieldName}-${value}`}
                  label={optionLabel}
                  name={fieldName}
                  onChange={() => onChangeHandler(option)}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  },
);

export default memo(CheckBoxGroup);
