import React from 'react';
import AuthLayoutCommon from '@lib/layouts/AuthLayout/AuthLayout';
import { APP_URLS } from 'constants/urls';
import { captureException } from 'utils/captureException';

function AuthLayout(): React.ReactElement {
  return (
    <AuthLayoutCommon
      captureException={captureException}
      finishRegistrationUrl={APP_URLS.setup.finishRegistration.path}
      forgotPasswordUrl={APP_URLS.auth.forgotPassword.path}
      loginUrl={APP_URLS.auth.login.path}
      logoUrl={APP_URLS.root.getPathWithQuery()}
    />
  );
}

export default AuthLayout;
