import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import styles from './CountdownCircleTimer.module.scss';

const renderTime = ({ remainingTime }: { remainingTime: number }) => (
  <div className={styles.value}>{remainingTime}</div>
);

interface Props {
  size: number;
  duration: number;
  onComplete?: () => void;
}

function CountdownCircleTimerCommon({ size, duration, onComplete }: Props) {
  return (
    <CountdownCircleTimer
      isPlaying
      size={size}
      duration={duration}
      // @ts-ignore
      colors="#7486F3"
      strokeWidth={2}
      onComplete={onComplete}
      trailColor="#9C9EB6"
    >
      {renderTime}
    </CountdownCircleTimer>
  );
}

export default CountdownCircleTimerCommon;
