import React from 'react';
import { ConfigProvider, ConfigProviderProps } from 'antd';
import frFR from 'antd/es/locale/fr_FR';
import usGB from 'antd/es/locale/en_GB';
import * as VARS from '@lib/assets/styles/colors';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';

const dateFnsLocaleMap = {
  fr: frFR,
  en: usGB,
};

const inputConfig = {
  borderRadius: 6,
  fontSize: 14,
  inputFontSizeLG: 14,
  inputFontSizeSM: 14,
  inputFontSizeSX: 14,
  lineHeightLG: 1.42,
  lineHeightSM: 1.42,
  lineHeightXS: 1.42,
  paddingInline: 16,
  paddingInlineLG: 16,
  paddingInlineSM: 16,
  paddingInlineXS: 16,
  paddingBlockLG: 16,
  paddingBlockSM: 16,
  paddingBlockXS: 16,
  activeShadow: `inset 0 0 0 1px ${VARS.blue500}`,
  errorActiveShadow: `inset 0 0 0 1px ${VARS.error900}`,
  hoverBorderColor: VARS.gray700,
  activeBorderColor: VARS.blue500,
  colorIcon: VARS.gray700,
  colorBgContainer: VARS.gray0,
  colorBgContainerDisabled: VARS.gray0,
  colorTextDisabled: VARS.disable500,
  colorTextPlaceholder: VARS.gray400,
  colorErrorOutline: VARS.error900,
  colorErrorBorderHover: VARS.error900,
};

const theme = {
  token: {
    borderRadiusLG: 6,
    colorPrimary: VARS.blue500,
    colorLink: VARS.success900,
    colorSuccess: VARS.success900,
    colorError: VARS.error900,
    colorErrorText: VARS.error900,
    colorErrorBgHover: VARS.error900,
    colorBorder: VARS.gray200,
    colorWarning: VARS.warning900,
    colorText: VARS.gray700,
    fontFamily: "'Inter', sans-serif",
  },
  components: {
    Input: {
      ...inputConfig,
    },
    TextArea: {
      ...inputConfig,
    },
    DatePicker: {
      ...inputConfig,
      sizePopupArrow: 0,
      zIndexPopup: 100, // $zIndexDropDown
    },
    Popover: {
      zIndexPopup: 100, // $zIndexDropDown
    },
  },
};

export default function ConfigProviderCommon(props: ConfigProviderProps) {
  const { children, ...rest } = props;
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const locale = _get(dateFnsLocaleMap, currentLocale, dateFnsLocaleMap.fr);

  return (
    <ConfigProvider {...rest} locale={locale} theme={theme}>
      {children}
    </ConfigProvider>
  );
}
