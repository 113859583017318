import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';
import classNames from 'classnames';
import Typography from '@lib/components/Typography/Typography';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import useStepParam from '@lib/hooks/useStepParam';
import styles from './PageSteps.module.scss';

interface Props {
  data: { title: string; optional?: boolean }[];
  errorsByStep?: Record<number, FieldError[]>;
}

function PageSteps({ data, errorsByStep }: Props) {
  const { t } = useTranslation();
  const [step, setStep] = useStepParam();
  const stepIndex = step ? Number(step) - 1 : 0;
  const getErrorsCount = (index: number) => {
    if (!errorsByStep) return null;
    const count = errorsByStep[index + 1]?.length;
    if (!count) return null;
    return (
      <Typography variant="caption" className={styles.stepErrorCount}>
        {count}
      </Typography>
    );
  };
  return (
    <div className={styles.steps}>
      <div className={styles.stepsGrid}>
        {data.map(({ title, optional }, index) => (
          <button
            type="button"
            onClick={() => setStep(index + 1)}
            className={classNames(styles.step, {
              [styles.stepActive]: stepIndex === index,
              [styles.stepCompleted]: stepIndex > index,
            })}
            key={title}
          >
            <div className={styles.stepGrid}>
              <div className={styles.stepNumber}>
                {stepIndex > index ? (
                  <MaterialIcon icon="check" />
                ) : (
                  <Typography variant="h3">{index + 1}</Typography>
                )}
              </div>
              <div>
                <div className={styles.stepTitle}>
                  <Typography variant="body" strong>
                    {title}
                  </Typography>
                  {getErrorsCount(index)}
                </div>
                {optional && (
                  <Typography
                    variant="label"
                    className={styles.stepOptional}
                    block
                  >
                    {t('optional')}
                  </Typography>
                )}
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}

export default PageSteps;
