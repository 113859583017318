import { useEffect, useState } from 'react';
import _debounce from 'lodash/debounce';

type EffectiveType = 'slow-2g' | '2g' | '3g' | '4g' | '5g' | unknown;

interface NetworkInformation {
  effectiveType?: EffectiveType;
  downlink?: number; // Mbps
  rtt?: number; // Ping
  saveData?: boolean;
  addEventListener?: (type: string, listener: (event: Event) => void) => void;
  removeEventListener?: (
    type: string,
    listener: (event: Event) => void,
  ) => void;
}

export interface IsOnlineValues {
  error: null | string;
  isOnline: boolean;
  connectionType?: EffectiveType;
  downlink?: number; // Mbps
}

const MISSING_BROWSER_ERROR =
  'useIsOnline only works in a browser environment.';

const nav = navigator as Navigator & { connection?: NetworkInformation };

const getStatus = () => {
  const isBrowser =
    typeof window !== 'undefined' && typeof navigator !== 'undefined';
  const isOnline = nav.onLine;
  const downlink = isBrowser ? nav.connection?.downlink || 0 : undefined;
  const connectionType = isBrowser
    ? nav.connection?.effectiveType || 'unknown'
    : undefined;
  const error = isBrowser ? null : MISSING_BROWSER_ERROR;
  return {
    error,
    isOnline,
    connectionType,
    downlink,
  };
};

const useIsOnline = (): boolean => {
  const isBrowser =
    typeof window !== 'undefined' && typeof navigator !== 'undefined';

  const [status, setStatus] = useState<IsOnlineValues>(getStatus());

  const updateStatus = _debounce(() => {
    setStatus(getStatus());
  }, 1000);

  useEffect(() => {
    if (!isBrowser) return () => {};

    window.addEventListener('online', updateStatus);
    window.addEventListener('offline', updateStatus);
    if (nav.connection?.addEventListener) {
      nav.connection.addEventListener('change', updateStatus);
    }

    return () => {
      window.removeEventListener('online', updateStatus);
      window.removeEventListener('offline', updateStatus);
      if (nav.connection?.removeEventListener) {
        nav.connection.removeEventListener('change', updateStatus);
      }
    };
  }, [isBrowser, updateStatus]);

  return status.isOnline;
};

export default useIsOnline;
