import React, { memo } from 'react';
import classNames from 'classnames';
import Typography from '@lib/components/Typography/Typography';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import styles from './SupportiveMessage.module.scss';

export enum SupportiveMessageColor {
  Blue = 'blue',
  Yellow = 'yellow',
  Gray = 'gray',
  Red = 'red',
}

export enum SupportiveMessageType {
  FilledWithBorder = 'filledWithBorder',
  FilledWithLeftBorder = 'filledWithLeftBorder',
  FilledOnly = 'filledOnly',
  Transparent = 'transparent',
}

export enum SupportiveMessageIconColor {
  Gray = 'iconGray',
  Inner = 'iconInner',
}

export enum SupportiveMessageIconType {
  FilledWithBorder = 'filledIconWithBorder',
  Filled = 'filledIcon',
}

export interface SupportiveMessageProps {
  actions?: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  hasBottomSpace?: boolean;
  leftIcon?: string;
  text?: React.ReactNode;
  color?: SupportiveMessageColor;
  type?: SupportiveMessageType;
  iconColor?: SupportiveMessageIconColor;
  iconType?: SupportiveMessageIconType;
}

function SupportiveMessage(props: SupportiveMessageProps) {
  const {
    actions,
    className,
    fullWidth,
    hasBottomSpace = true,
    leftIcon,
    text,
    color = SupportiveMessageColor.Blue,
    type = SupportiveMessageType.FilledWithLeftBorder,
    iconColor = SupportiveMessageIconColor.Gray,
    iconType = SupportiveMessageIconType.Filled,
  } = props;
  const hasActions = actions !== undefined;
  const hasLeftIcon = leftIcon !== undefined;
  if (!text) return null;
  const rootClassName = classNames(
    styles.messageRoot,
    {
      [styles.messageRootFullWidth]: fullWidth,
      [styles.messageRootFullBottomPadding]: hasBottomSpace,
    },
    className,
  );
  const messageClassName = classNames(
    styles.message,
    styles[color],
    styles[type],
    {
      [styles.withActions]: hasActions,
      [styles.withLeftIcon]:
        hasLeftIcon && iconType !== SupportiveMessageIconType.FilledWithBorder,
      [styles.withBigLeftIcon]:
        hasLeftIcon && iconType === SupportiveMessageIconType.FilledWithBorder,
    },
  );
  const iconClassName = classNames(
    styles.leftIcon,
    styles[iconColor],
    styles[iconType],
  );
  return (
    <div className={rootClassName}>
      <div className={messageClassName}>
        {hasLeftIcon && (
          <span className={iconClassName}>
            <MaterialIcon
              icon={leftIcon}
              size={
                iconType === SupportiveMessageIconType.FilledWithBorder ||
                type === SupportiveMessageType.Transparent
                  ? 'md-16'
                  : undefined
              }
            />
          </span>
        )}
        <Typography variant="label" block className={styles.messageContent}>
          {text}
        </Typography>
        {hasActions && <span className={styles.actions}>{actions}</span>}
      </div>
    </div>
  );
}

export default memo(SupportiveMessage);
