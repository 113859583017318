import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  useAuthenticationPasswordsResetMutation,
  ScopeEnum,
} from 'graphql-common';
import toast from '@lib/components/Toast/Toast';
import Form from '@lib/components/ReactHookForm/FormContainer';
import Typography from '@lib/components/Typography/Typography';
import { Values } from '@lib/interfaces/form';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import { APP_URLS } from 'constants/urls';
import { getResetPasswordFormFields } from 'utils/authUtils';
import { isTimestampExpired } from '@lib/utils/dateTimeHelpers';
import styles from '@lib/layouts/AuthLayout/AuthComponents.module.scss';
import formStyles from '@lib/assets/styles/Form.module.scss';
import useWhoamiQueryHook from 'utils/fetch/useWhoamiQueryHook';

const defaultValues = {
  password: '',
  passwordConfirmation: '',
};

function ResetPasswordForm() {
  const [completed, setCompleted] = useState(false);
  const { t } = useTranslation();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const resetPasswordToken = urlSearchParams.get('token') as string;
  const till = urlSearchParams.get('till') as string;

  const { refetch: whoamiRefetch, currentUserId } = useWhoamiQueryHook({
    fetchPolicy: 'cache-first',
  });

  const [updatePasswordWithTokenMutation, updatePasswordWithTokenResult] =
    useAuthenticationPasswordsResetMutation({
      onCompleted: () => {
        whoamiRefetch().then(() => {
          setCompleted(true);
        });
      },
      onError: (error: Error) => {
        toast({ content: error.message || 'Oops! Something went wrong' });
      },
    });
  const { error } = updatePasswordWithTokenResult;

  const fields = getResetPasswordFormFields({ t });

  const onSubmit = (values: Values) => {
    const password = values.password as string;
    const passwordConfirmation = values.passwordConfirmation as string;
    updatePasswordWithTokenMutation({
      variables: {
        input: {
          password,
          passwordConfirmation,
          token: resetPasswordToken,
          scope: ScopeEnum.User,
        },
      },
    });
  };

  if (till && isTimestampExpired(till)) {
    return (
      <>
        <Typography variant="h2" className={styles.title}>
          {t('expired-link-title')}
        </Typography>
        <Typography variant="body" className={styles.text}>
          {t('expired-link-text1')}
        </Typography>
        <Typography variant="body" className={styles.textLast}>
          {t('expired-link-text2')}
        </Typography>
        <Button
          buttonText={t('continue')}
          rightIcon="arrow_forward"
          to={APP_URLS.auth.forgotPassword.path}
          fullWidth
        />
      </>
    );
  }

  if (completed) {
    return (
      <>
        <Typography variant="h2" className={styles.title}>
          {t('reset-password-success')}
        </Typography>
        <Button
          buttonText={currentUserId ? t('continue') : t('log-in-now')}
          rightIcon="arrow_forward"
          to={APP_URLS.root.path}
          fullWidth
        />
      </>
    );
  }

  return (
    <div className={styles.resetPasswordForm}>
      <Typography variant="h2" className={styles.title}>
        {t('enter-new-password-title')}
      </Typography>
      <Form
        disableAwayConfirmation
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        fields={fields}
        formClassName={formStyles.form}
        graphQLErrors={error?.graphQLErrors}
        actionsComponent={Button}
        actionsComponentProps={{
          type: 'submit',
          buttonType: ButtonTypes.primaryFilled,
          buttonText: t('reset-password'),
          leftIcon: 'check',
          fullWidth: true,
        }}
      />
    </div>
  );
}

export default memo(ResetPasswordForm);
