import React, { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, useWatch } from 'react-hook-form';
import { FieldItem } from '@lib/interfaces/form';
import Typography from '@lib/components/Typography/Typography';
import SupportiveMessage, {
  SupportiveMessageColor,
  SupportiveMessageType,
} from '@lib/components/SupportiveMessage/SupportiveMessage';
import ControllerErrorRender from '@lib/components/ReactHookForm/ControllerErrorRender';
import getErrorStr from '@lib/components/ReactHookForm/getErrorStr';
import formStyles from './Form.module.scss';

interface Props {
  controllerProps: UseControllerReturn;
}

function ControllerRender(props: FieldItem & Props) {
  const {
    componentProps,
    controllerProps,
    customComponent: CustomComponent,
    customComponentProps,
    customRender,
    description,
    element: Element,
    formId,
    formItemClassName,
    formItemElementWrapClassName,
    getComponentProps = () => undefined,
    getDescription,
    getFieldKeyFn,
    getLeftLabel,
    getRightLabel,
    hideErrors = false,
    hideErrorsSpace = false,
    isDisabledFn,
    isOptionalFn,
    isUseWatch = false,
    isVisible = true,
    isVisibleFn,
    label,
    message,
    name,
    type,
    useKeyRefreshOnWindowFocus,
    validation,
  } = props;
  const {
    field,
    fieldState: { error },
  } = controllerProps;
  const { name: fieldName } = field;
  const { t } = useTranslation();
  const [lastFocusDate, setLastFocusDate] = useState<string>();
  const watch = useWatch({
    disabled:
      !isUseWatch &&
      isDisabledFn === undefined &&
      isVisibleFn === undefined &&
      isOptionalFn === undefined &&
      getDescription === undefined &&
      getLeftLabel === undefined &&
      getRightLabel === undefined &&
      getComponentProps === undefined,
  });
  const fieldComponentProps = getComponentProps
    ? { ...componentProps, ...getComponentProps(watch) }
    : componentProps;
  const disabledValue = isDisabledFn
    ? isDisabledFn(watch, name)
    : fieldComponentProps?.disabled;
  const formItemRootClassName = classNames(
    formItemClassName || formStyles.formItemColumn,
    {
      [formStyles.formItemWithErrors]: !(hideErrors || hideErrorsSpace),
      [formStyles.formItemWithLeftLabel]: !!getLeftLabel,
      [formStyles.formItemWithRightLabel]: !!getRightLabel,
    },
  );

  let newLabel = label;
  const isFieldOptional = (() => {
    if (isOptionalFn) return isOptionalFn(watch, name);
    return validation?.spec.optional;
  })();

  if (label && isFieldOptional) {
    newLabel = `${label} (${t('optional')})`;
  }

  let descriptionValue = description;
  if (getDescription) {
    descriptionValue = getDescription(watch, name);
  }

  let leftLabel;
  if (getLeftLabel) {
    leftLabel = getLeftLabel(watch, name);
  }

  let rightLabel;
  if (getRightLabel) {
    rightLabel = getRightLabel(watch, name);
  }

  let key = name;
  if (getFieldKeyFn) {
    key = getFieldKeyFn(watch, name, lastFocusDate);
  }
  if (useKeyRefreshOnWindowFocus && lastFocusDate) {
    key = `${key}-${lastFocusDate}`;
  }

  useEffect(() => {
    if (useKeyRefreshOnWindowFocus) {
      window.addEventListener('focus', () => {
        const nowDate = new Date();
        setLastFocusDate(nowDate.toISOString());
      });
    }
  }, [useKeyRefreshOnWindowFocus]);

  if (isVisibleFn && !isVisibleFn(watch, name)) {
    return null;
  }

  return (
    <div
      className={formItemRootClassName}
      key={key}
      style={isVisible ? undefined : { display: 'none' }}
    >
      {leftLabel && (
        <Typography variant="body" className={formStyles.leftLabel}>
          {leftLabel}
        </Typography>
      )}
      <div className={formItemElementWrapClassName}>
        {Element !== undefined && (
          <Element
            id={name}
            formId={formId}
            label={newLabel}
            hasError={!!error}
            error={getErrorStr(error)}
            type={type}
            {...field}
            {...fieldComponentProps}
            name={fieldName || name}
            disabled={disabledValue}
            values={watch}
          />
        )}
        {CustomComponent && (
          <CustomComponent {...(customComponentProps || {})} />
        )}
        {typeof customRender === 'function' && customRender(watch, name)}
        {descriptionValue !== undefined && (
          <SupportiveMessage
            className={formStyles.formItemDescription}
            text={descriptionValue}
            color={SupportiveMessageColor.Gray}
            type={SupportiveMessageType.Transparent}
            leftIcon="info"
          />
        )}
        {(message?.getTextFn !== undefined || message?.text !== undefined) && (
          <SupportiveMessage
            actions={message?.getActions?.(watch) || message?.actions}
            className={formStyles.formItemMessage}
            text={message.getTextFn?.(watch) || message.text}
            type={message?.type}
          />
        )}
        {!hideErrors && <ControllerErrorRender error={error} />}
      </div>
      {rightLabel && (
        <Typography variant="body" className={formStyles.rightLabel}>
          {rightLabel}
        </Typography>
      )}
    </div>
  );
}

function FormItem(props: FieldItem) {
  const {
    customComponent: CustomComponent,
    customRender,
    element: Element,
    name,
  } = props;
  if (!Element && !CustomComponent && !customRender) return null;
  return (
    <Controller
      name={name}
      render={(controllerProps) => (
        <ControllerRender controllerProps={controllerProps} {...props} />
      )}
    />
  );
}

export default memo(FormItem);
