import React, { forwardRef, memo } from 'react';
import classNames from 'classnames';
import mergeCssModules from '@lib/utils/mergeCssModules';
import selectStyles from '@lib/utils/selectStyles';
import Loader from '@lib/components/CircleLoader/CircleLoader';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import defaultStyles from './Default.module.scss';
import themeStyles from './IconButton.module.scss';

export type LoaderProps = { [key: string]: string | number };

export enum IconButtonTypes {
  PrimaryOutlined = 'primary-outlined',
  PrimaryFilled = 'primary-filled',
  SecondaryOutlined = 'secondary-outlined',
  SecondaryFilled = 'secondary-filled',
  SecondaryInvert = 'secondary-invert',
  TertiaryFilled = 'tertiary-filled',
  TertiaryInvert = 'tertiary-invert',
  QuaternaryOutlined = 'quaternary-outlined',
}

export enum IconButtonSizes {
  Medium = 'medium',
  Small = 'small',
}

export interface IconButtonProps {
  className?: string;
  classes?: Record<string, string>;
  disabled?: boolean;
  icon?: string;
  iconSize?: number;
  isFocused?: boolean;
  isLoading?: boolean;
  loaderProps?: LoaderProps;
  navigate?: (to: string) => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  size?: IconButtonSizes;
  svgIcon?: React.ReactNode;
  symbolsOutlined?: boolean;
  target?: string;
  to?: string;
  type?: IconButtonTypes;
  useRipple?: boolean;
  rounded?: boolean;
}

const createRipple = (event: React.MouseEvent<HTMLElement>) => {
  const button = event.currentTarget;
  const diameter = button.clientWidth;
  const circle = document.createElement('span');
  circle.style.width = `${diameter}px`;
  circle.style.height = `${diameter}px`;
  circle.style.left = '0px';
  circle.style.top = '0px';
  circle.classList.add(defaultStyles.ripple);
  const ripple = button.getElementsByClassName(defaultStyles.ripple)[0];
  if (ripple && ripple.parentElement !== null) {
    ripple.parentElement.removeChild(ripple);
  }
  button.appendChild(circle);
};

const IconButton = forwardRef(
  (props: IconButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      className,
      classes,
      disabled = false,
      icon,
      iconSize,
      isFocused,
      isLoading,
      loaderProps,
      navigate,
      onClick,
      size = IconButtonSizes.Medium,
      svgIcon,
      symbolsOutlined,
      target,
      to,
      type = IconButtonTypes.PrimaryOutlined,
      useRipple = true,
      rounded,
    } = props;

    let iconSizeValue = iconSize;
    if (!iconSizeValue && size === IconButtonSizes.Medium) {
      iconSizeValue = 24;
    } else if (!iconSizeValue && size === IconButtonSizes.Small) {
      iconSizeValue = 16;
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (useRipple) {
        createRipple(event);
      }
      if (to && target === '_blank') {
        window.open(to, '_blank')?.focus();
      } else if (to && navigate) {
        navigate(to);
      } else if (onClick) {
        onClick(event);
      }
    };

    const onMouseUp = (event: React.MouseEvent<HTMLElement>) =>
      event.currentTarget.blur();

    const styles = mergeCssModules(
      defaultStyles,
      selectStyles(themeStyles, classes),
    );

    const buttonClassName = classNames(
      defaultStyles.root,
      styles[type],
      styles[size],
      {
        [styles.disabled]: disabled,
        [styles.loading]: isLoading,
        [styles.focused]: isFocused,
        [styles.rounded]: rounded,
      },
      className,
    );

    return (
      <button
        ref={ref}
        type="button"
        className={buttonClassName}
        onMouseUp={onMouseUp}
        onClick={handleClick}
        disabled={disabled || isLoading}
      >
        {isLoading && (
          <div className={defaultStyles.loader}>
            <Loader
              color="currentColor"
              size={16}
              width={1}
              padding={0}
              {...loaderProps}
            />
          </div>
        )}
        <div
          className={classNames(defaultStyles.icon, {
            [defaultStyles.iconHidden]: isLoading,
          })}
        >
          {svgIcon || (
            <MaterialIcon
              icon={icon || ''}
              size={`md-${iconSizeValue}`}
              symbolsOutlined={symbolsOutlined}
            />
          )}
        </div>
      </button>
    );
  },
);

export default memo(IconButton);
