import { Values, Value } from '@lib/interfaces/form';

export default function removeIdFields(
  obj: Values,
  skipKeys?: string[],
): Values {
  // Handle the case when obj is a React element
  if (obj.$$typeof) return obj;

  // Handle the case when obj is an array
  if (Array.isArray(obj)) {
    return obj.map((item) => {
      if (typeof item === 'object' && item !== null) {
        return removeIdFields(item as Values, skipKeys);
      }
      return item;
    }) as unknown as Values;
  }

  // Handle the case when obj is an object
  const objCopy: Values = { ...obj };
  Object.keys(objCopy).forEach((key) => {
    const shouldSkip = skipKeys?.length ? skipKeys?.indexOf(key) > -1 : false;
    if (key === 'id') {
      delete objCopy[key];
    } else if (!shouldSkip) {
      const value = objCopy[key];
      if (typeof value === 'object' && value !== null) {
        objCopy[key] = removeIdFields(value as Values, skipKeys) as
          | Value
          | Value[];
      }
    }
  });

  return objCopy;
}
