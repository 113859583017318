import { FieldArrayItemType } from '@lib/components/ReactHookForm/types';

export default function findNewElementIndex(
  prevFields?: FieldArrayItemType[],
  fields?: FieldArrayItemType[],
): number | undefined {
  if (!prevFields || !fields) return undefined;
  const prevIds = new Set(
    prevFields.map((field) => field?.id).filter((field) => !!field),
  );
  return fields.findIndex((field) => !prevIds.has(field?.id));
}
