import React from 'react';
import styles from './DashboardContentMaxWidth.module.scss';

interface Props {
  children: React.ReactNode;
}
function DashboardContentMaxWidth({ children }: Props) {
  return <div className={styles.root}>{children}</div>;
}

export default DashboardContentMaxWidth;
