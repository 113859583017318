import React, { forwardRef, useEffect, useRef } from 'react';
import { useMaskito } from '@maskito/react';
import { MaskitoOptions } from '@maskito/core';
import _toNumber from 'lodash/toNumber';
import { StyleObj } from '@lib/interfaces/style';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import { InputTypes } from '@lib/components/Input/enums';
import { MAX_NUMBER_VALUE } from '@lib/enums/form';

const formatValue = (val: number, precision?: number) => {
  return _toNumber(val.toFixed(precision || 0));
};

type Props = {
  autoFocus?: boolean;
  disabled?: boolean;
  id?: string;
  inputClassName?: string;
  isFocused?: boolean;
  mask?: MaskitoOptions;
  max?: number;
  maxLength?: number;
  min?: number;
  onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  precision?: number;
  readOnly?: boolean;
  setIsFocused: (isFocused: boolean) => void;
  step?: string | number;
  styles: StyleObj;
  value?: string;
  type?: string;
};

const InputComponent = forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLInputElement | null>) => {
    const {
      autoFocus,
      disabled,
      id,
      inputClassName,
      isFocused,
      mask,
      maxLength,
      onChangeHandler,
      onClick,
      onKeyDown,
      placeholder,
      precision,
      readOnly,
      setIsFocused,
      step,
      styles,
      value = '',
      type,
      ...restProps
    } = props;
    const { max = MAX_NUMBER_VALUE, min } = restProps;
    const stepNumber = _toNumber(step) || 1;
    const inputType = type === InputTypes.number ? InputTypes.text : type;
    const defaultMask: MaskitoOptions = { mask: /^[\s\S]*$/ };
    const maskitoRef = useMaskito({ options: mask || defaultMask });
    const innerRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      if (innerRef.current) {
        maskitoRef(innerRef.current);
      }
    }, [maskitoRef]);

    const combinedRef = (element: HTMLInputElement | null) => {
      innerRef.current = element;
      if (typeof ref === 'function') {
        ref(element);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = element;
      }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (type !== InputTypes.number) {
        if (onChangeHandler) {
          onChangeHandler(event);
        }
        return;
      }

      const inputValue = event.target.value;

      const regex =
        precision && precision > 0
          ? new RegExp(`^-?\\d*(\\.\\d{0,${precision}})?$`)
          : /^-?\d*$/;

      if (!regex.test(inputValue)) {
        event.preventDefault();
        return;
      }

      const numericValue = parseFloat(inputValue);
      if (
        (max !== undefined && numericValue > max) ||
        (min !== undefined && numericValue < min)
      ) {
        event.preventDefault();
        return;
      }

      if (onChangeHandler) {
        const newEvent = {
          target: { value: inputValue },
        } as React.ChangeEvent<HTMLInputElement>;
        onChangeHandler(newEvent);
      }
    };

    const handleIncrement = () => {
      const currentValue = _toNumber(value as string) || 0;
      let newValue = formatValue(currentValue + stepNumber, precision);

      if (max !== undefined && newValue > max) {
        newValue = max;
      }

      if (onChangeHandler) {
        const event = {
          target: { value: String(newValue) },
        } as React.ChangeEvent<HTMLInputElement>;
        onChangeHandler(event);
      }
    };

    const handleDecrement = () => {
      const currentValue = _toNumber(value as string) || 0;
      let newValue = formatValue(currentValue - stepNumber, precision);

      if (min !== undefined && newValue < min) {
        newValue = min;
      }

      if (onChangeHandler) {
        const event = {
          target: { value: String(newValue) },
        } as React.ChangeEvent<HTMLInputElement>;
        onChangeHandler(event);
      }
    };

    const onWheel = (event) => event.currentTarget.blur();

    const onFocus = () => setIsFocused(true);

    const onBlur = value ? undefined : () => setIsFocused(false);

    const isIncrementDisabled =
      max !== undefined && value !== undefined
        ? _toNumber(value) >= max
        : undefined;
    const isDecrementDisabled =
      min !== undefined && value !== undefined
        ? _toNumber(value) <= min
        : undefined;

    return (
      <>
        <input
          autoComplete="new-password"
          autoFocus={autoFocus}
          className={inputClassName}
          disabled={disabled}
          id={id}
          maxLength={maxLength}
          onBlur={onBlur}
          onChange={handleChange}
          onClick={onClick}
          onFocus={onFocus}
          onInput={handleChange}
          onKeyDown={onKeyDown}
          onWheel={onWheel}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={combinedRef}
          type={inputType}
          value={value}
          {...restProps}
        />
        {!disabled && type === InputTypes.number && (
          <div className={styles.inputNumberBtns}>
            <button
              type="button"
              onClick={handleIncrement}
              disabled={isIncrementDisabled}
            >
              <MaterialIcon icon="arrow_drop_up" />
            </button>
            <button
              type="button"
              onClick={handleDecrement}
              disabled={isDecrementDisabled}
            >
              <MaterialIcon icon="arrow_drop_down" />
            </button>
          </div>
        )}
      </>
    );
  },
);

export default InputComponent;
