import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import _noop from 'lodash/noop';
import _isEqual from 'lodash/isEqual';
import { FormProvider, useForm } from 'react-hook-form';
import { useBlocker } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Values } from '@lib/interfaces/form';
import PageSteps from '@lib/layouts/DashboardLayout/PageSteps/PageSteps';
import { Steps } from '@lib/enums/form';
import useStepParam from '@lib/hooks/useStepParam';
import Portal from '@lib/components/Portal/Portal';
import usePrevious from '@lib/hooks/usePrevious';
import replaceEmptyStringWithNull from '@lib/utils/replaceEmptyStringWithNull';
import { pageStepsId } from '@lib/enums/selectors';
import ConfirmationModal from '@lib/components/ConfirmationModal/ConfirmationModal';
import isDeeplyEmpty from '@lib/utils/isDeeplyEmpty';
import useFormPersistValues from '@lib/components/ReactHookForm/useFormPersistValues';
import isEqualWithSkipKeys from '@lib/utils/isEqualWithSkipKeys';
import { IconName } from '@lib/components/Modal/enums';
import { FormContainerProps } from './types';
import getFormData from './getFormData';
import getErrorsByStep from './getErrorsByStep';
import useHandleGraphQLErrors from './useHandleGraphQLErrors';
import FormProviderCustom from './FormProvider';
import Form from './Form';

export type { FormRef, ActionsProps } from './types';

function FormContainer(props: FormContainerProps) {
  const {
    actions,
    actionsComponent,
    actionsComponentProps,
    defaultValues,
    disableAwayConfirmation = false,
    fields,
    formClassName,
    formId = 'form',
    formItemsClassName,
    formRef,
    graphQLErrors,
    graphQLErrorsReplacePathMap,
    graphQLErrorsSkipPath,
    loading = false,
    onSubmit,
    persistForm = false,
    persistFormSeconds = 0,
    renderAfterForm,
    sourceAppendValues,
    sourceFields,
    steps,
    stepsCount,
    submitOnChange = false,
  } = props;
  const prevDefaultValues = usePrevious(defaultValues);
  const [stepParam] = useStepParam();
  const { t } = useTranslation();
  const [isAwayModalOpen, setAwayModalOpen] = useState(false);

  const stepIndex =
    steps && steps.length > 1 && stepParam ? Number(stepParam) : Steps.step1;

  const { setPersistedValues, getPersistedValues } = useFormPersistValues({
    formId,
    persistForm,
    persistFormSeconds,
  });

  const persistedValues = getPersistedValues();

  const { fieldsArray, schemaObj, fieldChangeCallBacks } = useMemo(
    () =>
      getFormData({
        fields,
        formId,
        formItemsClassName,
        stepIndex,
        t,
        sourceAppendValues,
      }),
    [fields, formId, formItemsClassName, sourceAppendValues, stepIndex, t],
  );
  const methods = useForm({
    // @ts-ignore
    defaultValues: { ...defaultValues, ...persistedValues },
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(Yup.object(schemaObj)),
    shouldFocusError: true,
  });
  const {
    handleSubmit,
    formState,
    reset,
    setError,
    setValue,
    watch,
    getValues,
  } = methods;
  const { errors, isSubmitSuccessful, isDirty } = formState;
  const values = getValues();

  const isStepsVisible = !!stepsCount && !!steps?.length;

  const {
    proceed = _noop,
    state,
    reset: blockerReset,
  } = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !disableAwayConfirmation &&
      isDirty &&
      !isDeeplyEmpty(values) &&
      !_isEqual(currentLocation.pathname, nextLocation.pathname),
  );

  const onSubmitHandler = useCallback(
    (newValues: Values) => {
      if (onSubmit) onSubmit(replaceEmptyStringWithNull(newValues));
    },
    [onSubmit],
  );

  const onAwayConfirmed = () => {
    setAwayModalOpen(false);
    reset();
    proceed();
  };

  const onCloseAwayModal = () => {
    setAwayModalOpen(false);
    if (blockerReset) blockerReset();
  };

  if (formRef) {
    formRef.current = {
      reset,
      errors,
      setValue,
      values,
    };
  }

  useEffect(() => {
    if (
      defaultValues &&
      prevDefaultValues &&
      !isEqualWithSkipKeys(defaultValues, prevDefaultValues, ['avatarAttached'])
    ) {
      reset({
        ...values,
        ...defaultValues,
      });
    }
  }, [defaultValues, values, prevDefaultValues, reset]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset]);

  useEffect(() => {
    const subscription = watch((newValues, { name, type }) => {
      if (submitOnChange && type === 'change') {
        handleSubmit(onSubmitHandler)();
      }
      // @ts-ignore
      setPersistedValues(newValues);
      fieldChangeCallBacks.forEach((fieldChangeCallBack) => {
        if (fieldChangeCallBack) {
          fieldChangeCallBack({
            values: newValues as Values,
            type: type as string,
            setValue,
            name,
          });
        }
      });
    });
    return () => subscription.unsubscribe();
  }, [
    watch,
    submitOnChange,
    handleSubmit,
    onSubmitHandler,
    fieldChangeCallBacks,
    setValue,
    setPersistedValues,
  ]);

  useEffect(() => {
    if (state === 'blocked') {
      setAwayModalOpen(true);
    }
  }, [state]);

  useEffect(
    () => () => {
      if (blockerReset) blockerReset();
    },
    [blockerReset],
  );

  useHandleGraphQLErrors({
    graphQLErrors,
    graphQLErrorsSkipPath,
    graphQLErrorsReplacePathMap,
    setError,
  });

  const errorsByStep = getErrorsByStep(fields, errors);

  return (
    <>
      {isStepsVisible && (
        <Portal portalRootId={pageStepsId}>
          <PageSteps data={steps} errorsByStep={errorsByStep} />
        </Portal>
      )}
      <FormProvider {...methods}>
        <FormProviderCustom>
          <Form
            actions={actions}
            actionsComponent={actionsComponent}
            actionsComponentProps={actionsComponentProps}
            fieldsArray={fieldsArray}
            formClassName={formClassName}
            formState={formState}
            handleSubmit={handleSubmit}
            loading={loading}
            onSubmitHandler={onSubmitHandler}
            renderAfterForm={renderAfterForm}
            sourceFields={sourceFields}
            // stepIndex={stepIndex}
            stepsCount={stepsCount}
          />
        </FormProviderCustom>
      </FormProvider>
      <ConfirmationModal
        confirmButtonLabel={t('leave-without-saving')}
        description={t('changes-are-not-saved-desc')}
        icon={IconName.Warning}
        isOpen={isAwayModalOpen}
        onClose={onCloseAwayModal}
        onConfirm={onAwayConfirmed}
        title={t('changes-are-not-saved')}
      />
    </>
  );
}

export default memo(FormContainer);
