import React from 'react';

export enum InputTypes {
  date = 'date',
  dateRange = 'dateRange',
  dateTime = 'dateTime',
  number = 'number',
  password = 'password',
  tel = 'tel',
  text = 'text',
  textarea = 'textarea',
  time = 'time',
  timeRange = 'timeRange',
}

export type TooltipShape = {
  isPopoverOpened?: boolean;
  maxWidth?: number;
  children?: React.JSX.Element;
  body: React.ReactNode;
  tipSize?: number;
  className?: string;
  preferPlace?: string;
  contentClassname?: string;
};

export enum InputSizes {
  large = 'large',
  medium = 'medium',
}

export type ComponentsShape = {
  Tooltip?: () => React.JSX.Element;
  CharsRemaining?: () => React.JSX.Element;
};
