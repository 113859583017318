import * as Sentry from '@sentry/react';

type Args = {
  isUserAuth: boolean;
  userEmail?: string;
  userId?: string;
  userType?: string;
  username?: string;
};

export function setSentryTags({
  isUserAuth,
  userEmail,
  userId,
  userType,
  username,
}: Args) {
  if (isUserAuth) {
    Sentry.setUser({ id: userId, email: userEmail, type: userType, username });
    Sentry.setTag('user.type', userType);
    Sentry.setTag('user.email', userEmail);
    Sentry.setTag('user.id', userId);
    Sentry.setTag('user.username', username);
  } else {
    Sentry.setUser({});
    Sentry.setTag('user.type', 'anonymous');
    Sentry.setTag('user.email', null);
    Sentry.setTag('user.id', null);
    Sentry.setTag('user.username', null);
  }
}
