export enum GetUrlParams {
  AssetCode = 'assetCode',
  AssetId = 'assetId',
  TaskId = 'taskId',
  AssetIds = 'assetIds',
  AssetName = 'assetName',
  AssigneeIds = 'assigneeIds',
  AssetQrPrefix = 'assetQrPrefix',
  CounterId = 'counterId',
  CounterName = 'counterName',
  Dst = 'dst',
  DuplicatedVersionId = 'duplicatedVersionId',
  EndDate = 'endDate',
  FormCategoryIds = 'formCategoryIds',
  InterventionCategoryIds = 'interventionCategoryIds',
  OrderingDirection = 'ordering_direction',
  OrderingField = 'ordering_field',
  Page = 'page',
  PerPage = 'per_page',
  PeriodEnd = 'periodEnd',
  PeriodStart = 'periodStart',
  Priority = 'priority',
  Search = 'search',
  SiteAndArea = 'siteAndArea',
  SiteAreaCode = 'siteAreaCode',
  SiteAreaId = 'siteAreaId',
  SiteAreaIds = 'siteAreaIds',
  SiteAreaName = 'siteAreaName',
  SiteCode = 'siteCode',
  SiteId = 'siteId',
  SiteIds = 'siteIds',
  SiteName = 'siteName',
  Step = 'step',
  Tab = 'tab',
  Token = 'token',
  View = 'view',
  UserId = 'userId',
  RelatedToQrCodeScan = 'RelatedToQrCodeScan',
}

export const PER_PAGE_KEY_DEFAULT = '10';
export const PER_PAGE_KEY_SITES = '9';

export const ORDERING_DIRECTION_ASC = 'asc';
export const ORDERING_DIRECTION_DESC = 'desc';
export const DEFAULT_ORDERING_DIRECTION = ORDERING_DIRECTION_DESC;

export const PAGINATION_PARAMS = {
  [GetUrlParams.Page]: '1',
  [GetUrlParams.PerPage]: PER_PAGE_KEY_DEFAULT,
};

export const ORDERING_PARAMS = {
  [GetUrlParams.OrderingField]: 'createdAt',
  [GetUrlParams.OrderingDirection]: ORDERING_DIRECTION_DESC,
};

export const DEFAULT_LIST_PARAMS = {
  ...PAGINATION_PARAMS,
  ...ORDERING_PARAMS,
};

export const SEARCH_PARAMS = {
  [GetUrlParams.Search]: '',
};

export type OrderingParams = {
  [GetUrlParams.OrderingField]?: string | number | null;
  [GetUrlParams.OrderingDirection]?: string | number | null;
};

export type OrderingState = [OrderingParams, (v: OrderingParams) => void];

export type PaginationParams = {
  [GetUrlParams.Page]?: string | number | null;
  [GetUrlParams.PerPage]?: string | number | null;
};

export type PaginationState = [PaginationParams, (v: PaginationParams) => void];

export type SearchParams = {
  [GetUrlParams.Search]?: string | null;
};

export type SearchState = [SearchParams, (v: SearchParams) => void];

export const DEFAULT_SEARCH_LIST_VARIABLES = {
  page: 1,
  limit: 20,
};

export const PATH_ADD = 'add';
export const PATH_APPROVALS = 'approvals';
export const PATH_AREAS = 'areas';
export const PATH_ASSETS = 'assets';
export const PATH_AUTH = 'auth';
export const PATH_CATEGORIES = 'categories';
export const PATH_CODE_OF_CONDUCT = 'code-of-conduct';
export const PATH_COUNTER = 'counter';
export const PATH_COUNTERS = 'counters';
export const PATH_DASHBOARD = 'dashboard';
export const PATH_DOCUMENTATION = 'documentation';
export const PATH_DOCUMENT_ID = ':documentId';
export const PATH_ACTION_ID = ':actionId';
export const PATH_DUPLICATE = 'duplicate';
export const PATH_EDIT = 'edit';
export const PATH_ACCESS = 'access';
export const PATH_FORM_TEMPLATES = 'form-templates';
export const PATH_FORM_TEMPLATES_ACTIVE = 'form-templates-active';
export const PATH_FORM_TEMPLATES_ARCHIVED = 'form-templates-archived';
export const PATH_ID = ':id';
export const PATH_INTERVENTIONS = 'interventions';
export const PATH_REPORTING = 'reporting';
export const PATH_LATEST_VERSION = 'latest_version';
export const PATH_NOMENCLATURE = 'nomenclature';
export const PATH_OVERVIEW = 'overview';
export const PATH_PROFILE = 'profile';
export const PATH_SETUP = 'setup';
export const PATH_SITES = 'sites';
export const PATH_TASKS = 'tasks';
export const PATH_PERFORM_TASKS = 'perform-tasks';
export const PATH_TIMELINE = 'timeline';
export const PATH_ACTIONS = 'actions';
export const PATH_USERS = 'users';
export const PATH_TEAMS = 'teams';
export const PATH_VERSION_ID = ':versionId';
export const PATH_VIEW = 'view';
