import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import selectStyles from '@lib/utils/selectStyles';
import Tooltip, { TooltipProps } from '@lib/components/Tooltip/Tooltip';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import CharsRemaining from '@lib/components/CharsRemaining/CharsRemaining';
import InputTel, { InputTelValue } from '@lib/components/Input/InputTel';
import { getNumberInInputMask } from '@lib/components/Input/masks';
import { MAX_NUMBER_VALUE } from '@lib/enums/form';
import withFloatingLabel, {
  FloatingLabelProps,
} from '@lib/hocs/withFloatingLabel';
import InputComponent from '@lib/components/Input/InputComponent';
import { MaskitoOptions } from '@maskito/core';
import { ComponentsShape, InputSizes, InputTypes } from './enums';
import themeStyles from './Input.module.scss';

type ChangeEventType = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

export type BeforeValueChangeType = (event: ChangeEventType) => ChangeEventType;

export type OnCopyType = (
  e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>,
) => void;

export interface InputProps {
  autoFocus?: boolean;
  beforeValueChange?: BeforeValueChangeType;
  className?: string;
  classes?: Record<string, string>;
  clearIcon?: string;
  components?: ComponentsShape;
  disabled?: boolean;
  disabledIcon?: string;
  elementTooltipProps?: TooltipProps;
  error?: string;
  formId?: string;
  hasBorder?: boolean;
  hasError?: boolean;
  hasErrorAsTooltip?: boolean;
  inputWrapperClassName?: string;
  leftIcon?: string;
  leftIconClassName?: string;
  leftIconTooltipProps?: TooltipProps;
  max?: number;
  mask?: MaskitoOptions;
  maxLength?: number;
  min?: number;
  name?: string;
  onBlur?: () => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onChange?: (event: ChangeEventType) => void;
  onClear?: (event: React.MouseEvent<HTMLElement>) => void;
  onClick?: () => void;
  onLeftIconClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onRightIconClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onlyCountries?: string[];
  placeholder?: string;
  precision?: number;
  readOnly?: boolean;
  resize?: boolean;
  rightIcon?: string;
  rightIconClassName?: string;
  rightIconSymbolsOutlined?: boolean;
  rightIconTooltipProps?: TooltipProps;
  size?: InputSizes;
  step?: number | string;
  type?: InputTypes;
  value?: string | number | InputTelValue;
}

interface PreparedProps {
  max?: number;
  min?: number;
  onCopy?: OnCopyType;
  placeholder?: string;
  type?: string;
  precision?: number;
  step?: string | number;
  mask?: MaskitoOptions;
}

const Input = forwardRef(
  (
    props: InputProps & FloatingLabelProps,
    ref: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement | null>,
  ) => {
    const {
      autoFocus,
      beforeValueChange,
      className,
      classes,
      clearIcon = 'cancel',
      disabled = false,
      disabledIcon = 'lock',
      elementTooltipProps,
      error,
      formId,
      hasBorder = true,
      hasError = false,
      hasErrorAsTooltip = false,
      inputWrapperClassName,
      leftIcon,
      leftIconClassName,
      leftIconTooltipProps,
      mask,
      max,
      maxLength,
      min,
      name,
      onChange,
      onClear,
      onKeyDown,
      onClick,
      onLeftIconClick,
      onlyCountries,
      placeholder,
      precision,
      readOnly,
      resize,
      rightIconTooltipProps,
      rightIconSymbolsOutlined,
      setIsFocused,
      size = InputSizes.large,
      step,
      type = InputTypes.text,
      value = '',
    } = props;
    let { rightIcon, rightIconClassName, onRightIconClick } = props;
    const id = formId ? `input-${name}-${formId}` : `input-${name}`;

    const styles = selectStyles(themeStyles, classes);

    const passwordVisibilityTimer = useRef<NodeJS.Timeout>();
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const onVisibilityToggle = () =>
      setPasswordVisibility((prevState) => !prevState);

    const onChangeHandler = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      if (onChange) {
        if (beforeValueChange) {
          const newEvent = beforeValueChange(event);
          onChange(newEvent);
        } else {
          onChange(event);
        }
      }
    };

    const preparedProps: PreparedProps = {};
    if (type === InputTypes.password) {
      preparedProps.type = passwordVisibility
        ? InputTypes.text
        : InputTypes.password;
      preparedProps.onCopy = (e) => {
        e.preventDefault();
      };
      rightIcon = passwordVisibility ? 'visibility' : 'visibility_off';
      rightIconClassName = styles.passwordInputIcon;
      onRightIconClick = onVisibilityToggle;
    } else if (type === InputTypes.number) {
      preparedProps.type = InputTypes.number;
      // TODO: use it only if possible hide native min max validations
      preparedProps.min = min !== undefined ? min : -MAX_NUMBER_VALUE;
      preparedProps.max = max !== undefined ? max : MAX_NUMBER_VALUE;
      preparedProps.placeholder = ''; // use for trigger label position via css
      preparedProps.precision = precision;
      preparedProps.step = step;
      if (!mask) {
        preparedProps.mask = getNumberInInputMask({
          precision,
          maxLength: `${preparedProps.max}`.length,
          min: preparedProps.min,
          max: preparedProps.max,
          minusSign: preparedProps.min < 0 ? '-' : undefined,
        });
      }
    }

    // Clear icon should be visible only when value exist and input is not disabled.
    const isClearable = onClear && value && !disabled;
    const isCharsRemainingVisible =
      !!maxLength && maxLength > 1 && !!CharsRemaining;
    const inputClassName = classNames(
      styles.input,
      {
        [styles.inputWithError]: !!hasError,
        [styles.inputWithLeftIcon]: !!leftIcon,
        [styles.inputWithRightIcon]:
          !!rightIcon || disabled || (hasErrorAsTooltip && error),
        [styles.inputClearable]: isClearable,
        [styles.inputDisabled]: disabled,
        [styles.textareaWithResize]: resize,
        [styles.inputLarge]:
          type !== InputTypes.textarea && size === InputSizes.large,
        [styles.inputMedium]:
          type !== InputTypes.textarea && size === InputSizes.medium,
        [styles.textareaLarge]:
          type === InputTypes.textarea && size === InputSizes.large && !resize,
        [styles.textareaMedium]:
          type === InputTypes.textarea && size === InputSizes.medium && !resize,
        [styles.textareaLargeWithResize]:
          type === InputTypes.textarea && size === InputSizes.large && resize,
        [styles.textareaMediumWithResize]:
          type === InputTypes.textarea && size === InputSizes.medium && resize,
        [styles.withoutBorder]: !hasBorder,
      },
      className,
    );
    const getDisabledIcon = () =>
      _isEmpty(rightIconTooltipProps) || !Tooltip ? (
        <div className={styles.disabledIconWrap}>
          <MaterialIcon icon={disabledIcon} symbolsOutlined />
        </div>
      ) : (
        <button
          type="button"
          onClick={onRightIconClick}
          className={styles.disabledIconWrap}
        >
          <Tooltip
            {...rightIconTooltipProps}
            contentClassName={styles.rightTooltipContent}
          >
            <MaterialIcon icon={disabledIcon} symbolsOutlined />
          </Tooltip>
        </button>
      );
    const getLeftIcon = () => {
      if (!leftIcon || type === InputTypes.tel) return null;
      return (
        <button
          className={classNames(styles.leftIconWrap, leftIconClassName)}
          type="button"
          onClick={onLeftIconClick}
        >
          {_isEmpty(leftIconTooltipProps) || !Tooltip ? (
            <MaterialIcon icon={leftIcon} />
          ) : (
            <Tooltip
              key="left-icon-tooltip"
              {...leftIconTooltipProps}
              contentClassName={styles.leftTooltipContent}
            >
              <MaterialIcon icon={leftIcon} />
            </Tooltip>
          )}
        </button>
      );
    };
    const getRightIcon = () => {
      if (disabled) return getDisabledIcon();
      if (hasErrorAsTooltip && error) {
        return (
          <button
            className={classNames(styles.rightIconWrap, rightIconClassName)}
            type="button"
            onClick={onRightIconClick}
          >
            <Tooltip
              key="right-icon-tooltip"
              body={error}
              contentClassName={styles.rightTooltipContentError}
            >
              <MaterialIcon icon="error" />
            </Tooltip>
          </button>
        );
      }
      if (!rightIcon) return null;
      return (
        <button
          className={classNames(styles.rightIconWrap, rightIconClassName)}
          type="button"
          onClick={onRightIconClick}
        >
          {_isEmpty(rightIconTooltipProps) || !Tooltip ? (
            <MaterialIcon icon={rightIcon} />
          ) : (
            <Tooltip
              key="right-icon-tooltip"
              {...rightIconTooltipProps}
              contentClassName={styles.rightTooltipContent}
            >
              <MaterialIcon
                icon={rightIcon}
                symbolsOutlined={rightIconSymbolsOutlined}
              />
            </Tooltip>
          )}
        </button>
      );
    };
    const getClearButton = () =>
      isClearable ? (
        <button
          className={styles.clearIconWrap}
          type="button"
          onClick={onClear}
        >
          <MaterialIcon icon={clearIcon} />
        </button>
      ) : null;
    const getInput = () => {
      const element = (
        <InputComponent
          autoFocus={autoFocus}
          disabled={disabled}
          id={id}
          inputClassName={inputClassName}
          mask={mask}
          maxLength={maxLength}
          onChangeHandler={onChangeHandler}
          onClick={onClick}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={ref as React.RefObject<HTMLInputElement>}
          setIsFocused={setIsFocused}
          styles={styles}
          value={(value as string) || ''}
          {...preparedProps}
        />
      );
      if (_isEmpty(elementTooltipProps) || !Tooltip) {
        return element;
      }
      return (
        <Tooltip
          key="element-tooltip"
          {...elementTooltipProps}
          contentClassName={styles.elementTooltipContent}
        >
          {element}
        </Tooltip>
      );
    };
    const getTextArea = () => {
      const element = (
        <textarea
          autoComplete="off"
          className={inputClassName}
          disabled={disabled}
          id={id}
          maxLength={maxLength}
          onBlur={value ? undefined : () => setIsFocused(false)}
          onChange={onChangeHandler}
          onFocus={() => setIsFocused(true)}
          placeholder={placeholder}
          ref={ref as React.RefObject<HTMLTextAreaElement>}
          // eslint-disable-next-line react/no-unknown-property
          value={(value as string) || ''}
          {...preparedProps}
        />
      );
      if (_isEmpty(elementTooltipProps) || !Tooltip) {
        return element;
      }
      return (
        <Tooltip
          key="element-tooltip"
          {...elementTooltipProps}
          contentClassName={styles.elementTooltipContent}
        >
          {element}
        </Tooltip>
      );
    };
    const getPhoneInput = () => (
      <InputTel
        disabled={disabled}
        hasError={hasError}
        id={id}
        // @ts-ignore TODO: find a way to fix it
        onChange={onChangeHandler}
        onlyCountries={onlyCountries}
        value={value as InputTelValue}
      />
    );
    const getElement = () => {
      switch (type) {
        case InputTypes.textarea:
          return getTextArea();
        case InputTypes.tel:
          return getPhoneInput();
        case InputTypes.number:
        case InputTypes.text:
        case InputTypes.password:
        default:
          return getInput();
      }
    };

    useEffect(() => {
      if (passwordVisibility) {
        passwordVisibilityTimer.current = setTimeout(() => {
          setPasswordVisibility(false);
        }, 2000);
      }
      return () => {
        if (passwordVisibilityTimer.current) {
          clearInterval(passwordVisibilityTimer.current);
        }
      };
    }, [passwordVisibility]);

    return (
      <div className={styles.root}>
        <div
          className={classNames(
            styles.inputWrapper,
            {
              [styles.inputWrapperWithTextarea]: type === InputTypes.textarea,
              [styles.inputWrapperWithNumber]:
                type === InputTypes.number && !disabled,
              [styles.inputWrapperWithDisabled]: disabled,
              [styles.inputWrapperWhenValue]: !!value,
              [styles.inputWrapperWhenError]: hasError,
            },
            inputWrapperClassName,
          )}
        >
          {getElement()}
          {getLeftIcon()}
          {getRightIcon()}
          {getClearButton()}
        </div>
        {isCharsRemainingVisible && (
          <CharsRemaining
            value={value as string}
            maxLength={maxLength}
            className={styles.charsRemainingClass}
          />
        )}
      </div>
    );
  },
);

export default memo(withFloatingLabel(Input));
