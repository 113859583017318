import React from 'react';
import { APP_URLS } from 'constants/urls';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';
import { ErrorPageStatusEnum } from '@lib/components/ErrorPage/enums';

type Props = {
  status?: ErrorPageStatusEnum;
};

export default function NotFoundPage({
  status = ErrorPageStatusEnum.Status404,
}: Props) {
  return <ErrorPage status={status} logoUrl={APP_URLS.root.path} />;
}
