import { useEffect } from 'react';
import _get from 'lodash/get';
import { GraphQLErrors } from '@apollo/client/errors';
import { UseFormSetError } from 'react-hook-form';

interface GraphQLErrorDetail {
  message: string;
  path: string[];
  predicate?: never;
  meta?: never;
}

interface Props {
  graphQLErrors?: GraphQLErrors;
  graphQLErrorsReplacePathMap?: { [key: string]: string };
  graphQLErrorsSkipPath?: string[];
  setError: UseFormSetError<object>;
}

const useHandleGraphQLErrors = ({
  graphQLErrors,
  graphQLErrorsReplacePathMap,
  graphQLErrorsSkipPath,
  setError,
}: Props) => {
  useEffect(() => {
    if (Array.isArray(graphQLErrors)) {
      // Group errors by their path
      graphQLErrors.forEach((error) => {
        const details = _get(error, 'extensions.details', []);
        if (details && Array.isArray(details)) {
          details.forEach((detail: GraphQLErrorDetail) => {
            const { message, path } = detail;
            const detailPath = graphQLErrorsSkipPath?.length
              ? path.filter(
                  (pathItem) => !graphQLErrorsSkipPath.includes(pathItem),
                )
              : path;
            const dotSeparatedPath = detailPath.join('.');
            if (message && dotSeparatedPath) {
              const resultPath =
                graphQLErrorsReplacePathMap?.[dotSeparatedPath] ||
                dotSeparatedPath;
              // @ts-ignore
              setError(resultPath, { type: 'custom', message });
            }
          });
        }
      });
    }
  }, [
    graphQLErrors,
    graphQLErrorsReplacePathMap,
    graphQLErrorsSkipPath,
    setError,
  ]);
};

export default useHandleGraphQLErrors;
