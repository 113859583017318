import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import LinkComponent, { LinkSizes, LinkTypes } from '@lib/components/Link/Link';
import useWindowDimensions from '@lib/hooks/useWindowDimensions';
import styles from './Collapse.module.scss';

type CollapseProps = {
  children: React.ReactNode;
  className?: string;
  minHeight?: number;
  useCropBlurGray?: boolean;
  useCropBlurWhite?: boolean;
};

function Collapse({
  children,
  className,
  minHeight = 0,
  useCropBlurGray,
  useCropBlurWhite,
}: CollapseProps) {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [collapsed, setCollapsed] = useState(true);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (contentRef.current) {
      const height = contentRef.current.scrollHeight || 0;
      setContentHeight(height);
    }
  }, [children, width]);

  const toggleCollapse = () => {
    setCollapsed((prevCollapsed) => !prevCollapsed);
  };

  const isContentShort = contentHeight !== null && contentHeight <= minHeight;

  return (
    <div>
      <div
        className={classNames(
          styles.content,
          {
            [styles.contentCollapsed]: collapsed && !isContentShort,
            [styles.contentCropBlurWhite]: useCropBlurWhite,
            [styles.contentCropBlurGray]: useCropBlurGray,
          },
          className,
        )}
        style={{
          maxHeight: collapsed ? minHeight : 'none',
          overflow: isContentShort ? 'unset' : 'hidden',
          opacity: contentHeight ? 'unset' : 0,
        }}
        ref={contentRef}
      >
        {children}
      </div>
      {!isContentShort && (
        <div className={styles.toggleButton}>
          <LinkComponent
            type={LinkTypes.secondaryGray}
            onClick={toggleCollapse}
            size={LinkSizes.small}
          >
            {t(collapsed ? 'show-more' : 'show-less')}
          </LinkComponent>
        </div>
      )}
    </div>
  );
}

export default memo(Collapse);
