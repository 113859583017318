import { TFunction } from 'i18next';
import { InputTypes } from '@lib/components/Input/enums';

export const MIN_PASSWORD_FIELD_LENGTH = 8;
export const MAX_PASSWORD_FIELD_LENGTH = 64;
export const MAX_TEXT_FIELD_LENGTH = 255;
export const MAX_TEXT_EDITOR_FIELD_LENGTH = 200000;
export const MAX_INTERVENTION_DESCRIPTION_LENGTH = 80;
export const MAX_ASSET_CODE_FIELD_LENGTH = 9;
export const MAX_ASSET_PREFIX_CODE_FIELD_LENGTH = 4;
export const MIN_USERNAME_FIELD_LENGTH = 6;
export const MAX_USERNAME_FIELD_LENGTH = 30;
export const MAX_NUMBER_VALUE = 99999999;
export const MAX_MINUTES_VALUE = 9999;

export const DEFAULT_NUMBER_INPUT_PROPS = {
  max: MAX_NUMBER_VALUE,
  min: 1,
  precision: 0,
  step: 1,
  type: InputTypes.number,
};

export const DECIMAL_NUMBER_INPUT_PROPS = {
  min: 0,
  precision: 2,
  step: 0.01,
  type: InputTypes.number,
};

export const QUESTION_NUMBER_INPUT_PROPS = {
  precision: 1,
  step: 0.1,
  type: InputTypes.number,
};

// eslint-disable-next-line
export const EMAIL_REGEX = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
);

export const USER_NAME_REGEX = /^[a-z0-9]+([-_.][a-z0-9]+)*$/;

export enum Steps {
  step1 = 1,
  step2 = 2,
  step3 = 3,
  step4 = 4,
  step5 = 5,
}

export enum PredefinedValues {
  all = 'all',
}

export const getAllOption = (t: TFunction<'translation', undefined>) => ({
  value: PredefinedValues.all,
  label: t('all'),
});

export enum SubmitAction {
  CreateAndClose = 'createAndClose',
  CreateAndNew = 'createAndNew',
}
