import queryString, { ParsedQuery } from 'query-string';
import _isEmpty from 'lodash/isEmpty';

export const stringify = (params: object) => {
  const queryStr = queryString.stringify(params, { arrayFormat: 'bracket' });
  return queryStr || '';
};

export const stringifyWithPrefix = (params: object) => {
  const queryStr = queryString.stringify(params, { arrayFormat: 'bracket' });
  return queryStr ? `?${queryStr}` : '';
};

export const parseSearch = (search: string): ParsedQuery<string> =>
  queryString.parse(search, { arrayFormat: 'bracket' });

export const isSearchEmpty = (search: string) => {
  if (!search || search === '?') return true;
  return _isEmpty(parseSearch(search));
};
