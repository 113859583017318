import React from 'react';
import { NavLink } from 'react-router-dom';
import Typography from '@lib/components/Typography/Typography';
import { useTranslation } from 'react-i18next';
import { APP_URLS } from 'constants/urls';
import styles from '@lib/layouts/AuthLayout/AuthComponents.module.scss';

function ForgotPasswordLink() {
  const { t } = useTranslation();
  return (
    <div className={styles.forgotPassword} key="forgot-password">
      <Typography
        variant="link"
        component={NavLink}
        to={APP_URLS.auth.forgotPassword.path}
      >
        {t('forgot-password-link')}
      </Typography>
    </div>
  );
}

export default ForgotPasswordLink;
