import React from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import './InputTel.scss';

export type InputTelValue = {
  country: string;
  number: string;
};

interface Props {
  disabled?: boolean;
  hasError?: boolean;
  id?: string;
  onChange?: (v: InputTelValue) => void;
  onlyCountries?: string[];
  value?: InputTelValue;
}

function InputTel(props: Props) {
  const {
    disabled,
    hasError,
    id,
    onChange = _noop,
    onlyCountries,
    value,
  } = props;
  const onChangeHandler = (v: string, country: CountryData) =>
    onChange({
      number: v,
      country: _get(country, 'countryCode', '').toUpperCase(),
    });
  return (
    <PhoneInput
      key={onlyCountries?.length}
      inputProps={{ id, autoComplete: 'off' }}
      value={value?.number}
      onChange={onChangeHandler}
      placeholder=" "
      country="fr"
      onlyCountries={onlyCountries}
      inputClass={hasError ? 'form-control--hasError' : undefined}
      disabled={disabled}
    />
  );
}

export default InputTel;
