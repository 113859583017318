import React, { memo } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { TFunction } from 'i18next';
import { useFormContext } from 'react-hook-form';
import _get from 'lodash/get';
import classNames from 'classnames';
import { FieldComponents, FieldItem, Values } from '@lib/interfaces/form';
import removeIdFields from '@lib/utils/removeIdsFromObject';
import FormItem from './FormItem';
import FieldArrayItemAccordion from './FieldArrayItemAccordion';
// eslint-disable-next-line import/no-cycle
import SubFieldsArray from './FieldArray'; // TODO: fix it
import styles from './Form.module.scss';

export interface ItemProps {
  append: (v: Values) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  emptyStateText?: string;
  emptyStateTitle?: string;
  entity?: string;
  fieldComponents?: FieldComponents;
  formId?: string;
  formItemsClassName?: string;
  groupIndex?: number;
  id?: string;
  index: number;
  isDraggable?: boolean;
  isFocused?: boolean;
  onClick?: () => void;
  parentName: string;
  remove: (index: number) => void;
  sourceAppendValues?: { [key: string]: unknown };
  subFields: FieldItem[];
  subFieldsMaxLength?: number;
  subFieldsMinLength?: number;
  t: TFunction<'translation', undefined>;
  title?: string;
}

function FieldArrayItem(props: ItemProps) {
  const {
    append,
    dragHandleProps,
    fieldComponents,
    formId,
    formItemsClassName,
    groupIndex,
    id,
    index,
    isDraggable,
    isFocused,
    onClick,
    parentName,
    remove,
    sourceAppendValues,
    subFields,
    subFieldsMinLength,
    subFieldsMaxLength,
    title,
  } = props;
  const { formState, watch, resetField } = useFormContext();
  const errors = _get(formState, ['errors', parentName, index]);
  const errorsCount = errors ? Object.keys(errors).length : 0;
  const values = watch();
  const parentValue = _get(values, parentName, []);
  const value = parentValue?.[index];

  const onDuplicate = () => append(removeIdFields(value));
  const onRemove = () => {
    remove(index);
    resetField(parentName, {
      defaultValue: parentValue.filter((_, idx) => idx !== index),
    });
  };

  const getSubFieldContent = () => (
    <div className={classNames(styles.formSubItems, formItemsClassName)}>
      {subFields.map((subField) => {
        const {
          name,
          validation,
          getLabel,
          subFields: subSubFields,
          ...restFieldProps
        } = subField;
        const { label } = restFieldProps;
        let newLabel = label;
        const fieldName = `${parentName}.${index}.${name}`;
        if (getLabel) {
          newLabel = getLabel(values, fieldName);
        }
        if (subSubFields !== undefined) {
          return (
            <SubFieldsArray
              parentName={fieldName}
              subFields={subSubFields}
              key={`${fieldName}-${id}`}
              formId={formId}
              sourceAppendValues={sourceAppendValues}
              {...subField}
            />
          );
        }
        return (
          <FormItem
            {...restFieldProps}
            key={`${fieldName}-${id}`}
            name={fieldName}
            label={newLabel}
            formId={formId}
            validation={validation}
          />
        );
      })}
    </div>
  );

  if (fieldComponents?.FieldArrayItemWrapperComponent) {
    const { FieldArrayItemWrapperComponent } = fieldComponents;
    return (
      <FieldArrayItemWrapperComponent
        dragHandleProps={dragHandleProps}
        errorsCount={errorsCount}
        isDraggable={isDraggable}
        isFocused={isFocused}
        onClick={onClick}
        onDuplicate={onDuplicate}
        onRemove={onRemove}
        parentGroupIndex={groupIndex}
        parentIndex={index}
        parentName={parentName}
        parentValue={parentValue}
        subFieldsMinLength={subFieldsMinLength}
        subFieldsMaxLength={subFieldsMaxLength}
        value={value}
      >
        {getSubFieldContent()}
      </FieldArrayItemWrapperComponent>
    );
  }

  return (
    <FieldArrayItemAccordion
      dragHandleProps={dragHandleProps}
      errorsCount={errorsCount}
      isDraggable={isDraggable}
      title={`${title} ${index + 1}`}
      onRemove={() => remove(index)}
    >
      {getSubFieldContent()}
    </FieldArrayItemAccordion>
  );
}

export default memo(FieldArrayItem);
