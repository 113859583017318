import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ISO8601Date: { input: any; output: any; }
  ISO8601DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

/** Access entity */
export type Access = {
  __typename?: 'Access';
  /** Component name (Access control component) */
  component: ComponentEnum;
  /** Related items IDs */
  itemIds: Array<Scalars['ID']['output']>;
  /** Level of access */
  level: Maybe<LevelEnum>;
  /** Policy type */
  policy: Maybe<PolicyEnum>;
  /** Scope level */
  scopeLevel: Maybe<ScopeLevelEnum>;
};

/** Access attributes */
export type AccessCuInputObject = {
  /** Destroy access */
  _destroy?: InputMaybe<Scalars['Boolean']['input']>;
  /** Component name (Access control component) */
  component: ComponentEnum;
  /** Scope items attributes array */
  items?: InputMaybe<Array<LinkingInputObject>>;
  /** Level of access (for non-scope access) */
  level?: InputMaybe<LevelEnum>;
  /** Scope level of access (for scope access) */
  scopeLevel?: InputMaybe<ScopeLevelEnum>;
};

/** Address entity */
export type Address = {
  __typename?: 'Address';
  /** Latitude */
  lat: Scalars['String']['output'];
  /** Longitude */
  lon: Scalars['String']['output'];
  /** Address string */
  string: Scalars['String']['output'];
};

/** Address attributes */
export type AddressInputObject = {
  /** Latitude */
  lat: Scalars['Float']['input'];
  /** Longitude */
  lon: Scalars['Float']['input'];
  /** Address string */
  string: Scalars['String']['input'];
};

/** Asset entity */
export type Asset = TimestampInterface & {
  __typename?: 'Asset';
  /**
   * Asset Category entity
   * NOTE: if you only need an Object ID, please use the categoryId field to reduce the load on the database.
   */
  category: Maybe<AssetCategory>;
  /** Asset Category ID */
  categoryId: Maybe<Scalars['ID']['output']>;
  /** Code */
  code: Scalars['String']['output'];
  /** Counter count */
  countersCount: Maybe<Scalars['Int']['output']>;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Description */
  description: Maybe<Scalars['String']['output']>;
  /** Full code (As on the QR code) */
  fullCode: Scalars['String']['output'];
  /** Asset icon */
  iconAttached: Maybe<Attachment>;
  /** Asset ID */
  id: Scalars['ID']['output'];
  /** Manufacturer */
  manufacturer: Maybe<Scalars['String']['output']>;
  /** Model */
  model: Maybe<Scalars['String']['output']>;
  /** Name */
  name: Scalars['String']['output'];
  /** Indicates if asset location has poor wifi or does not have wifi */
  poorWifi: Scalars['Boolean']['output'];
  /** QR code prefix */
  qrPrefix: Maybe<Scalars['String']['output']>;
  /** Serial number */
  serialNumber: Maybe<Scalars['String']['output']>;
  /** Service date */
  serviceDate: Maybe<Scalars['ISO8601Date']['output']>;
  /**
   * Site entity
   * NOTE: if you only need an Object ID, please use the siteId field to reduce the load on the database.
   */
  site: Maybe<Site>;
  /**
   * Site Area entity
   * NOTE: if you only need an Object ID, please use the siteAreaId field to reduce the load on the database.
   */
  siteArea: Maybe<SiteArea>;
  /** Site Area ID */
  siteAreaId: Maybe<Scalars['ID']['output']>;
  /** Site ID */
  siteId: Maybe<Scalars['ID']['output']>;
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Indicates number of nomenclatures where is used */
  usesAsNomenclatureCount: Scalars['Int']['output'];
};

/** Description from base input object. Please set your description for child object. */
export type AssetBulkDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** IDs */
  ids: Array<Scalars['ID']['input']>;
};

/** Description from base input object. Please set your description for child object. */
export type AssetBulkQrCodeCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Create params */
  params: QrCodesCreateInputObject;
};

/** Asset Category entity */
export type AssetCategory = TimestampInterface & {
  __typename?: 'AssetCategory';
  /** Assets collection */
  assets: AssetCollection;
  canDestroy: AuthorizationResult;
  /** Color */
  color: Scalars['String']['output'];
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Asset Category ID */
  id: Scalars['ID']['output'];
  /** Name */
  name: Scalars['String']['output'];
  /** Prefix */
  prefix: Maybe<Scalars['String']['output']>;
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Number of visible Assets */
  visibleAssetsCount: Scalars['Int']['output'];
};


/** Asset Category entity */
export type AssetCategoryAssetsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** AssetCategoryCollection type */
export type AssetCategoryCollection = {
  __typename?: 'AssetCategoryCollection';
  /** A collection of paginated AssetCategoryCollection */
  collection: Array<AssetCategory>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type AssetCategoryCreateInput = {
  /** Create attributes */
  attributes: AssetCategoryCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Asset Category attributes */
export type AssetCategoryCreateInputObject = {
  /** Color */
  color: Scalars['String']['input'];
  /** Name */
  name: Scalars['String']['input'];
  /** Prefix */
  prefix?: InputMaybe<Scalars['String']['input']>;
};

/** Description from base input object. Please set your description for child object. */
export type AssetCategoryDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Asset Category ID */
  id: Scalars['ID']['input'];
};

/** Asset Category filters */
export type AssetCategoryFilterInputObject = {
  /** Asset Category ID */
  id?: InputMaybe<FilterIdInputObject>;
  /** Asset Category name */
  name?: InputMaybe<FilterInputObject>;
};

/** Asset Category sorts */
export type AssetCategorySortInputObject = {
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Name */
  name?: InputMaybe<SortDirectionEnum>;
  /** Number of visible Assets in this category */
  visibleAssetsCount?: InputMaybe<SortDirectionEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type AssetCategoryUpdateInput = {
  /** Update attributes */
  attributes: AssetCategoryUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Asset Category ID */
  id: Scalars['ID']['input'];
  /** Refresh QR prefix for related assets */
  refreshQrPrefixForAssets?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Asset Category attributes */
export type AssetCategoryUpdateInputObject = {
  /** Color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Prefix */
  prefix?: InputMaybe<Scalars['String']['input']>;
};

/** AssetCollection type */
export type AssetCollection = {
  __typename?: 'AssetCollection';
  /** A collection of paginated AssetCollection */
  collection: Array<Asset>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type AssetCreateInput = {
  /** Create attributes */
  attributes: AssetCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Asset attributes */
export type AssetCreateInputObject = {
  /** Asset Category ID */
  categoryId: Scalars['ID']['input'];
  /** Code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Icon */
  iconAttached?: InputMaybe<AttachmentCuInputObject>;
  /** Manufacturer */
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  /** Model */
  model?: InputMaybe<Scalars['String']['input']>;
  /** Name */
  name: Scalars['String']['input'];
  /** Indicates if asset location has poor wifi or does not have wifi */
  poorWifi?: InputMaybe<Scalars['Boolean']['input']>;
  /** QR prefix */
  qrPrefix?: InputMaybe<Scalars['String']['input']>;
  /** Serial number */
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  /** Service date */
  serviceDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Site Area ID */
  siteAreaId?: InputMaybe<Scalars['ID']['input']>;
  /** Site ID */
  siteId: Scalars['ID']['input'];
};

/** Asset filters */
export type AssetFilterInputObject = {
  /** Asset Category name */
  categoryName?: InputMaybe<FilterInputObject>;
  /** Asset code */
  code?: InputMaybe<FilterInputObject>;
  /** Counter count */
  countersCount?: InputMaybe<FilterIntInputObject>;
  /** Asset ID */
  id?: InputMaybe<FilterIdInputObject>;
  /** Asset manufacturer */
  manufacturer?: InputMaybe<FilterInputObject>;
  /** Asset name */
  name?: InputMaybe<FilterInputObject>;
  /** QR prefix */
  qrPrefix?: InputMaybe<FilterInputObject>;
  /** Asset serial number */
  serialNumber?: InputMaybe<FilterInputObject>;
  /** Site Area ID */
  siteAreaId?: InputMaybe<FilterIdInputObject>;
  /** Site Area name */
  siteAreaName?: InputMaybe<FilterInputObject>;
  /** Site ID */
  siteId?: InputMaybe<FilterIdInputObject>;
  /** Site name */
  siteName?: InputMaybe<FilterInputObject>;
};

/** Asset attributes */
export type AssetFindByInputObject = {
  /** Code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Asset sorts */
export type AssetSortInputObject = {
  /** Asset Category name */
  categoryName?: InputMaybe<SortDirectionEnum>;
  /** Code */
  code?: InputMaybe<SortDirectionEnum>;
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Asset manufacturer */
  manufacturer?: InputMaybe<SortDirectionEnum>;
  /** Name */
  name?: InputMaybe<SortDirectionEnum>;
  /** Asset serial number */
  serialNumber?: InputMaybe<SortDirectionEnum>;
  /** Site Area name */
  siteAreaName?: InputMaybe<SortDirectionEnum>;
  /** Site name */
  siteName?: InputMaybe<SortDirectionEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type AssetUpdateInput = {
  /** Update attributes */
  attributes: AssetUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Asset ID */
  id: Scalars['ID']['input'];
};

/** Asset attributes */
export type AssetUpdateInputObject = {
  /** Asset Category ID */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Icon */
  iconAttached?: InputMaybe<AttachmentCuInputObject>;
  /** Manufacturer */
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  /** Model */
  model?: InputMaybe<Scalars['String']['input']>;
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if asset location has poor wifi or does not have wifi */
  poorWifi?: InputMaybe<Scalars['Boolean']['input']>;
  /** QR prefix */
  qrPrefix?: InputMaybe<Scalars['String']['input']>;
  /** Serial number */
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  /** Service date */
  serviceDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Site Area ID */
  siteAreaId?: InputMaybe<Scalars['ID']['input']>;
};

/** Attachment */
export type Attachment = {
  __typename?: 'Attachment';
  /** Raw file data JSON (to copy attached files from one object to another without reprocessing) */
  fileData: Maybe<Scalars['JSON']['output']>;
  /** Any string to identify file in CDN (content delivery network) (e.g. some default icon or image or whatever) */
  fileTag: Maybe<Scalars['String']['output']>;
  /** Filename */
  filename: Maybe<Scalars['String']['output']>;
  /** Attachment ID */
  id: Scalars['ID']['output'];
  /** MIME type */
  mimeType: Maybe<Scalars['String']['output']>;
  /** Position */
  position: Maybe<Scalars['Int']['output']>;
  /** Indicates if attachment is processed */
  processed: Scalars['Boolean']['output'];
  /** URLs */
  urls: Maybe<AttachmentUrl>;
};

/** Attachment attributes */
export type AttachmentCuInputObject = {
  /** Destroy attachment */
  _destroy?: InputMaybe<Scalars['Boolean']['input']>;
  /** File */
  file?: InputMaybe<AttachmentFileInputObject>;
  /** Any string to identify file in CDN (content delivery network) (e.g. some default icon or image or whatever) */
  fileTag?: InputMaybe<Scalars['String']['input']>;
  /** Filename */
  filename?: InputMaybe<Scalars['String']['input']>;
  /** Attachment ID (to update existing attachment pass ID. To create new attachment do not pass ID) */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Position */
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** File attributes */
export type AttachmentFileDerivativesInputObject = {
  /** Large */
  large: AttachmentFileInfoInputObject;
  /** Medium */
  medium: AttachmentFileInfoInputObject;
  /** Small */
  small: AttachmentFileInfoInputObject;
};

/** File attributes */
export type AttachmentFileInfoInputObject = {
  /** File ID */
  id: Scalars['String']['input'];
  /** Metadata */
  metadata: AttachmentFileMetadataInputObject;
  /** Storage */
  storage: StorageEnum;
};

/** File attributes */
export type AttachmentFileInputObject = {
  /** Derivatives */
  derivatives?: InputMaybe<AttachmentFileDerivativesInputObject>;
  /** File ID */
  id: Scalars['String']['input'];
  /** Metadata */
  metadata: AttachmentFileMetadataInputObject;
  /** Storage */
  storage: StorageEnum;
};

/** File attributes */
export type AttachmentFileMetadataInputObject = {
  /** Filename */
  filename: Scalars['String']['input'];
  /** Height */
  height?: InputMaybe<Scalars['Int']['input']>;
  /** Mime type */
  mimeType: Scalars['String']['input'];
  /** Size of file */
  size: Scalars['Int']['input'];
  /** Width */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Attachment URLs */
export type AttachmentUrl = {
  __typename?: 'AttachmentUrl';
  /** Image preview URL */
  imagePreview: Maybe<Scalars['String']['output']>;
  /** Large URL */
  large: Maybe<Scalars['String']['output']>;
  /** Medium URL */
  medium: Maybe<Scalars['String']['output']>;
  /** Original URL */
  original: Maybe<Scalars['String']['output']>;
  /** Original direct download URL (without preview in browser even for known formats) */
  originalDirectDownload: Maybe<Scalars['String']['output']>;
  /** Small URL */
  small: Maybe<Scalars['String']['output']>;
};

/** Description from base input object. Please set your description for child object. */
export type AuthenticationConfirmationsEmailConfirmationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Scope */
  scope: ScopeEnum;
  /** Token */
  token: Scalars['String']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type AuthenticationConfirmationsRegistrationConfirmationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Scope */
  scope: ScopeEnum;
  /** Token */
  token: Scalars['String']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type AuthenticationConfirmationsResendEmailConfirmationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Resource ID */
  id: Scalars['String']['input'];
  /** Url to confirm new email */
  redirectUrl: Scalars['String']['input'];
  /** Scope */
  scope: ScopeEnum;
};

/** Description from base input object. Please set your description for child object. */
export type AuthenticationConfirmationsResendRegistrationConfirmationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Resource ID */
  id: Scalars['String']['input'];
  /** Url to confirm registration */
  redirectUrl: Scalars['String']['input'];
  /** Scope */
  scope: ScopeEnum;
};

/** Description from base input object. Please set your description for child object. */
export type AuthenticationPasswordsForgotInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Email */
  email: Scalars['String']['input'];
  /** Url to change password */
  redirectUrl: Scalars['String']['input'];
  /** Scope */
  scope: ScopeEnum;
};

/** Description from base input object. Please set your description for child object. */
export type AuthenticationPasswordsResetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** New password */
  password: Scalars['String']['input'];
  /** New password confirmation */
  passwordConfirmation: Scalars['String']['input'];
  /** Scope */
  scope: ScopeEnum;
  /** Token */
  token: Scalars['String']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type AuthenticationPasswordsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** New password */
  password: Scalars['String']['input'];
  /** Current password */
  passwordChallenge: Scalars['String']['input'];
  /** New password confirmation */
  passwordConfirmation: Scalars['String']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type AuthenticationSessionCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Password */
  password: Scalars['String']['input'];
  /** Scope */
  scope: ScopeEnum;
  /** Email or username */
  uid: Scalars['String']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type AuthenticationSessionDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AuthorizationResult = {
  __typename?: 'AuthorizationResult';
  /** Human-readable error message */
  message: Maybe<Scalars['String']['output']>;
  /** Reasons of check failure */
  reasons: Maybe<FailureReasons>;
  /** Result of applying a policy rule */
  value: Scalars['Boolean']['output'];
};

/** Type for CollectionMetadataType */
export type CollectionMetadata = {
  __typename?: 'CollectionMetadata';
  /** Current Page of loaded data */
  currentPage: Scalars['Int']['output'];
  /** The number of items per page */
  limitValue: Scalars['Int']['output'];
  /** The total number of items to be paginated */
  totalCount: Scalars['Int']['output'];
  /** The total number of pages in the pagination */
  totalPages: Scalars['Int']['output'];
};

/** Company entity */
export type Company = TimestampInterface & {
  __typename?: 'Company';
  /** Active users count in the company */
  activeUsersCount: Scalars['Int']['output'];
  /** Country */
  country: CountryEnum;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Indicates if company is deactivated */
  deactivated: Scalars['Boolean']['output'];
  /** Description */
  description: Scalars['String']['output'];
  /** Company ID */
  id: Scalars['ID']['output'];
  /** Industry */
  industry: Maybe<Industry>;
  /** Name */
  name: Scalars['String']['output'];
  /** Company full admin owner entity */
  owner: Maybe<User>;
  /** Plan entity */
  plan: CompanyPlan;
  /** Timezone name */
  timezone: Scalars['String']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Website */
  website: Maybe<Scalars['String']['output']>;
};

/** CompanyCollection type */
export type CompanyCollection = {
  __typename?: 'CompanyCollection';
  /** A collection of paginated CompanyCollection */
  collection: Array<Company>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type CompanyCreateInput = {
  /** Create attributes */
  attributes: CompanyCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Url to confirm registration */
  redirectUrl: Scalars['String']['input'];
};

/** Company attributes */
export type CompanyCreateInputObject = {
  /** Admin attributes */
  admin: UserCreateOwnerInputObject;
  /** Country */
  country: CountryEnum;
  /** Description */
  description: Scalars['String']['input'];
  /** Industry ID */
  industryId?: InputMaybe<Scalars['ID']['input']>;
  /** Name */
  name: Scalars['String']['input'];
  /** Plan attributes */
  plan: CompanyPlanCuInputObject;
  /** Website */
  website?: InputMaybe<Scalars['String']['input']>;
};

/** Description from base input object. Please set your description for child object. */
export type CompanyDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Company ID */
  id: Scalars['ID']['input'];
};

/** Company filters */
export type CompanyFilterInputObject = {
  /** Country name */
  country?: InputMaybe<FilterInputObject>;
  /** Industry ID */
  industryId?: InputMaybe<FilterIdInputObject>;
  /** Industry name */
  industryName?: InputMaybe<FilterInputObject>;
  /** Company name */
  name?: InputMaybe<FilterInputObject>;
  /** Owner full name */
  ownerFullName?: InputMaybe<FilterInputObject>;
  /** Plan kind */
  planKind?: InputMaybe<FilterIntInputObject>;
};

/** Company plan entity */
export type CompanyPlan = TimestampInterface & {
  __typename?: 'CompanyPlan';
  /** Accesses */
  accesses: Array<Access>;
  /** Shows a list of available access components to create or edit for company users */
  allowedUsersAccessComponents: Array<ComponentEnum>;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /**
   * Currency
   * ATTENTION: Access allowed for system admins.
   */
  currency: Maybe<CurrencyEnum>;
  /** Company plan ID */
  id: Scalars['ID']['output'];
  /** Kind */
  kind: CompanyPlanKindEnum;
  /**
   * Payment period
   * ATTENTION: Access allowed for system admins.
   */
  paymentPeriod: Maybe<PaymentPeriodEnum>;
  /**
   * Price
   * ATTENTION: Access allowed for system admins.
   */
  price: Maybe<Scalars['Float']['output']>;
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Maximum active users */
  usersLimit: Maybe<Scalars['Int']['output']>;
};

/** Description from base input object. Please set your description for child object. */
export type CompanyPlanChangeRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Params */
  params: CompanyPlanChangeRequestInputObject;
};

/** Company Plan change params */
export type CompanyPlanChangeRequestInputObject = {
  /**  Link to open the company profile page for system admins. Supported variables: `companyId`. Example: `https://system.datizy.com/dashboard/accounts/:companyId/view`  */
  companyProfileLink: Scalars['String']['input'];
  /** Email to contact */
  contactEmail: Scalars['String']['input'];
  /** Message */
  message: Scalars['String']['input'];
};

/** Company Plan attributes */
export type CompanyPlanCuInputObject = {
  /** Accesses */
  accesses: Array<AccessCuInputObject>;
  /** Currency */
  currency: CurrencyEnum;
  /** Kind */
  kind: CompanyPlanKindEnum;
  /** Payment period */
  paymentPeriod: PaymentPeriodEnum;
  /** Price */
  price: Scalars['Float']['input'];
  /** Limit of users */
  usersLimit?: InputMaybe<Scalars['Int']['input']>;
};

/** Available plans */
export enum CompanyPlanKindEnum {
  Custom = 'CUSTOM',
  Gold = 'GOLD',
  Silver = 'SILVER',
  Starter = 'STARTER'
}

/** Company sorts */
export type CompanySortInputObject = {
  /** Active users count */
  activeUsersCount?: InputMaybe<SortDirectionEnum>;
  /** Country name */
  country?: InputMaybe<SortDirectionEnum>;
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Industry name */
  industryName?: InputMaybe<SortDirectionEnum>;
  /** Company name */
  name?: InputMaybe<SortDirectionEnum>;
  /** Owner full name */
  ownerFullName?: InputMaybe<SortDirectionEnum>;
  /** Plan kind */
  planKind?: InputMaybe<SortDirectionEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type CompanyUpdateInput = {
  /** Update attributes */
  attributes: CompanyUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Company ID */
  id: Scalars['ID']['input'];
};

/** Company attributes */
export type CompanyUpdateInputObject = {
  /** Admin ID */
  adminId?: InputMaybe<Scalars['ID']['input']>;
  /** Country */
  country?: InputMaybe<CountryEnum>;
  /** Deactivate company */
  deactivated?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Industry ID */
  industryId?: InputMaybe<Scalars['ID']['input']>;
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Plan attributes */
  plan?: InputMaybe<CompanyPlanCuInputObject>;
  /** Website */
  website?: InputMaybe<Scalars['String']['input']>;
};

/** Available components */
export enum ComponentEnum {
  /** Assets counter reading (index adding) */
  AssetsCounterReading = 'ASSETS_COUNTER_READING',
  /** Assets management */
  AssetsManagement = 'ASSETS_MANAGEMENT',
  /** Assets management in asset categories only */
  AssetsManagementCategoriesScope = 'ASSETS_MANAGEMENT_CATEGORIES_SCOPE',
  /** Asset categories management */
  AssetCategoriesManagement = 'ASSET_CATEGORIES_MANAGEMENT',
  /** Form categories management */
  FormCategoriesManagement = 'FORM_CATEGORIES_MANAGEMENT',
  /** Interventions management */
  InterventionsManagement = 'INTERVENTIONS_MANAGEMENT',
  /** Interventions management in intervention categories only */
  InterventionsManagementCategoriesScope = 'INTERVENTIONS_MANAGEMENT_CATEGORIES_SCOPE',
  /** Intervention categories management */
  InterventionCategoriesManagement = 'INTERVENTION_CATEGORIES_MANAGEMENT',
  /** Sites management */
  SitesManagement = 'SITES_MANAGEMENT',
  /** Tasks management */
  TasksManagement = 'TASKS_MANAGEMENT',
  /** Tasks performing */
  TasksPerforming = 'TASKS_PERFORMING',
  /** Users and teams management */
  UsersManagement = 'USERS_MANAGEMENT'
}

/** All available form template question field option conditions */
export enum ConditionEnum {
  Between = 'BETWEEN',
  Equal = 'EQUAL',
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN',
  NotEqual = 'NOT_EQUAL'
}

/** Counter entity */
export type Counter = TimestampInterface & {
  __typename?: 'Counter';
  /**
   * Asset entity
   * NOTE: if you only need an Object ID, please use the assetId field to reduce the load on the database.
   */
  asset: Asset;
  /** Asset ID */
  assetId: Scalars['ID']['output'];
  /** Code */
  code: Scalars['String']['output'];
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Full code (As on the QR code) */
  fullCode: Scalars['String']['output'];
  /** Counter ID */
  id: Scalars['ID']['output'];
  /** Last check at */
  lastCheckAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Latest index value */
  latestIndex: Maybe<Scalars['Float']['output']>;
  /** Measure type */
  measureType: MeasureTypeEnum;
  /** Measure unit */
  measureUnit: MeasureUnitEnum;
  /** Name */
  name: Scalars['String']['output'];
  /** Prohibit lower index. The next index must be higher than the previous one. The cumulative usage is calculated by adding the gap to the last cumulative total that is stored after the counter is reset. Allow lower index. The next index can be lower than the previous one. The cumulative usage is calculated by adding the index to the last cumulative total that is stored after the counter is reset. */
  prohibitLowerIndexThanPrevious: Scalars['Boolean']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Description from base input object. Please set your description for child object. */
export type CounterBulkDestroyInput = {
  /** Asset ID */
  assetId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Counter IDs */
  ids: Array<Scalars['ID']['input']>;
};

/** Description from base input object. Please set your description for child object. */
export type CounterBulkQrCodeCreateInput = {
  /** Asset ID */
  assetId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Create params */
  params: QrCodesCreateInputObject;
};

/** CounterCollection type */
export type CounterCollection = {
  __typename?: 'CounterCollection';
  /** A collection of paginated CounterCollection */
  collection: Array<Counter>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type CounterCreateInput = {
  /** Create attributes */
  attributes: CounterCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Counter attributes */
export type CounterCreateInputObject = {
  /** Asset ID */
  assetId: Scalars['ID']['input'];
  /** Code */
  code: Scalars['String']['input'];
  /** Measure type */
  measureType: MeasureTypeEnum;
  /** Measure unit */
  measureUnit: MeasureUnitEnum;
  /** Name */
  name: Scalars['String']['input'];
  /** Prohibit lower index. The next index must be higher than the previous one. The cumulative usage is calculated by adding the gap to the last cumulative total that is stored after the counter is reset. Allow lower index. The next index can be lower than the previous one. The cumulative usage is calculated by adding the index to the last cumulative total that is stored after the counter is reset. */
  prohibitLowerIndexThanPrevious: Scalars['Boolean']['input'];
};

/** Counter filters */
export type CounterFilterInputObject = {
  /** Code */
  code?: InputMaybe<FilterInputObject>;
};

/** Counter attributes */
export type CounterFindByInputObject = {
  /** Code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Counter Measure entity */
export type CounterMeasure = TimestampInterface & {
  __typename?: 'CounterMeasure';
  /**
   * Counter entity
   * NOTE: if you only need an Object ID, please use the counterId field to reduce the load on the database.
   */
  counter: Counter;
  /** Counter ID */
  counterId: Scalars['ID']['output'];
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** (Total Consumption) - Previous cumulative value + the gap (to get the total usage over time) */
  cumulativeUsage: Scalars['Float']['output'];
  /** (Consumption) - The difference between the current index and the previous check index. If a new meter is installed, calculate the difference accordingly */
  gap: Scalars['Float']['output'];
  /** Counter ID */
  id: Scalars['ID']['output'];
  /** Manually entered the reading value */
  index: Scalars['Float']['output'];
  /** Indicates if the measure was reset to zero */
  resetToZero: Scalars['Boolean']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** CounterMeasureCollection type */
export type CounterMeasureCollection = {
  __typename?: 'CounterMeasureCollection';
  /** A collection of paginated CounterMeasureCollection */
  collection: Array<CounterMeasure>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type CounterMeasureCreateInput = {
  /** Asset ID */
  assetId: Scalars['ID']['input'];
  /** Create attributes */
  attributes: CounterMeasureCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Counter Measure attributes */
export type CounterMeasureCreateInputObject = {
  /** Counter ID */
  counterId: Scalars['ID']['input'];
  /** Manually entered the reading value */
  index: Scalars['Float']['input'];
};

/** Counter Measure filters */
export type CounterMeasureFilterInputObject = {
  /** Created at */
  createdAt?: InputMaybe<FilterDateInputObject>;
};

/** Counter Measure graph data */
export type CounterMeasureGraphData = {
  __typename?: 'CounterMeasureGraphData';
  /** Created at */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** (Total Consumption) - Previous cumulative value + the gap (to get the total usage over time) */
  cumulativeUsage: Scalars['Float']['output'];
  /** (Consumption) - The difference between the current index and the previous check index. If a new meter is installed, calculate the difference accordingly */
  gap: Scalars['Float']['output'];
};

/** Counter Measure sorts */
export type CounterMeasureSortInputObject = {
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type CounterResetToZeroInput = {
  /** Asset ID */
  assetId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Counter ID */
  id: Scalars['ID']['input'];
};

/** Counter sorts */
export type CounterSortInputObject = {
  /** Code */
  code?: InputMaybe<SortDirectionEnum>;
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Last check at */
  lastCheckAt?: InputMaybe<SortDirectionEnum>;
  /** Latest index value */
  latestIndex?: InputMaybe<SortDirectionEnum>;
  /** Name */
  name?: InputMaybe<SortDirectionEnum>;
  /** Updated at */
  updatedAt?: InputMaybe<SortDirectionEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type CounterUpdateInput = {
  /** Counter ID */
  assetId: Scalars['ID']['input'];
  /** Update attributes */
  attributes: CounterUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Counter ID */
  id: Scalars['ID']['input'];
};

/** Counter attributes */
export type CounterUpdateInputObject = {
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Prohibit lower index. The next index must be higher than the previous one. The cumulative usage is calculated by adding the gap to the last cumulative total that is stored after the counter is reset. Allow lower index. The next index can be lower than the previous one. The cumulative usage is calculated by adding the index to the last cumulative total that is stored after the counter is reset. */
  prohibitLowerIndexThanPrevious?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Country */
export type Country = {
  __typename?: 'Country';
  /** Country code */
  code: Scalars['String']['output'];
  /** Country name */
  name: Scalars['String']['output'];
};

/** Available country codes */
export enum CountryCodeEnum {
  /** Andorra */
  Ad = 'AD',
  /** Albania */
  Al = 'AL',
  /** Austria */
  At = 'AT',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Belgium */
  Be = 'BE',
  /** Bulgaria */
  Bg = 'BG',
  /** Canada */
  Ca = 'CA',
  /** Switzerland */
  Ch = 'CH',
  /** Cyprus */
  Cy = 'CY',
  /** Czech Republic */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Denmark */
  Dk = 'DK',
  /** Estonia */
  Ee = 'EE',
  /** Spain */
  Es = 'ES',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** United Kingdom */
  Gb = 'GB',
  /** Greenland */
  Gl = 'GL',
  /** Greece */
  Gr = 'GR',
  /** Croatia */
  Hr = 'HR',
  /** Hungary */
  Hu = 'HU',
  /** Ireland */
  Ie = 'IE',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Monaco */
  Mc = 'MC',
  /** Moldova */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** North Macedonia */
  Mk = 'MK',
  /** Malta */
  Mt = 'MT',
  /** Mexico */
  Mx = 'MX',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Portugal */
  Pt = 'PT',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Sweden */
  Se = 'SE',
  /** Slovenia */
  Si = 'SI',
  /** Slovakia */
  Sk = 'SK',
  /** San Marino */
  Sm = 'SM',
  /** Ukraine */
  Ua = 'UA',
  /** United States of America */
  Us = 'US',
  /** Vatican City */
  Va = 'VA'
}

/** Available countries */
export enum CountryEnum {
  /** Andorra */
  Ad = 'AD',
  /** Albania */
  Al = 'AL',
  /** Austria */
  At = 'AT',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Belgium */
  Be = 'BE',
  /** Bulgaria */
  Bg = 'BG',
  /** Canada */
  Ca = 'CA',
  /** Switzerland */
  Ch = 'CH',
  /** Cyprus */
  Cy = 'CY',
  /** Czech Republic */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Denmark */
  Dk = 'DK',
  /** Estonia */
  Ee = 'EE',
  /** Spain */
  Es = 'ES',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** United Kingdom */
  Gb = 'GB',
  /** Greenland */
  Gl = 'GL',
  /** Greece */
  Gr = 'GR',
  /** Croatia */
  Hr = 'HR',
  /** Hungary */
  Hu = 'HU',
  /** Ireland */
  Ie = 'IE',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Monaco */
  Mc = 'MC',
  /** Moldova */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** North Macedonia */
  Mk = 'MK',
  /** Malta */
  Mt = 'MT',
  /** Mexico */
  Mx = 'MX',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Portugal */
  Pt = 'PT',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Sweden */
  Se = 'SE',
  /** Slovenia */
  Si = 'SI',
  /** Slovakia */
  Sk = 'SK',
  /** San Marino */
  Sm = 'SM',
  /** Ukraine */
  Ua = 'UA',
  /** United States of America */
  Us = 'US',
  /** Vatican City */
  Va = 'VA'
}

/** Available currencies */
export enum CurrencyEnum {
  Eur = 'EUR'
}

/** Date range attributes */
export type DateRangeInputObject = {
  /** End date */
  endDate: Scalars['ISO8601Date']['input'];
  /** Start date */
  startDate: Scalars['ISO8601Date']['input'];
};

/** Documentation entity */
export type Documentation = TimestampInterface & {
  __typename?: 'Documentation';
  /**
   * Asset entity
   * NOTE: if you only need an Object ID, please use the assetId field to reduce the load on the database.
   */
  asset: Asset;
  /** Asset ID */
  assetId: Scalars['ID']['output'];
  /** File */
  attachmentAttached: Maybe<Attachment>;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /**
   * User entity
   * NOTE: if you only need an Object ID, please use the createdById field to reduce the load on the database.
   */
  createdBy: User;
  /** User ID */
  createdById: Scalars['ID']['output'];
  /** Documentation ID */
  id: Scalars['ID']['output'];
  /** Name */
  name: Scalars['String']['output'];
  /** Tags collection */
  tags: Array<Tag>;
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Description from base input object. Please set your description for child object. */
export type DocumentationBulkCreateInput = {
  /** Asset ID */
  assetId: Scalars['ID']['input'];
  /** Create attributes */
  attributes: Array<DocumentationCreateInputObject>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Description from base input object. Please set your description for child object. */
export type DocumentationBulkDestroyInput = {
  /** Asset ID */
  assetId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Documentation IDs */
  ids: Array<Scalars['ID']['input']>;
};

/** DocumentationCollection type */
export type DocumentationCollection = {
  __typename?: 'DocumentationCollection';
  /** A collection of paginated DocumentationCollection */
  collection: Array<Documentation>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Documentation attributes */
export type DocumentationCreateInputObject = {
  /** File */
  attachmentAttached: AttachmentCuInputObject;
  /** Tags */
  documentationTags?: InputMaybe<Array<LinkingInputObject>>;
  /** Name */
  name: Scalars['String']['input'];
};

/** Documentation filters */
export type DocumentationFilterInputObject = {
  /** Name */
  name?: InputMaybe<FilterInputObject>;
  /** Tag name */
  tagsName?: InputMaybe<FilterInputObject>;
};

/** Documentation sorts */
export type DocumentationSortInputObject = {
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Created by user full name */
  createdByFullName?: InputMaybe<SortDirectionEnum>;
  /** Name */
  name?: InputMaybe<SortDirectionEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type DocumentationUpdateInput = {
  /** Documentation ID */
  assetId: Scalars['ID']['input'];
  /** Update attributes */
  attributes: DocumentationUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Documentation ID */
  id: Scalars['ID']['input'];
};

/** Documentation attributes */
export type DocumentationUpdateInputObject = {
  /** Tags */
  documentationTags?: InputMaybe<Array<LinkingInputObject>>;
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Available early completion units */
export enum EarlyCompletionUnitEnum {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK'
}

export type FailureReasons = {
  __typename?: 'FailureReasons';
  /** JSON-encoded map of reasons */
  details: Scalars['String']['output'];
  /** Human-readable errors */
  fullMessages: Array<Scalars['String']['output']>;
};

/**
 * Filters
 * Examples:
 *   ```
 *   {
 *       "siteId": {
 *       "grouping": "or",
 *       "predicate": {
 *           "in": [
 *             "bab53e5d-be4b-4f5d-8429-ff5d3521d08e",
 *             "14fd0a18-6a3c-4523-aa68-a661641360f6"
 *           ]
 *       },
 *       "filterGroup": "filter1"
 *     }
 *   },
 *   {
 *       "siteAreaId": {
 *           "grouping": "or",
 *           "predicate": {
 *               "in": [
 *                   "57c6364d-0f55-43b0-8e7c-776011f8e2a9",
 *                   "e226b0e1-6ffd-4918-9aad-bbf217258ea7"
 *               ]
 *           },
 *           "filterGroup": "filter1"
 *       }
 *   },
 *   {
 *       "name": {
 *           "grouping": "or",
 *           "predicate": { "cont": "cleaning" },
 *           "filterGroup": "filter2"
 *       }
 *   },
 *   {
 *       "priority": {
 *           "grouping": "or",
 *           "predicate": { "in": ["HIGH", "MEDIUM", "LOW"] },
 *           "filterGroup": "filter2"
 *       }
 *   }
 *   ```
 *
 */
export type FilterDateInputObject = {
  /** Allows to group many filters by AND. For example, if you want to filter by (name or email) AND by (site or area), you can group them together. Specify the same group for the (name and email) and then specify an another group for (site and area). The filters in the same group will be combined by AND/OR (depends on grouping attribute) and the groups will be combined by AND. The filter_group is a string and it can be any string. It is used to group filters together. You can see the example in the description, where the filter_group is 'filter1' and 'filter2'. In the example, the result will be in the following format: (siteId OR siteAreaId) AND (name OR priority) */
  filterGroup?: InputMaybe<Scalars['String']['input']>;
  /** Determines if result must be matched with all filters ('AND') or any of them ('OR') */
  grouping: FilterGroupingEnum;
  /** Predicate */
  predicate: FilterDatePredicateInputObject;
};

/** Filters */
export type FilterDatePredicateInputObject = {
  /** Equal to */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Greater than */
  gt?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to */
  gteq?: InputMaybe<Scalars['String']['input']>;
  /** Less than */
  lt?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to */
  lteq?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to */
  notEq?: InputMaybe<Scalars['String']['input']>;
  /** Is not null */
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is null */
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Determines if result must be matched with all filters ('and') or any of them ('or') */
export enum FilterGroupingEnum {
  /** All */
  And = 'and',
  /** Any */
  Or = 'or'
}

/**
 * Filters
 * Examples:
 *   ```
 *   {
 *       "siteId": {
 *       "grouping": "or",
 *       "predicate": {
 *           "in": [
 *             "bab53e5d-be4b-4f5d-8429-ff5d3521d08e",
 *             "14fd0a18-6a3c-4523-aa68-a661641360f6"
 *           ]
 *       },
 *       "filterGroup": "filter1"
 *     }
 *   },
 *   {
 *       "siteAreaId": {
 *           "grouping": "or",
 *           "predicate": {
 *               "in": [
 *                   "57c6364d-0f55-43b0-8e7c-776011f8e2a9",
 *                   "e226b0e1-6ffd-4918-9aad-bbf217258ea7"
 *               ]
 *           },
 *           "filterGroup": "filter1"
 *       }
 *   },
 *   {
 *       "name": {
 *           "grouping": "or",
 *           "predicate": { "cont": "cleaning" },
 *           "filterGroup": "filter2"
 *       }
 *   },
 *   {
 *       "priority": {
 *           "grouping": "or",
 *           "predicate": { "in": ["HIGH", "MEDIUM", "LOW"] },
 *           "filterGroup": "filter2"
 *       }
 *   }
 *   ```
 *
 */
export type FilterIdInputObject = {
  /** Allows to group many filters by AND. For example, if you want to filter by (name or email) AND by (site or area), you can group them together. Specify the same group for the (name and email) and then specify an another group for (site and area). The filters in the same group will be combined by AND/OR (depends on grouping attribute) and the groups will be combined by AND. The filter_group is a string and it can be any string. It is used to group filters together. You can see the example in the description, where the filter_group is 'filter1' and 'filter2'. In the example, the result will be in the following format: (siteId OR siteAreaId) AND (name OR priority) */
  filterGroup?: InputMaybe<Scalars['String']['input']>;
  /** Determines if result must be matched with all filters ('AND') or any of them ('OR') */
  grouping?: InputMaybe<FilterGroupingEnum>;
  /** Predicate */
  predicate: FilterIdPredicateInputObject;
};

/** Filters */
export type FilterIdPredicateInputObject = {
  /** Is null or empty (SQL: col is null OR col = '') */
  blank?: InputMaybe<Scalars['Boolean']['input']>;
  /** Equal to */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** In */
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Not equal to */
  notEq?: InputMaybe<Scalars['String']['input']>;
  /** Not in */
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Is not null */
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is null */
  null?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not null and not empty (SQL: col is not null AND col != '') */
  present?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * Filters
 * Examples:
 *   ```
 *   {
 *       "siteId": {
 *       "grouping": "or",
 *       "predicate": {
 *           "in": [
 *             "bab53e5d-be4b-4f5d-8429-ff5d3521d08e",
 *             "14fd0a18-6a3c-4523-aa68-a661641360f6"
 *           ]
 *       },
 *       "filterGroup": "filter1"
 *     }
 *   },
 *   {
 *       "siteAreaId": {
 *           "grouping": "or",
 *           "predicate": {
 *               "in": [
 *                   "57c6364d-0f55-43b0-8e7c-776011f8e2a9",
 *                   "e226b0e1-6ffd-4918-9aad-bbf217258ea7"
 *               ]
 *           },
 *           "filterGroup": "filter1"
 *       }
 *   },
 *   {
 *       "name": {
 *           "grouping": "or",
 *           "predicate": { "cont": "cleaning" },
 *           "filterGroup": "filter2"
 *       }
 *   },
 *   {
 *       "priority": {
 *           "grouping": "or",
 *           "predicate": { "in": ["HIGH", "MEDIUM", "LOW"] },
 *           "filterGroup": "filter2"
 *       }
 *   }
 *   ```
 *
 */
export type FilterInputObject = {
  /** Allows to group many filters by AND. For example, if you want to filter by (name or email) AND by (site or area), you can group them together. Specify the same group for the (name and email) and then specify an another group for (site and area). The filters in the same group will be combined by AND/OR (depends on grouping attribute) and the groups will be combined by AND. The filter_group is a string and it can be any string. It is used to group filters together. You can see the example in the description, where the filter_group is 'filter1' and 'filter2'. In the example, the result will be in the following format: (siteId OR siteAreaId) AND (name OR priority) */
  filterGroup?: InputMaybe<Scalars['String']['input']>;
  /** Determines if result must be matched with all filters ('AND') or any of them ('OR') */
  grouping: FilterGroupingEnum;
  /** Predicate */
  predicate: FilterPredicateInputObject;
};

/**
 * Filters
 * Examples:
 *   ```
 *   {
 *       "siteId": {
 *       "grouping": "or",
 *       "predicate": {
 *           "in": [
 *             "bab53e5d-be4b-4f5d-8429-ff5d3521d08e",
 *             "14fd0a18-6a3c-4523-aa68-a661641360f6"
 *           ]
 *       },
 *       "filterGroup": "filter1"
 *     }
 *   },
 *   {
 *       "siteAreaId": {
 *           "grouping": "or",
 *           "predicate": {
 *               "in": [
 *                   "57c6364d-0f55-43b0-8e7c-776011f8e2a9",
 *                   "e226b0e1-6ffd-4918-9aad-bbf217258ea7"
 *               ]
 *           },
 *           "filterGroup": "filter1"
 *       }
 *   },
 *   {
 *       "name": {
 *           "grouping": "or",
 *           "predicate": { "cont": "cleaning" },
 *           "filterGroup": "filter2"
 *       }
 *   },
 *   {
 *       "priority": {
 *           "grouping": "or",
 *           "predicate": { "in": ["HIGH", "MEDIUM", "LOW"] },
 *           "filterGroup": "filter2"
 *       }
 *   }
 *   ```
 *
 */
export type FilterIntInputObject = {
  /** Allows to group many filters by AND. For example, if you want to filter by (name or email) AND by (site or area), you can group them together. Specify the same group for the (name and email) and then specify an another group for (site and area). The filters in the same group will be combined by AND/OR (depends on grouping attribute) and the groups will be combined by AND. The filter_group is a string and it can be any string. It is used to group filters together. You can see the example in the description, where the filter_group is 'filter1' and 'filter2'. In the example, the result will be in the following format: (siteId OR siteAreaId) AND (name OR priority) */
  filterGroup?: InputMaybe<Scalars['String']['input']>;
  /** Determines if result must be matched with all filters ('AND') or any of them ('OR') */
  grouping: FilterGroupingEnum;
  /** Predicate */
  predicate: FilterIntPredicateInputObject;
};

/** Filters */
export type FilterIntPredicateInputObject = {
  /** Is null or empty (SQL: col is null OR col = '') */
  blank?: InputMaybe<Scalars['Boolean']['input']>;
  /** Equal to */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Equal to all */
  eqAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Greater than */
  gt?: InputMaybe<Scalars['String']['input']>;
  /** Greater than all */
  gtAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Greater than any */
  gtAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Greater than or equal to */
  gteq?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to all */
  gteqAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Greater than or equal to any */
  gteqAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** In */
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Less than */
  lt?: InputMaybe<Scalars['String']['input']>;
  /** Less than all */
  ltAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Less than any */
  ltAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Less than or equal to */
  lteq?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to all */
  lteqAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Less than or equal to any */
  lteqAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Not equal to */
  notEq?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to all */
  notEqAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Not in */
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Is not null */
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is null */
  null?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not null and not empty (SQL: col is not null AND col != '') */
  present?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Filters */
export type FilterPredicateInputObject = {
  /** Is null or empty (SQL: col is null OR col = '') */
  blank?: InputMaybe<Scalars['Boolean']['input']>;
  /** Contains (ILIKE %value%) */
  cont?: InputMaybe<Scalars['String']['input']>;
  /** Contains all (ILIKE %all values%) */
  contAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains any (ILIKE %any value%) */
  contAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not match (NOT ILIKE value) */
  doesNotMatch?: InputMaybe<Scalars['String']['input']>;
  /** Does not match all (NOT ILIKE all values) */
  doesNotMatchAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not match any (NOT ILIKE any value) */
  doesNotMatchAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Ends with (ILIKE %value) */
  end?: InputMaybe<Scalars['String']['input']>;
  /** Ends with all (ILIKE %all values) */
  endAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Ends with any (ILIKE %any value) */
  endAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Equal to */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Equal to all */
  eqAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** false */
  false?: InputMaybe<Scalars['Boolean']['input']>;
  /** Greater than */
  gt?: InputMaybe<Scalars['String']['input']>;
  /** Greater than all */
  gtAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Greater than any */
  gtAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Greater than or equal to */
  gteq?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to all */
  gteqAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Greater than or equal to any */
  gteqAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains value with case insensitive */
  iCont?: InputMaybe<Scalars['String']['input']>;
  /** Contains all values with case insensitive */
  iContAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains any value with case insensitive */
  iContAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** In */
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Less than */
  lt?: InputMaybe<Scalars['String']['input']>;
  /** Less than all */
  ltAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Less than any */
  ltAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Less than or equal to */
  lteq?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to all */
  lteqAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Less than or equal to any */
  lteqAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches (ILIKE value) */
  matches?: InputMaybe<Scalars['String']['input']>;
  /** Matches all (ILIKE all values) */
  matchesAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches any (ILIKE any value) */
  matchesAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not contain (NOT ILIKE %value%) */
  notCont?: InputMaybe<Scalars['String']['input']>;
  /** Does not contain all (NOT ILIKE %all values%) */
  notContAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not contain any (NOT ILIKE %any value%) */
  notContAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not end with (NOT ILIKE %value) */
  notEnd?: InputMaybe<Scalars['String']['input']>;
  /** Does not end with all (NOT ILIKE %all values) */
  notEndAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not end with any (NOT ILIKE %any value) */
  notEndAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Not equal to */
  notEq?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to all */
  notEqAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not contain value with case insensitive */
  notICont?: InputMaybe<Scalars['String']['input']>;
  /** Does not contain all values with case insensitive */
  notIContAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not contain any value with case insensitive */
  notIContAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Not in */
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Is not null */
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Does not start with (NOT ILIKE value%) */
  notStart?: InputMaybe<Scalars['String']['input']>;
  /** Does not start with all (NOT ILIKE all values%) */
  notStartAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not start with any (NOT ILIKE any value%) */
  notStartAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Is null */
  null?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not null and not empty (SQL: col is not null AND col != '') */
  present?: InputMaybe<Scalars['Boolean']['input']>;
  /** Starts with (ILIKE value%) */
  start?: InputMaybe<Scalars['String']['input']>;
  /** Starts with all (ILIKE all values%) */
  startAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Starts with any (ILIKE any value%) */
  startAny?: InputMaybe<Array<Scalars['String']['input']>>;
  /** true */
  true?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Form Answer entity */
export type FormAnswer = TimestampInterface & {
  __typename?: 'FormAnswer';
  /** Attachments */
  attachmentsAttached: Array<Attachment>;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Form Template Question Field */
  field: FormTemplateQuestionField;
  /** Form Template Question Field ID */
  fieldId: Scalars['ID']['output'];
  /** Form Answer ID */
  id: Scalars['ID']['output'];
  /** Form Template Question Field kind */
  kind: FormTemplateQuestionFieldsKindEnum;
  /**
   * Performer entity
   * NOTE: if you only need an Object ID, please use the performerId field to reduce the load on the database.
   */
  performer: Maybe<User>;
  /** Performer ID */
  performerId: Maybe<Scalars['ID']['output']>;
  /** Position */
  position: Scalars['Int']['output'];
  /** Form Template Question description */
  questionDescription: Maybe<Scalars['String']['output']>;
  /** Form Template Question ID */
  questionId: Scalars['ID']['output'];
  /** Form Template Question name */
  questionName: Maybe<Scalars['String']['output']>;
  /** Response object to a Form Answer */
  resp: Array<FormAnswerRespType>;
  /** Task Completion ID */
  taskCompletionId: Scalars['ID']['output'];
  /** Form Template Version ID */
  templateVersionId: Scalars['ID']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /**
   * Indicates if response is expected to be valid
   * ATTENTION: Access allowed for users: [TASKS_MANAGEMENT:READ].
   */
  validityHint: Maybe<FormTemplateQuestionFieldOptionsValidityHintEnum>;
};

/** FormAnswerCollection type */
export type FormAnswerCollection = {
  __typename?: 'FormAnswerCollection';
  /** A collection of paginated FormAnswerCollection */
  collection: Array<FormAnswer>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Form Answer attributes */
export type FormAnswerCreateInputObject = {
  /** Attachments */
  attachmentsAttached?: InputMaybe<Array<AttachmentCuInputObject>>;
  /** Form Template Question Field ID */
  fieldId: Scalars['ID']['input'];
  /** Form Template Question ID */
  questionId: Scalars['ID']['input'];
  /** Response object */
  resp?: InputMaybe<Array<FormAnswerRespInputObject>>;
};

/** Form Answer entity */
export type FormAnswerReportingInfo = {
  __typename?: 'FormAnswerReportingInfo';
  /** Form Template Question Field ID */
  fieldId: Scalars['ID']['output'];
  /** Form Template Question Field kind */
  kind: FormTemplateQuestionFieldsKindEnum;
  /** Position */
  position: Scalars['Int']['output'];
  /** Form Template Question description */
  questionDescription: Maybe<Scalars['String']['output']>;
  /** Form Template Question name */
  questionName: Maybe<Scalars['String']['output']>;
  /** Form Template Version ID */
  templateVersionId: Scalars['ID']['output'];
};

/** Form Answer sorts */
export type FormAnswerReportingSortInputObject = {
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Performer full name */
  performerFullName?: InputMaybe<SortDirectionEnum>;
  /** End date for date range question */
  respDateRangeEndDate?: InputMaybe<SortDirectionEnum>;
  /** Start date for date range question */
  respDateRangeStartDate?: InputMaybe<SortDirectionEnum>;
  /** Value for date question */
  respDateValue?: InputMaybe<SortDirectionEnum>;
  /** Value for datetime question */
  respDatetimeValue?: InputMaybe<SortDirectionEnum>;
  /** Max value for min/max question */
  respMinMaxMaxValue?: InputMaybe<SortDirectionEnum>;
  /** Min value for min/max question */
  respMinMaxMinValue?: InputMaybe<SortDirectionEnum>;
  /** Value for number input question */
  respNumberInputValue?: InputMaybe<SortDirectionEnum>;
  /** Value for rating question */
  respRatingValue?: InputMaybe<SortDirectionEnum>;
  /** End time for time range question */
  respTimeRangeEndTime?: InputMaybe<SortDirectionEnum>;
  /** Start time for time range question */
  respTimeRangeStartTime?: InputMaybe<SortDirectionEnum>;
  /** Value for time question */
  respTimeValue?: InputMaybe<SortDirectionEnum>;
  /** No value for yes/no question */
  respYesNoNoSelected?: InputMaybe<SortDirectionEnum>;
  /** Yes value for yes/no question */
  respYesNoYesSelected?: InputMaybe<SortDirectionEnum>;
};

/** Response object */
export type FormAnswerRespInputObject = {
  /** Group */
  group?: InputMaybe<RespGroupEnum>;
  /** Form Template Question Field Option ID (Row of the checkbox button grid) */
  optionId?: InputMaybe<Scalars['ID']['input']>;
  /** Indicates if option is selected */
  selected?: InputMaybe<Scalars['Boolean']['input']>;
  /** Value */
  value?: InputMaybe<Scalars['String']['input']>;
  /** Form Template Question Field Option ID (Column for the checkbox button grid and only for the checkbox button grid) */
  valueId?: InputMaybe<Scalars['ID']['input']>;
};

/** Response type */
export type FormAnswerRespType = {
  __typename?: 'FormAnswerRespType';
  /** Group */
  group: Maybe<RespGroupEnum>;
  /** Form Template Question Field Option ID (Row of the checkbox button grid) */
  optionId: Maybe<Scalars['ID']['output']>;
  /** Position */
  position: Maybe<Scalars['Int']['output']>;
  /** Indicates if option is selected */
  selected: Maybe<Scalars['Boolean']['output']>;
  /**
   * Indicates if option is expected to be valid
   * ATTENTION: Access allowed for users: [TASKS_MANAGEMENT:READ].
   */
  validityHint: Maybe<FormTemplateQuestionFieldOptionsValidityHintEnum>;
  /** Value */
  value: Maybe<Scalars['String']['output']>;
  /** Form Template Question Field Option ID (Column for the checkbox button grid and only for the checkbox button grid) */
  valueId: Maybe<Scalars['ID']['output']>;
};

/** Form Category entity */
export type FormCategory = TimestampInterface & {
  __typename?: 'FormCategory';
  canDestroy: AuthorizationResult;
  /** Color */
  color: Scalars['String']['output'];
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Form Templates collection */
  formTemplates: FormTemplateCollection;
  /** Number of form templates */
  formTemplatesCount: Scalars['Int']['output'];
  /** Form Category ID */
  id: Scalars['ID']['output'];
  /** Name */
  name: Scalars['String']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


/** Form Category entity */
export type FormCategoryFormTemplatesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** FormCategoryCollection type */
export type FormCategoryCollection = {
  __typename?: 'FormCategoryCollection';
  /** A collection of paginated FormCategoryCollection */
  collection: Array<FormCategory>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type FormCategoryCreateInput = {
  /** Create attributes */
  attributes: FormCategoryCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Form Category attributes */
export type FormCategoryCreateInputObject = {
  /** Color */
  color: Scalars['String']['input'];
  /** Name */
  name: Scalars['String']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type FormCategoryDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Form Category ID */
  id: Scalars['ID']['input'];
};

/** Form Category filters */
export type FormCategoryFilterInputObject = {
  /** Form Category name */
  name?: InputMaybe<FilterInputObject>;
};

/** Form Category sorts */
export type FormCategorySortInputObject = {
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Number of form templates in this category */
  formTemplatesCount?: InputMaybe<SortDirectionEnum>;
  /** Name */
  name?: InputMaybe<SortDirectionEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type FormCategoryUpdateInput = {
  /** Update attributes */
  attributes: FormCategoryUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Form Category ID */
  id: Scalars['ID']['input'];
};

/** Form Category attributes */
export type FormCategoryUpdateInputObject = {
  /** Color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Form Template entity */
export type FormTemplate = TimestampInterface & {
  __typename?: 'FormTemplate';
  /** Archived date */
  archivedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  canArchive: AuthorizationResult;
  canDestroy: AuthorizationResult;
  canRestore: AuthorizationResult;
  canUpdate: AuthorizationResult;
  /**
   * Form Category entity
   * NOTE: if you only need an Object ID, please use the categoryId field to reduce the load on the database.
   */
  category: Maybe<FormCategory>;
  /** Form Category ID */
  categoryId: Maybe<Scalars['ID']['output']>;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Latest Form Template Version entity */
  currentVersion: Maybe<FormTemplateVersion>;
  /** Description */
  description: Maybe<Scalars['String']['output']>;
  /** Form Template ID */
  id: Scalars['ID']['output'];
  /** Name */
  name: Scalars['String']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Number of versions */
  versionsCount: Scalars['Int']['output'];
  /** Number of visible linked tasks for current user */
  visibleLinkedTasksCount: Scalars['Int']['output'];
};

/** Description from base input object. Please set your description for child object. */
export type FormTemplateBulkArchiveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** IDs */
  ids: Array<Scalars['ID']['input']>;
};

/** Description from base input object. Please set your description for child object. */
export type FormTemplateBulkDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** IDs */
  ids: Array<Scalars['ID']['input']>;
};

/** Description from base input object. Please set your description for child object. */
export type FormTemplateBulkRestoreInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** IDs */
  ids: Array<Scalars['ID']['input']>;
};

/** FormTemplateCollection type */
export type FormTemplateCollection = {
  __typename?: 'FormTemplateCollection';
  /** A collection of paginated FormTemplateCollection */
  collection: Array<FormTemplate>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type FormTemplateCreateInput = {
  /** Create attributes */
  attributes: FormTemplateCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Form Template attributes */
export type FormTemplateCreateInputObject = {
  /** Form Category ID */
  categoryId: Scalars['ID']['input'];
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name */
  name: Scalars['String']['input'];
  /** Version */
  version: FormTemplateVersionCuInputObject;
};

/** Form Template filters */
export type FormTemplateFilterInputObject = {
  /** Form Category name */
  categoryName?: InputMaybe<FilterInputObject>;
  /** Form Template name */
  name?: InputMaybe<FilterInputObject>;
};

/** Form Question entity */
export type FormTemplateQuestion = TimestampInterface & {
  __typename?: 'FormTemplateQuestion';
  /** Related Attachments */
  attachmentsAttached: Array<Attachment>;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Description */
  description: Maybe<Scalars['String']['output']>;
  /** Related Form Question Fields */
  fields: Array<FormTemplateQuestionField>;
  /** Form Question ID */
  id: Scalars['ID']['output'];
  /** Name */
  name: Maybe<Scalars['String']['output']>;
  /** Position */
  position: Scalars['Int']['output'];
  /** Form Template ID */
  templateId: Scalars['ID']['output'];
  /** Form Template Version ID */
  templateVersionId: Scalars['ID']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Form Question Field entity */
export type FormTemplateQuestionField = TimestampInterface & {
  __typename?: 'FormTemplateQuestionField';
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Form Question Field ID */
  id: Scalars['ID']['output'];
  /** Kind */
  kind: FormTemplateQuestionFieldsKindEnum;
  /** Related Form Question Field Options */
  options: Array<FormTemplateQuestionFieldOption>;
  /** Position */
  position: Scalars['Int']['output'];
  /** Form Question ID */
  questionId: Scalars['ID']['output'];
  /** Indicates if field is required */
  required: Scalars['Boolean']['output'];
  /** Field settings */
  settings: Settings;
  /** Form Template ID */
  templateId: Scalars['ID']['output'];
  /** Form Template Version ID */
  templateVersionId: Scalars['ID']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /**
   * Validity hint config
   * ATTENTION: Access allowed for users: [TASKS_MANAGEMENT:READ].
   */
  validityHintConfig: Array<ValidityHintConfig>;
};

/** Form Question Field Option entity */
export type FormTemplateQuestionFieldOption = TimestampInterface & {
  __typename?: 'FormTemplateQuestionFieldOption';
  /**
   * Condition
   * ATTENTION: Access allowed for users: [TASKS_MANAGEMENT:READ].
   */
  condition: Maybe<ConditionEnum>;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Form Question Field ID */
  fieldId: Scalars['ID']['output'];
  /** Group */
  group: Maybe<GroupEnum>;
  /** Form Question Field Option ID */
  id: Scalars['ID']['output'];
  /** Position */
  position: Scalars['Int']['output'];
  /** Form Question ID */
  questionId: Scalars['ID']['output'];
  /** Form Template ID */
  templateId: Scalars['ID']['output'];
  /** Form Template Version ID */
  templateVersionId: Scalars['ID']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /**
   * Indicates if option will have expected result after user input in answers
   * ATTENTION: Access allowed for users: [TASKS_MANAGEMENT:READ].
   */
  validityHint: Maybe<FormTemplateQuestionFieldOptionsValidityHintEnum>;
  /** Array of values which will be used to validate user input or to display in the form (depends on the kind of the field) */
  values: Array<Scalars['String']['output']>;
};

/** Form Question Field Option attributes */
export type FormTemplateQuestionFieldOptionUpdateInputObject = {
  /** Condition */
  condition?: InputMaybe<ConditionEnum>;
  /** Group */
  group?: InputMaybe<GroupEnum>;
  /** Form Question Field Option ID (to update existing question pass ID. To create new question do not pass ID) */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Position */
  position: Scalars['Int']['input'];
  /** Indicates if option will have expected result after user input in answers */
  validityHint?: InputMaybe<FormTemplateQuestionFieldOptionsValidityHintEnum>;
  /** Values */
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** All available form template question field option validity hints */
export enum FormTemplateQuestionFieldOptionsValidityHintEnum {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
  Warning = 'WARNING'
}

/** Field settings */
export type FormTemplateQuestionFieldSettingsInputObject = {
  /** Allow display last answer */
  allowDisplayLastAnswer?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates if checkbox_button_grid field should limit responses to one per column */
  limitToOneRespPerColumn?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates if checkbox_button_grid field should limit responses to one per row */
  limitToOneRespPerRow?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Form Question Field attributes */
export type FormTemplateQuestionFieldUpdateInputObject = {
  /** Form Question Field ID (to update existing field pass ID. To create new field do not pass ID) */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Kind */
  kind: FormTemplateQuestionFieldsKindEnum;
  /** Form Question Field Options attributes array */
  options?: InputMaybe<Array<FormTemplateQuestionFieldOptionUpdateInputObject>>;
  /** Position */
  position: Scalars['Int']['input'];
  /** Indicates if field is required */
  required: Scalars['Boolean']['input'];
  /** Field settings */
  settings?: InputMaybe<FormTemplateQuestionFieldSettingsInputObject>;
  /** Validity hint config */
  validityHintConfig?: InputMaybe<Array<FormTemplateQuestionFieldValidityHintConfigInputObject>>;
};

/** Validity hint config */
export type FormTemplateQuestionFieldValidityHintConfigInputObject = {
  /** Option positions */
  optionPositions: Array<Scalars['Int']['input']>;
  /** Validity hint */
  validityHint?: InputMaybe<FormTemplateQuestionFieldOptionsValidityHintEnum>;
};

/** Available form template question field kinds */
export enum FormTemplateQuestionFieldsKindEnum {
  /** Attachment */
  Attachment = 'ATTACHMENT',
  /** Multi select */
  Checkbox = 'CHECKBOX',
  /** Checkbox grid in table */
  CheckboxButtonGrid = 'CHECKBOX_BUTTON_GRID',
  /** Date */
  Date = 'DATE',
  /** Date and time */
  Datetime = 'DATETIME',
  /** Date range */
  DateRange = 'DATE_RANGE',
  /** Decision box */
  DecisionBox = 'DECISION_BOX',
  /** Important notice */
  ImportantNotice = 'IMPORTANT_NOTICE',
  /** Single line text */
  Input = 'INPUT',
  /** Numeric range */
  MinMax = 'MIN_MAX',
  /** Numeric */
  NumberInput = 'NUMBER_INPUT',
  /** Single select */
  RadioButton = 'RADIO_BUTTON',
  /** Rating */
  Rating = 'RATING',
  /** Section */
  Section = 'SECTION',
  /** Dropdown */
  SingleSelect = 'SINGLE_SELECT',
  /** Multi line text */
  TextArea = 'TEXT_AREA',
  /** Time */
  Time = 'TIME',
  /** Time range */
  TimeRange = 'TIME_RANGE',
  /** Yes/no */
  YesNo = 'YES_NO'
}

/** Form Question attributes */
export type FormTemplateQuestionUpdateInputObject = {
  /** Attachments */
  attachmentsAttached?: InputMaybe<Array<AttachmentCuInputObject>>;
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Form Question Fields attributes array */
  fields: Array<FormTemplateQuestionFieldUpdateInputObject>;
  /** Form Question ID (to update existing question pass ID. To create new question do not pass ID) */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Position */
  position: Scalars['Int']['input'];
};

/** Available form template scope names */
export enum FormTemplateScopeNameEnum {
  /** Active form templates */
  Active = 'ACTIVE',
  /** Archived form templates */
  Archived = 'ARCHIVED'
}

/** Form Template sorts */
export type FormTemplateSortInputObject = {
  /** Archived at */
  archivedAt?: InputMaybe<SortDirectionEnum>;
  /** Form Category name */
  categoryName?: InputMaybe<SortDirectionEnum>;
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Name */
  name?: InputMaybe<SortDirectionEnum>;
  /** Number of versions */
  versionsCount?: InputMaybe<SortDirectionEnum>;
  /** Number of visible linked tasks for current user */
  visibleLinkedTasksCount?: InputMaybe<SortDirectionEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type FormTemplateUpdateInput = {
  /** Update attributes */
  attributes: FormTemplateUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Form Template ID */
  id: Scalars['ID']['input'];
};

/** Form Template attributes */
export type FormTemplateUpdateInputObject = {
  /** Form Category ID */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Form Template Version entity */
export type FormTemplateVersion = TimestampInterface & {
  __typename?: 'FormTemplateVersion';
  /** Archived date */
  archivedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  canArchive: AuthorizationResult;
  canDestroy: AuthorizationResult;
  canRestore: AuthorizationResult;
  canUpdate: AuthorizationResult;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Form Template ID */
  id: Scalars['ID']['output'];
  /** Related Form Questions */
  questions: Array<FormTemplateQuestion>;
  /** Status (Virtual field) */
  status: FormTemplateVersionStatusEnum;
  /** Form Template ID */
  templateId: Scalars['ID']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Version */
  version: Maybe<Scalars['String']['output']>;
  /** Number of visible linked tasks for current user */
  visibleLinkedTasksCount: Scalars['Int']['output'];
};

/** Description from base input object. Please set your description for child object. */
export type FormTemplateVersionArchiveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Form Template ID */
  formTemplateId: Scalars['ID']['input'];
  /** Form Template Version ID */
  id: Scalars['ID']['input'];
};

/** FormTemplateVersionCollection type */
export type FormTemplateVersionCollection = {
  __typename?: 'FormTemplateVersionCollection';
  /** A collection of paginated FormTemplateVersionCollection */
  collection: Array<FormTemplateVersion>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type FormTemplateVersionCreateInput = {
  /** Create attributes */
  attributes: FormTemplateVersionCuInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Form Template ID */
  formTemplateId: Scalars['ID']['input'];
};

/** Form Template Version attributes */
export type FormTemplateVersionCuInputObject = {
  /** Form Template Questions attributes array */
  questions: Array<FormTemplateQuestionUpdateInputObject>;
  /** Task IDs to link */
  taskIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Description from base input object. Please set your description for child object. */
export type FormTemplateVersionDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Form Template ID */
  formTemplateId: Scalars['ID']['input'];
  /** Form Template Version ID */
  id: Scalars['ID']['input'];
};

/** Form Template Version filters */
export type FormTemplateVersionFilterInputObject = {
  /** Archived at */
  archivedAt?: InputMaybe<FilterDateInputObject>;
  /** Version */
  version?: InputMaybe<FilterIntInputObject>;
};

/** Description from base input object. Please set your description for child object. */
export type FormTemplateVersionRestoreInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Form Template ID */
  formTemplateId: Scalars['ID']['input'];
  /** Form Template Version ID */
  id: Scalars['ID']['input'];
};

/** Form Template Version sorts */
export type FormTemplateVersionSortInputObject = {
  /** Archived at */
  archivedAt?: InputMaybe<SortDirectionEnum>;
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Version */
  version?: InputMaybe<SortDirectionEnum>;
  /** Number of visible linked tasks for current user */
  visibleLinkedTasksCount?: InputMaybe<SortDirectionEnum>;
};

/** Available form template version statuses */
export enum FormTemplateVersionStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Unused = 'UNUSED'
}

/** Description from base input object. Please set your description for child object. */
export type FormTemplateVersionUpdateInput = {
  /** Update attributes */
  attributes: FormTemplateVersionCuInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Form Template ID */
  formTemplateId: Scalars['ID']['input'];
  /** Form Template Version ID */
  id: Scalars['ID']['input'];
};

/** All available form template question field option groups */
export enum GroupEnum {
  Columns = 'COLUMNS',
  Max = 'MAX',
  Min = 'MIN',
  No = 'NO',
  Rows = 'ROWS',
  Yes = 'YES'
}

/** History entity */
export type History = {
  __typename?: 'History';
  /** Diff data */
  data: Maybe<HistoryDataUnion>;
  /** History ID */
  id: Scalars['ID']['output'];
  /** Kind of operation */
  kind: HistoryKindEnum;
  /** Metadata */
  metadata: Maybe<HistoryMetadataUnion>;
  /** Occurred at (actual time when a record was created/updated/etc.) */
  occurredAt: Scalars['ISO8601DateTime']['output'];
  /** Indicates if operation was done by the Datizy system */
  system: Maybe<Scalars['Boolean']['output']>;
  /**
   * User entity who made the operation
   * NOTE: if you only need an Object ID, please use the userId field to reduce the load on the database.
   */
  user: Maybe<User>;
  /** User ID who made the operation */
  userId: Maybe<Scalars['ID']['output']>;
};

/** HistoryCollection type */
export type HistoryCollection = {
  __typename?: 'HistoryCollection';
  /** A collection of paginated HistoryCollection */
  collection: Array<History>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** History data */
export type HistoryDataUnion = InterventionActionHistoryDataType;

/** Available intervention action history metadata reasons */
export enum HistoryInterventionActionHistoryMetadataReasonEnum {
  /** When intervention was closed */
  InterventionClosed = 'INTERVENTION_CLOSED'
}

/** Available history kinds */
export enum HistoryKindEnum {
  /** Created action */
  Created = 'CREATED',
  /** Updated action */
  Updated = 'UPDATED'
}

/** History metadata */
export type HistoryMetadataUnion = InterventionActionHistoryMetadataType;

/** History sorts */
export type HistorySortInputObject = {
  /** Occurred at (actual time when a record was created/updated/etc.) */
  occurredAt?: InputMaybe<SortDirectionEnum>;
};

/** Available source types */
export enum HistorySourceableTypeEnum {
  /** Intervention Action entity */
  InterventionAction = 'INTERVENTION_ACTION'
}

/** Industry entity */
export type Industry = TimestampInterface & {
  __typename?: 'Industry';
  /** Color */
  color: Scalars['String']['output'];
  /** Companies */
  companies: CompanyCollection;
  /** Number of companies in this industry */
  companiesCount: Scalars['Int']['output'];
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Industry ID */
  id: Scalars['ID']['output'];
  /** Name */
  name: Scalars['String']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


/** Industry entity */
export type IndustryCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** IndustryCollection type */
export type IndustryCollection = {
  __typename?: 'IndustryCollection';
  /** A collection of paginated IndustryCollection */
  collection: Array<Industry>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type IndustryCreateInput = {
  /** Create attributes */
  attributes: IndustryCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Industry attributes */
export type IndustryCreateInputObject = {
  /** Color */
  color: Scalars['String']['input'];
  /** Companies attributes array */
  companies?: InputMaybe<Array<LinkingInputObject>>;
  /** Name */
  name: Scalars['String']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type IndustryDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Industry ID */
  id: Scalars['ID']['input'];
};

/** Industry filters */
export type IndustryFilterInputObject = {
  /** Industry name */
  name?: InputMaybe<FilterInputObject>;
};

/** Industry sorts */
export type IndustrySortInputObject = {
  /** Number of companies in this industry */
  companiesCount?: InputMaybe<SortDirectionEnum>;
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Name */
  name?: InputMaybe<SortDirectionEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type IndustryUpdateInput = {
  /** Update attributes */
  attributes: IndustryUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Industry ID */
  id: Scalars['ID']['input'];
};

/** Industry attributes */
export type IndustryUpdateInputObject = {
  /** Color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Companies attributes array */
  companies?: InputMaybe<Array<LinkingInputObject>>;
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Intervention entity */
export type Intervention = TimestampInterface & {
  __typename?: 'Intervention';
  /**
   * Asset entity
   * NOTE: if you only need an Object ID, please use the assetId field to reduce the load on the database.
   */
  asset: Maybe<Asset>;
  /** Asset ID */
  assetId: Maybe<Scalars['ID']['output']>;
  /** Related Attachments */
  attachmentsAttached: Array<Attachment>;
  canClose: AuthorizationResult;
  canCreate: AuthorizationResult;
  canCreateAction: AuthorizationResult;
  canDestroy: AuthorizationResult;
  canUpdate: AuthorizationResult;
  /**
   * Intervention Category entity
   * NOTE: if you only need an Object ID, please use the categoryId field to reduce the load on the database.
   */
  category: Maybe<InterventionCategory>;
  /** Intervention Category ID */
  categoryId: Maybe<Scalars['ID']['output']>;
  /** Closed date */
  closedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Code */
  code: Maybe<Scalars['String']['output']>;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /**
   * User entity
   * NOTE: if you only need an Object ID, please use the createdById field to reduce the load on the database.
   */
  createdBy: Maybe<User>;
  /** User ID */
  createdById: Maybe<Scalars['ID']['output']>;
  /** Description */
  description: Maybe<Scalars['String']['output']>;
  /** Duration time (in minutes) */
  durationTime: Maybe<Scalars['Int']['output']>;
  /** Estimated date */
  estimatedDate: Maybe<Scalars['ISO8601Date']['output']>;
  /** Estimated time (in minutes) */
  estimatedTime: Maybe<Scalars['Int']['output']>;
  /** Intervention ID */
  id: Scalars['ID']['output'];
  /** Logistic time (in minutes) */
  logisticTime: Maybe<Scalars['Int']['output']>;
  /** Name */
  name: Scalars['String']['output'];
  /** Priority */
  priority: InterventionPriorityEnum;
  /**
   * Site entity
   * NOTE: if you only need an Object ID, please use the siteId field to reduce the load on the database.
   */
  site: Maybe<Site>;
  /**
   * Site Area entity
   * NOTE: if you only need an Object ID, please use the siteAreaId field to reduce the load on the database.
   */
  siteArea: Maybe<SiteArea>;
  /** Site Area ID */
  siteAreaId: Maybe<Scalars['ID']['output']>;
  /** Site ID */
  siteId: Maybe<Scalars['ID']['output']>;
  /** Status */
  status: InterventionStatusEnum;
  /**
   * User entity
   * NOTE: if you only need an Object ID, please use the supervisorId field to reduce the load on the database.
   */
  supervisor: Maybe<User>;
  /** User ID */
  supervisorId: Maybe<Scalars['ID']['output']>;
  /**
   * Task entity
   * NOTE: if you only need an Object ID, please use the taskId field to reduce the load on the database.
   */
  task: Maybe<Task>;
  /** Key to group the same tasks which are recurring or have been recurred (if recurring rule was deleted in some point) */
  taskGroupKey: Maybe<Scalars['ID']['output']>;
  /** Task ID */
  taskId: Maybe<Scalars['ID']['output']>;
  /** Total duration time (in minutes) (intervention.duration_time + SUM(intervention_actions.duration_time)) */
  totalDurationTime: Maybe<Scalars['Int']['output']>;
  /** Total estimated time (in minutes) (intervention.estimated_time + SUM(intervention_actions.estimated_time)) */
  totalEstimatedTime: Maybe<Scalars['Int']['output']>;
  /** Total logistic time (in minutes) (intervention.logistic_time + SUM(intervention_actions.logistic_time)) */
  totalLogisticTime: Maybe<Scalars['Int']['output']>;
  /** Total duration time + Total logistic time */
  totalTime: Maybe<Scalars['Int']['output']>;
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Intervention Action entity */
export type InterventionAction = TimestampInterface & {
  __typename?: 'InterventionAction';
  /** Related Attachments */
  attachmentsAttached: Array<Attachment>;
  canDestroy: AuthorizationResult;
  canUpdate: AuthorizationResult;
  /** Closed date */
  closedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /**
   * User entity
   * NOTE: if you only need an Object ID, please use the createdById field to reduce the load on the database.
   */
  createdBy: Maybe<User>;
  /** User ID */
  createdById: Maybe<Scalars['ID']['output']>;
  /** Duration time (in minutes) */
  durationTime: Maybe<Scalars['Int']['output']>;
  /** Estimated date */
  estimatedDate: Maybe<Scalars['ISO8601Date']['output']>;
  /** Estimated time (in minutes) */
  estimatedTime: Maybe<Scalars['Int']['output']>;
  /** Intervention Action ID */
  id: Scalars['ID']['output'];
  /** Intervention ID */
  interventionId: Maybe<Scalars['ID']['output']>;
  /** Logistic time (in minutes) */
  logisticTime: Maybe<Scalars['Int']['output']>;
  /** Status */
  status: InterventionActionStatusEnum;
  /** Test */
  text: Maybe<Scalars['String']['output']>;
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** InterventionActionCollection type */
export type InterventionActionCollection = {
  __typename?: 'InterventionActionCollection';
  /** A collection of paginated InterventionActionCollection */
  collection: Array<InterventionAction>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type InterventionActionCreateInput = {
  /**  Link to open the intervention action from notification. Supported variables: `interventionId` and `interventionActionId`. Example: `https://admin.datizy.com/interventions/:interventionId/:interventionActionId`  */
  assigneeRedirectUrl: Scalars['String']['input'];
  /** Create attributes */
  attributes: InterventionActionCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Create attributes */
  interventionId: Scalars['ID']['input'];
  /**  Link to open the intervention action from notification where user was mentioned. Supported variables: `interventionId` and `interventionActionId`. Example: `https://admin.datizy.com/interventions/:interventionId/:interventionActionId`  */
  mentionRedirectUrl: Scalars['String']['input'];
};

/** Intervention action attributes */
export type InterventionActionCreateInputObject = {
  /** Assignees attributes array */
  assignees?: InputMaybe<Array<LinkingInputObject>>;
  /** Attachments */
  attachmentsAttached?: InputMaybe<Array<AttachmentCuInputObject>>;
  /** Duration time (in minutes) */
  durationTime?: InputMaybe<Scalars['Int']['input']>;
  /** Estimated date */
  estimatedDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Estimated time (in minutes) */
  estimatedTime?: InputMaybe<Scalars['Int']['input']>;
  /** Logistic time (in minutes) */
  logisticTime?: InputMaybe<Scalars['Int']['input']>;
  /** Status */
  status: InterventionActionStatusEnum;
  /** Text */
  text: Scalars['String']['input'];
  /** Mentions attributes array for text field */
  textMentions?: InputMaybe<Array<LinkingInputObject>>;
};

/** Description from base input object. Please set your description for child object. */
export type InterventionActionDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Intervention Action ID */
  id: Scalars['ID']['input'];
  /** Intervention ID */
  interventionId: Scalars['ID']['input'];
};

/** Intervention Action filters */
export type InterventionActionFilterInputObject = {
  /** Assignee users */
  assigneeUsersId?: InputMaybe<FilterIdInputObject>;
  /** Created at */
  createdAt?: InputMaybe<FilterDateInputObject>;
  /** Created by user */
  createdById?: InputMaybe<FilterIdInputObject>;
  /** Mentioned users in text field */
  textMentionsUsersId?: InputMaybe<FilterIdInputObject>;
};

/** Intervention Action entity */
export type InterventionActionHistoryDataType = {
  __typename?: 'InterventionActionHistoryDataType';
  /** Duration time (in minutes) */
  durationTime: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Estimated date */
  estimatedDate: Maybe<Array<Maybe<Scalars['ISO8601Date']['output']>>>;
  /** Estimated time (in minutes) */
  estimatedTime: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Logistic time (in minutes) */
  logisticTime: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Status */
  status: Maybe<Array<InterventionActionStatusEnum>>;
};

/** Intervention Action History Metadata entity */
export type InterventionActionHistoryMetadataType = {
  __typename?: 'InterventionActionHistoryMetadataType';
  /** Reason */
  reason: Maybe<HistoryInterventionActionHistoryMetadataReasonEnum>;
};

/** Intervention Action sorts */
export type InterventionActionSortInputObject = {
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
};

/** Available intervention action statuses */
export enum InterventionActionStatusEnum {
  ClassifiedSatsContinued = 'CLASSIFIED_SATS_CONTINUED',
  Closed = 'CLOSED',
  InCheck = 'IN_CHECK',
  InProgress = 'IN_PROGRESS',
  MaterialOrdered = 'MATERIAL_ORDERED',
  MaterialReceived = 'MATERIAL_RECEIVED',
  Planned = 'PLANNED'
}

/** Description from base input object. Please set your description for child object. */
export type InterventionActionUpdateInput = {
  /**  Link to open the intervention action from notification. Supported variables: `interventionId` and `interventionActionId`. Example: `https://admin.datizy.com/interventions/:interventionId/:interventionActionId`  */
  assigneeRedirectUrl: Scalars['String']['input'];
  /** Update attributes */
  attributes: InterventionActionUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Intervention Action ID */
  id: Scalars['ID']['input'];
  /** Intervention ID */
  interventionId: Scalars['ID']['input'];
  /**  Link to open the intervention action from notification where user was mentioned. Supported variables: `interventionId` and `interventionActionId`. Example: `https://admin.datizy.com/interventions/:interventionId/:interventionActionId`  */
  mentionRedirectUrl: Scalars['String']['input'];
};

/** Intervention action attributes */
export type InterventionActionUpdateInputObject = {
  /** Assignees attributes array */
  assignees?: InputMaybe<Array<LinkingInputObject>>;
  /** Attachments */
  attachmentsAttached?: InputMaybe<Array<AttachmentCuInputObject>>;
  /** Duration time (in minutes) */
  durationTime?: InputMaybe<Scalars['Int']['input']>;
  /** Estimated date */
  estimatedDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Estimated time (in minutes) */
  estimatedTime?: InputMaybe<Scalars['Int']['input']>;
  /** Logistic time (in minutes) */
  logisticTime?: InputMaybe<Scalars['Int']['input']>;
  /** Status */
  status?: InputMaybe<InterventionActionStatusEnum>;
  /** Text */
  text?: InputMaybe<Scalars['String']['input']>;
  /** Mentions attributes array for text field */
  textMentions?: InputMaybe<Array<LinkingInputObject>>;
};

/** Description from base input object. Please set your description for child object. */
export type InterventionBulkCloseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Intervention IDs */
  ids: Array<Scalars['ID']['input']>;
};

/** Intervention Category entity */
export type InterventionCategory = TimestampInterface & {
  __typename?: 'InterventionCategory';
  canDestroy: AuthorizationResult;
  /** Color */
  color: Scalars['String']['output'];
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Description */
  description: Maybe<Scalars['String']['output']>;
  /** Intervention Category ID */
  id: Scalars['ID']['output'];
  /** Intervention collection */
  interventions: InterventionCollection;
  /** Name */
  name: Scalars['String']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Number of visible Interventions */
  visibleInterventionsCount: Scalars['Int']['output'];
};


/** Intervention Category entity */
export type InterventionCategoryInterventionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** InterventionCategoryCollection type */
export type InterventionCategoryCollection = {
  __typename?: 'InterventionCategoryCollection';
  /** A collection of paginated InterventionCategoryCollection */
  collection: Array<InterventionCategory>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type InterventionCategoryCreateInput = {
  /** Create attributes */
  attributes: InterventionCategoryCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Intervention Category attributes */
export type InterventionCategoryCreateInputObject = {
  /** Color */
  color: Scalars['String']['input'];
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name */
  name: Scalars['String']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type InterventionCategoryDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Intervention Category ID */
  id: Scalars['ID']['input'];
};

/** Intervention Category filters */
export type InterventionCategoryFilterInputObject = {
  /** Intervention ID */
  id?: InputMaybe<FilterIdInputObject>;
  /** Intervention Category name */
  name?: InputMaybe<FilterInputObject>;
};

/** Intervention Category sorts */
export type InterventionCategorySortInputObject = {
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Name */
  name?: InputMaybe<SortDirectionEnum>;
  /** Number of visible Interventions in this category */
  visibleInterventionsCount?: InputMaybe<SortDirectionEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type InterventionCategoryUpdateInput = {
  /** Update attributes */
  attributes: InterventionCategoryUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Intervention Category ID */
  id: Scalars['ID']['input'];
};

/** Intervention Category attributes */
export type InterventionCategoryUpdateInputObject = {
  /** Color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** InterventionCollection type */
export type InterventionCollection = {
  __typename?: 'InterventionCollection';
  /** A collection of paginated InterventionCollection */
  collection: Array<Intervention>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type InterventionCreateInput = {
  /**  Link to open the intervention from notification. Supported variables: `interventionId`. Example: `https://admin.datizy.com/interventions/:interventionId`  */
  assigneeRedirectUrl: Scalars['String']['input'];
  /** Create attributes */
  attributes: InterventionCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Intervention attributes */
export type InterventionCreateInputObject = {
  /** Asset ID */
  assetId?: InputMaybe<Scalars['ID']['input']>;
  /** Assignees attributes array */
  assignees?: InputMaybe<Array<LinkingInputObject>>;
  /** Attachments */
  attachmentsAttached?: InputMaybe<Array<AttachmentCuInputObject>>;
  /** Intervention Category ID */
  categoryId: Scalars['ID']['input'];
  /** Code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Description */
  description: Scalars['String']['input'];
  /** Duration time (in minutes) */
  durationTime?: InputMaybe<Scalars['Int']['input']>;
  /** Estimated date */
  estimatedDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Estimated time (in minutes) */
  estimatedTime?: InputMaybe<Scalars['Int']['input']>;
  /** Logistic time (in minutes) */
  logisticTime?: InputMaybe<Scalars['Int']['input']>;
  /** Name */
  name: Scalars['String']['input'];
  /** Priority */
  priority: InterventionPriorityEnum;
  /** Site Area ID */
  siteAreaId?: InputMaybe<Scalars['ID']['input']>;
  /** Site ID */
  siteId: Scalars['ID']['input'];
  /** Status */
  status: InterventionStatusEnum;
  /** User ID */
  supervisorId?: InputMaybe<Scalars['ID']['input']>;
  /** Task ID */
  taskId?: InputMaybe<Scalars['ID']['input']>;
};

/** Description from base input object. Please set your description for child object. */
export type InterventionDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Intervention ID */
  id: Scalars['ID']['input'];
};

/** Intervention filters */
export type InterventionFilterInputObject = {
  /** Asset code */
  assetCode?: InputMaybe<FilterInputObject>;
  /** Asset ID */
  assetId?: InputMaybe<FilterIdInputObject>;
  /** Asset name */
  assetName?: InputMaybe<FilterInputObject>;
  /** Asset QR prefix */
  assetQrPrefix?: InputMaybe<FilterInputObject>;
  /** Assignee users */
  assigneeUsersId?: InputMaybe<FilterIdInputObject>;
  /** Intervention Category ID */
  categoryId?: InputMaybe<FilterIdInputObject>;
  /** Intervention Category name */
  categoryName?: InputMaybe<FilterInputObject>;
  /** Code */
  code?: InputMaybe<FilterInputObject>;
  /** Created at */
  createdAt?: InputMaybe<FilterDateInputObject>;
  /** Created by user */
  createdById?: InputMaybe<FilterIdInputObject>;
  /** Name */
  name?: InputMaybe<FilterInputObject>;
  /** Priority */
  priority?: InputMaybe<FilterIntInputObject>;
  /** Site Area code */
  siteAreaCode?: InputMaybe<FilterInputObject>;
  /** Site Area ID */
  siteAreaId?: InputMaybe<FilterIdInputObject>;
  /** Site Area name */
  siteAreaName?: InputMaybe<FilterInputObject>;
  /** Site code */
  siteCode?: InputMaybe<FilterInputObject>;
  /** Site ID */
  siteId?: InputMaybe<FilterIdInputObject>;
  /** Site name */
  siteName?: InputMaybe<FilterInputObject>;
  /** Status */
  status?: InputMaybe<FilterIntInputObject>;
  /** Task group key */
  taskGroupKey?: InputMaybe<FilterIdInputObject>;
};

/** Available intervention priorities */
export enum InterventionPriorityEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

/** Available intervention scope names */
export enum InterventionScopeNameEnum {
  /** All */
  All = 'ALL',
  /** Closed interventions */
  Closed = 'CLOSED',
  /** Opened interventions */
  Opened = 'OPENED',
  /** Opened interventions where user is participant (creator, supervisor, assignee, action creator, action assignee or mentioned in comments) */
  OpenedMyParticipation = 'OPENED_MY_PARTICIPATION'
}

/** Intervention sorts */
export type InterventionSortInputObject = {
  /** Asset name */
  assetName?: InputMaybe<SortDirectionEnum>;
  /** Intervention Category name */
  categoryName?: InputMaybe<SortDirectionEnum>;
  /** Closed at */
  closedAt?: InputMaybe<SortDirectionEnum>;
  /** Code */
  code?: InputMaybe<SortDirectionEnum>;
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Name */
  name?: InputMaybe<SortDirectionEnum>;
  /** Priority */
  priority?: InputMaybe<SortDirectionEnum>;
  /** Site Area name */
  siteAreaName?: InputMaybe<SortDirectionEnum>;
  /** Site name */
  siteName?: InputMaybe<SortDirectionEnum>;
  /** Status */
  status?: InputMaybe<SortDirectionEnum>;
};

/** Available intervention statuses */
export enum InterventionStatusEnum {
  ClassifiedSatsContinued = 'CLASSIFIED_SATS_CONTINUED',
  Closed = 'CLOSED',
  InCheck = 'IN_CHECK',
  InProgress = 'IN_PROGRESS',
  MaterialOrdered = 'MATERIAL_ORDERED',
  MaterialReceived = 'MATERIAL_RECEIVED',
  Planned = 'PLANNED'
}

/** Description from base input object. Please set your description for child object. */
export type InterventionUpdateInput = {
  /**  Link to open the intervention from notification. Supported variables: `interventionId`. Example: `https://admin.datizy.com/interventions/:interventionId`  */
  assigneeRedirectUrl: Scalars['String']['input'];
  /** Update attributes */
  attributes: InterventionUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Intervention ID */
  id: Scalars['ID']['input'];
};

/** Intervention attributes */
export type InterventionUpdateInputObject = {
  /** Assignees attributes array */
  assignees?: InputMaybe<Array<LinkingInputObject>>;
  /** Attachments */
  attachmentsAttached?: InputMaybe<Array<AttachmentCuInputObject>>;
  /** Intervention Category ID */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** Code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Duration time (in minutes) */
  durationTime?: InputMaybe<Scalars['Int']['input']>;
  /** Estimated date */
  estimatedDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Estimated time (in minutes) */
  estimatedTime?: InputMaybe<Scalars['Int']['input']>;
  /** Logistic time (in minutes) */
  logisticTime?: InputMaybe<Scalars['Int']['input']>;
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Priority */
  priority?: InputMaybe<InterventionPriorityEnum>;
  /** Status */
  status?: InputMaybe<InterventionStatusEnum>;
  /** User ID */
  supervisorId?: InputMaybe<Scalars['ID']['input']>;
};

/** Job entity */
export type Job = {
  __typename?: 'Job';
  /** Number of the object currently being processed */
  at: Maybe<Scalars['Int']['output']>;
  /** Estimated time of completion (in seconds) */
  eta: Maybe<Scalars['Float']['output']>;
  /** Job ID */
  jid: Maybe<Scalars['String']['output']>;
  /** Current progress message */
  message: Maybe<Scalars['String']['output']>;
  /** Payload */
  payload: Maybe<Scalars['JSON']['output']>;
  /** Percentage of completion */
  pctComplete: Maybe<Scalars['Int']['output']>;
  /** Status */
  status: Maybe<JobStatusEnum>;
  /** Total number of objects to be processed */
  total: Maybe<Scalars['Int']['output']>;
  /** Last update time */
  updateTime: Maybe<Scalars['Int']['output']>;
};

/** Available job statuses */
export enum JobStatusEnum {
  /** Completed successfully */
  Complete = 'COMPLETE',
  /** Failed (after all retries) */
  Failed = 'FAILED',
  /** Interrupted */
  Interrupted = 'INTERRUPTED',
  /** Queued (waiting to be processed) */
  Queued = 'QUEUED',
  /** Retrying again (after failure) */
  Retrying = 'RETRYING',
  /** Stopped */
  Stopped = 'STOPPED',
  /** Work in progress */
  Working = 'WORKING'
}

/** Job status attributes */
export type JobStatusInputObject = {
  /** Job ID */
  jid: Scalars['String']['input'];
};

/** Available languages */
export enum LanguageEnum {
  /** English */
  En = 'en',
  /** French */
  Fr = 'fr'
}

/** Available levels of access */
export enum LevelEnum {
  LimitedRead = 'LIMITED_READ',
  Read = 'READ',
  Write = 'WRITE'
}

/** Linking attributes */
export type LinkingInputObject = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Unlink entity */
  unlink?: InputMaybe<Scalars['Boolean']['input']>;
};

/** All available measure types */
export enum MeasureTypeEnum {
  Distance = 'DISTANCE',
  Energy = 'ENERGY',
  Mass = 'MASS',
  Quantity = 'QUANTITY',
  Time = 'TIME'
}

/** All available measure units */
export enum MeasureUnitEnum {
  Centimeter = 'CENTIMETER',
  Foot = 'FOOT',
  Gram = 'GRAM',
  Hour = 'HOUR',
  Kilogram = 'KILOGRAM',
  Kilometer = 'KILOMETER',
  KilowattHour = 'KILOWATT_HOUR',
  Meter = 'METER',
  Mile = 'MILE',
  Minute = 'MINUTE',
  Piece = 'PIECE',
  Second = 'SECOND',
  Ton = 'TON',
  Yard = 'YARD'
}

/** List of mutations */
export type Mutation = {
  __typename?: 'Mutation';
  /** Bulk destroy Assets */
  assetBulkDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Create QR codes for Assets in bulk */
  assetBulkQrCodeCreate: Maybe<Job>;
  /** Create an Asset Category */
  assetCategoryCreate: Maybe<AssetCategory>;
  /** Destroy an Asset Category */
  assetCategoryDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Update an Asset Category */
  assetCategoryUpdate: Maybe<AssetCategory>;
  /** Create an Asset */
  assetCreate: Maybe<Asset>;
  /** Update an Asset */
  assetUpdate: Maybe<Asset>;
  /** Confirm email changing by token */
  authenticationConfirmationsEmailConfirmation: Whoami;
  /** Confirm registration by token */
  authenticationConfirmationsRegistrationConfirmation: Whoami;
  /** Resend email confirmation email */
  authenticationConfirmationsResendEmailConfirmation: ResponseMessage;
  /** Resend registration confirmation */
  authenticationConfirmationsResendRegistrationConfirmation: ResponseMessage;
  /** Send password reset instructions */
  authenticationPasswordsForgot: ResponseMessage;
  /** Reset password by token */
  authenticationPasswordsReset: Whoami;
  /** Change password */
  authenticationPasswordsUpdate: Whoami;
  /** Create a session (aka Login) */
  authenticationSessionCreate: Whoami;
  /** Destroy a session (aka Logout) */
  authenticationSessionDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Create a Company */
  companyCreate: Maybe<Company>;
  /** Destroy a Company */
  companyDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Plan change request */
  companyPlanChangeRequest: Maybe<Scalars['Boolean']['output']>;
  /** Update a Company */
  companyUpdate: Maybe<Company>;
  /** Bulk Destroy Counters */
  counterBulkDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Create QR codes for Counter in bulk */
  counterBulkQrCodeCreate: Maybe<Job>;
  /** Create a Counter */
  counterCreate: Maybe<Counter>;
  /** Create a Counter Measure */
  counterMeasureCreate: Maybe<CounterMeasure>;
  /** Reset to zero a Counter */
  counterResetToZero: Maybe<Counter>;
  /** Update a Counter */
  counterUpdate: Maybe<Counter>;
  /** Create a Documentation in bulk */
  documentationBulkCreate: Maybe<Scalars['Boolean']['output']>;
  /** Bulk Destroy Documentations */
  documentationBulkDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Update a Documentation */
  documentationUpdate: Maybe<Documentation>;
  /** Create a Form Category */
  formCategoryCreate: Maybe<FormCategory>;
  /** Destroy a Form Category */
  formCategoryDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Update a Form Category */
  formCategoryUpdate: Maybe<FormCategory>;
  /** Bulk archive Form Templates */
  formTemplateBulkArchive: Maybe<Scalars['Boolean']['output']>;
  /** Bulk destroy Form Templates */
  formTemplateBulkDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Bulk restore Form Templates */
  formTemplateBulkRestore: Maybe<Scalars['Boolean']['output']>;
  /** Create a Form Template */
  formTemplateCreate: Maybe<FormTemplate>;
  /** Update a Form Template */
  formTemplateUpdate: Maybe<FormTemplate>;
  /** Archive a Form Template Version */
  formTemplateVersionArchive: Maybe<FormTemplateVersion>;
  /** Create a Form Template Version */
  formTemplateVersionCreate: Maybe<FormTemplateVersion>;
  /** Destroy a Form Template Version */
  formTemplateVersionDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Restore a Form Template Version */
  formTemplateVersionRestore: Maybe<FormTemplateVersion>;
  /** Update a Form Template Version */
  formTemplateVersionUpdate: Maybe<FormTemplateVersion>;
  /** Create an Industry */
  industryCreate: Maybe<Industry>;
  /** Destroy an Industry */
  industryDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Update an Industry */
  industryUpdate: Maybe<Industry>;
  /** Create an Intervention Action */
  interventionActionCreate: Maybe<InterventionAction>;
  /** Destroy an Intervention Action */
  interventionActionDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Update an Intervention Action */
  interventionActionUpdate: Maybe<InterventionAction>;
  /** Bulk close Interventions */
  interventionBulkClose: Maybe<Scalars['Boolean']['output']>;
  /** Create an Intervention Category */
  interventionCategoryCreate: Maybe<InterventionCategory>;
  /** Destroy an Intervention Category */
  interventionCategoryDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Update an Intervention Category */
  interventionCategoryUpdate: Maybe<InterventionCategory>;
  /** Create an Intervention */
  interventionCreate: Maybe<Intervention>;
  /** Destroy an Intervention */
  interventionDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Update an Intervention */
  interventionUpdate: Maybe<Intervention>;
  /** Bulk Destroy Nomenclatures */
  nomenclatureBulkDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Create QR codes for component assets of Nomenclatures in bulk */
  nomenclatureBulkQrCodeCreate: Maybe<Job>;
  /** Create a Nomenclature */
  nomenclatureCreate: Maybe<Nomenclature>;
  /** Update a Nomenclature */
  nomenclatureUpdate: Maybe<Nomenclature>;
  /** Destroy Site Areas in bulk */
  siteAreaBulkDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Create QR codes for Site Areas in bulk */
  siteAreaBulkQrCodeCreate: Maybe<Job>;
  /** Update a Site Area */
  siteAreaUpdate: Maybe<SiteArea>;
  /** Create a Site */
  siteCreate: Maybe<Site>;
  /** Destroy a Site */
  siteDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Update a Site */
  siteUpdate: Maybe<Site>;
  /** Create a System Admin */
  systemAdminCreate: Maybe<SystemAdmin>;
  /** Destroy a System Admin */
  systemAdminDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Update self System Admin */
  systemAdminSelfUpdate: Maybe<SystemAdmin>;
  /** Finish setup of a System Admin */
  systemAdminSetup: Maybe<SystemAdmin>;
  /** Update a System Admin by other System Admin */
  systemAdminUpdate: Maybe<SystemAdmin>;
  /** Create a User by System Admin */
  systemAdminUserCreate: Maybe<User>;
  /** Create a Tag */
  tagCreate: Maybe<Tag>;
  /** Destroy a Tag */
  tagDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Create same Tasks in bulk */
  taskBulkCreateSame: Maybe<Array<Scalars['ID']['output']>>;
  /** Bulk update a Task's Deadline Missed Reason */
  taskBulkDeadlineMissedReasonUpdate: Maybe<Scalars['Boolean']['output']>;
  /** Bulk schedule Tasks */
  taskBulkScheduling: Maybe<Scalars['Boolean']['output']>;
  /** Bulk update a Task's Template Version */
  taskBulkTemplateVersionUpdate: Maybe<Scalars['Boolean']['output']>;
  /** Update Tasks status */
  taskBulkUpdateStatus: Maybe<Scalars['Boolean']['output']>;
  /** Create a Task Completion */
  taskCompletionCreate: Maybe<TaskCompletion>;
  /** Update a Task's Completion status */
  taskCompletionStatusUpdate: Maybe<TaskCompletion>;
  /** Destroy a Task */
  taskDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Update a Task */
  taskUpdate: Maybe<Task>;
  /** Team creation */
  teamCreate: Maybe<Team>;
  /** Team destroy */
  teamDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Team update */
  teamUpdate: Maybe<Team>;
  /** Activate Users in bulk */
  userBulkActivate: Maybe<Scalars['Boolean']['output']>;
  /** Deactivate Users in bulk */
  userBulkDeactivate: Maybe<Scalars['Boolean']['output']>;
  /** Accept the Code of Conduct */
  userCodeOfConductAccept: Maybe<User>;
  /** Create a User */
  userCreate: Maybe<User>;
  /** Destroy a User */
  userDestroy: Maybe<Scalars['Boolean']['output']>;
  /** Update self User */
  userSelfUpdate: Maybe<User>;
  /** Finish setup of a User */
  userSetup: Maybe<User>;
  /** Update a User by other Full Admin/Admin */
  userUpdate: Maybe<User>;
};


/** List of mutations */
export type MutationAssetBulkDestroyArgs = {
  input: AssetBulkDestroyInput;
};


/** List of mutations */
export type MutationAssetBulkQrCodeCreateArgs = {
  input: AssetBulkQrCodeCreateInput;
};


/** List of mutations */
export type MutationAssetCategoryCreateArgs = {
  input: AssetCategoryCreateInput;
};


/** List of mutations */
export type MutationAssetCategoryDestroyArgs = {
  input: AssetCategoryDestroyInput;
};


/** List of mutations */
export type MutationAssetCategoryUpdateArgs = {
  input: AssetCategoryUpdateInput;
};


/** List of mutations */
export type MutationAssetCreateArgs = {
  input: AssetCreateInput;
};


/** List of mutations */
export type MutationAssetUpdateArgs = {
  input: AssetUpdateInput;
};


/** List of mutations */
export type MutationAuthenticationConfirmationsEmailConfirmationArgs = {
  input: AuthenticationConfirmationsEmailConfirmationInput;
};


/** List of mutations */
export type MutationAuthenticationConfirmationsRegistrationConfirmationArgs = {
  input: AuthenticationConfirmationsRegistrationConfirmationInput;
};


/** List of mutations */
export type MutationAuthenticationConfirmationsResendEmailConfirmationArgs = {
  input: AuthenticationConfirmationsResendEmailConfirmationInput;
};


/** List of mutations */
export type MutationAuthenticationConfirmationsResendRegistrationConfirmationArgs = {
  input: AuthenticationConfirmationsResendRegistrationConfirmationInput;
};


/** List of mutations */
export type MutationAuthenticationPasswordsForgotArgs = {
  input: AuthenticationPasswordsForgotInput;
};


/** List of mutations */
export type MutationAuthenticationPasswordsResetArgs = {
  input: AuthenticationPasswordsResetInput;
};


/** List of mutations */
export type MutationAuthenticationPasswordsUpdateArgs = {
  input: AuthenticationPasswordsUpdateInput;
};


/** List of mutations */
export type MutationAuthenticationSessionCreateArgs = {
  input: AuthenticationSessionCreateInput;
};


/** List of mutations */
export type MutationAuthenticationSessionDestroyArgs = {
  input: AuthenticationSessionDestroyInput;
};


/** List of mutations */
export type MutationCompanyCreateArgs = {
  input: CompanyCreateInput;
};


/** List of mutations */
export type MutationCompanyDestroyArgs = {
  input: CompanyDestroyInput;
};


/** List of mutations */
export type MutationCompanyPlanChangeRequestArgs = {
  input: CompanyPlanChangeRequestInput;
};


/** List of mutations */
export type MutationCompanyUpdateArgs = {
  input: CompanyUpdateInput;
};


/** List of mutations */
export type MutationCounterBulkDestroyArgs = {
  input: CounterBulkDestroyInput;
};


/** List of mutations */
export type MutationCounterBulkQrCodeCreateArgs = {
  input: CounterBulkQrCodeCreateInput;
};


/** List of mutations */
export type MutationCounterCreateArgs = {
  input: CounterCreateInput;
};


/** List of mutations */
export type MutationCounterMeasureCreateArgs = {
  input: CounterMeasureCreateInput;
};


/** List of mutations */
export type MutationCounterResetToZeroArgs = {
  input: CounterResetToZeroInput;
};


/** List of mutations */
export type MutationCounterUpdateArgs = {
  input: CounterUpdateInput;
};


/** List of mutations */
export type MutationDocumentationBulkCreateArgs = {
  input: DocumentationBulkCreateInput;
};


/** List of mutations */
export type MutationDocumentationBulkDestroyArgs = {
  input: DocumentationBulkDestroyInput;
};


/** List of mutations */
export type MutationDocumentationUpdateArgs = {
  input: DocumentationUpdateInput;
};


/** List of mutations */
export type MutationFormCategoryCreateArgs = {
  input: FormCategoryCreateInput;
};


/** List of mutations */
export type MutationFormCategoryDestroyArgs = {
  input: FormCategoryDestroyInput;
};


/** List of mutations */
export type MutationFormCategoryUpdateArgs = {
  input: FormCategoryUpdateInput;
};


/** List of mutations */
export type MutationFormTemplateBulkArchiveArgs = {
  input: FormTemplateBulkArchiveInput;
};


/** List of mutations */
export type MutationFormTemplateBulkDestroyArgs = {
  input: FormTemplateBulkDestroyInput;
};


/** List of mutations */
export type MutationFormTemplateBulkRestoreArgs = {
  input: FormTemplateBulkRestoreInput;
};


/** List of mutations */
export type MutationFormTemplateCreateArgs = {
  input: FormTemplateCreateInput;
};


/** List of mutations */
export type MutationFormTemplateUpdateArgs = {
  input: FormTemplateUpdateInput;
};


/** List of mutations */
export type MutationFormTemplateVersionArchiveArgs = {
  input: FormTemplateVersionArchiveInput;
};


/** List of mutations */
export type MutationFormTemplateVersionCreateArgs = {
  input: FormTemplateVersionCreateInput;
};


/** List of mutations */
export type MutationFormTemplateVersionDestroyArgs = {
  input: FormTemplateVersionDestroyInput;
};


/** List of mutations */
export type MutationFormTemplateVersionRestoreArgs = {
  input: FormTemplateVersionRestoreInput;
};


/** List of mutations */
export type MutationFormTemplateVersionUpdateArgs = {
  input: FormTemplateVersionUpdateInput;
};


/** List of mutations */
export type MutationIndustryCreateArgs = {
  input: IndustryCreateInput;
};


/** List of mutations */
export type MutationIndustryDestroyArgs = {
  input: IndustryDestroyInput;
};


/** List of mutations */
export type MutationIndustryUpdateArgs = {
  input: IndustryUpdateInput;
};


/** List of mutations */
export type MutationInterventionActionCreateArgs = {
  input: InterventionActionCreateInput;
};


/** List of mutations */
export type MutationInterventionActionDestroyArgs = {
  input: InterventionActionDestroyInput;
};


/** List of mutations */
export type MutationInterventionActionUpdateArgs = {
  input: InterventionActionUpdateInput;
};


/** List of mutations */
export type MutationInterventionBulkCloseArgs = {
  input: InterventionBulkCloseInput;
};


/** List of mutations */
export type MutationInterventionCategoryCreateArgs = {
  input: InterventionCategoryCreateInput;
};


/** List of mutations */
export type MutationInterventionCategoryDestroyArgs = {
  input: InterventionCategoryDestroyInput;
};


/** List of mutations */
export type MutationInterventionCategoryUpdateArgs = {
  input: InterventionCategoryUpdateInput;
};


/** List of mutations */
export type MutationInterventionCreateArgs = {
  input: InterventionCreateInput;
};


/** List of mutations */
export type MutationInterventionDestroyArgs = {
  input: InterventionDestroyInput;
};


/** List of mutations */
export type MutationInterventionUpdateArgs = {
  input: InterventionUpdateInput;
};


/** List of mutations */
export type MutationNomenclatureBulkDestroyArgs = {
  input: NomenclatureBulkDestroyInput;
};


/** List of mutations */
export type MutationNomenclatureBulkQrCodeCreateArgs = {
  input: NomenclatureBulkQrCodeCreateInput;
};


/** List of mutations */
export type MutationNomenclatureCreateArgs = {
  input: NomenclatureCreateInput;
};


/** List of mutations */
export type MutationNomenclatureUpdateArgs = {
  input: NomenclatureUpdateInput;
};


/** List of mutations */
export type MutationSiteAreaBulkDestroyArgs = {
  input: SiteAreaBulkDestroyInput;
};


/** List of mutations */
export type MutationSiteAreaBulkQrCodeCreateArgs = {
  input: SiteAreaBulkQrCodeCreateInput;
};


/** List of mutations */
export type MutationSiteAreaUpdateArgs = {
  input: SiteAreaUpdateInput;
};


/** List of mutations */
export type MutationSiteCreateArgs = {
  input: SiteCreateInput;
};


/** List of mutations */
export type MutationSiteDestroyArgs = {
  input: SiteDestroyInput;
};


/** List of mutations */
export type MutationSiteUpdateArgs = {
  input: SiteUpdateInput;
};


/** List of mutations */
export type MutationSystemAdminCreateArgs = {
  input: SystemAdminCreateInput;
};


/** List of mutations */
export type MutationSystemAdminDestroyArgs = {
  input: SystemAdminDestroyInput;
};


/** List of mutations */
export type MutationSystemAdminSelfUpdateArgs = {
  input: SystemAdminSelfUpdateInput;
};


/** List of mutations */
export type MutationSystemAdminSetupArgs = {
  input: SystemAdminSetupInput;
};


/** List of mutations */
export type MutationSystemAdminUpdateArgs = {
  input: SystemAdminUpdateInput;
};


/** List of mutations */
export type MutationSystemAdminUserCreateArgs = {
  input: SystemAdminUserCreateInput;
};


/** List of mutations */
export type MutationTagCreateArgs = {
  input: TagCreateInput;
};


/** List of mutations */
export type MutationTagDestroyArgs = {
  input: TagDestroyInput;
};


/** List of mutations */
export type MutationTaskBulkCreateSameArgs = {
  input: TaskBulkCreateSameInput;
};


/** List of mutations */
export type MutationTaskBulkDeadlineMissedReasonUpdateArgs = {
  input: TaskBulkDeadlineMissedReasonUpdateInput;
};


/** List of mutations */
export type MutationTaskBulkSchedulingArgs = {
  input: TaskBulkSchedulingInput;
};


/** List of mutations */
export type MutationTaskBulkTemplateVersionUpdateArgs = {
  input: TaskBulkTemplateVersionUpdateInput;
};


/** List of mutations */
export type MutationTaskBulkUpdateStatusArgs = {
  input: TaskBulkUpdateStatusInput;
};


/** List of mutations */
export type MutationTaskCompletionCreateArgs = {
  input: TaskCompletionCreateInput;
};


/** List of mutations */
export type MutationTaskCompletionStatusUpdateArgs = {
  input: TaskCompletionStatusUpdateInput;
};


/** List of mutations */
export type MutationTaskDestroyArgs = {
  input: TaskDestroyInput;
};


/** List of mutations */
export type MutationTaskUpdateArgs = {
  input: TaskUpdateInput;
};


/** List of mutations */
export type MutationTeamCreateArgs = {
  input: TeamCreateInput;
};


/** List of mutations */
export type MutationTeamDestroyArgs = {
  input: TeamDestroyInput;
};


/** List of mutations */
export type MutationTeamUpdateArgs = {
  input: TeamUpdateInput;
};


/** List of mutations */
export type MutationUserBulkActivateArgs = {
  input: UserBulkActivateInput;
};


/** List of mutations */
export type MutationUserBulkDeactivateArgs = {
  input: UserBulkDeactivateInput;
};


/** List of mutations */
export type MutationUserCodeOfConductAcceptArgs = {
  input: UserCodeOfConductAcceptInput;
};


/** List of mutations */
export type MutationUserCreateArgs = {
  input: UserCreateInput;
};


/** List of mutations */
export type MutationUserDestroyArgs = {
  input: UserDestroyInput;
};


/** List of mutations */
export type MutationUserSelfUpdateArgs = {
  input: UserSelfUpdateInput;
};


/** List of mutations */
export type MutationUserSetupArgs = {
  input: UserSetupInput;
};


/** List of mutations */
export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
};

/** Nomenclature entity */
export type Nomenclature = TimestampInterface & {
  __typename?: 'Nomenclature';
  /**
   * Component Asset entity
   * NOTE: if you only need an Object ID, please use the componentAssetId field to reduce the load on the database.
   */
  componentAsset: Maybe<Asset>;
  /** Component Asset ID */
  componentAssetId: Maybe<Scalars['ID']['output']>;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Nomenclature ID */
  id: Scalars['ID']['output'];
  /**
   * Main Asset entity
   * NOTE: if you only need an Object ID, please use the mainAssetId field to reduce the load on the database.
   */
  mainAsset: Maybe<Asset>;
  /** Main Asset ID */
  mainAssetId: Maybe<Scalars['ID']['output']>;
  /** Quantity */
  quantity: Scalars['Int']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Description from base input object. Please set your description for child object. */
export type NomenclatureBulkDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Nomenclature IDs */
  ids: Array<Scalars['ID']['input']>;
  /** Main Asset ID */
  mainAssetId: Scalars['ID']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type NomenclatureBulkQrCodeCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Create params */
  params: QrCodesCreateInputObject;
};

/** NomenclatureCollection type */
export type NomenclatureCollection = {
  __typename?: 'NomenclatureCollection';
  /** A collection of paginated NomenclatureCollection */
  collection: Array<Nomenclature>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type NomenclatureCreateInput = {
  /** Create attributes */
  attributes: NomenclatureCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Nomenclature attributes */
export type NomenclatureCreateInputObject = {
  /** Component asset ID */
  componentAssetId: Scalars['ID']['input'];
  /** Main asset ID (Asset nomenclature for) */
  mainAssetId: Scalars['ID']['input'];
  /** Quantity */
  quantity: Scalars['Int']['input'];
};

/** Nomenclature filters */
export type NomenclatureFilterInputObject = {
  /** Component Asset code */
  componentAssetCode?: InputMaybe<FilterInputObject>;
  /** Component Asset name */
  componentAssetName?: InputMaybe<FilterInputObject>;
};

/** Nomenclature sorts */
export type NomenclatureSortInputObject = {
  /** Component Asset code */
  componentAssetCode?: InputMaybe<SortDirectionEnum>;
  /** Component Asset name */
  componentAssetName?: InputMaybe<SortDirectionEnum>;
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Quantity */
  quantity?: InputMaybe<SortDirectionEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type NomenclatureUpdateInput = {
  /** Update attributes */
  attributes: NomenclatureUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Nomenclature ID */
  id: Scalars['ID']['input'];
  /** Main Asset ID */
  mainAssetId: Scalars['ID']['input'];
};

/** Nomenclature attributes */
export type NomenclatureUpdateInputObject = {
  /** Quantity */
  quantity: Scalars['Int']['input'];
};

/** Available payment periods */
export enum PaymentPeriodEnum {
  Month = 'MONTH'
}

/** Phone number entity */
export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  /** Country */
  country: CountryCodeEnum;
  /** Country code */
  countryCode: Scalars['String']['output'];
  /** International number */
  international: Scalars['String']['output'];
  /** National number */
  national: Scalars['String']['output'];
  /** User input */
  number: Scalars['String']['output'];
};

/** Phone number attributes */
export type PhoneNumberInputObject = {
  /** Country code */
  country: CountryCodeEnum;
  /** Phone number */
  number: Scalars['String']['input'];
};

/** Phone number object */
export type PhoneNumberInterface = {
  /** Phone number value */
  phoneNumber: Maybe<PhoneNumber>;
};

/** Available policies of accesses based on every possible setup */
export enum PolicyEnum {
  CompanyPlan = 'COMPANY_PLAN',
  Default = 'DEFAULT',
  Team = 'TEAM',
  User = 'USER'
}

/** Available QR code file formats */
export enum QrCodeFileFormatEnum {
  /** EPS */
  Eps = 'EPS',
  /** PDF */
  Pdf = 'PDF',
  /** PNG */
  Png = 'PNG',
  /** SVG */
  Svg = 'SVG'
}

/** Available QR code file layouts */
export enum QrCodeFileLayoutEnum {
  /** Single file */
  SingleFile = 'SINGLE_FILE',
  /** Single page */
  SinglePage = 'SINGLE_PAGE'
}

/** Available QR code page sizes */
export enum QrCodePageSizeEnum {
  /** 29x39 mm */
  W29H39 = 'W29H39',
  /** 60x80 mm */
  W60H80 = 'W60H80',
  /** 67x76 mm */
  W67H76 = 'W67H76',
  /** A5 (148x210 mm) */
  W148H210 = 'W148H210'
}

/** QR codes attributes */
export type QrCodesCreateInputObject = {
  /** File format */
  fileFormat: QrCodeFileFormatEnum;
  /** File layout */
  fileLayout: QrCodeFileLayoutEnum;
  /** IDs */
  ids: Array<Scalars['ID']['input']>;
  /** Message to recipient */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Page size */
  pageSize: QrCodePageSizeEnum;
  /** Recipient user ID */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** List of queries */
export type Query = {
  __typename?: 'Query';
  /** Asset */
  asset: Maybe<Asset>;
  /** List of Asset Categories */
  assetCategories: AssetCategoryCollection;
  /** Show an Asset Category */
  assetCategory: Maybe<AssetCategory>;
  /** List of Assets */
  assets: AssetCollection;
  /** List of Companies */
  companies: CompanyCollection;
  /** Show a Company */
  company: Maybe<Company>;
  /** Show a Counter */
  counter: Maybe<Counter>;
  /** Show a Counter Measure */
  counterMeasure: Maybe<CounterMeasure>;
  /** List of Counter Measures */
  counterMeasures: CounterMeasureCollection;
  /** Counter Measures Graph Data */
  counterMeasuresGraphData: Array<CounterMeasureGraphData>;
  /** List of Counters */
  counters: CounterCollection;
  /** List of countries */
  countries: Array<Country>;
  /** Show a documentation */
  documentation: Maybe<Documentation>;
  /** List of documentations */
  documentations: DocumentationCollection;
  /** Reporting based on Form Answers. DO NOT request all kinds of fields. Use formAnswerReporting query to know the position of each kind and request each kind of reporting when user scrolling the page. */
  formAnswerReporting: Maybe<FormAnswerCollection>;
  /** Reporting info based on Form Answers. Shows all unique available fields and their kinds. Use it before fetching data for reporting to know the position of each kind and request each kind of reporting when user scrolling the page. */
  formAnswerReportingInfo: Maybe<Array<FormAnswerReportingInfo>>;
  /** List of Form Categories */
  formCategories: FormCategoryCollection;
  /** Show a Form Category */
  formCategory: Maybe<FormCategory>;
  /** Show a Form Template */
  formTemplate: Maybe<FormTemplate>;
  /** Show a Form Template Version */
  formTemplateVersion: Maybe<FormTemplateVersion>;
  /** List of Form Template Versions */
  formTemplateVersions: FormTemplateVersionCollection;
  /** List of Form Templates */
  formTemplates: FormTemplateCollection;
  /** List of Histories */
  histories: HistoryCollection;
  /** List of Industries */
  industries: IndustryCollection;
  /** Show an Industry */
  industry: Maybe<Industry>;
  /** Show an Intervention */
  intervention: Maybe<Intervention>;
  /** Show an Intervention Action */
  interventionAction: Maybe<InterventionAction>;
  /** List of Intervention Actions */
  interventionActions: InterventionActionCollection;
  /** List of Intervention Categories */
  interventionCategories: InterventionCategoryCollection;
  /** Show an Intervention Category */
  interventionCategory: Maybe<InterventionCategory>;
  /** List of Interventions */
  interventions: InterventionCollection;
  /** Show QR generation job statuses */
  jobStatuses: Array<Job>;
  /** Show a Nomenclature */
  nomenclature: Maybe<Nomenclature>;
  /** List of Nomenclatures */
  nomenclatures: NomenclatureCollection;
  /** Show a Site */
  site: Maybe<Site>;
  /** Show a Site Area */
  siteArea: Maybe<SiteArea>;
  /** List of Site Areas */
  siteAreas: SiteAreaCollection;
  /** List of Sites */
  sites: SiteCollection;
  /** Show a System Admin */
  systemAdmin: Maybe<SystemAdmin>;
  /** List of System Admins */
  systemAdmins: SystemAdminCollection;
  /** Show a tag */
  tag: Maybe<Tag>;
  /** List of tags */
  tags: TagCollection;
  /** Task */
  task: Maybe<Task>;
  /** List of Tasks */
  tasks: TaskCollection;
  /** Tasks data which can be completed from today until a provided date and assigned to the current user */
  tasksMyTodoCompletableByDateData: Array<TasksTodoCompletableByDateDataType>;
  /** Tasks Timeline */
  tasksTimeline: Array<Timeline>;
  /** Show a Team */
  team: Maybe<Team>;
  /** List of Teams */
  teams: TeamCollection;
  /** Show a User */
  user: Maybe<User>;
  /** List of Users */
  users: UserCollection;
  /** Show current user */
  whoami: Whoami;
};


/** List of queries */
export type QueryAssetArgs = {
  findBy: AssetFindByInputObject;
};


/** List of queries */
export type QueryAssetCategoriesArgs = {
  filters?: InputMaybe<Array<AssetCategoryFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<AssetCategorySortInputObject>;
};


/** List of queries */
export type QueryAssetCategoryArgs = {
  id: Scalars['ID']['input'];
};


/** List of queries */
export type QueryAssetsArgs = {
  filters?: InputMaybe<Array<AssetFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyIds?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<AssetSortInputObject>;
};


/** List of queries */
export type QueryCompaniesArgs = {
  filters?: InputMaybe<Array<CompanyFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<CompanySortInputObject>;
};


/** List of queries */
export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};


/** List of queries */
export type QueryCounterArgs = {
  assetFindBy: AssetFindByInputObject;
  findBy: CounterFindByInputObject;
};


/** List of queries */
export type QueryCounterMeasureArgs = {
  assetId: Scalars['ID']['input'];
  counterId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


/** List of queries */
export type QueryCounterMeasuresArgs = {
  assetId: Scalars['ID']['input'];
  counterId: Scalars['ID']['input'];
  filters?: InputMaybe<Array<CounterMeasureFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyIds?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<CounterMeasureSortInputObject>;
};


/** List of queries */
export type QueryCounterMeasuresGraphDataArgs = {
  assetId: Scalars['ID']['input'];
  counterId: Scalars['ID']['input'];
  dateRange: DateRangeInputObject;
};


/** List of queries */
export type QueryCountersArgs = {
  assetId: Scalars['ID']['input'];
  filters?: InputMaybe<Array<CounterFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyIds?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<CounterSortInputObject>;
};


/** List of queries */
export type QueryDocumentationArgs = {
  assetId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


/** List of queries */
export type QueryDocumentationsArgs = {
  assetId: Scalars['ID']['input'];
  filters?: InputMaybe<Array<DocumentationFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyIds?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<DocumentationSortInputObject>;
};


/** List of queries */
export type QueryFormAnswerReportingArgs = {
  fieldId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<FormAnswerReportingSortInputObject>;
  taskId: Scalars['ID']['input'];
  templateVersionId: Scalars['ID']['input'];
};


/** List of queries */
export type QueryFormAnswerReportingInfoArgs = {
  taskId: Scalars['ID']['input'];
  templateVersionId: Scalars['ID']['input'];
};


/** List of queries */
export type QueryFormCategoriesArgs = {
  filters?: InputMaybe<Array<FormCategoryFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<FormCategorySortInputObject>;
};


/** List of queries */
export type QueryFormCategoryArgs = {
  id: Scalars['ID']['input'];
};


/** List of queries */
export type QueryFormTemplateArgs = {
  id: Scalars['ID']['input'];
};


/** List of queries */
export type QueryFormTemplateVersionArgs = {
  formTemplateId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


/** List of queries */
export type QueryFormTemplateVersionsArgs = {
  filters?: InputMaybe<Array<FormTemplateVersionFilterInputObject>>;
  formTemplateId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<FormTemplateVersionSortInputObject>;
};


/** List of queries */
export type QueryFormTemplatesArgs = {
  filters?: InputMaybe<Array<FormTemplateFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyIds?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  scopeName: FormTemplateScopeNameEnum;
  sorts?: InputMaybe<FormTemplateSortInputObject>;
};


/** List of queries */
export type QueryHistoriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<HistorySortInputObject>;
  sourceableId: Scalars['ID']['input'];
  sourceableType: HistorySourceableTypeEnum;
};


/** List of queries */
export type QueryIndustriesArgs = {
  filters?: InputMaybe<Array<IndustryFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<IndustrySortInputObject>;
};


/** List of queries */
export type QueryIndustryArgs = {
  id: Scalars['ID']['input'];
};


/** List of queries */
export type QueryInterventionArgs = {
  id: Scalars['ID']['input'];
};


/** List of queries */
export type QueryInterventionActionArgs = {
  id: Scalars['ID']['input'];
  interventionId: Scalars['ID']['input'];
};


/** List of queries */
export type QueryInterventionActionsArgs = {
  filters?: InputMaybe<Array<InterventionActionFilterInputObject>>;
  interventionId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyIds?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<InterventionActionSortInputObject>;
};


/** List of queries */
export type QueryInterventionCategoriesArgs = {
  filters?: InputMaybe<Array<InterventionCategoryFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<InterventionCategorySortInputObject>;
};


/** List of queries */
export type QueryInterventionCategoryArgs = {
  id: Scalars['ID']['input'];
};


/** List of queries */
export type QueryInterventionsArgs = {
  filters?: InputMaybe<Array<InterventionFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyIds?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  scopeName: InterventionScopeNameEnum;
  sorts?: InputMaybe<InterventionSortInputObject>;
};


/** List of queries */
export type QueryJobStatusesArgs = {
  jobs: Array<JobStatusInputObject>;
};


/** List of queries */
export type QueryNomenclatureArgs = {
  id: Scalars['ID']['input'];
  mainAssetId: Scalars['ID']['input'];
};


/** List of queries */
export type QueryNomenclaturesArgs = {
  filters?: InputMaybe<Array<NomenclatureFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mainAssetId: Scalars['ID']['input'];
  onlyIds?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<NomenclatureSortInputObject>;
};


/** List of queries */
export type QuerySiteArgs = {
  findBy: SiteFindByInputObject;
};


/** List of queries */
export type QuerySiteAreaArgs = {
  findBy: SiteAreaFindByInputObject;
  siteFindBy: SiteFindByInputObject;
};


/** List of queries */
export type QuerySiteAreasArgs = {
  filters?: InputMaybe<Array<SiteAreaFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyIds?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['ID']['input'];
  sorts?: InputMaybe<SiteAreaSortInputObject>;
};


/** List of queries */
export type QuerySitesArgs = {
  filters?: InputMaybe<Array<SiteFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<SiteSortInputObject>;
};


/** List of queries */
export type QuerySystemAdminArgs = {
  id: Scalars['ID']['input'];
};


/** List of queries */
export type QuerySystemAdminsArgs = {
  filters?: InputMaybe<Array<SystemAdminFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<SystemAdminSortInputObject>;
};


/** List of queries */
export type QueryTagArgs = {
  id: Scalars['ID']['input'];
};


/** List of queries */
export type QueryTagsArgs = {
  filters?: InputMaybe<Array<TagFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyIds?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<TagSortInputObject>;
};


/** List of queries */
export type QueryTaskArgs = {
  id: Scalars['ID']['input'];
};


/** List of queries */
export type QueryTasksArgs = {
  filters?: InputMaybe<Array<TaskFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyIds?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  scope: TaskScopeInputObject;
  sorts?: InputMaybe<TaskSortInputObject>;
};


/** List of queries */
export type QueryTasksMyTodoCompletableByDateDataArgs = {
  date: Scalars['ISO8601DateTime']['input'];
};


/** List of queries */
export type QueryTasksTimelineArgs = {
  dateRange: DateRangeInputObject;
  groupBy: TaskTimelineGroupByEnum;
  ids: Array<Scalars['ID']['input']>;
  validityHints?: InputMaybe<Array<FormTemplateQuestionFieldOptionsValidityHintEnum>>;
};


/** List of queries */
export type QueryTeamArgs = {
  id: Scalars['ID']['input'];
};


/** List of queries */
export type QueryTeamsArgs = {
  filters?: InputMaybe<Array<TeamFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyIds?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<TeamSortInputObject>;
};


/** List of queries */
export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


/** List of queries */
export type QueryUsersArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  filters?: InputMaybe<Array<UserFilterInputObject>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyIds?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<UserScopeInputObject>;
  sorts?: InputMaybe<UserSortInputObject>;
};

/** Recurring rule entity */
export type RecurringRule = TimestampInterface & {
  __typename?: 'RecurringRule';
  /** Countdown type */
  countdownType: Maybe<RecurringRuleCountdownTypeEnum>;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** End time */
  endTime: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Number of occurrences after which recurring rule will be stopped */
  endsAfterOccurrences: Maybe<Scalars['Int']['output']>;
  /** Days that should be moved to the nearest available day */
  excludedWdays: Maybe<Array<Scalars['Int']['output']>>;
  /** Recurring rule ID */
  id: Scalars['ID']['output'];
  /** Recurring rule schema */
  schema: Schema;
  /** Start time */
  startTime: Scalars['ISO8601DateTime']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Available countdown types */
export enum RecurringRuleCountdownTypeEnum {
  Completion = 'COMPLETION',
  Deadline = 'DEADLINE'
}

/** Recurring rule attributes */
export type RecurringRuleInputObject = {
  /** Countdown type */
  countdownType: RecurringRuleCountdownTypeEnum;
  /** End time */
  endTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Number of occurrences after which recurring rule will be stopped */
  endsAfterOccurrences?: InputMaybe<Scalars['Int']['input']>;
  /** Days that should be moved to the nearest available day */
  excludedWdays: Array<Scalars['Int']['input']>;
  /** Schema attributes */
  schema: RecurringRuleSchemaInputObject;
  /** Start time */
  startTime: Scalars['ISO8601DateTime']['input'];
};

/** Available recurring rule types */
export enum RecurringRuleRuleTypeEnum {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

/**
 * Recurring rule schema attributes
 * Examples:
 *
 *   - Daily:
 *     ```
 *     [
 *       {
 *         "ruleType": "DAILY",
 *         "interval": 1
 *       }
 *     ]
 *     ```
 *
 *   - Weekly on Sundays, Mondays, Wednesdays, and Fridays:
 *     ```
 *     [
 *       {
 *         "ruleType": "WEEKLY",
 *         "interval": 1,
 *         "validations": {
 *           "day": [0,1,3,5]
 *         }
 *       }
 *     ]
 *     ```
 *
 *   - Monthly on the 1st, 15th, and 31st days of the month:
 *     ```
 *     [
 *       {
 *         "ruleType": "MONTHLY",
 *         "interval": 1,
 *         "validations": {
 *           "dayOfMonth": [1,15,31]
 *         }
 *       }
 *     ]
 *     ```
 *
 *   - Monthly on the 1st Monday and on the last day of the month
 *     ```
 *     [
 *       {
 *         "ruleType": "MONTHLY",
 *         "interval": 1,
 *         "validations": {
 *           "dayOfWeek": {
 *             "1": [1]
 *           }
 *         }
 *       },
 *       {
 *         "ruleType": "MONTHLY",
 *         "interval": 1,
 *         "validations": {
 *           "dayOfMonth": [-1]
 *         }
 *       }
 *     ]
 *     ```
 *
 *   - Yearly:
 *     ```
 *     [
 *       {
 *         "ruleType": "YEARLY",
 *         "interval": 1
 *       }
 *     ]
 *     ```
 *
 *   - Every 2 years on the 1st and last days of the month in January, February, and December:
 *     ```
 *     [
 *       {
 *         "ruleType": "YEARLY",
 *         "interval": 2,
 *         "validations": {
 *           "dayOfMonth": [1,-1],
 *           "monthOfYear": [1,2,12]
 *         }
 *       }
 *     ]
 *     ```
 *   - Every 2 years on the 1st Monday and last Friday (1) and on the last day of the month (2) in January and February
 *     ```
 *     [
 *       {
 *         "ruleType": "YEARLY",
 *         "interval": 2,
 *         "validations": {
 *           "dayOfWeek": {
 *             "1": [1],
 *             "5": [-1]
 *           },
 *           "monthOfYear": [1,2]
 *         }
 *       },
 *       {
 *         "ruleType": "YEARLY",
 *         "interval": 2,
 *         "validations": {
 *           "dayOfMonth": [-1],
 *           "monthOfYear": [1,2]
 *         }
 *       }
 *     ]
 *     ```
 *
 */
export type RecurringRuleSchemaInputObject = {
  /** Metadata */
  metadata?: InputMaybe<RecurringRuleSchemaMetadataInputObject>;
  /** Recurring schema rule */
  rrules: Array<RecurringRuleSchemaRuleInputObject>;
};

/** Recurring rule schema metadata attributes */
export type RecurringRuleSchemaMetadataInputObject = {
  /** Schema option */
  schemaOption?: InputMaybe<RecurringRuleSchemaOptionEnum>;
};

/** Available recurring rule schema options */
export enum RecurringRuleSchemaOptionEnum {
  Each = 'EACH',
  OnThe = 'ON_THE'
}

/** Recurring rule schema rule attributes */
export type RecurringRuleSchemaRuleInputObject = {
  /** Interval */
  interval: Scalars['Int']['input'];
  /** Rule type */
  ruleType: RecurringRuleRuleTypeEnum;
  /** Validations attributes */
  validations?: InputMaybe<RecurringRuleValidationsInputObject>;
};

/** Recurring rule validations */
export type RecurringRuleValidationsInputObject = {
  /** Day (1..31) */
  day?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Day of month (-1, 1..31) where -1 is the last day of the month, 1 is the first day of the month, etc. */
  dayOfMonth?: InputMaybe<Array<Scalars['Int']['input']>>;
  /**
   * Days of week.
   * Example: `{"1": [-1,1,3,5]}` where key `1` is the day of the week (Monday) and value `[-1,1,3,5]` are the number of the day in the month (last, 1st, 3rd, 5th)
   *
   */
  dayOfWeek?: InputMaybe<Scalars['JSON']['input']>;
  /** Month of year (1..12) */
  monthOfYear?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** All available form answer response groups */
export enum RespGroupEnum {
  EndDate = 'END_DATE',
  EndTime = 'END_TIME',
  Max = 'MAX',
  Min = 'MIN',
  No = 'NO',
  StartDate = 'START_DATE',
  StartTime = 'START_TIME',
  Yes = 'YES'
}

/** Response message */
export type ResponseMessage = {
  __typename?: 'ResponseMessage';
  /** Message */
  message: Scalars['String']['output'];
};

/** Available roles */
export enum RoleEnum {
  FullAdmin = 'FULL_ADMIN',
  User = 'USER'
}

/** Recurring rule schema type */
export type Schema = {
  __typename?: 'Schema';
  /** Recurring rule schema metadata */
  metadata: Maybe<SchemaMetadata>;
  /** Recurring schema rule */
  rrules: Array<SchemaRule>;
};

/** Recurring rule schema metadata */
export type SchemaMetadata = {
  __typename?: 'SchemaMetadata';
  /** Recurring rule schema option */
  schemaOption: RecurringRuleSchemaOptionEnum;
};

/**
 * Recurring schema rule
 * Examples:
 *
 * - Daily:
 *   ```
 *   [
 *     {
 *       "ruleType": "DAILY",
 *       "interval": 1
 *     }
 *   ]
 *   ```
 *
 * - Weekly on Sundays, Mondays, Wednesdays, and Fridays:
 *   ```
 *   [
 *     {
 *       "ruleType": "WEEKLY",
 *       "interval": 1,
 *       "validations": {
 *         "day": [0,1,3,5]
 *       }
 *     }
 *   ]
 *   ```
 *
 * - Monthly on the 1st, 15th, and 31st days of the month:
 *   ```
 *   [
 *     {
 *       "ruleType": "MONTHLY",
 *       "interval": 1,
 *       "validations": {
 *         "dayOfMonth": [1,15,31]
 *       }
 *     }
 *   ]
 *   ```
 *
 * - Monthly on the 1st Monday and on the last day of the month
 *   ```
 *   [
 *     {
 *       "ruleType": "MONTHLY",
 *       "interval": 1,
 *       "validations": {
 *         "dayOfWeek": {
 *           "1": [1]
 *         }
 *       }
 *     },
 *     {
 *       "ruleType": "MONTHLY",
 *       "interval": 1,
 *       "validations": {
 *         "dayOfMonth": [-1]
 *       }
 *     }
 *   ]
 *   ```
 *
 * - Yearly:
 *   ```
 *   [
 *     {
 *       "ruleType": "YEARLY",
 *       "interval": 1
 *     }
 *   ]
 *   ```
 *
 * - Every 2 years on the 1st and last days of the month in January, February, and December:
 *   ```
 *   [
 *     {
 *       "ruleType": "YEARLY",
 *       "interval": 2,
 *       "validations": {
 *         "dayOfMonth": [1,-1],
 *         "monthOfYear": [1,2,12]
 *       }
 *     }
 *   ]
 *   ```
 * - Every 2 years on the 1st Monday and last Friday (1) and on the last day of the month (2) in January and February
 *   ```
 *   [
 *     {
 *       "ruleType": "YEARLY",
 *       "interval": 2,
 *       "validations": {
 *         "dayOfWeek": {
 *           "1": [1],
 *           "5": [-1]
 *         },
 *         "monthOfYear": [1,2]
 *       }
 *     },
 *     {
 *       "ruleType": "YEARLY",
 *       "interval": 2,
 *       "validations": {
 *         "dayOfMonth": [-1],
 *         "monthOfYear": [1,2]
 *       }
 *     }
 *   ]
 *   ```
 *
 */
export type SchemaRule = {
  __typename?: 'SchemaRule';
  /** Interval */
  interval: Scalars['Int']['output'];
  /** Rule type */
  ruleType: RecurringRuleRuleTypeEnum;
  /** Recurring schema rule validations */
  validations: Maybe<Validations>;
};

/** Available resource scopes */
export enum ScopeEnum {
  SystemAdmin = 'SYSTEM_ADMIN',
  User = 'USER'
}

/** Available scope levels of access */
export enum ScopeLevelEnum {
  All = 'ALL',
  Selected = 'SELECTED'
}

/** Session */
export type Session = {
  __typename?: 'Session';
  /** Expiration time */
  expireAt: Scalars['ISO8601DateTime']['output'];
};

/** Field settings */
export type Settings = {
  __typename?: 'Settings';
  /** Indicates if field should display last response for the same question */
  allowDisplayLastAnswer: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if checkbox_button_grid field should limit responses to one per column */
  limitToOneRespPerColumn: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if checkbox_button_grid field should limit responses to one per row */
  limitToOneRespPerRow: Maybe<Scalars['Boolean']['output']>;
};

/** Site entity */
export type Site = TimestampInterface & {
  __typename?: 'Site';
  /** Site address */
  address: Maybe<Address>;
  /** Site cellphone */
  cellphone: Maybe<PhoneNumber>;
  /** Code */
  code: Scalars['String']['output'];
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Description */
  description: Maybe<Scalars['String']['output']>;
  /** Site contact email */
  email: Scalars['String']['output'];
  /** Site ID */
  id: Scalars['ID']['output'];
  /** Site location photo */
  locationPhotoAttached: Maybe<Attachment>;
  /** Name */
  name: Scalars['String']['output'];
  /** Site contact phone number */
  phoneNumber: Maybe<PhoneNumber>;
  /** Number of Site Areas */
  siteAreasCount: Scalars['Int']['output'];
  /** Site Code of Conduct sections */
  siteConductCodeSections: Array<SiteConductCodeSection>;
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Responsible user */
  user: Maybe<User>;
  /** Number of Users */
  usersCount: Scalars['Int']['output'];
};

/** Site Area entity */
export type SiteArea = TimestampInterface & {
  __typename?: 'SiteArea';
  /** Site Area address */
  address: Maybe<Scalars['String']['output']>;
  /** Code */
  code: Scalars['String']['output'];
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Description */
  description: Maybe<Scalars['String']['output']>;
  /** Full code (As on the QR code) */
  fullCode: Scalars['String']['output'];
  /** Site Area ID */
  id: Scalars['ID']['output'];
  /** Name */
  name: Scalars['String']['output'];
  /** Indicates if site area location has poor wifi or does not have wifi */
  poorWifi: Scalars['Boolean']['output'];
  /**
   * Site entity
   * NOTE: if you only need an Object ID, please use the siteId field to reduce the load on the database.
   */
  site: Maybe<Site>;
  /** Site ID */
  siteId: Maybe<Scalars['ID']['output']>;
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Description from base input object. Please set your description for child object. */
export type SiteAreaBulkDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** IDs */
  ids: Array<Scalars['ID']['input']>;
  /** Site ID */
  siteId: Scalars['ID']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type SiteAreaBulkQrCodeCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Create params */
  params: QrCodesCreateInputObject;
  /** Site ID */
  siteId: Scalars['ID']['input'];
};

/** SiteAreaCollection type */
export type SiteAreaCollection = {
  __typename?: 'SiteAreaCollection';
  /** A collection of paginated SiteAreaCollection */
  collection: Array<SiteArea>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Site area attributes */
export type SiteAreaCreateInputObject = {
  /** Address */
  address?: InputMaybe<Scalars['String']['input']>;
  /** Code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name */
  name: Scalars['String']['input'];
  /** Indicates if site area location has poor wifi or does not have wifi */
  poorWifi?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Site Area filters */
export type SiteAreaFilterInputObject = {
  /** Site Area code */
  code?: InputMaybe<FilterInputObject>;
  /** Site Area name */
  name?: InputMaybe<FilterInputObject>;
};

/** Site area attributes */
export type SiteAreaFindByInputObject = {
  /** Code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Site Area sorts */
export type SiteAreaSortInputObject = {
  /** Code */
  code?: InputMaybe<SortDirectionEnum>;
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Name */
  name?: InputMaybe<SortDirectionEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type SiteAreaUpdateInput = {
  /** Update attributes */
  attributes: SiteAreaUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Site Area ID */
  id: Scalars['ID']['input'];
  /** Site ID */
  siteId: Scalars['ID']['input'];
};

/** Site area attributes */
export type SiteAreaUpdateInputObject = {
  /** Address */
  address?: InputMaybe<Scalars['String']['input']>;
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if site area location has poor wifi or does not have wifi */
  poorWifi?: InputMaybe<Scalars['Boolean']['input']>;
};

/** SiteCollection type */
export type SiteCollection = {
  __typename?: 'SiteCollection';
  /** A collection of paginated SiteCollection */
  collection: Array<Site>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Site Code of Conduct section entity */
export type SiteConductCodeSection = TimestampInterface & {
  __typename?: 'SiteConductCodeSection';
  /** Body text */
  body: Scalars['String']['output'];
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Site Code of Conduct section ID */
  id: Scalars['ID']['output'];
  /** Position */
  position: Scalars['Int']['output'];
  /** Title */
  title: Scalars['String']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Site conduct code section attributes */
export type SiteConductCodeSectionCreateInputObject = {
  /** Body */
  body: Scalars['String']['input'];
  /** Position */
  position: Scalars['Int']['input'];
  /** Title */
  title: Scalars['String']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type SiteCreateInput = {
  /** Create attributes */
  attributes: SiteCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Site attributes */
export type SiteCreateInputObject = {
  /** Address */
  address: AddressInputObject;
  /** Create Site Areas attributes array */
  areas?: InputMaybe<Array<SiteAreaCreateInputObject>>;
  /** Site cellphone */
  cellphone?: InputMaybe<PhoneNumberInputObject>;
  /** Code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Create Conduct Code sections attributes array */
  conductCodeSections?: InputMaybe<Array<SiteConductCodeSectionCreateInputObject>>;
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Site contact email */
  email: Scalars['String']['input'];
  /** Location photo */
  locationPhotoAttached?: InputMaybe<AttachmentCuInputObject>;
  /** Name */
  name: Scalars['String']['input'];
  /** Site contact phone number */
  phoneNumber: PhoneNumberInputObject;
  /** Responsible user ID */
  userId: Scalars['ID']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type SiteDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Site ID */
  id: Scalars['ID']['input'];
};

/** Site filters */
export type SiteFilterInputObject = {
  /** Site area code */
  areasCode?: InputMaybe<FilterInputObject>;
  /** Site area name */
  areasName?: InputMaybe<FilterInputObject>;
  /** Site code */
  code?: InputMaybe<FilterInputObject>;
  /** Site name */
  name?: InputMaybe<FilterInputObject>;
  /** Responsible user full name */
  userFullName?: InputMaybe<FilterInputObject>;
};

/** Site attributes */
export type SiteFindByInputObject = {
  /** Code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Site sorts */
export type SiteSortInputObject = {
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Name */
  name?: InputMaybe<SortDirectionEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type SiteUpdateInput = {
  /** Update attributes */
  attributes: SiteUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Site ID */
  id: Scalars['ID']['input'];
};

/** Site attributes */
export type SiteUpdateInputObject = {
  /** Address */
  address?: InputMaybe<AddressInputObject>;
  /** Create Site Areas attributes array */
  areas?: InputMaybe<Array<SiteAreaCreateInputObject>>;
  /** Site cellphone */
  cellphone?: InputMaybe<PhoneNumberInputObject>;
  /** Create Conduct Code sections attributes array */
  conductCodeSections?: InputMaybe<Array<SiteConductCodeSectionCreateInputObject>>;
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Site contact email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Location photo */
  locationPhotoAttached?: InputMaybe<AttachmentCuInputObject>;
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Site contact phone number */
  phoneNumber?: InputMaybe<PhoneNumberInputObject>;
  /** Responsible user ID */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Sort directions */
export enum SortDirectionEnum {
  /** Ascending */
  Asc = 'asc',
  /** Descending */
  Desc = 'desc'
}

/** Available storages */
export enum StorageEnum {
  Cache = 'cache',
  Store = 'store'
}

/** SystemAdmin entity */
export type SystemAdmin = TimestampInterface & UserInterface & {
  __typename?: 'SystemAdmin';
  /** Avatar */
  avatarAttached: Maybe<Attachment>;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Deactivation time */
  deactivatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Email */
  email: Maybe<Scalars['String']['output']>;
  /** First name */
  firstName: Scalars['String']['output'];
  /** SystemAdmin ID */
  id: Scalars['ID']['output'];
  /** Job title */
  jobTitle: Maybe<Scalars['String']['output']>;
  /** Preferred language */
  lang: LanguageEnum;
  /** Last name */
  lastName: Scalars['String']['output'];
  /** Indicates if user has completed setup */
  setup: Scalars['Boolean']['output'];
  /** Email awaiting confirmation */
  unconfirmedEmail: Maybe<Scalars['String']['output']>;
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Indicates if user has verified email */
  verified: Maybe<Scalars['Boolean']['output']>;
};

/** SystemAdminCollection type */
export type SystemAdminCollection = {
  __typename?: 'SystemAdminCollection';
  /** A collection of paginated SystemAdminCollection */
  collection: Array<SystemAdmin>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type SystemAdminCreateInput = {
  /** Create attributes */
  attributes: SystemAdminCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Url to confirm registration */
  redirectUrl: Scalars['String']['input'];
};

/** System Admin attributes */
export type SystemAdminCreateInputObject = {
  /** Email */
  email: Scalars['String']['input'];
  /** First name */
  firstName: Scalars['String']['input'];
  /** Job title */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** Interface preferred language */
  lang: LanguageEnum;
  /** Last name */
  lastName: Scalars['String']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type SystemAdminDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** System Admin ID */
  id: Scalars['ID']['input'];
};

/** System Admin filters */
export type SystemAdminFilterInputObject = {
  /** Full name */
  fullName?: InputMaybe<FilterInputObject>;
  /** Job title */
  jobTitle?: InputMaybe<FilterInputObject>;
};

/** Description from base input object. Please set your description for child object. */
export type SystemAdminSelfUpdateInput = {
  /** Update attributes */
  attributes: SystemAdminSelfUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Url to confirm new email */
  redirectUrl: Scalars['String']['input'];
};

/** System Admin attributes */
export type SystemAdminSelfUpdateInputObject = {
  /** Avatar */
  avatarAttached?: InputMaybe<AttachmentCuInputObject>;
  /** Cancel email changing */
  cancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** First name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Job title */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** Interface preferred language */
  lang?: InputMaybe<LanguageEnum>;
  /** Last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
};

/** Description from base input object. Please set your description for child object. */
export type SystemAdminSetupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** New password */
  password: Scalars['String']['input'];
  /** New password confirmation */
  passwordConfirmation: Scalars['String']['input'];
};

/** System Admin sorts */
export type SystemAdminSortInputObject = {
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Full name */
  fullName?: InputMaybe<SortDirectionEnum>;
  /** Job title */
  jobTitle?: InputMaybe<SortDirectionEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type SystemAdminUpdateInput = {
  /** Update attributes */
  attributes: SystemAdminUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Url to confirm new email */
  confirmEmailRedirectUrl: Scalars['String']['input'];
  /** Url to finish registration */
  finishRegistrationRedirectUrl: Scalars['String']['input'];
  /** System Admin ID */
  id: Scalars['ID']['input'];
};

/** System Admin attributes */
export type SystemAdminUpdateInputObject = {
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** First name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Job title */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** Last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
};

/** Description from base input object. Please set your description for child object. */
export type SystemAdminUserCreateInput = {
  /** Create attributes */
  attributes: SystemAdminUserCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Company ID */
  companyId: Scalars['ID']['input'];
  /** Url to confirm registration */
  redirectUrl: Scalars['String']['input'];
};

/** User attributes */
export type SystemAdminUserCreateInputObject = {
  /** Email */
  email: Scalars['String']['input'];
  /** First name */
  firstName: Scalars['String']['input'];
  /** Last name */
  lastName: Scalars['String']['input'];
  /** Role */
  role: RoleEnum;
};

/** Tag entity */
export type Tag = TimestampInterface & {
  __typename?: 'Tag';
  canDestroy: AuthorizationResult;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Tag ID */
  id: Scalars['ID']['output'];
  /** Name */
  name: Scalars['String']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** TagCollection type */
export type TagCollection = {
  __typename?: 'TagCollection';
  /** A collection of paginated TagCollection */
  collection: Array<Tag>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type TagCreateInput = {
  /** Create attributes */
  attributes: TagCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Tag attributes */
export type TagCreateInputObject = {
  /** Name */
  name: Scalars['String']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type TagDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID */
  id: Scalars['ID']['input'];
};

/** Tag filters */
export type TagFilterInputObject = {
  /** Name */
  name?: InputMaybe<FilterInputObject>;
};

/** Tag sorts */
export type TagSortInputObject = {
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Name */
  name?: InputMaybe<SortDirectionEnum>;
};

/** Task entity */
export type Task = TimestampInterface & {
  __typename?: 'Task';
  /**
   * User entity
   * NOTE: if you only need an Object ID, please use the approverId field to reduce the load on the database.
   */
  approver: Maybe<User>;
  /** Approver ID */
  approverId: Maybe<Scalars['ID']['output']>;
  /** Archived date */
  archivedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /**
   * Asset entity
   * NOTE: if you only need an Object ID, please use the assetId field to reduce the load on the database.
   */
  asset: Maybe<Asset>;
  /** Asset ID */
  assetId: Maybe<Scalars['ID']['output']>;
  canArchive: AuthorizationResult;
  canCompletionCreate: AuthorizationResult;
  canDestroy: AuthorizationResult;
  canFormTemplateVersionUpdate: AuthorizationResult;
  canInterventionCreate: AuthorizationResult;
  canReschedule: AuthorizationResult;
  canUpdate: AuthorizationResult;
  /** Completed date */
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Task completion entity */
  completion: Maybe<TaskCompletion>;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Deadline date (On date or Next check - for non completed tasks) */
  deadlineAt: Scalars['ISO8601DateTime']['output'];
  /** Shows the reason why the deadline was missed. */
  deadlineMissedReason: Maybe<TaskDeadlineMissedReasonEnum>;
  /** Indicates if the deadline was missed before. */
  deadlineWasMissed: Scalars['Boolean']['output'];
  /** Description */
  description: Maybe<Scalars['String']['output']>;
  /** Early completion */
  earlyCompletion: Maybe<Scalars['Int']['output']>;
  /** Early completion unit */
  earlyCompletionUnit: Maybe<EarlyCompletionUnitEnum>;
  /** Form Template entity */
  formTemplate: FormTemplate;
  /** Form Template Version entity */
  formTemplateVersion: FormTemplateVersion;
  /** Key to group the same tasks which are recurring or have been recurred (if recurring rule was deleted in some point) */
  groupKey: Scalars['ID']['output'];
  /** Task ID */
  id: Scalars['ID']['output'];
  /** Intervention entity */
  intervention: Maybe<Intervention>;
  /** Form Answers from previous completion that allowed to be shown in the form */
  lastAllowedAnswers: Array<FormAnswer>;
  /** Last check date (Completion date for previous task completion) */
  lastCheckAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Last deadline date */
  lastDeadlineAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Shows how many days the missed deadline. If the task is not missed, the value is null. */
  lateFor: Maybe<Scalars['Int']['output']>;
  /** Name */
  name: Scalars['String']['output'];
  /** Next deadline date (Next check date - for recurring tasks which have the next created task) */
  nextDeadlineAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Important data digest for todo tasks (changes when the data is changed) [NULL means that not implemented for the query] */
  offlineImportantDataDigest: Maybe<Scalars['String']['output']>;
  /** Original creation date (for recurring tasks) */
  originalCreatedAt: Scalars['ISO8601DateTime']['output'];
  /** Priority */
  priority: TaskPriorityEnum;
  /**
   * Recurring rule entity
   * NOTE: if you only need an Object ID, please use the recurringRuleId field to reduce the load on the database.
   */
  recurringRule: Maybe<RecurringRule>;
  /** Recurring rule ID */
  recurringRuleId: Maybe<Scalars['ID']['output']>;
  /**
   * Site entity
   * NOTE: if you only need an Object ID, please use the siteId field to reduce the load on the database.
   */
  site: Maybe<Site>;
  /**
   * Site Area entity
   * NOTE: if you only need an Object ID, please use the siteAreaId field to reduce the load on the database.
   */
  siteArea: Maybe<SiteArea>;
  /** Site Area ID */
  siteAreaId: Maybe<Scalars['ID']['output']>;
  /** Site ID */
  siteId: Maybe<Scalars['ID']['output']>;
  /** Status */
  status: TaskStatusEnum;
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Task attributes */
export type TaskAssignationInputObject = {
  /** Asset ID */
  assetId?: InputMaybe<Scalars['ID']['input']>;
  /** Site Area ID */
  siteAreaId: Scalars['ID']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type TaskBulkCreateSameInput = {
  /** Create attributes */
  attributes: TaskBulkCreateSameInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Task attributes */
export type TaskBulkCreateSameInputObject = {
  /** Approver ID */
  approverId: Scalars['ID']['input'];
  /** Assignations attributes array */
  assignations: Array<TaskAssignationInputObject>;
  /** Deadline at (On date) */
  deadlineAt: Scalars['ISO8601DateTime']['input'];
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Early completion */
  earlyCompletion?: InputMaybe<Scalars['Int']['input']>;
  /** Early completion unit */
  earlyCompletionUnit?: InputMaybe<EarlyCompletionUnitEnum>;
  /** Name */
  name: Scalars['String']['input'];
  /** Priority */
  priority: TaskPriorityEnum;
  /** Recurring rule attributes */
  recurringRule?: InputMaybe<RecurringRuleInputObject>;
  /** Site ID */
  siteId: Scalars['ID']['input'];
  /** Form Template ID */
  templateId: Scalars['ID']['input'];
  /** Form Template Version ID */
  templateVersionId: Scalars['ID']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type TaskBulkDeadlineMissedReasonUpdateInput = {
  /** Update attributes */
  attributes: TaskUpdateDeadlineMissedReasonInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Task IDs */
  ids: Array<Scalars['ID']['input']>;
};

/** Description from base input object. Please set your description for child object. */
export type TaskBulkSchedulingInput = {
  /** Scheduling params */
  attributes: TaskBulkSchedulingInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Task IDs */
  ids: Array<Scalars['ID']['input']>;
};

/** Task attributes */
export type TaskBulkSchedulingInputObject = {
  /** Deadline at (On date) */
  deadlineAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Early completion */
  earlyCompletion?: InputMaybe<Scalars['Int']['input']>;
  /** Early completion unit */
  earlyCompletionUnit?: InputMaybe<EarlyCompletionUnitEnum>;
  /** Recurring rule attributes */
  recurringRule?: InputMaybe<RecurringRuleInputObject>;
};

/** Description from base input object. Please set your description for child object. */
export type TaskBulkTemplateVersionUpdateInput = {
  /** Update attributes */
  attributes: TaskUpdateTemplateVersionInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Task IDs */
  ids: Array<Scalars['ID']['input']>;
  /** Form Template ID */
  templateId: Scalars['ID']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type TaskBulkUpdateStatusInput = {
  /** Update attributes */
  attributes: TaskUpdateStatusInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Task IDs */
  ids: Array<Scalars['ID']['input']>;
};

/** TaskCollection type */
export type TaskCollection = {
  __typename?: 'TaskCollection';
  /** A collection of paginated TaskCollection */
  collection: Array<Task>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Task completion entity */
export type TaskCompletion = TimestampInterface & {
  __typename?: 'TaskCompletion';
  /** Form Answers collection */
  answers: Maybe<Array<FormAnswer>>;
  canStatusUpdate: AuthorizationResult;
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Task Completion ID */
  id: Scalars['ID']['output'];
  /**
   * Performer entity
   * NOTE: if you only need an Object ID, please use the performerId field to reduce the load on the database.
   */
  performer: Maybe<User>;
  /** Performer ID */
  performerId: Maybe<Scalars['ID']['output']>;
  /**
   * Solved date
   * ATTENTION: Access allowed for users: [TASKS_MANAGEMENT:READ].
   */
  solvedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /**
   * Result status
   * ATTENTION: Access allowed for users: [TASKS_MANAGEMENT:READ].
   */
  status: Maybe<TaskCompletionStatusEnum>;
  /**
   * Task entity
   * NOTE: if you only need an Object ID, please use the taskId field to reduce the load on the database.
   */
  task: Maybe<Task>;
  /** Task ID */
  taskId: Maybe<Scalars['ID']['output']>;
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /**
   * Indicates if task completion is expected to be valid
   * ATTENTION: Access allowed for users: [TASKS_MANAGEMENT:READ].
   */
  validityHint: Maybe<FormTemplateQuestionFieldOptionsValidityHintEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type TaskCompletionCreateInput = {
  /** Create attributes */
  attributes: TaskCompletionCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Task completion attributes */
export type TaskCompletionCreateInputObject = {
  /** Answers attributes array */
  answers: Array<FormAnswerCreateInputObject>;
  /** Task ID */
  taskId: Scalars['ID']['input'];
};

/** Available task completion statuses */
export enum TaskCompletionStatusEnum {
  InProgress = 'IN_PROGRESS',
  Solved = 'SOLVED',
  Unsolved = 'UNSOLVED'
}

/** Description from base input object. Please set your description for child object. */
export type TaskCompletionStatusUpdateInput = {
  /** Update attributes */
  attributes: TaskCompletionUpdateStatusInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Task ID */
  taskId: Scalars['ID']['input'];
};

/** Task completion attributes */
export type TaskCompletionUpdateStatusInputObject = {
  /** Status */
  status: TaskCompletionStatusEnum;
};

/** Available deadline missed reasons */
export enum TaskDeadlineMissedReasonEnum {
  Inaccessible = 'INACCESSIBLE',
  Other = 'OTHER',
  SiteClosed = 'SITE_CLOSED',
  StaffProblem = 'STAFF_PROBLEM',
  UnderConstruction = 'UNDER_CONSTRUCTION'
}

/** Description from base input object. Please set your description for child object. */
export type TaskDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Task ID */
  id: Scalars['ID']['input'];
};

/** Task filters */
export type TaskFilterInputObject = {
  /** Asset code */
  assetCode?: InputMaybe<FilterInputObject>;
  /** Asset ID */
  assetId?: InputMaybe<FilterIdInputObject>;
  /** Asset name */
  assetName?: InputMaybe<FilterInputObject>;
  /** Asset QR prefix */
  assetQrPrefix?: InputMaybe<FilterInputObject>;
  /** Completed at */
  completedAt?: InputMaybe<FilterDateInputObject>;
  /** Deadline at */
  deadlineAt?: InputMaybe<FilterDateInputObject>;
  /** Form Category ID */
  formCategoryId?: InputMaybe<FilterIdInputObject>;
  /** Form Category name */
  formCategoryName?: InputMaybe<FilterInputObject>;
  /** Task name */
  name?: InputMaybe<FilterInputObject>;
  /** Task priority */
  priority?: InputMaybe<FilterIntInputObject>;
  /** Site Area code */
  siteAreaCode?: InputMaybe<FilterInputObject>;
  /** Site Area ID */
  siteAreaId?: InputMaybe<FilterIdInputObject>;
  /** Site Area name */
  siteAreaName?: InputMaybe<FilterInputObject>;
  /** Site code */
  siteCode?: InputMaybe<FilterInputObject>;
  /** Site ID */
  siteId?: InputMaybe<FilterIdInputObject>;
  /** Site name */
  siteName?: InputMaybe<FilterInputObject>;
};

/** Available task priorities */
export enum TaskPriorityEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

/** Tasks which are linked to form template */
export type TaskScopeArgsGroupedLinkedToFormTemplateInputObject = {
  /** Form Template ID */
  templateId: Scalars['ID']['input'];
};

/** Custom scope attributes */
export type TaskScopeCustomInputObject = {
  /** Tasks which are linked to form template */
  groupedLinkedToFormTemplate?: InputMaybe<TaskScopeArgsGroupedLinkedToFormTemplateInputObject>;
};

/** Task scope attributes */
export type TaskScopeInputObject = {
  /** Custom scope attributes */
  custom?: InputMaybe<TaskScopeCustomInputObject>;
  /** Scope name */
  name?: InputMaybe<TaskScopeNameEnum>;
};

/** Available task scope names */
export enum TaskScopeNameEnum {
  /** Current active tasks (not completed yet but validated) */
  Active = 'ACTIVE',
  /** Archived tasks grouped by recurrence */
  Archived = 'ARCHIVED',
  /** Completed tasks grouped by recurrence */
  Completed = 'COMPLETED',
  /** All tasks for dashboard */
  DashboardAll = 'DASHBOARD_ALL',
  /** Tasks with missed deadline */
  DeadlineMissed = 'DEADLINE_MISSED',
  /** Tasks with missed deadline without selected deadline missed reason */
  DeadlineMissedWithoutReason = 'DEADLINE_MISSED_WITHOUT_REASON',
  /** Completed tasks with failure result */
  Failure = 'FAILURE',
  /** Solved completed tasks with failure result */
  FailureSolved = 'FAILURE_SOLVED',
  /** Unsolved completed tasks with failure result */
  FailureUnsolved = 'FAILURE_UNSOLVED',
  /** Tasks which have not processed yet and grouped by recurring rule */
  GroupNonProcessed = 'GROUP_NON_PROCESSED',
  /** Current tasks need to be validated */
  MyApprovals = 'MY_APPROVALS',
  /** Completed tasks assigned to me */
  MyCompleted = 'MY_COMPLETED',
  /** Tasks assigned to me and available to complete */
  MyTodoAvailableToComplete = 'MY_TODO_AVAILABLE_TO_COMPLETE',
  /** Tasks assigned to me and not available to complete yet because of deadline date */
  MyTodoUpcoming = 'MY_TODO_UPCOMING',
  /** Completed tasks with non success result and without intervention */
  NonSuccessWithoutIntervention = 'NON_SUCCESS_WITHOUT_INTERVENTION',
  /** Current pending tasks (not completed nor validated yet) */
  Pending = 'PENDING',
  /** Solved completed tasks (with warning or failure result) */
  Solved = 'SOLVED',
  /** Completed tasks with success result */
  Success = 'SUCCESS',
  /** All tasks for timeline */
  TimelineAll = 'TIMELINE_ALL',
  /** Completed tasks with failure result for timeline */
  TimelineFailure = 'TIMELINE_FAILURE',
  /** Completed tasks with success result for timeline */
  TimelineSuccess = 'TIMELINE_SUCCESS',
  /** Completed tasks with warning result for timeline */
  TimelineWarning = 'TIMELINE_WARNING',
  /** Completed tasks with warning result */
  Warning = 'WARNING',
  /** Solved completed tasks with warning result */
  WarningSolved = 'WARNING_SOLVED',
  /** Unsolved completed tasks with warning result */
  WarningUnsolved = 'WARNING_UNSOLVED'
}

/** Task sorts */
export type TaskSortInputObject = {
  /** Archived at */
  archivedAt?: InputMaybe<SortDirectionEnum>;
  /** Completed at */
  completedAt?: InputMaybe<SortDirectionEnum>;
  /** Completion solved at */
  completionSolvedAt?: InputMaybe<SortDirectionEnum>;
  /** Completion status */
  completionStatus?: InputMaybe<SortDirectionEnum>;
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Deadline date (On date or Next check - for non completed tasks) */
  deadlineAt?: InputMaybe<SortDirectionEnum>;
  /** Deadline missed reason */
  deadlineMissedReason?: InputMaybe<SortDirectionEnum>;
  /** Form Category name */
  formCategoryName?: InputMaybe<SortDirectionEnum>;
  /** Last check at */
  lastCheckAt?: InputMaybe<SortDirectionEnum>;
  /** Last deadline date */
  lastDeadlineAt?: InputMaybe<SortDirectionEnum>;
  /** Shows how many days the missed deadline. If the task is not missed, the value is null. */
  lateFor?: InputMaybe<SortDirectionEnum>;
  /** Name */
  name?: InputMaybe<SortDirectionEnum>;
  /** Next deadline date (Next check date - for recurring tasks which have the next created task) */
  nextDeadlineAt?: InputMaybe<SortDirectionEnum>;
  /** Priority */
  priority?: InputMaybe<SortDirectionEnum>;
  /** Site Area name */
  siteAreaName?: InputMaybe<SortDirectionEnum>;
  /** Site name */
  siteName?: InputMaybe<SortDirectionEnum>;
  /** Status */
  status?: InputMaybe<SortDirectionEnum>;
  /** Form Template Version version */
  templateVersionVersion?: InputMaybe<SortDirectionEnum>;
};

/** Available task statuses */
export enum TaskStatusEnum {
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  OnValidation = 'ON_VALIDATION',
  Rejected = 'REJECTED',
  Validated = 'VALIDATED'
}

/** Available timeline group by options */
export enum TaskTimelineGroupByEnum {
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

/** Task attributes */
export type TaskUpdateDeadlineMissedReasonInputObject = {
  /** Deadline missed reason */
  deadlineMissedReason: TaskDeadlineMissedReasonEnum;
};

/** Description from base input object. Please set your description for child object. */
export type TaskUpdateInput = {
  /** Update attributes */
  attributes: TaskUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Task ID */
  id: Scalars['ID']['input'];
};

/** Task attributes */
export type TaskUpdateInputObject = {
  /** Approver ID */
  approverId?: InputMaybe<Scalars['ID']['input']>;
  /** Asset ID */
  assetId?: InputMaybe<Scalars['ID']['input']>;
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Priority */
  priority?: InputMaybe<TaskPriorityEnum>;
  /** Site Area ID */
  siteAreaId?: InputMaybe<Scalars['ID']['input']>;
  /** Site ID */
  siteId?: InputMaybe<Scalars['ID']['input']>;
  /** Form Template ID */
  templateId?: InputMaybe<Scalars['ID']['input']>;
  /** Form Template Version ID */
  templateVersionId?: InputMaybe<Scalars['ID']['input']>;
};

/** Task attributes */
export type TaskUpdateStatusInputObject = {
  /** Status */
  status: TaskStatusEnum;
};

/** Task attributes */
export type TaskUpdateTemplateVersionInputObject = {
  /** Form Template Version ID */
  templateVersionId: Scalars['ID']['input'];
};

/** Task entity */
export type TasksTodoCompletableByDateDataType = {
  __typename?: 'TasksTodoCompletableByDateDataType';
  /** Task ID */
  id: Scalars['ID']['output'];
  /** Important data digest for todo tasks (changes when the data is changed) [NULL means that not implemented for the query] */
  offlineImportantDataDigest: Scalars['String']['output'];
};

/** Team entity */
export type Team = TimestampInterface & {
  __typename?: 'Team';
  /** Related accesses */
  accesses: Array<Access>;
  canDestroy: AuthorizationResult;
  canUpdate: AuthorizationResult;
  /** Color */
  color: Scalars['String']['output'];
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Description */
  description: Maybe<Scalars['String']['output']>;
  /** Team ID */
  id: Scalars['ID']['output'];
  /** Name */
  name: Scalars['String']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Assigned users count (only visible by site memberships) */
  visibleUsersCount: Maybe<Scalars['Int']['output']>;
};

/** TeamCollection type */
export type TeamCollection = {
  __typename?: 'TeamCollection';
  /** A collection of paginated TeamCollection */
  collection: Array<Team>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type TeamCreateInput = {
  /** Create attributes */
  attributes: TeamCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Team attributes */
export type TeamCreateInputObject = {
  /** Accesses attributes array */
  accesses: Array<AccessCuInputObject>;
  /** Color */
  color: Scalars['String']['input'];
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name */
  name: Scalars['String']['input'];
  /** Users attributes array */
  users?: InputMaybe<Array<LinkingInputObject>>;
};

/** Description from base input object. Please set your description for child object. */
export type TeamDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Team ID */
  id: Scalars['ID']['input'];
};

/** Team filters */
export type TeamFilterInputObject = {
  /** Name */
  name?: InputMaybe<FilterInputObject>;
  /** Users full name */
  usersFullName?: InputMaybe<FilterInputObject>;
};

/** Team sorts */
export type TeamSortInputObject = {
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** Name */
  name?: InputMaybe<SortDirectionEnum>;
  /** Assigned users count (only visible by site memberships) */
  visibleUsersCount?: InputMaybe<SortDirectionEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type TeamUpdateInput = {
  /** Update attributes */
  attributes: TeamUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Team ID */
  id: Scalars['ID']['input'];
};

/** Team attributes */
export type TeamUpdateInputObject = {
  /** Accesses attributes array */
  accesses?: InputMaybe<Array<AccessCuInputObject>>;
  /** Color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Users attributes array */
  users?: InputMaybe<Array<LinkingInputObject>>;
};

/** Task timeline */
export type Timeline = {
  __typename?: 'Timeline';
  /** Group by (day or month) */
  groupBy: TaskTimelineGroupByEnum;
  /** Task ID */
  id: Scalars['ID']['output'];
  /** Timeline rows */
  rows: Array<TimelineRow>;
};

/** Timeline row */
export type TimelineRow = {
  __typename?: 'TimelineRow';
  /** Date */
  date: Scalars['String']['output'];
  /** Validity hint counters */
  validityHintCounters: TimelineValidityHintCounter;
};

/** Validity hint counter */
export type TimelineValidityHintCounter = {
  __typename?: 'TimelineValidityHintCounter';
  /** Failure */
  failure: Maybe<Scalars['Int']['output']>;
  /** Hint is absent (no answers where hint was set) */
  none: Maybe<Scalars['Int']['output']>;
  /** Success */
  success: Maybe<Scalars['Int']['output']>;
  /** Warning */
  warning: Maybe<Scalars['Int']['output']>;
};

/** Shows timestamp */
export type TimestampInterface = {
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** User entity */
export type User = PhoneNumberInterface & TimestampInterface & UserInterface & {
  __typename?: 'User';
  /** Avatar */
  avatarAttached: Maybe<Attachment>;
  /** Indicates if user accepted the Code of Conduct */
  codeOfConductAccepted: Scalars['Boolean']['output'];
  /**
   * Company
   * NOTE: if you only need an Object ID, please use the companyId field to reduce the load on the database.
   */
  company: Company;
  /** Company ID */
  companyId: Scalars['ID']['output'];
  /** Time when was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Deactivation time */
  deactivatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Email */
  email: Maybe<Scalars['String']['output']>;
  /** Final Access setup */
  finalAccesses: Maybe<Array<Access>>;
  /** First name */
  firstName: Scalars['String']['output'];
  /** User ID */
  id: Scalars['ID']['output'];
  /** Preferred language */
  lang: LanguageEnum;
  /** Last name */
  lastName: Scalars['String']['output'];
  /** Owned company */
  ownedCompany: Maybe<Company>;
  /** Related personal accesses */
  personalAccesses: Array<Access>;
  /** Personal ID */
  personalId: Maybe<Scalars['String']['output']>;
  /** Phone number value */
  phoneNumber: Maybe<PhoneNumber>;
  /** Role */
  role: RoleEnum;
  /** Indicates if user has completed setup */
  setup: Scalars['Boolean']['output'];
  /** Sites collection */
  sites: SiteCollection;
  /** Team entity */
  team: Team;
  /** Email awaiting confirmation */
  unconfirmedEmail: Maybe<Scalars['String']['output']>;
  /** Last update time */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Username */
  username: Maybe<Scalars['String']['output']>;
  /** Indicates if user has verified email */
  verified: Maybe<Scalars['Boolean']['output']>;
};


/** User entity */
export type UserSitesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Description from base input object. Please set your description for child object. */
export type UserBulkActivateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** IDs */
  ids: Array<Scalars['ID']['input']>;
};

/** Description from base input object. Please set your description for child object. */
export type UserBulkDeactivateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** IDs */
  ids: Array<Scalars['ID']['input']>;
};

/** Description from base input object. Please set your description for child object. */
export type UserCodeOfConductAcceptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if user accepted the Code of Conduct */
  codeOfConductAccepted: Scalars['Boolean']['input'];
};

/** UserCollection type */
export type UserCollection = {
  __typename?: 'UserCollection';
  /** A collection of paginated UserCollection */
  collection: Array<User>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Description from base input object. Please set your description for child object. */
export type UserCreateInput = {
  /** Create attributes */
  attributes: UserCreateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Url to confirm registration */
  redirectUrl: Scalars['String']['input'];
};

/** User attributes */
export type UserCreateInputObject = {
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** First name */
  firstName: Scalars['String']['input'];
  /** Last name */
  lastName: Scalars['String']['input'];
  /** Password */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Personal ID */
  personalId?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  phoneNumber?: InputMaybe<PhoneNumberInputObject>;
  /** Role */
  role: RoleEnum;
  /** Site memberships attributes array */
  siteMemberships?: InputMaybe<Array<LinkingInputObject>>;
  /** Team ID */
  teamId?: InputMaybe<Scalars['String']['input']>;
  /** Username */
  username?: InputMaybe<Scalars['String']['input']>;
};

/** User attributes */
export type UserCreateOwnerInputObject = {
  /** Email */
  email: Scalars['String']['input'];
  /** First name */
  firstName: Scalars['String']['input'];
  /** Last name */
  lastName: Scalars['String']['input'];
};

/** Description from base input object. Please set your description for child object. */
export type UserDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** User ID */
  id: Scalars['ID']['input'];
};

/** User filters */
export type UserFilterInputObject = {
  /** User email */
  email?: InputMaybe<FilterInputObject>;
  /** User full name */
  fullName?: InputMaybe<FilterInputObject>;
  /** Intervention Action assignees */
  interventionActionAssigneesActionId?: InputMaybe<FilterIdInputObject>;
  /** Intervention Action mentioned users */
  interventionActionsTextMentionsId?: InputMaybe<FilterIdInputObject>;
  /** Intervention assignees */
  interventionAssigneesInterventionId?: InputMaybe<FilterIdInputObject>;
  /** Assigned Site ID */
  memberAssignedSitesId?: InputMaybe<FilterIdInputObject>;
  /** Assigned Site name */
  memberAssignedSitesName?: InputMaybe<FilterInputObject>;
  /** User personal ID */
  personalId?: InputMaybe<FilterInputObject>;
  /** User role */
  role?: InputMaybe<FilterIntInputObject>;
  /** Team ID */
  teamId?: InputMaybe<FilterIdInputObject>;
};

/** Common user fields */
export type UserInterface = {
  /** Avatar */
  avatarAttached: Maybe<Attachment>;
  /** Deactivation time */
  deactivatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Email */
  email: Maybe<Scalars['String']['output']>;
  /** First name */
  firstName: Scalars['String']['output'];
  /** Preferred language */
  lang: LanguageEnum;
  /** Last name */
  lastName: Scalars['String']['output'];
  /** Indicates if user has completed setup */
  setup: Scalars['Boolean']['output'];
  /** Email awaiting confirmation */
  unconfirmedEmail: Maybe<Scalars['String']['output']>;
  /** Indicates if user has verified email */
  verified: Maybe<Scalars['Boolean']['output']>;
};

/** User access */
export type UserScopeArgsAccessInputObject = {
  /** Component name */
  component: ComponentEnum;
  /** Min level of access (Means provided value or higher) */
  level: LevelEnum;
  /** Sourceable ID */
  sourceableId?: InputMaybe<Scalars['ID']['input']>;
};

/** Users core filter */
export type UserScopeArgsByCoreInputObject = {
  /** User access */
  access?: InputMaybe<UserScopeArgsAccessInputObject>;
  /** Only active users */
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Site ID */
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

/** Custom scope attributes */
export type UserScopeCustomInputObject = {
  /** Users core filter */
  byCore?: InputMaybe<UserScopeArgsByCoreInputObject>;
};

/** User scope attributes */
export type UserScopeInputObject = {
  /** Custom scope attributes */
  custom?: InputMaybe<UserScopeCustomInputObject>;
  /** Scope name */
  name?: InputMaybe<UserScopeNameEnum>;
};

/** Available user scope names */
export enum UserScopeNameEnum {
  /** Active users */
  Active = 'ACTIVE'
}

/** Description from base input object. Please set your description for child object. */
export type UserSelfUpdateInput = {
  /** Update attributes */
  attributes: UserSelfUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Url to confirm new email */
  redirectUrl: Scalars['String']['input'];
};

/** User attributes */
export type UserSelfUpdateInputObject = {
  /** Avatar */
  avatarAttached?: InputMaybe<AttachmentCuInputObject>;
  /** Cancel email changing */
  cancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** First name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Interface preferred language */
  lang?: InputMaybe<LanguageEnum>;
  /** Last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Personal ID */
  personalId?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  phoneNumber?: InputMaybe<PhoneNumberInputObject>;
};

/** Description from base input object. Please set your description for child object. */
export type UserSetupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if user accepted the Code of Conduct */
  codeOfConductAccepted: Scalars['Boolean']['input'];
  /** New password */
  password: Scalars['String']['input'];
  /** New password confirmation */
  passwordConfirmation: Scalars['String']['input'];
};

/** User sorts */
export type UserSortInputObject = {
  /** Created at */
  createdAt?: InputMaybe<SortDirectionEnum>;
  /** User email */
  email?: InputMaybe<SortDirectionEnum>;
  /** Full name */
  fullName?: InputMaybe<SortDirectionEnum>;
  /** Personal ID */
  personalId?: InputMaybe<SortDirectionEnum>;
  /** Role */
  role?: InputMaybe<SortDirectionEnum>;
  /** Team name */
  teamName?: InputMaybe<SortDirectionEnum>;
};

/** Description from base input object. Please set your description for child object. */
export type UserUpdateInput = {
  /** Update attributes */
  attributes: UserUpdateInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Url to confirm new email */
  confirmEmailRedirectUrl: Scalars['String']['input'];
  /** Url to finish registration */
  finishRegistrationRedirectUrl: Scalars['String']['input'];
  /** User ID */
  id: Scalars['ID']['input'];
};

/** User attributes */
export type UserUpdateInputObject = {
  /** Accesses attributes array */
  accesses?: InputMaybe<Array<AccessCuInputObject>>;
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** First name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Password */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Personal ID */
  personalId?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  phoneNumber?: InputMaybe<PhoneNumberInputObject>;
  /** Role */
  role?: InputMaybe<RoleEnum>;
  /** Site memberships attributes array */
  siteMemberships?: InputMaybe<Array<LinkingInputObject>>;
  /** Team ID */
  teamId?: InputMaybe<Scalars['String']['input']>;
  /** Username */
  username?: InputMaybe<Scalars['String']['input']>;
};

/** Recurring schema rule validations */
export type Validations = {
  __typename?: 'Validations';
  /** Day (1..31) */
  day: Maybe<Array<Scalars['Int']['output']>>;
  /** Days of month (-1, 1..31) where -1 is the last day of the month, 1 is the first day of the month, etc. */
  dayOfMonth: Maybe<Array<Scalars['Int']['output']>>;
  /**
   * Days of week.
   * Example: `{"1": [-1,1,3,5]}` where key `1` is the day of the week (Monday) and value `[-1,1,3,5]` are the number of the day in the month (last, 1st, 3rd, 5th)
   *
   */
  dayOfWeek: Maybe<Scalars['JSON']['output']>;
  /** Month of year (1..12) */
  monthOfYear: Maybe<Array<Scalars['Int']['output']>>;
};

/** Validity hint config */
export type ValidityHintConfig = {
  __typename?: 'ValidityHintConfig';
  /** Option positions */
  optionPositions: Array<Scalars['Int']['output']>;
  /** Validity hint */
  validityHint: FormTemplateQuestionFieldOptionsValidityHintEnum;
};

/** Current resource */
export type Whoami = {
  __typename?: 'Whoami';
  /** Resource entity */
  resource: WhoamiUnion;
  /** Session entity */
  session: Session;
};

/** Show current user */
export type WhoamiUnion = SystemAdmin | User;

export type AuthenticationConfirmationsEmailConfirmationMutationVariables = Exact<{
  input: AuthenticationConfirmationsEmailConfirmationInput;
}>;


export type AuthenticationConfirmationsEmailConfirmationMutation = { __typename?: 'Mutation', data: { __typename?: 'Whoami', resource: { __typename?: 'SystemAdmin', id: string } | { __typename?: 'User' }, session: { __typename?: 'Session', expireAt: any } } };

export type AuthenticationConfirmationsRegistrationConfirmationMutationVariables = Exact<{
  input: AuthenticationConfirmationsRegistrationConfirmationInput;
}>;


export type AuthenticationConfirmationsRegistrationConfirmationMutation = { __typename?: 'Mutation', data: { __typename?: 'Whoami', resource: { __typename?: 'SystemAdmin', id: string } | { __typename?: 'User' }, session: { __typename?: 'Session', expireAt: any } } };

export type AuthenticationConfirmationsResendEmailConfirmationMutationVariables = Exact<{
  input: AuthenticationConfirmationsResendEmailConfirmationInput;
}>;


export type AuthenticationConfirmationsResendEmailConfirmationMutation = { __typename?: 'Mutation', data: { __typename?: 'ResponseMessage', message: string } };

export type AuthenticationConfirmationsResendRegistrationConfirmationMutationVariables = Exact<{
  input: AuthenticationConfirmationsResendRegistrationConfirmationInput;
}>;


export type AuthenticationConfirmationsResendRegistrationConfirmationMutation = { __typename?: 'Mutation', data: { __typename?: 'ResponseMessage', message: string } };

export type AuthenticationPasswordsForgotMutationVariables = Exact<{
  input: AuthenticationPasswordsForgotInput;
}>;


export type AuthenticationPasswordsForgotMutation = { __typename?: 'Mutation', data: { __typename?: 'ResponseMessage', message: string } };

export type AuthenticationPasswordsResetMutationVariables = Exact<{
  input: AuthenticationPasswordsResetInput;
}>;


export type AuthenticationPasswordsResetMutation = { __typename?: 'Mutation', data: { __typename?: 'Whoami', resource: { __typename?: 'SystemAdmin', id: string } | { __typename?: 'User' } } };

export type AuthenticationPasswordsUpdateMutationVariables = Exact<{
  input: AuthenticationPasswordsUpdateInput;
}>;


export type AuthenticationPasswordsUpdateMutation = { __typename?: 'Mutation', data: { __typename?: 'Whoami', resource: { __typename?: 'SystemAdmin', id: string } | { __typename?: 'User' } } };

export type AuthenticationSessionCreateMutationVariables = Exact<{
  input: AuthenticationSessionCreateInput;
}>;


export type AuthenticationSessionCreateMutation = { __typename?: 'Mutation', data: { __typename?: 'Whoami', resource: { __typename?: 'SystemAdmin', id: string } | { __typename?: 'User' }, session: { __typename?: 'Session', expireAt: any } } };

export type AuthenticationSessionDestroyMutationVariables = Exact<{
  input: AuthenticationSessionDestroyInput;
}>;


export type AuthenticationSessionDestroyMutation = { __typename?: 'Mutation', data: boolean | null };

export type WhoamiQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoamiQuery = { __typename?: 'Query', whoami: { __typename?: 'Whoami', resource: { __typename: 'SystemAdmin' } | { __typename: 'User', deactivatedAt: any | null, createdAt: any, email: string | null, firstName: string, id: string, lang: LanguageEnum, lastName: string, personalId: string | null, setup: boolean, unconfirmedEmail: string | null, updatedAt: any, username: string | null, verified: boolean | null, role: RoleEnum, codeOfConductAccepted: boolean, avatarAttached: { __typename?: 'Attachment', id: string, processed: boolean, fileTag: string | null, fileData: any | null, filename: string | null, urls: { __typename?: 'AttachmentUrl', large: string | null, medium: string | null, original: string | null, small: string | null, imagePreview: string | null } | null } | null, phoneNumber: { __typename?: 'PhoneNumber', country: CountryCodeEnum, countryCode: string, international: string, national: string, number: string } | null, sites: { __typename?: 'SiteCollection', collection: Array<{ __typename?: 'Site', id: string, name: string }> } }, session: { __typename?: 'Session', expireAt: any } } };

export type AttachmentFragment = { __typename?: 'Attachment', id: string, processed: boolean, fileTag: string | null, fileData: any | null, filename: string | null, urls: { __typename?: 'AttachmentUrl', large: string | null, medium: string | null, original: string | null, small: string | null, imagePreview: string | null } | null };

export type PhoneNumberFragment = { __typename?: 'PhoneNumber', country: CountryCodeEnum, countryCode: string, international: string, national: string, number: string };

export type MetadataFragment = { __typename?: 'CollectionMetadata', currentPage: number, limitValue: number, totalCount: number, totalPages: number };

export type UserSetupMutationVariables = Exact<{
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  codeOfConductAccepted: Scalars['Boolean']['input'];
}>;


export type UserSetupMutation = { __typename?: 'Mutation', data: { __typename?: 'User', id: string } | null };

export const AttachmentFragmentDoc = gql`
    fragment attachment on Attachment {
  id
  processed
  fileTag
  fileData
  filename
  urls {
    large
    medium
    original
    small
    imagePreview
  }
}
    `;
export const PhoneNumberFragmentDoc = gql`
    fragment phoneNumber on PhoneNumber {
  country
  countryCode
  international
  national
  number
}
    `;
export const MetadataFragmentDoc = gql`
    fragment metadata on CollectionMetadata {
  currentPage
  limitValue
  totalCount
  totalPages
}
    `;
export const AuthenticationConfirmationsEmailConfirmationDocument = gql`
    mutation authenticationConfirmationsEmailConfirmation($input: AuthenticationConfirmationsEmailConfirmationInput!) {
  data: authenticationConfirmationsEmailConfirmation(input: $input) {
    resource {
      ... on SystemAdmin {
        id
      }
    }
    session {
      expireAt
    }
  }
}
    `;
export type AuthenticationConfirmationsEmailConfirmationMutationFn = Apollo.MutationFunction<AuthenticationConfirmationsEmailConfirmationMutation, AuthenticationConfirmationsEmailConfirmationMutationVariables>;

/**
 * __useAuthenticationConfirmationsEmailConfirmationMutation__
 *
 * To run a mutation, you first call `useAuthenticationConfirmationsEmailConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticationConfirmationsEmailConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticationConfirmationsEmailConfirmationMutation, { data, loading, error }] = useAuthenticationConfirmationsEmailConfirmationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticationConfirmationsEmailConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticationConfirmationsEmailConfirmationMutation, AuthenticationConfirmationsEmailConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticationConfirmationsEmailConfirmationMutation, AuthenticationConfirmationsEmailConfirmationMutationVariables>(AuthenticationConfirmationsEmailConfirmationDocument, options);
      }
export type AuthenticationConfirmationsEmailConfirmationMutationHookResult = ReturnType<typeof useAuthenticationConfirmationsEmailConfirmationMutation>;
export type AuthenticationConfirmationsEmailConfirmationMutationResult = Apollo.MutationResult<AuthenticationConfirmationsEmailConfirmationMutation>;
export type AuthenticationConfirmationsEmailConfirmationMutationOptions = Apollo.BaseMutationOptions<AuthenticationConfirmationsEmailConfirmationMutation, AuthenticationConfirmationsEmailConfirmationMutationVariables>;
export const AuthenticationConfirmationsRegistrationConfirmationDocument = gql`
    mutation authenticationConfirmationsRegistrationConfirmation($input: AuthenticationConfirmationsRegistrationConfirmationInput!) {
  data: authenticationConfirmationsRegistrationConfirmation(input: $input) {
    resource {
      ... on SystemAdmin {
        id
      }
    }
    session {
      expireAt
    }
  }
}
    `;
export type AuthenticationConfirmationsRegistrationConfirmationMutationFn = Apollo.MutationFunction<AuthenticationConfirmationsRegistrationConfirmationMutation, AuthenticationConfirmationsRegistrationConfirmationMutationVariables>;

/**
 * __useAuthenticationConfirmationsRegistrationConfirmationMutation__
 *
 * To run a mutation, you first call `useAuthenticationConfirmationsRegistrationConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticationConfirmationsRegistrationConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticationConfirmationsRegistrationConfirmationMutation, { data, loading, error }] = useAuthenticationConfirmationsRegistrationConfirmationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticationConfirmationsRegistrationConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticationConfirmationsRegistrationConfirmationMutation, AuthenticationConfirmationsRegistrationConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticationConfirmationsRegistrationConfirmationMutation, AuthenticationConfirmationsRegistrationConfirmationMutationVariables>(AuthenticationConfirmationsRegistrationConfirmationDocument, options);
      }
export type AuthenticationConfirmationsRegistrationConfirmationMutationHookResult = ReturnType<typeof useAuthenticationConfirmationsRegistrationConfirmationMutation>;
export type AuthenticationConfirmationsRegistrationConfirmationMutationResult = Apollo.MutationResult<AuthenticationConfirmationsRegistrationConfirmationMutation>;
export type AuthenticationConfirmationsRegistrationConfirmationMutationOptions = Apollo.BaseMutationOptions<AuthenticationConfirmationsRegistrationConfirmationMutation, AuthenticationConfirmationsRegistrationConfirmationMutationVariables>;
export const AuthenticationConfirmationsResendEmailConfirmationDocument = gql`
    mutation authenticationConfirmationsResendEmailConfirmation($input: AuthenticationConfirmationsResendEmailConfirmationInput!) {
  data: authenticationConfirmationsResendEmailConfirmation(input: $input) {
    message
  }
}
    `;
export type AuthenticationConfirmationsResendEmailConfirmationMutationFn = Apollo.MutationFunction<AuthenticationConfirmationsResendEmailConfirmationMutation, AuthenticationConfirmationsResendEmailConfirmationMutationVariables>;

/**
 * __useAuthenticationConfirmationsResendEmailConfirmationMutation__
 *
 * To run a mutation, you first call `useAuthenticationConfirmationsResendEmailConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticationConfirmationsResendEmailConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticationConfirmationsResendEmailConfirmationMutation, { data, loading, error }] = useAuthenticationConfirmationsResendEmailConfirmationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticationConfirmationsResendEmailConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticationConfirmationsResendEmailConfirmationMutation, AuthenticationConfirmationsResendEmailConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticationConfirmationsResendEmailConfirmationMutation, AuthenticationConfirmationsResendEmailConfirmationMutationVariables>(AuthenticationConfirmationsResendEmailConfirmationDocument, options);
      }
export type AuthenticationConfirmationsResendEmailConfirmationMutationHookResult = ReturnType<typeof useAuthenticationConfirmationsResendEmailConfirmationMutation>;
export type AuthenticationConfirmationsResendEmailConfirmationMutationResult = Apollo.MutationResult<AuthenticationConfirmationsResendEmailConfirmationMutation>;
export type AuthenticationConfirmationsResendEmailConfirmationMutationOptions = Apollo.BaseMutationOptions<AuthenticationConfirmationsResendEmailConfirmationMutation, AuthenticationConfirmationsResendEmailConfirmationMutationVariables>;
export const AuthenticationConfirmationsResendRegistrationConfirmationDocument = gql`
    mutation authenticationConfirmationsResendRegistrationConfirmation($input: AuthenticationConfirmationsResendRegistrationConfirmationInput!) {
  data: authenticationConfirmationsResendRegistrationConfirmation(input: $input) {
    message
  }
}
    `;
export type AuthenticationConfirmationsResendRegistrationConfirmationMutationFn = Apollo.MutationFunction<AuthenticationConfirmationsResendRegistrationConfirmationMutation, AuthenticationConfirmationsResendRegistrationConfirmationMutationVariables>;

/**
 * __useAuthenticationConfirmationsResendRegistrationConfirmationMutation__
 *
 * To run a mutation, you first call `useAuthenticationConfirmationsResendRegistrationConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticationConfirmationsResendRegistrationConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticationConfirmationsResendRegistrationConfirmationMutation, { data, loading, error }] = useAuthenticationConfirmationsResendRegistrationConfirmationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticationConfirmationsResendRegistrationConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticationConfirmationsResendRegistrationConfirmationMutation, AuthenticationConfirmationsResendRegistrationConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticationConfirmationsResendRegistrationConfirmationMutation, AuthenticationConfirmationsResendRegistrationConfirmationMutationVariables>(AuthenticationConfirmationsResendRegistrationConfirmationDocument, options);
      }
export type AuthenticationConfirmationsResendRegistrationConfirmationMutationHookResult = ReturnType<typeof useAuthenticationConfirmationsResendRegistrationConfirmationMutation>;
export type AuthenticationConfirmationsResendRegistrationConfirmationMutationResult = Apollo.MutationResult<AuthenticationConfirmationsResendRegistrationConfirmationMutation>;
export type AuthenticationConfirmationsResendRegistrationConfirmationMutationOptions = Apollo.BaseMutationOptions<AuthenticationConfirmationsResendRegistrationConfirmationMutation, AuthenticationConfirmationsResendRegistrationConfirmationMutationVariables>;
export const AuthenticationPasswordsForgotDocument = gql`
    mutation authenticationPasswordsForgot($input: AuthenticationPasswordsForgotInput!) {
  data: authenticationPasswordsForgot(input: $input) {
    message
  }
}
    `;
export type AuthenticationPasswordsForgotMutationFn = Apollo.MutationFunction<AuthenticationPasswordsForgotMutation, AuthenticationPasswordsForgotMutationVariables>;

/**
 * __useAuthenticationPasswordsForgotMutation__
 *
 * To run a mutation, you first call `useAuthenticationPasswordsForgotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticationPasswordsForgotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticationPasswordsForgotMutation, { data, loading, error }] = useAuthenticationPasswordsForgotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticationPasswordsForgotMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticationPasswordsForgotMutation, AuthenticationPasswordsForgotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticationPasswordsForgotMutation, AuthenticationPasswordsForgotMutationVariables>(AuthenticationPasswordsForgotDocument, options);
      }
export type AuthenticationPasswordsForgotMutationHookResult = ReturnType<typeof useAuthenticationPasswordsForgotMutation>;
export type AuthenticationPasswordsForgotMutationResult = Apollo.MutationResult<AuthenticationPasswordsForgotMutation>;
export type AuthenticationPasswordsForgotMutationOptions = Apollo.BaseMutationOptions<AuthenticationPasswordsForgotMutation, AuthenticationPasswordsForgotMutationVariables>;
export const AuthenticationPasswordsResetDocument = gql`
    mutation authenticationPasswordsReset($input: AuthenticationPasswordsResetInput!) {
  data: authenticationPasswordsReset(input: $input) {
    resource {
      ... on SystemAdmin {
        id
      }
    }
  }
}
    `;
export type AuthenticationPasswordsResetMutationFn = Apollo.MutationFunction<AuthenticationPasswordsResetMutation, AuthenticationPasswordsResetMutationVariables>;

/**
 * __useAuthenticationPasswordsResetMutation__
 *
 * To run a mutation, you first call `useAuthenticationPasswordsResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticationPasswordsResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticationPasswordsResetMutation, { data, loading, error }] = useAuthenticationPasswordsResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticationPasswordsResetMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticationPasswordsResetMutation, AuthenticationPasswordsResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticationPasswordsResetMutation, AuthenticationPasswordsResetMutationVariables>(AuthenticationPasswordsResetDocument, options);
      }
export type AuthenticationPasswordsResetMutationHookResult = ReturnType<typeof useAuthenticationPasswordsResetMutation>;
export type AuthenticationPasswordsResetMutationResult = Apollo.MutationResult<AuthenticationPasswordsResetMutation>;
export type AuthenticationPasswordsResetMutationOptions = Apollo.BaseMutationOptions<AuthenticationPasswordsResetMutation, AuthenticationPasswordsResetMutationVariables>;
export const AuthenticationPasswordsUpdateDocument = gql`
    mutation authenticationPasswordsUpdate($input: AuthenticationPasswordsUpdateInput!) {
  data: authenticationPasswordsUpdate(input: $input) {
    resource {
      ... on SystemAdmin {
        id
      }
    }
  }
}
    `;
export type AuthenticationPasswordsUpdateMutationFn = Apollo.MutationFunction<AuthenticationPasswordsUpdateMutation, AuthenticationPasswordsUpdateMutationVariables>;

/**
 * __useAuthenticationPasswordsUpdateMutation__
 *
 * To run a mutation, you first call `useAuthenticationPasswordsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticationPasswordsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticationPasswordsUpdateMutation, { data, loading, error }] = useAuthenticationPasswordsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticationPasswordsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticationPasswordsUpdateMutation, AuthenticationPasswordsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticationPasswordsUpdateMutation, AuthenticationPasswordsUpdateMutationVariables>(AuthenticationPasswordsUpdateDocument, options);
      }
export type AuthenticationPasswordsUpdateMutationHookResult = ReturnType<typeof useAuthenticationPasswordsUpdateMutation>;
export type AuthenticationPasswordsUpdateMutationResult = Apollo.MutationResult<AuthenticationPasswordsUpdateMutation>;
export type AuthenticationPasswordsUpdateMutationOptions = Apollo.BaseMutationOptions<AuthenticationPasswordsUpdateMutation, AuthenticationPasswordsUpdateMutationVariables>;
export const AuthenticationSessionCreateDocument = gql`
    mutation authenticationSessionCreate($input: AuthenticationSessionCreateInput!) {
  data: authenticationSessionCreate(input: $input) {
    resource {
      ... on SystemAdmin {
        id
      }
    }
    session {
      expireAt
    }
  }
}
    `;
export type AuthenticationSessionCreateMutationFn = Apollo.MutationFunction<AuthenticationSessionCreateMutation, AuthenticationSessionCreateMutationVariables>;

/**
 * __useAuthenticationSessionCreateMutation__
 *
 * To run a mutation, you first call `useAuthenticationSessionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticationSessionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticationSessionCreateMutation, { data, loading, error }] = useAuthenticationSessionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticationSessionCreateMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticationSessionCreateMutation, AuthenticationSessionCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticationSessionCreateMutation, AuthenticationSessionCreateMutationVariables>(AuthenticationSessionCreateDocument, options);
      }
export type AuthenticationSessionCreateMutationHookResult = ReturnType<typeof useAuthenticationSessionCreateMutation>;
export type AuthenticationSessionCreateMutationResult = Apollo.MutationResult<AuthenticationSessionCreateMutation>;
export type AuthenticationSessionCreateMutationOptions = Apollo.BaseMutationOptions<AuthenticationSessionCreateMutation, AuthenticationSessionCreateMutationVariables>;
export const AuthenticationSessionDestroyDocument = gql`
    mutation authenticationSessionDestroy($input: AuthenticationSessionDestroyInput!) {
  data: authenticationSessionDestroy(input: $input)
}
    `;
export type AuthenticationSessionDestroyMutationFn = Apollo.MutationFunction<AuthenticationSessionDestroyMutation, AuthenticationSessionDestroyMutationVariables>;

/**
 * __useAuthenticationSessionDestroyMutation__
 *
 * To run a mutation, you first call `useAuthenticationSessionDestroyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticationSessionDestroyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticationSessionDestroyMutation, { data, loading, error }] = useAuthenticationSessionDestroyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticationSessionDestroyMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticationSessionDestroyMutation, AuthenticationSessionDestroyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticationSessionDestroyMutation, AuthenticationSessionDestroyMutationVariables>(AuthenticationSessionDestroyDocument, options);
      }
export type AuthenticationSessionDestroyMutationHookResult = ReturnType<typeof useAuthenticationSessionDestroyMutation>;
export type AuthenticationSessionDestroyMutationResult = Apollo.MutationResult<AuthenticationSessionDestroyMutation>;
export type AuthenticationSessionDestroyMutationOptions = Apollo.BaseMutationOptions<AuthenticationSessionDestroyMutation, AuthenticationSessionDestroyMutationVariables>;
export const WhoamiDocument = gql`
    query Whoami {
  whoami {
    resource {
      __typename
      ... on User {
        avatarAttached {
          ...attachment
        }
        deactivatedAt
        createdAt
        email
        firstName
        id
        lang
        lastName
        personalId
        setup
        unconfirmedEmail
        updatedAt
        username
        verified
        phoneNumber {
          ...phoneNumber
        }
        role
        sites {
          collection {
            id
            name
          }
        }
        codeOfConductAccepted
      }
    }
    session {
      expireAt
    }
  }
}
    ${AttachmentFragmentDoc}
${PhoneNumberFragmentDoc}`;

/**
 * __useWhoamiQuery__
 *
 * To run a query within a React component, call `useWhoamiQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoamiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoamiQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoamiQuery(baseOptions?: Apollo.QueryHookOptions<WhoamiQuery, WhoamiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoamiQuery, WhoamiQueryVariables>(WhoamiDocument, options);
      }
export function useWhoamiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoamiQuery, WhoamiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoamiQuery, WhoamiQueryVariables>(WhoamiDocument, options);
        }
export type WhoamiQueryHookResult = ReturnType<typeof useWhoamiQuery>;
export type WhoamiLazyQueryHookResult = ReturnType<typeof useWhoamiLazyQuery>;
export type WhoamiQueryResult = Apollo.QueryResult<WhoamiQuery, WhoamiQueryVariables>;
export function refetchWhoamiQuery(variables?: WhoamiQueryVariables) {
      return { query: WhoamiDocument, variables: variables }
    }
export const UserSetupDocument = gql`
    mutation userSetup($password: String!, $passwordConfirmation: String!, $codeOfConductAccepted: Boolean!) {
  data: userSetup(
    input: {password: $password, passwordConfirmation: $passwordConfirmation, codeOfConductAccepted: $codeOfConductAccepted}
  ) {
    id
  }
}
    `;
export type UserSetupMutationFn = Apollo.MutationFunction<UserSetupMutation, UserSetupMutationVariables>;

/**
 * __useUserSetupMutation__
 *
 * To run a mutation, you first call `useUserSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSetupMutation, { data, loading, error }] = useUserSetupMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      codeOfConductAccepted: // value for 'codeOfConductAccepted'
 *   },
 * });
 */
export function useUserSetupMutation(baseOptions?: Apollo.MutationHookOptions<UserSetupMutation, UserSetupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserSetupMutation, UserSetupMutationVariables>(UserSetupDocument, options);
      }
export type UserSetupMutationHookResult = ReturnType<typeof useUserSetupMutation>;
export type UserSetupMutationResult = Apollo.MutationResult<UserSetupMutation>;
export type UserSetupMutationOptions = Apollo.BaseMutationOptions<UserSetupMutation, UserSetupMutationVariables>;