import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from '@lib/components/Modal/Modal';
import Button from '@lib/components/Button/Button';
import { PATH_LATEST_VERSION } from '@lib/enums/urls';
import useIsOnline from '@lib/hooks/useIsOnline';
import styles from './ReleaseTracker.module.scss';

type Props = {
  captureException: (error: Error) => void;
  domain: string;
  releaseVersion: string;
  releaseTrackerSeconds: string | number;
  mode: string;
};

export default function ReleaseTracker(props: Props) {
  const {
    captureException,
    releaseTrackerSeconds,
    releaseVersion,
    domain,
    mode,
  } = props;
  const { t } = useTranslation();
  const [lastReleaseVersion, setLastReleaseVersion] = useState<string | null>(
    null,
  );
  const { pathname, search } = useLocation();
  const lastFetchTimeRef = useRef<Date | null>(null);
  const isOnline = useIsOnline();

  const isModalAvailable = !!releaseVersion && !!lastReleaseVersion;
  const isModalOpen = releaseVersion !== lastReleaseVersion;

  useEffect(() => {
    const fetchFileContent = async () => {
      try {
        lastFetchTimeRef.current = new Date(); // Update last fetch time
        const response = await fetch(`${domain}/${PATH_LATEST_VERSION}.txt`, {
          cache: 'reload',
        });
        const content = await response.text();
        const contentValue = content.trim();
        if (contentValue) setLastReleaseVersion(contentValue);
      } catch (error) {
        captureException(
          error instanceof Error ? error : new Error(String(error)),
        );
      }
    };

    const shouldFetch = ((): boolean => {
      if (!domain || mode === 'development' || !releaseTrackerSeconds)
        return false;
      if (!lastFetchTimeRef.current) return true;
      const now = new Date();
      const diff = now.getTime() - lastFetchTimeRef.current.getTime();
      const seconds =
        typeof releaseTrackerSeconds === 'string'
          ? parseInt(releaseTrackerSeconds, 10) * 1000
          : releaseTrackerSeconds * 1000;
      return diff >= seconds;
    })();

    if (shouldFetch && isOnline) {
      fetchFileContent();
    }
  }, [
    captureException,
    domain,
    isOnline,
    mode,
    pathname,
    releaseTrackerSeconds,
    search,
  ]);

  if (!isModalAvailable) return null;

  const reload = () => {
    const doReload = () => {
      window.location.replace(window.location.href);
    };

    if ('serviceWorker' in navigator) {
      const serviceWorkerNavigator = navigator as Navigator & {
        serviceWorker: ServiceWorkerContainer;
      };
      serviceWorkerNavigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          const unregisterPromises = registrations.map((registration) =>
            registration.unregister(),
          );
          Promise.all(unregisterPromises).then(() => {
            doReload();
          });
        });
    } else {
      doReload();
    }
  };

  const modalActions = (
    <div className={styles.actions}>
      <Button
        key="refresh"
        leftIcon="refresh"
        buttonText={t('refresh-page')}
        onClick={reload}
      />
    </div>
  );

  return (
    <Modal
      isOpen={isModalAvailable && isModalOpen}
      title={t('release-tracker-title')}
      description={t('release-tracker-desc')}
      actions={modalActions}
    />
  );
}
