import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Typography from '@lib/components/Typography/Typography';
import MaterialIcon from '@lib/components/MaterialIcon/MaterialIcon';
import useOutsideClickCallback from '@lib/hooks/useOutsideClickCallback';
import styles from './PlatformSwitcher.module.scss';

const {
  VITE_ADMIN_PLATFORM_URL: ADMIN_PLATFORM,
  VITE_USER_PLATFORM_URL: USER_PLATFORM,
} = import.meta.env;

export enum Platforms {
  User = 'user',
  Admin = 'admin',
}

type Props = {
  selected: Platforms;
  available: Platforms;
};

const PLATFORMS = {
  [Platforms.User]: {
    name: 'operational',
    icon: (
      <div className={styles.platformItemIconGreen}>
        <MaterialIcon icon="verified_user" size="md-18" />
      </div>
    ),
    url: USER_PLATFORM,
  },
  [Platforms.Admin]: {
    name: 'admin',
    icon: (
      <div className={styles.platformItemIconBlue}>
        <MaterialIcon icon="construction" size="md-18" />
      </div>
    ),
    url: ADMIN_PLATFORM,
  },
};

export default function PlatformSwitcher(props: Props) {
  const { selected, available } = props;
  const ref = useRef<HTMLDivElement | null>(null);
  const [isOpened, setIsOpened] = useState(false);
  const { t } = useTranslation();
  useOutsideClickCallback(ref, () => setIsOpened(false));
  const toggleOpened = () => setIsOpened((prevState) => !prevState);
  return (
    <div
      ref={ref}
      className={classNames(styles.platformSwitcher, {
        [styles.platformSwitcherOpened]: isOpened,
      })}
    >
      <div className={styles.platformSwitcherValue}>
        <button
          type="button"
          onClick={toggleOpened}
          className={styles.platformItem}
        >
          {PLATFORMS[selected].icon}
          <Typography variant="body">{t(PLATFORMS[selected].name)}</Typography>
        </button>
      </div>
      <div className={styles.platformSwitcherDropDown}>
        <button
          type="button"
          className={styles.platformItem}
          onClick={() => {
            window.location.href = PLATFORMS[available].url;
          }}
        >
          {PLATFORMS[available].icon}
          <Typography variant="body">{t(PLATFORMS[available].name)}</Typography>
        </button>
      </div>
      <div className={styles.platformSwitcherIcon}>
        <MaterialIcon icon="unfold_more" />
      </div>
    </div>
  );
}
