import React, { memo } from 'react';
import classNames from 'classnames';
import styles from './Typography.module.scss';

export type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'body'
  | 'link'
  | 'label'
  | 'caption'
  | 'span';

interface Props {
  block?: boolean;
  children: React.ReactNode;
  className?: string;
  component?: Variant | React.JSX.ElementType;
  href?: string;
  onClick?: () => void;
  strong?: boolean;
  strikethrough?: boolean;
  target?: string;
  to?: string;
  variant: Variant;
}

const defaultElements = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  body: 'div',
  link: 'a',
  label: 'span',
  caption: 'small',
  span: 'span',
};

function getTypographyStyle(variant: Variant) {
  switch (variant) {
    case 'h1':
    case 'h2':
    case 'h3':
      return styles[`title--${variant}`];
    case 'body':
      return styles.body;
    case 'link':
      return styles.link;
    case 'label':
      return styles.label;
    case 'caption':
      return styles.small;
    default:
      return undefined;
  }
}

function Typography(props: Props): React.JSX.Element {
  const {
    block,
    children,
    className,
    component,
    href,
    onClick,
    strong,
    strikethrough,
    target,
    to,
    variant,
  } = props;
  const Element = component || defaultElements[variant];
  let renderChildren = children;
  if (strong) {
    renderChildren = <strong>{children}</strong>;
  }
  if (strikethrough) {
    renderChildren = <s>{children}</s>;
  }
  return (
    // @ts-ignore
    <Element
      className={classNames(
        styles.typographyRoot,
        getTypographyStyle(variant),
        { [styles.block]: block },
        className,
      )}
      href={href}
      onClick={onClick}
      target={target}
      to={to}
    >
      {renderChildren}
    </Element>
  );
}

export default memo(Typography);
