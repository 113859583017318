import React from 'react';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import Input from '@lib/components/Input/Input';
import { InputTypes } from '@lib/components/Input/enums';
import ForgotPasswordLink from 'components/ForgotPasswordLink';
import {
  EMAIL_REGEX,
  MAX_PASSWORD_FIELD_LENGTH,
  MAX_TEXT_FIELD_LENGTH,
  MIN_PASSWORD_FIELD_LENGTH,
} from '@lib/enums/form';
import CheckBoxGroup, {
  CheckBoxGroupProps,
} from '@lib/components/CheckBoxGroup/CheckBoxGroup';
import styles from '@lib/layouts/AuthLayout/AuthComponents.module.scss';
import { getOptionShape } from '@lib/utils/yup';
import Trans from '@lib/components/Trans/Trans';
import { FieldItem } from '@lib/interfaces/form';

type SetupPasswordFormFieldsArgs = {
  t: TFunction<'translation', undefined>;
};

export function getSetupPasswordFormFields({
  t,
}: SetupPasswordFormFieldsArgs): FieldItem[] {
  return [
    {
      name: 'email',
      label: t('email-or-username'),
      element: Input,
      validation: Yup.string().required(t('required-field-error')),
      componentProps: {
        disabled: true,
      },
    },
    {
      name: 'password',
      type: InputTypes.password,
      label: t('password'),
      element: Input,
      validation: Yup.string()
        .trim()
        .min(MIN_PASSWORD_FIELD_LENGTH, t('password-field-min-error'))
        .max(MAX_PASSWORD_FIELD_LENGTH, t('password-field-max-error'))
        .required(t('required-field-error')),
      description: t('password-field-description'),
    },
    {
      name: 'passwordConfirmation',
      type: InputTypes.password,
      label: t('confirm-password'),
      element: Input,
      validation: Yup.string()
        .trim()
        .test(
          'passwords-match',
          t('password-field-match-error'),
          // @ts-ignore
          function matchTest(this, value: string): boolean {
            // eslint-disable-next-line react/no-this-in-sfc
            return this.parent.password === value;
          },
        )
        .required(t('required-field-error')),
    },
    {
      name: 'codeOfConductAccepted',
      element: CheckBoxGroup,
      componentProps: {
        options: [
          {
            label: (
              <Trans
                i18nKey="code-of-conduct-accept-label"
                values={{ url: 'https://datizy.com/conditions-dutilisation/' }}
              />
            ),
            value: 'accepted',
          },
        ],
      } as CheckBoxGroupProps,
      validation: Yup.array()
        .of(Yup.object().shape(getOptionShape(t)))
        .required(t('required-field-error-code-of-conduct')),
    },
  ];
}

type ForgotPasswordFormFieldsArgs = {
  t: TFunction<'translation', undefined>;
  completed: boolean;
};

export function getForgotPasswordFormFields({
  t,
  completed,
}: ForgotPasswordFormFieldsArgs): FieldItem[] {
  return [
    {
      name: 'email',
      label: t('email-address'),
      element: Input,
      validation: Yup.string()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .trim()
        .matches(EMAIL_REGEX, t('email-field-error'))
        .required(t('required-field-error')),
      componentProps: completed
        ? {
            rightIcon: 'check_circle',
            inputWrapperClassName: styles.input,
          }
        : undefined,
    },
  ];
}

type LoginFormFieldsArgs = {
  t: TFunction<'translation', undefined>;
};

export function getLoginFormFields({ t }: LoginFormFieldsArgs): FieldItem[] {
  return [
    {
      name: 'login',
      label: t('email-or-username'),
      element: Input,
      validation: Yup.string()
        .max(MAX_TEXT_FIELD_LENGTH, t('max-text-length-field-error'))
        .trim()
        .required(t('required-field-error')),
    },
    {
      name: 'password',
      type: InputTypes.password,
      label: t('password'),
      element: Input,
      validation: Yup.string()
        .trim()
        .min(MIN_PASSWORD_FIELD_LENGTH, t('password-field-min-error'))
        .max(MAX_PASSWORD_FIELD_LENGTH, t('password-field-max-error'))
        .required(t('required-field-error')),
    },
    {
      name: 'forgot-password-link',
      customComponent: ForgotPasswordLink,
    },
  ];
}

type ResetPasswordFormFieldsArgs = {
  t: TFunction<'translation', undefined>;
};

export function getResetPasswordFormFields({
  t,
}: ResetPasswordFormFieldsArgs): FieldItem[] {
  return [
    {
      name: 'password',
      type: InputTypes.password,
      label: t('password'),
      element: Input,
      validation: Yup.string()
        .trim()
        .min(MIN_PASSWORD_FIELD_LENGTH, t('password-field-min-error'))
        .max(MAX_PASSWORD_FIELD_LENGTH, t('password-field-max-error'))
        .required(t('required-field-error')),
      description: t('password-field-description'),
    },
    {
      name: 'passwordConfirmation',
      type: InputTypes.password,
      label: t('confirm-password'),
      element: Input,
      validation: Yup.string()
        .trim()
        .test(
          'passwords-match',
          t('password-field-match-error'),
          // @ts-ignore
          function matchTest(this, value: string): boolean {
            // eslint-disable-next-line react/no-this-in-sfc
            return this.parent.password === value;
          },
        )
        .required(t('required-field-error')),
    },
  ];
}
