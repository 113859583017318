import { RefObject, useEffect } from 'react';

const useOutsideClickCallback = (
  ref: RefObject<HTMLElement>,
  callback: (event: Event) => void,
): void =>
  useEffect(() => {
    const handleClickOutside: EventListener = (event) => {
      if (ref && ref.current && !ref.current.contains(event.target as Node)) {
        callback(event);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, callback]);

export default useOutsideClickCallback;
