import _isObject from 'lodash/isObject';
import _isArray from 'lodash/isArray';
import _every from 'lodash/every';
import _isEmpty from 'lodash/isEmpty';
import { Value, Values } from '@lib/interfaces/form';

function isDeeplyEmpty(value: Values | Value): boolean {
  if (_isObject(value) && !_isArray(value)) {
    return _every(value, (val) => isDeeplyEmpty(val));
  }
  return _isEmpty(value);
}

export default isDeeplyEmpty;
