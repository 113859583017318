import { RouteBuilder } from '@lib/utils/routeBuilder';
import { PATH_AUTH, PATH_SETUP } from '@lib/enums/urls';

export const APP_URLS = {
  root: new RouteBuilder({
    path: '/',
  }),
  auth: {
    index: new RouteBuilder({
      path: `/${PATH_AUTH}`,
    }),
    login: new RouteBuilder({
      path: `/${PATH_AUTH}/login`,
    }),
    forgotPassword: new RouteBuilder({
      path: `/${PATH_AUTH}/forgot-password`,
    }),
    resetPassword: new RouteBuilder({
      path: `/${PATH_AUTH}/reset-password`,
    }),
    logout: new RouteBuilder({
      path: `/${PATH_AUTH}/logout`,
    }),
  },
  setup: {
    index: new RouteBuilder({
      path: `/${PATH_SETUP}`,
    }),
    finishRegistration: new RouteBuilder({
      path: `/${PATH_SETUP}/finish-registration`,
    }),
    confirmEmail: new RouteBuilder({
      path: `/${PATH_SETUP}/confirm-email`,
    }),
    updatePassword: new RouteBuilder({
      path: `/${PATH_SETUP}/update-password`,
    }),
  },
  notFound: new RouteBuilder({
    path: '/not-found',
  }),
};
