export enum ErrorPageStatusEnum {
  Status401 = 401,
  Status404 = 404,
  Status500 = 500,
  Status503 = 503,
  AccessDenied = 'access-denied',
  DesktopOnly = 'desktop-only',
  MobileOnly = 'mobile-only',
  QrCodeScanFailed = 'qr-code-scan-failed',
  QrCodeScanFailedCounter = 'qr-code-scan-failed-counter',
  QrCodeScanFailedOffline = 'qr-code-scan-failed-offline',
}
