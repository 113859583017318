import React, { type ErrorInfo } from 'react';
import ErrorPage from '@lib/components/ErrorPage/ErrorPage';

interface IProps {
  children: React.ReactElement;
  location: { pathname: string; search: string };
  captureException: (error: Error, response: unknown) => void;
}

interface IState {
  error: string | null;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { error: null };
  }

  componentDidUpdate(prevProps: IProps): void {
    this.handleURLChange(prevProps);
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const { captureException } = this.props;
    captureException(error, errorInfo);
    // Display fallback UI
    this.setState({ error: error.message || '' });
  }

  handleURLChange(prevProps: IProps): void {
    const { location: prevLocation } = prevProps;
    const { location: currentLocation } = this.props;
    const { pathname: prevPathname, search: prevSearch } = prevLocation;
    const { pathname: currentPathname, search: currentSearch } =
      currentLocation;

    if (prevPathname !== currentPathname || prevSearch !== currentSearch) {
      this.setState({ error: null });
    }
  }

  render(): React.ReactElement {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return <ErrorPage status={500} />;
    }
    return children;
  }
}

export default ErrorBoundary;
