import React, { memo } from 'react';
import selectStyles from '@lib/utils/selectStyles';
import defaultStyles from './Default.module.scss';

export interface Props {
  color?: string;
  width?: number;
  size?: number;
  padding?: string | number;
  classes?: Record<string, string>;
}

function CircleLoader(props: Props) {
  const {
    color = '#214EEB',
    width = 2,
    size = 24,
    padding = '25px 0',
    classes,
  } = props;
  const styles = selectStyles(defaultStyles, classes);
  return (
    <div
      className={styles.root}
      style={{ padding }}
      data-test-id="circle-loader"
    >
      <svg
        className={styles.spinner}
        style={{ width: size, height: size }}
        viewBox={`0 0 ${size} ${size}`}
      >
        <circle
          className={styles.path}
          cx={size / 2}
          cy={size / 2}
          r={size / 2.5}
          fill="none"
          stroke={color}
          strokeWidth={width}
        />
      </svg>
    </div>
  );
}

export default memo(CircleLoader);
