import qs, { type ParsedQs } from 'qs';

export const getQueryParams = (location = window.location): ParsedQs =>
  qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

type Params = Record<string, string>;
export type DynamicPathParams = {
  pathParts?: Params;
  params?: Params;
  anchor?: string;
};

interface Props {
  path: string;
  anchors?: object;
  defaultParams?: Params | undefined;
}

export class RouteBuilder {
  public path = '';

  public anchors;

  public defaultParams;

  constructor(props: Props) {
    const { path, defaultParams, anchors } = props;
    this.path = path;
    this.anchors = anchors;
    this.defaultParams = defaultParams;
  }

  getQuery = (params = this.defaultParams): string => {
    const newParams = { ...this.defaultParams, ...params };
    return qs.stringify(newParams, { addQueryPrefix: true });
  };

  getPathWithQuery = (params = this.defaultParams, anchor?: string): string => {
    const search = this.getQuery(params);
    const anchorString = typeof anchor === 'string' ? `#${anchor}` : '';
    return `${this.path}${anchorString}${search}`;
  };

  getDynamicPath = ({
    pathParts,
    params,
    anchor,
  }: DynamicPathParams): string => {
    let preparedPath = this.path;
    const pathPartsKeys = pathParts ? Object.keys(pathParts) : [];
    if (pathParts && pathPartsKeys.length > 0) {
      pathPartsKeys.forEach((key) => {
        const pathPartValue = pathParts[key];
        if (pathPartValue !== '') {
          preparedPath = preparedPath.replace(`:${key}`, pathParts[key]);
        }
      });
    }
    const search = this.getQuery(params);
    const anchorString = anchor ? `#${anchor}` : '';
    return `${preparedPath}${search}${anchorString}`;
  };

  isTheSameUrlAs = (url: string, dynamicPathOptions?: object) => {
    return !!(
      this.path === url ||
      (dynamicPathOptions &&
        this.getDynamicPath(dynamicPathOptions).indexOf(url) === 0)
    );
  };
}
