import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from '@lib/components/ErrorBoundary/ErrorBoundary';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import Typography from '@lib/components/Typography/Typography';
import ReleaseTracker from '@lib/components/ReleaseTreacker/ReleaseTracker';
import styles from './ForgotPasswordLayout.module.scss';

const {
  VITE_PLATFORM_URL: domain,
  VITE_RELEASE_VERSION: releaseVersion,
  VITE_RELEASE_TRACKER_SECONDS: releaseTrackerSeconds,
  MODE: mode,
  // @ts-ignore
} = import.meta.env;

interface Props {
  captureException: (error: Error, response?: unknown) => void;
  loginUrl: string;
}

function ForgotPasswordLayout(props: Props): React.ReactElement {
  const { loginUrl, captureException } = props;
  const [completed, setCompleted] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <>
      <ReleaseTracker
        captureException={captureException}
        domain={domain}
        releaseVersion={releaseVersion}
        releaseTrackerSeconds={releaseTrackerSeconds}
        mode={mode}
      />
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.header}>
            <Button
              buttonText={t('back-to-log-in')}
              buttonType={ButtonTypes.quaternaryOutlined}
              to={loginUrl}
              leftIcon="arrow_back"
            />
            <Typography variant="h1">
              {completed ? t('check-email-title') : t('forgot-password-title')}
            </Typography>
            <Typography variant="body">
              <strong>
                {completed ? t('check-email-text') : t('forgot-password-text')}
              </strong>
            </Typography>
          </div>
          <div className={styles.body}>
            <ErrorBoundary
              location={location}
              captureException={captureException}
            >
              <Outlet context={{ completed, setCompleted }} />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordLayout;
