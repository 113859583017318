interface Sizes {
  width: number;
  height: number;
}

export const getDocumentSizes = (): Sizes => {
  const {
    body: { scrollWidth: width, scrollHeight: height },
  } = document;
  return { width, height };
};

export const getWindowSizes = (): Sizes => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};
