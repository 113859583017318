import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Trans from '@lib/components/Trans/Trans';
import DashboardContentMaxWidth from '@lib/layouts/DashboardLayout/DashboardContentMaxWidth/DashboardContentMaxWidth';
import Typography from '@lib/components/Typography/Typography';
import Button, {
  ButtonSizes,
  ButtonTypes,
} from '@lib/components/Button/Button';
import Chip, { ChipTypes } from '@lib/components/Chip/Chip';
import PageBody from '@lib/layouts/DashboardLayout/PageBody/PageBody';
import Logo from '@lib/components/Logo/Logo';
import LogoSmall from '@lib/assets/images/logo-image.svg?react';
import PlatformSwitcher, {
  Platforms,
} from '@lib/components/PlatformSwitcher/PlatformSwitcher';
import { ErrorPageStatusEnum } from './enums';
import styles from './ErrorPage.module.scss';

export interface ErrorPageProps {
  description?: string;
  logoUrl?: string;
  status: ErrorPageStatusEnum;
  btnOnClickHandler?: () => void;
  showBtn?: boolean;
  useSwitcher?: boolean;
}

export default function ErrorPage(props: ErrorPageProps) {
  const {
    status,
    logoUrl,
    description,
    btnOnClickHandler,
    showBtn = true,
    useSwitcher = false,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const contentMap = {
    [ErrorPageStatusEnum.Status401]: {
      chipLabel: t('401-error'),
      title: t('unauthorized-server-error'),
      desc: <Trans i18nKey="unauthorized-server-desc" />,
      btnText: undefined,
      btnLeftIcon: undefined,
      btnOnClick: undefined,
    },
    [ErrorPageStatusEnum.Status404]: {
      chipLabel: t('404-error'),
      title: t('page-not-found'),
      desc: description || <Trans i18nKey="page-not-found-desc" />,
      btnText: t('home-page'),
      btnLeftIcon: 'arrow_back',
      btnOnClick: () => navigate('/'),
    },
    [ErrorPageStatusEnum.Status500]: {
      chipLabel: t('500-error'),
      title: t('internal-server-error'),
      desc: <Trans i18nKey="internal-server-error-desc" />,
      btnText: t('refresh-page'),
      btnLeftIcon: 'refresh',
      btnOnClick: () => navigate(0),
    },
    [ErrorPageStatusEnum.Status503]: {
      chipLabel: undefined,
      title: t('503-error-title'),
      desc: <Trans i18nKey="503-error-desc" />,
      btnText: t('refresh-page'),
      btnLeftIcon: 'refresh',
      btnOnClick: () => navigate(0),
    },
    [ErrorPageStatusEnum.AccessDenied]: {
      chipLabel: t('page-unavailable'),
      title: t('access-denied'),
      desc: <Trans i18nKey="access-denied-text" />,
      btnText: t('home-page'),
      btnLeftIcon: 'arrow_back',
      btnOnClick: () => navigate('/'),
    },
    [ErrorPageStatusEnum.DesktopOnly]: {
      chipLabel: t('page-unavailable'),
      title: t('blocked-ui-desktop-only-title'),
      desc: <Trans i18nKey="blocked-ui-desktop-only-text" />,
      btnText: undefined,
      btnLeftIcon: undefined,
      btnOnClick: undefined,
    },
    [ErrorPageStatusEnum.MobileOnly]: {
      chipLabel: t('page-unavailable'),
      title: t('blocked-ui-mobile-only-title'),
      desc: <Trans i18nKey="blocked-ui-mobile-only-text" />,
      btnText: undefined,
      btnLeftIcon: undefined,
      btnOnClick: undefined,
    },
    [ErrorPageStatusEnum.QrCodeScanFailed]: {
      chipLabel: t('error-qr-code-scan'),
      title: t('error-qr-code-scan-title'),
      desc: <Trans i18nKey="error-qr-code-scan-text" />,
      btnText: t('close'),
      btnLeftIcon: 'close',
      btnOnClick: btnOnClickHandler,
    },
    [ErrorPageStatusEnum.QrCodeScanFailedCounter]: {
      chipLabel: t('error-qr-code-scan'),
      title: t('error-qr-code-scan-title'),
      desc: <Trans i18nKey="error-qr-code-scan-text-counter" />,
      btnText: t('close'),
      btnLeftIcon: 'close',
      btnOnClick: btnOnClickHandler,
    },
    [ErrorPageStatusEnum.QrCodeScanFailedOffline]: {
      chipLabel: t('error-qr-code-scan-offline'),
      title: t('error-qr-code-scan-offline-title'),
      desc: <Trans i18nKey="error-qr-code-scan-offline-text" />,
      btnText: t('close'),
      btnLeftIcon: 'close',
      btnOnClick: btnOnClickHandler,
    },
  };
  const { chipLabel, title, desc, btnText, btnLeftIcon, btnOnClick } =
    contentMap[status];
  const showPlatformSwitcher =
    useSwitcher &&
    (status === ErrorPageStatusEnum.AccessDenied ||
      status === ErrorPageStatusEnum.DesktopOnly);
  return (
    <PageBody className={styles.root} withoutHeader>
      <div className={styles.header}>
        <div className={styles.row}>
          <div className={styles.col}>
            {logoUrl ? (
              <NavLink to={logoUrl} className={styles.logo}>
                <Logo className={styles.logoImage} />
                <LogoSmall className={styles.logoImageSmall} />
              </NavLink>
            ) : (
              <span />
            )}
          </div>
          {showPlatformSwitcher && (
            <div className={styles.col}>
              <PlatformSwitcher
                selected={Platforms.Admin}
                available={Platforms.User}
              />
            </div>
          )}
        </div>
      </div>
      <DashboardContentMaxWidth>
        <div className={styles.wrap}>
          <div>
            <div className={styles.animationContainer}>
              <div className={styles.circle} />
              <div className={styles.rectangle} />
            </div>
          </div>
          <div className={styles.content}>
            {!!chipLabel && (
              <div>
                <Chip type={ChipTypes.chipBlue} label={chipLabel} />
              </div>
            )}
            <Typography variant="h1">{title}</Typography>
            <Typography variant="caption" className={styles.desc}>
              {desc}
            </Typography>
            {showBtn && btnText && (
              <div>
                <Button
                  buttonText={btnText}
                  buttonType={ButtonTypes.primaryFilled}
                  buttonSize={ButtonSizes.large}
                  leftIcon={btnLeftIcon}
                  onClick={btnOnClick}
                  className={styles.btn}
                />
              </div>
            )}
          </div>
        </div>
      </DashboardContentMaxWidth>
    </PageBody>
  );
}
