import React, { memo } from 'react';
import { FieldError } from 'react-hook-form';
import _get from 'lodash/get';
import Typography from '@lib/components/Typography/Typography';
import styles from '@lib/components/ReactHookForm/Form.module.scss';

function ControllerErrorRender({ error }: { error?: FieldError | undefined }) {
  return (
    <>
      {error !== undefined && error.message && (
        <Typography variant="label" className={styles.formItemError} block>
          {error.message}
        </Typography>
      )}
      {error &&
        Object.keys(error).length > 0 &&
        Object.keys(error).map((errorKey) => {
          const errorMessage = _get(error, [errorKey, 'message']);
          if (!errorMessage) return null;
          return (
            <Typography
              variant="label"
              className={styles.formItemError}
              key={errorKey}
              block
            >
              {errorMessage}
            </Typography>
          );
        })}
      {Array.isArray(error) &&
        error.map((errorItem) => {
          if (!errorItem) return null;
          if (errorItem && Object.keys(errorItem).length) {
            return Object.keys(errorItem).map((errorKey) =>
              errorItem[errorKey]?.message ? (
                <Typography
                  variant="label"
                  className={styles.formItemError}
                  key={errorKey}
                  block
                >
                  {errorItem[errorKey].message}
                </Typography>
              ) : null,
            );
          }
          return errorItem?.message ? (
            <Typography
              variant="label"
              className={styles.formItemError}
              key={errorItem.message}
              block
            >
              {errorItem.message}
            </Typography>
          ) : null;
        })}
    </>
  );
}

export default memo(ControllerErrorRender);
