import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { GetUrlParams } from '@lib/enums/urls';
import { Steps } from '@lib/enums/form';

function useStepParam() {
  return useQueryParam(
    GetUrlParams.Step,
    withDefault(NumberParam, Steps.step1),
  );
}

export default useStepParam;
