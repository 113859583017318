import * as Yup from 'yup';
import _isNaN from 'lodash/isNaN';
import { MAX_NUMBER_VALUE } from '@lib/enums/form';

export function numberRangeValidationFn(
  value?: (number | undefined)[] | undefined,
  required?: boolean,
) {
  // Destructure the input value; assign undefined to min and max if value is not provided
  const [min, max] = value || [undefined, undefined];

  // Check if the input is required but not provided correctly
  if (required && (!Array.isArray(value) || value.length !== 2)) return false;

  // Check for the presence of both min and max values when input is required
  if (required && (min === undefined || max === undefined)) return false;

  // Handle cases where input is not required but one of the values is missing
  if (
    !required &&
    ((min === undefined && max !== undefined) ||
      (max === undefined && min !== undefined))
  )
    return false;

  // If both min and max are undefined and input is not required, consider it valid
  if (!required && min === undefined && max === undefined) return true;

  // Validate that min is less than or equal to max when both are defined
  if (min !== undefined && max !== undefined) return min <= max;

  // Default to false for any other unhandled cases
  return false;
}

export function timeRangeValidationFn(
  value?: (string | undefined)[] | undefined,
  required?: boolean,
) {
  // Destructure the input value; assign undefined to min and max if value is not provided
  const [min, max] = value || [undefined, undefined];

  // Check if the input is required but not provided correctly
  if (required && (!Array.isArray(value) || value.length !== 2)) return false;

  // Check for the presence of both min and max values when input is required
  if (required && (min === undefined || max === undefined)) return false;

  // Handle cases where input is not required but one of the values is missing
  if (
    !required &&
    ((min === undefined && max !== undefined) ||
      (max === undefined && min !== undefined))
  )
    return false;

  // If both min and max are undefined and input is not required, consider it valid
  if (!required && min === undefined && max === undefined) return true;

  return !!(min && max);
}

export function rangeRequiredValidationFn(
  value?: (string | number | undefined)[] | undefined,
  required?: boolean,
) {
  if (required) {
    return (
      Array.isArray(value) &&
      value.length === 2 &&
      value.every((v) => v !== undefined && v !== null)
    );
  }
  return true; // If not required, always pass this test
}

export function getOptionShape<T extends (key: string) => string>(
  t: T,
  required?: boolean,
) {
  if (required) {
    return {
      value: Yup.string().required(t('required-field-error')),
      label: Yup.mixed(),
    };
  }
  return {
    value: Yup.string(),
    label: Yup.mixed(),
  };
}

export function getCodeValidation<T extends (key: string) => string>(
  t: T,
  max,
  required?: boolean,
) {
  const schema = Yup.string()
    .trim()
    .max(max, t(`max-text-length-field-error-${max}`))
    .matches(/^[^-]*$/, t('error-hyphen-not-allowed'));
  if (required) {
    return schema
      .min(1, t('required-field-error'))
      .required(t('required-field-error'));
  }
  return schema.nullable();
}

export function getDefaultNumberValidation<T extends (key: string) => string>(
  t: T,
  required?: boolean,
  max?: number,
) {
  let validation = Yup.string()
    .test('is-number', t('number-field-error'), (value) => !_isNaN(value))
    .test('is-min', t('number-field-error-min-1'), (value) => {
      const number = value ? parseFloat(value) : 0;
      return number >= 0;
    });
  if (max) {
    validation = validation.test(
      'is-max',
      t('number-field-error-max-number'),
      (value) => {
        const number = value ? parseFloat(value) : 0;
        return number <= MAX_NUMBER_VALUE;
      },
    );
  }
  if (required) {
    return validation.required(t('required-field-error'));
  }
  return validation.nullable();
}
