import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import _get from 'lodash/get';
import _without from 'lodash/without';
import { ToastContainer } from '@lib/components/Toast/Toast';
import { RoleEnum } from 'graphql-common';
import { APP_URLS } from 'constants/urls';
import { GetUrlParams, PATH_AUTH, PATH_SETUP } from '@lib/enums/urls';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import isMobile from '@lib/utils/isMobile';
import useWhoamiQueryHook from 'utils/fetch/useWhoamiQueryHook';
import { stringifyWithPrefix } from '@lib/utils/url';
import { setSentryTags } from '@lib/utils/sentryHelpers';
import UpdateHtmlLang from '@lib/components/UpdateHtmlLang/UpdateHtmlLang';
import {
  FullStory,
  isInitialized as isFullStoryInitialized,
} from '@fullstory/browser';

const {
  MODE,
  VITE_ADMIN_PLATFORM_URL: ADMIN_PLATFORM,
  VITE_USER_PLATFORM_URL: USER_PLATFORM,
} = import.meta.env;

function ProtectedRoute() {
  const { pathname, search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const dst = urlSearchParams.get(GetUrlParams.Dst) as string;
  const isDst = dst && dst !== '/';

  // Whoami
  const {
    data,
    loading,
    error,
    refetch: refetchWhoami,
    firstLoading,
  } = useWhoamiQueryHook();
  const { phone, tablet } = isMobile();
  const isMobileDevice = phone || tablet;
  const userId = _get(data, 'whoami.resource.id');
  const userEmail = _get(data, 'whoami.resource.email');
  const username = _get(data, 'whoami.resource.username');
  // const deactivatedAt = _get(data, 'whoami.resource.deactivatedAt');
  const userType = _get(data, 'whoami.resource.__typename');
  const setup = _get(data, 'whoami.resource.setup');
  const role = _get(data, 'whoami.resource.role.value');
  const graphQLErrors = _get(error, 'graphQLErrors', []);
  const authError = graphQLErrors.find(
    ({ extensions }) => extensions.code === 'AUTHENTICATION_ERROR',
  );

  const isUserAuth = !authError && !!userId;
  const isAdminBased = role && RoleEnum.FullAdmin === role;
  const pathnameArr = _without(pathname.split('/'), '');
  const pathBase = pathnameArr.length ? pathnameArr[0] : '/';
  const defaultUrlParamsAfterAuth = isDst ? { dst } : undefined;

  let defaultUrlAfterAuth = USER_PLATFORM;
  if (isDst && dst.indexOf(ADMIN_PLATFORM) === 0) {
    defaultUrlAfterAuth = ADMIN_PLATFORM;
  } else if (isDst && dst.indexOf(USER_PLATFORM) === 0) {
    defaultUrlAfterAuth = USER_PLATFORM;
  } else if (isAdminBased && !isDst) {
    defaultUrlAfterAuth = isMobileDevice ? USER_PLATFORM : ADMIN_PLATFORM;
  }
  defaultUrlAfterAuth = `${defaultUrlAfterAuth}/auth`;

  if (MODE !== 'development' && !loading) {
    setSentryTags({ isUserAuth, userId, userEmail, userType, username });

    if (userId && hotjar.initialized()) {
      hotjar.identify(userId, {
        isUserAuth,
        userId,
        userEmail,
        userType,
        username,
      });
    }

    if (userId && isFullStoryInitialized()) {
      FullStory('setProperties', {
        type: 'user',
        properties: {
          isUserAuth,
          userId,
          userEmail,
          userType,
          username,
        },
      });
    }
  }

  useEffect(() => {
    refetchWhoami();
  }, [pathBase, refetchWhoami]);

  if (firstLoading) {
    return <CircleLoader />;
  }

  const appRender = () => (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <UpdateHtmlLang />
      <Outlet />
      <ToastContainer />
    </QueryParamProvider>
  );

  const appNavigate = (to: string, external?: boolean, params?: unknown) => {
    if (external) {
      let url = to;
      if (params) {
        const searchStr = stringifyWithPrefix(params);
        url = `${to}${searchStr}`;
      }
      window.location.href = url;
      return undefined;
    }
    return <Navigate to={to} replace />;
  };

  switch (pathBase) {
    case APP_URLS.root.path:
      if (isUserAuth) {
        return appNavigate(
          defaultUrlAfterAuth,
          true,
          defaultUrlParamsAfterAuth,
        );
      }
      return appNavigate(APP_URLS.auth.login.path);
    case PATH_AUTH:
      if (pathname !== APP_URLS.auth.resetPassword.path) {
        if (setup && isUserAuth) {
          return appNavigate(
            defaultUrlAfterAuth,
            true,
            defaultUrlParamsAfterAuth,
          );
        }
        if (!setup && isUserAuth) {
          return appNavigate(APP_URLS.setup.updatePassword.path);
        }
      }
      return appRender();
    case PATH_SETUP:
      if (pathname.indexOf(APP_URLS.setup.finishRegistration.path) > -1) {
        return appRender();
      }
      if (pathname.indexOf(APP_URLS.setup.confirmEmail.path) > -1) {
        return appRender();
      }
      if (setup && isUserAuth) {
        return appNavigate(
          defaultUrlAfterAuth,
          true,
          defaultUrlParamsAfterAuth,
        );
      }
      if (
        !isUserAuth &&
        pathname.indexOf(APP_URLS.setup.updatePassword.path) > -1
      ) {
        return appNavigate(APP_URLS.auth.login.path);
      }
      return appRender();
    default:
      return appRender();
  }
}

export default ProtectedRoute;
