import _isObject from 'lodash/isObject';
import _isEqual from 'lodash/isEqual';
import _isEqualWith from 'lodash/isEqualWith';
import { Values } from '@lib/interfaces/form';

export default function isEqualWithSkipKeys(
  object1: Values | undefined = {},
  object2: Values | undefined = {},
  skipKeys: string[] = [],
): boolean {
  // Custom comparator function for _isEqualWith
  function customComparator(
    objValue: unknown,
    othValue: unknown,
    key?: string | number | symbol,
    // object: unknown,
    // other: unknown,
    // stack: _Stack,
  ): boolean | undefined {
    // If we're at a key that should be skipped, return true to ignore this part of the comparison
    if (key && typeof key === 'string' && skipKeys.includes(key)) {
      return true;
    }

    // If both values are objects, continue the comparison recursively,
    // allowing nested fields to be skipped as specified.
    if (_isObject(objValue) && _isObject(othValue)) {
      // Explicitly returning undefined makes lodash continue with its default deep comparison
      // but with our custom comparator applied to each level.
      return undefined;
    }

    // For non-object values or if the objects are not the same type, let lodash handle the comparison
    // If objValue and othValue are not objects, _isEqualWith doesn't automatically fall back to a basic comparison,
    // so we perform this manually using ==.
    return _isEqual(objValue, othValue) ? true : undefined;
  }

  // Use _isEqualWith for the comparison, providing our custom comparator
  return _isEqualWith(object1, object2, customComparator);
}
