import { FieldErrors, FieldError, FieldValues } from 'react-hook-form';
import { FieldItem } from '@lib/interfaces/form';
import { Steps } from '@lib/enums/form';

const getErrorsByStep = (
  fields: FieldItem[],
  errors: FieldErrors<FieldValues>,
): Record<number, FieldError[]> => {
  const errorsByStep: Record<number, FieldError[]> = {};

  const addErrorToStep = (step: number, error: FieldError) => {
    if (!errorsByStep[step]) {
      errorsByStep[step] = [];
    }
    errorsByStep[step].push(error);
  };

  const processError = (
    step?: Steps,
    fieldError?: FieldError | FieldError[],
  ) => {
    if (step && fieldError) {
      if (Array.isArray(fieldError)) {
        fieldError.forEach((nestedError) => processError(step, nestedError));
      } else if (typeof fieldError === 'object') {
        // If the error object itself could contain nested errors, recursively process them
        if ('message' in fieldError && 'type' in fieldError) {
          // It's a FieldError object
          addErrorToStep(step, fieldError);
        } else {
          // If it's not a direct FieldError, it might be an object with nested FieldError(s)
          Object.values(fieldError).forEach((nestedError) =>
            processError(step, nestedError as FieldError),
          );
        }
      }
    }
  };

  const processFieldError = (field: FieldItem) => {
    const { step, name } = field;
    // Directly accessing the error for the field; if it's nested, processError will handle it
    const fieldError = errors[name];
    processError(step, fieldError as FieldError);
  };

  fields.forEach((field) => {
    if (Array.isArray(field.groupFields)) {
      field.groupFields.forEach(processFieldError);
    } else {
      processFieldError(field);
    }
  });

  return errorsByStep;
};

export default getErrorsByStep;
