import { useCallback } from 'react';
import { Values } from '@lib/interfaces/form';

interface UseFormPersistValuesProps {
  formId: string;
  persistForm: boolean;
  persistFormSeconds?: number;
}

const useFormPersistValues = ({
  formId,
  persistForm,
  persistFormSeconds = 0,
}: UseFormPersistValuesProps) => {
  const setPersistedValues = useCallback(
    (values: Values) => {
      if (persistForm) {
        const dataToPersist = {
          values,
          timestamp: new Date().getTime(),
        };
        localStorage.setItem(formId, JSON.stringify(dataToPersist));
      }
    },
    [formId, persistForm],
  );

  const getPersistedValues = (): Values => {
    if (!persistForm) return {};
    const persistedString = localStorage.getItem(formId);
    if (persistedString) {
      const persistedData = JSON.parse(persistedString);
      const currentTime = new Date().getTime();
      const timeElapsed = (currentTime - persistedData.timestamp) / 1000;

      if (!persistFormSeconds || timeElapsed <= persistFormSeconds) {
        return persistedData.values;
      }
      // Time expired, remove from storage
      localStorage.removeItem(formId);
    }
    return {};
  };

  return { setPersistedValues, getPersistedValues };
};

export default useFormPersistValues;
