import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ScopeEnum,
  useAuthenticationConfirmationsEmailConfirmationMutation,
  useAuthenticationConfirmationsRegistrationConfirmationMutation,
} from 'graphql-common';
import { useTranslation } from 'react-i18next';
import toast from '@lib/components/Toast/Toast';
import CircleLoader from '@lib/components/CircleLoader/CircleLoader';
import { APP_URLS } from 'constants/urls';
import Modal from '@lib/components/Modal/Modal';
import Button, { ButtonTypes } from '@lib/components/Button/Button';
import useWhoamiQueryHook from 'utils/fetch/useWhoamiQueryHook';
import { IconName } from '@lib/components/Modal/enums';

let firstMount = false;

function FinishRegistration() {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(search);
  const confirmationToken = urlSearchParams.get('token') as string;

  const [isModalOpened, setModalOpened] = useState(false);

  const { currentUserUnconfirmedEmail, refetch: refetchWhoami } =
    useWhoamiQueryHook({
      fetchPolicy: 'cache-first',
    });

  const openModal = () => setModalOpened(true);

  const isConfirmEmailFlow =
    !!currentUserUnconfirmedEmail ||
    pathname === APP_URLS.setup.confirmEmail.path;

  const handleComplete = () => {
    refetchWhoami().then(() => {
      navigate(APP_URLS.root.path, { replace: true });
    });
  };

  const [emailConfirmationMutation] =
    useAuthenticationConfirmationsEmailConfirmationMutation({
      onCompleted: () => {
        openModal();
      },
      onError: (error) => {
        toast({ content: error.message || 'Oops! Something went wrong' });
        setTimeout(() => {
          navigate(APP_URLS.auth.login.path);
        }, 2000);
      },
    });

  const [registrationConfirmationMutation] =
    useAuthenticationConfirmationsRegistrationConfirmationMutation({
      onCompleted: () => {
        handleComplete();
      },
      onError: (error) => {
        toast({ content: error.message || 'Oops! Something went wrong' });
        setTimeout(() => {
          navigate(APP_URLS.auth.login.path);
        }, 2000);
      },
    });

  const getModalTitle = () => {
    if (isConfirmEmailFlow) return t('email-successfully-confirmed-title');
    return 'Oops! Something went wrong';
  };

  const getModalText = () => {
    if (isConfirmEmailFlow) return t('email-successfully-confirmed-text');
    return undefined;
  };

  useEffect(() => {
    if (!firstMount) {
      if (isConfirmEmailFlow) {
        emailConfirmationMutation({
          variables: {
            input: { token: confirmationToken, scope: ScopeEnum.User },
          },
        });
      } else {
        registrationConfirmationMutation({
          variables: {
            input: { token: confirmationToken, scope: ScopeEnum.User },
          },
        });
      }
    }

    return () => {
      firstMount = true;
    };
  }, [
    confirmationToken,
    emailConfirmationMutation,
    isConfirmEmailFlow,
    registrationConfirmationMutation,
  ]);

  return (
    <>
      {!isModalOpened && <CircleLoader />}
      <Modal
        isOpen={isModalOpened}
        title={getModalTitle()}
        description={getModalText()}
        icon={IconName.Thanks}
        onClose={handleComplete}
        actions={
          <Button
            onClick={handleComplete}
            buttonType={ButtonTypes.primaryFilled}
            buttonText={t('thanks')}
            leftIcon="check"
            fullWidth
          />
        }
      />
    </>
  );
}

export default FinishRegistration;
