import {
  addDays,
  addMonths,
  addSeconds,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  fromUnixTime,
  isBefore,
  isValid,
  parse,
  parseISO,
  startOfDay,
  subDays,
  subMonths,
  subSeconds,
  subWeeks,
  subYears,
} from 'date-fns';
import {
  format as formatTz,
  utcToZonedTime,
  zonedTimeToUtc,
} from 'date-fns-tz';
import { enUS, fr } from 'date-fns/locale';
import {
  dateFormat,
  timePickerDateFormat,
  timePickerDateFullFormat,
} from '@lib/enums/dateTime';

export type DateArg = string | null | undefined | Date;

export const CET = 'Europe/Paris';

export function isTimestampExpired(timestamp: number | string): boolean {
  const now = new Date();
  const timestampDate = fromUnixTime(Number(timestamp));
  return isBefore(timestampDate, now);
}

export function getLastTimeFromArray(dates?: string[]) {
  if (!dates) {
    return null;
  }

  const validDates = dates
    .map((date) => parseISO(date))
    .filter((date) => isValid(date));
  if (validDates.length === 0) {
    return null;
  }

  return validDates.reduce((acc, currentDate) =>
    isBefore(acc, currentDate) ? currentDate : acc,
  );
}

export function toIsoStringWithMicroseconds(date: Date): string {
  if (!date) return date;
  // Convert date to CET before formatting
  const cetDate = utcToZonedTime(date, CET);
  let isoString = cetDate.toISOString();
  const parts = isoString.split('.');
  const milliseconds = parts[1].substring(0, 3);
  let secondPartOfMilliseconds = '000';
  if (milliseconds === '999') {
    secondPartOfMilliseconds = '999';
  }
  // Construct ISO string with CET time and artificial microseconds
  isoString = `${parts[0]}.${milliseconds}${secondPartOfMilliseconds}Z`;
  return isoString;
}

export function formatTimeToISODateString(timeString: string): string {
  if (!timeString) return timeString;
  const [hours = 0, minutes = 0, seconds = 0] = timeString
    .split(':')
    .map(Number);
  // Create a date with the current date but specified time
  const now = new Date();
  now.setHours(hours, minutes, seconds, 0);
  // Convert this time to CET before formatting
  const cetDate = zonedTimeToUtc(now, CET); // Note: This converts the local time to equivalent UTC time for CET zone
  return toIsoStringWithMicroseconds(cetDate);
}

export function transformDate(
  date: Date,
  setEndDate = false,
  setStartDate = false,
): string {
  let newDate = date;
  if (setEndDate) newDate = endOfDay(date);
  if (setStartDate) newDate = startOfDay(date);
  const formatPattern = setEndDate
    ? "yyyy-MM-dd'T'HH:mm:ss.SSS'999'XXX"
    : "yyyy-MM-dd'T'HH:mm:ss.SSS'000'XXX";
  // Format date in CET
  return formatTz(newDate, formatPattern, { timeZone: CET });
}

export function revertTransformDate(value: string) {
  return utcToZonedTime(parseISO(value), CET);
}

export function getFormattedDate(
  value?: DateArg,
  customFormat?: string,
  locale?: string,
) {
  if (!value) return null;
  const date = typeof value === 'string' ? revertTransformDate(value) : value;
  return formatTz(date, customFormat || dateFormat, {
    timeZone: CET,
    locale: locale === 'en' ? enUS : fr,
  });
}

export function subYearsFn(value: DateArg, amount: number) {
  if (!value) return undefined;
  const date = typeof value === 'string' ? revertTransformDate(value) : value;
  return endOfMonth(subYears(date, amount));
}

export function subMonthsFn(value: DateArg, amount: number) {
  if (!value) return undefined;
  const date = typeof value === 'string' ? revertTransformDate(value) : value;
  return endOfMonth(subMonths(date, amount));
}

export function subWeeksFn(value: DateArg, amount: number) {
  if (!value) return undefined;
  const date = typeof value === 'string' ? revertTransformDate(value) : value;
  return endOfWeek(subWeeks(date, amount), { weekStartsOn: 1 });
}

export function subDaysFn(value: DateArg, amount: number) {
  if (!value) return undefined;
  const date = typeof value === 'string' ? revertTransformDate(value) : value;
  return subDays(date, amount);
}

export function subSecondsFn(value: DateArg, amount: number) {
  if (!value) return undefined;
  const date = typeof value === 'string' ? revertTransformDate(value) : value;
  return subSeconds(date, amount);
}

export function addYearsFn(value: DateArg, amount: number) {
  if (!value) return undefined;
  const date = typeof value === 'string' ? revertTransformDate(value) : value;
  return endOfMonth(addYears(date, amount));
}

export function addMonthsFn(value: DateArg, amount: number) {
  if (!value) return undefined;
  const date = typeof value === 'string' ? revertTransformDate(value) : value;
  return endOfMonth(addMonths(date, amount));
}

export function addWeeksFn(value: DateArg, amount: number) {
  if (!value) return undefined;
  const date = typeof value === 'string' ? revertTransformDate(value) : value;
  return endOfWeek(addWeeks(date, amount), { weekStartsOn: 1 });
}

export function addDaysFn(value: DateArg, amount: number) {
  if (!value) return undefined;
  const date = typeof value === 'string' ? revertTransformDate(value) : value;
  return addDays(date, amount);
}

export function addSecondsFn(value: DateArg, amount: number) {
  if (!value) return undefined;
  const date = typeof value === 'string' ? revertTransformDate(value) : value;
  return addSeconds(date, amount);
}

export function startOfDayFn(value: DateArg) {
  if (!value) return undefined;
  const date = typeof value === 'string' ? revertTransformDate(value) : value;
  return startOfDay(date);
}

export function endOfDayFn(value: DateArg) {
  if (!value) return undefined;
  const date = typeof value === 'string' ? revertTransformDate(value) : value;
  return endOfDay(date);
}

/**
 * Converts a time string to HH:mm format
 * @param time
 * @returns A string in HH:mm format
 */
export function convertTimeToHHmm(time?: unknown): string | undefined {
  try {
    if (typeof time === 'string' && !!time) {
      const parsedTime = parse(time, timePickerDateFullFormat, new Date());
      return format(parsedTime, timePickerDateFormat);
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
}

/**
 * Converts a decimal time value to `hh:mm` format.
 * @param decimalValue - The decimal time value to convert (e.g., 10.10).
 * @returns The formatted time string in `hh:mm` format.
 */
export function convertDecimalHoursToTime(decimalValue: number): string {
  const isNegative = decimalValue < 0;
  const absoluteValue = Math.abs(decimalValue);

  const hours = Math.floor(absoluteValue);
  const minutes = Math.round((absoluteValue - hours) * 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return isNegative
    ? `-${formattedHours}:${formattedMinutes}`
    : `${formattedHours}:${formattedMinutes}`;
}

/**
 * Converts a decimal time value in minutes to `mm:ss` format.
 * @param decimalValue - The decimal time value to convert (e.g., 10.10).
 * @returns The formatted time string in `mm:ss` format.
 */
export function convertDecimalMinutesToTime(decimalValue: number): string {
  const isNegative = decimalValue < 0;
  const absoluteValue = Math.abs(decimalValue);

  const minutes = Math.floor(absoluteValue);
  const seconds = Math.round((absoluteValue - minutes) * 60);

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return isNegative
    ? `-${formattedMinutes}:${formattedSeconds}`
    : `${formattedMinutes}:${formattedSeconds}`;
}

/**
 * Converts a decimal time value in seconds to `ss:milliseconds` format.
 * @param decimalValue - The decimal time value to convert (e.g., 10.10).
 * @returns The formatted time string in `ss:milliseconds` format.
 */
export function convertDecimalSecondsToTime(decimalValue: number): string {
  const isNegative = decimalValue < 0;
  const absoluteValue = Math.abs(decimalValue);

  const seconds = Math.floor(absoluteValue);
  const milliseconds = Math.round((absoluteValue - seconds) * 1000);

  const formattedSeconds = String(seconds).padStart(2, '0');
  const formattedMilliseconds = String(milliseconds).padStart(3, '0'); // Ensure 3 digits for milliseconds

  return isNegative
    ? `-${formattedSeconds}:${formattedMilliseconds}`
    : `${formattedSeconds}:${formattedMilliseconds}`;
}
